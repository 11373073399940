import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeArrow = (componentStyles) => `
  .jme-arrow {
    background: #7070709e;
    opacity: 0.4;
    transition: 0.3s;
    position: absolute;
    bottom: 0%;
    font-size: 3rem;
    color: white;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-arrow:hover {
    background: #707070e0;
    opacity: 1;
  }
  .right-up-round {
    border-radius: 0px 8px 0px 0px;
  }
  .left-up-round {
    border-radius: 8px 0px 0px 0px;
  }
  .left-down-round {
    border-radius: 0px 0px 0px 8px;
  }
  .right-down-round {
    border-radius: 0px 0px 8px 0px;
  }
  .up {
    top: 0%;
  }
  .down {
    bottom: 0%;
  }
  .right {
    right: 0px;
  }
  .left {
    left: 0px;
  }
  .jme-side-arrow {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 10;
  }

  .jme-tagCaourselArrow-left {
    top: 50%;
    background: #FCFCFC;
    left:-15px;
    color: #0BA1E2;
    border-radius: 50%;
    opacity:0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, -50%);
  }
  .jme-tagCaourselArrow-right {
    top: 50%;
    right:-15px;
    background: #FCFCFC;
    color: #0BA1E2;
    border-radius: 50%;
    opacity:0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, -50%);
  }
  
  .jme-tagCaourselArrow-left:hover {
    background: #FCFCFC !important;
    opacity: 1 !important;
  }

  .jme-tagCaourselArrow-right:hover {
    background: #FCFCFC !important;
    opacity: 1 !important;
  }

  .arrow-left-edge {
    left: -40px;
  }
  .arrow-right-edge {
    right: -40px;
  }
  .arrow-left-center {
    top: 50%;
    background: #FCFCFC;
    color: #0BA1E2;
    border-radius: 50%;
    opacity:0.8;
    border: 1px solid rgba(0,0,0,.125);
    margin-left:1rem;
    padding:1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(0, -50%);
  }
  .arrow-right-center {
    top: 50%;
    background: #FCFCFC;
    color: #0BA1E2;
    border-radius: 50%;
    opacity:0.8;
    border: 1px solid rgba(0,0,0,.125);
    margin-right:1rem;
    padding:1rem;
    align-items: center;
    justify-content: center;
    transform: translate(0, -50%);
  }
  .arrow-right-center:hover {
    background: #232470 !important;
    color: #FCFCFC !important;
    opacity: 1 !important;
  }
  .arrow-left-center:hover {
    background: #232470 !important;
    color:#FCFCFC !important;
    opacity: 1 !important;
  }
`;

export default stylesJmeArrow;
