import PropTypes from 'prop-types';
import { stylesPropType, vmPropTypes } from '../../global-prop-types';

const JmeDivider = ({
  styles,
  linearGradientInitColor,
  linearGradientEndColor,
  type,
}) => (
  <hr
    className={type === 'vertical' ? 'jme-divider-vertical' : 'jme-divider-wrapper'}
    style={{
      background: (linearGradientInitColor && linearGradientEndColor)
        && `linear-gradient(to right, ${linearGradientInitColor}, ${linearGradientEndColor}`,
      ...styles?.wrapper,
    }}
  />
);

JmeDivider.propTypes = {
  styles: stylesPropType,
  type: PropTypes.string,
  linearGradientInitColor: PropTypes.string,
  linearGradientEndColor: PropTypes.string,
};

JmeDivider.defaultProps = {
  styles: { wrapper: {} },
  type: 'vertical',
  linearGradientInitColor: '#000',
  linearGradientEndColor: 'transparent',
};

JmeDivider.vmPreview = {
  styles: { wrapper: { width: '50%' } }
};

JmeDivider.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  type: vmPropTypes.string,
  linearGradientInitColor: vmPropTypes.string,
  linearGradientEndColor: vmPropTypes.string,
};

export default JmeDivider;
