import { isArr, isNumber } from '../../../globalUtils';
import { parsePost } from '../groupposts/grouppostUtils';
import { apiGetQuestionsets } from './questionsetsApi';

export const enrichWithQuestionsets = (post) => {
  const parsedPost = parsePost(post);
  const questionsetIds = parsedPost?.comment?.body?.text?.questionsetsId;
  if (!questionsetIds) return post;
  return apiGetQuestionsets({ questionsetIds }).then((res) => ({
    ...parsedPost,
    questionsets: res.data
  }));
};

export const isAlreadyAnswered = (answers) => (isArr(answers) ? answers?.some((answer) => answer?.user_answered) : answers);
export const getAllAnswersCount = (answers) => {
  let sum = 0;
  if (isArr(answers)) answers.forEach((el) => { sum += el?.selected_count; });
  return sum;
};

export const generatePercentage = (answers, numOfSelectedAnswer) => {
  if (isArr(answers) && isNumber(numOfSelectedAnswer)) {
    return (numOfSelectedAnswer / getAllAnswersCount(answers)) * 100;
  }
  return 0;
};
