import { useMemo } from 'react';
import { MODULE_NAME } from './constants';
import {
  useIsActive as useIsActiveModules,
  useSettings as useSettingsModules
} from '../../hooks';
import { isPagePreview } from './utils';
import { pagePreviewSchema } from './schema';

export const useIsActive = () => useIsActiveModules(MODULE_NAME);

export const useConfig = () => {
  const settings = useSettingsModules(MODULE_NAME);
  const config = useMemo(() => pagePreviewSchema.parse(settings || {}), []);
  return config;
};

export const useIfHideAppTop = () => {
  const { enableAppTop, ...config } = useConfig();

  if (enableAppTop) return () => true;
  return isPagePreview(config);
};

export const useIfHideAppFooter = () => {
  const { enableAppFooter, ...config } = useConfig();

  if (enableAppFooter) return () => true;
  return isPagePreview(config);
};
