import {
  isPast,
  parseISO,
  formatDistanceToNow as formatDistanceToNowFns
} from 'date-fns';

import localeList from './localeList';
import store from '../../../global-state/redux';
import { vmTypes } from '../../../global-prop-types';
import { isDate, isStr } from '../../globalUtils';

const { getState } = store;

export const nowInMs = () => Date.now();
nowInMs.vmFunctionMetaData = {
  returnVmType: vmTypes.number,
  argsInfo: []
};

export const setWindowTimer = (timerName) => {
  if (!window.timers) {
    window.timers = {};
    window.timers[timerName] = Date.now();
  }
  window.timers[timerName] = Date.now();
};

export const getWindowTimer = (timerName) => {
  if (!window.timers[timerName]) return null;
  return window.timers[timerName];
};

// be carefull with using this function in apiDataInfo, becaue it includes mili seconds
// that means each time page/component uses useApiData it generate new storeMask and the
// API call cashing passes call trhougt. SO the page willmake as many calls as it renders
// what can be >20 times :)
export const nowInIso = () => new Date().toISOString();

export const isDateInThePast = (timestamp) => isPast(parseISO(timestamp));

export const isToday = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  return (
    date.getDate() === now.getDate()
    && date.getMonth() === now.getMonth()
    && date.getFullYear() === now.getFullYear()
  );
};
isToday.vmFunctionMetaData = {
  returnVmType: vmTypes.boolean,
  argsInfo: [{ uiTitle: 'Timestamp', vmType: vmTypes.timestamp }]
};

export const isDateAfterNow = (date) => {
  // date should be a string as we get it from back
  if (!date) return false;

  // Converting the date and time into number e.g 202203081646745053000
  const givenDate = new Date(date).getTime();

  return Date.now() < givenDate;
};

export const isMidnight = (timestamp, timezone) => {
  if (!isDate(timestamp)) {
    return false;
  }

  let ret;
  // @try/catch - the Intl.supportedValuesOf is not always impemented (eg. nodee < v18), so we use old good try/catch
  //   to test for correct timeZone strings
  try {
    ret = new Date(timestamp).toLocaleTimeString([], {
      timeZone: timezone,
      hourCycle: 'h23' // this is important, otherwise some locale can return AM/PM suffix
    }) === '00:00:00';
  } catch {
    ret = false;
  }

  return ret;
};
isMidnight.vmFunctionMetaData = {
  returnVmType: vmTypes.boolean,
  argsInfo: [
    { uiTitle: 'timetamp', vmType: vmTypes.timestamp },
    { uiTitle: 'timezone', vmType: vmTypes.timezone }
  ]
};

export const dateFormatter = (timestamp, dateTimeFormat = {}) => {
  const { appTimezone, currentLocale } = getState().user ?? {};

  const dateTimeFormatNew = { ...dateTimeFormat, timeZone: appTimezone };
  const dtFormatter = new Intl.DateTimeFormat(
    currentLocale,
    dateTimeFormatNew
  );
  let timestampFormatted;
  try {
    timestampFormatted = dtFormatter.format(
      timestamp ? new Date(timestamp) : new Date()
    );
  } catch (e) {
    return null;
  }
  return timestampFormatted;
};
dateFormatter.vmFunctionMetaData = {
  argsInfo: [
    { uiTitle: 'timestamp', vmType: { type: vmTypes.string } },
    { uiTitle: 'dateTimeFormat', vmType: { type: vmTypes.object } }
  ],
  returnVmType: vmTypes.string
};

export const getTimeInMilliseconds = (dateStr) => (isDate(dateStr) ? new Date(dateStr).getTime() : null);

export const getTimeInSeconds = (dateStr) => (isDate(dateStr) ? new Date(dateStr).getTime() / 1000 : null);

export const formatDistanceToNow = (dateStr, optionsArgs) => {
  const date = isStr(dateStr) ? parseISO(dateStr) : dateStr;
  const { currentLocale } = getState().user;

  const locale = localeList[currentLocale];
  let options = {};
  if (locale) options = { locale, ...optionsArgs };
  try {
    return formatDistanceToNowFns(date, options);
  } catch {
    // do not wory about error
    return '';
  }
};
