import PropTypes from 'prop-types';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useSelector } from 'react-redux';
import { postToContainer } from '../../global-utils/HrefUtils';
import { useDispatchVmEventList } from '../../global-utils';
import { stylesPropType, vmPropTypes, componentsPropType } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import vmFunctions from '../../global-utils/vmFunctions';
// import storageAndCookie from '../../global-utils/storage-and-cookies';

const options = ['_blank', '_parent', '_self', '_top'];

const ExternalHref = (props) => {
  const dispatchVmEventList = useDispatchVmEventList();
  const {
    href, styles, variant, vmOnClick, target, ...restProps
  } = props;
  const matomoModule = useSelector((state) => state?.appState?.settings?.modules?.matomoModule);
  const { trackEvent } = useMatomo();

  if (!href) return null;

  const handleClick = (e) => {
    if (matomoModule?.settings?.isActive) {
      trackEvent({ category: href, action: 'click-event-external' }); // Matomo
    }
    postToContainer(e, href);
    const dataBank = { vmFunctions, props };
    dispatchVmEventList(vmOnClick, dataBank);
  };

  const validateTarget = () => {
    if (!options.includes(target)) {
      return '_blank';
    }
    return target;
  };

  return (
    <a
      href={href}
      target={validateTarget()}
      rel="noreferrer"
      className={`externalHref-wrapper ${variant || ''}`}
      style={styles?.wrapper}
      onClick={handleClick}
    >
      <ComponentsArrRenderer {...restProps} />
    </a>
  );
};

ExternalHref.propTypes = {
  variant: PropTypes.string,
  target: PropTypes.oneOf(options),
  href: PropTypes.string,
  components: componentsPropType,
  styles: stylesPropType,
  vmOnClick: PropTypes.array,
};

ExternalHref.defaultProps = {
  variant: '',
  target: '_blank',
  href: '',
  components: [],
  styles: { wrapper: {} }
};

ExternalHref.vmPreview = {
  href: '/',
  components: [
    {
      componentName: 'JmeText',
      text: 'External Href Example Text'
    }
  ]
};

ExternalHref.vmPropTypes = {
  variant: vmPropTypes.className,
  target: vmPropTypes.string,
  href: vmPropTypes.url,
  components: vmPropTypes.components,
  styles: { wrapper: vmPropTypes.styles },
  vmOnClick: vmPropTypes.array
};

export default ExternalHref;
