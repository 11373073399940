import { componentStylesToCss } from '../componentStylesUtils';

const stylesSplashScreen = (componentStyles) => `
  .splashScreen-wrapper {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .splashScreen-appLogo {
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 50px;
    ${componentStylesToCss(componentStyles?.appLogo)}
  }
  .splashScreen-subHeadline {
    margin-bottom: 50px;
    color: #032D41;
    ${componentStylesToCss(componentStyles?.subHeadline)}
  }
  .splashScreen-footer {
    max-width: 100px;
    max-height: 100px;
    margin-top: 200px;
    ${componentStylesToCss(componentStyles?.footer)}
  }
`;

export default stylesSplashScreen;
