import { getArrayVmTypeOf, vmTypes } from '../../../global-prop-types/vmTypes';
import { mapData } from '../../dataMapping';
import { isArr, isObj } from '../../globalUtils';
import { vmTreeSet } from '../../vmTree';
import vmFunctions from '..';

export { isArr }; // expose this function as vmFunction

export const map = (arr, func = (el) => el) => (isArr(arr) ? arr.map(func) : []);
map.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'Map function', vmType: vmTypes.func }
  ]
};

export const slice = (arr, a, b) => (isArr(arr) ? arr.slice(a, b) : []);
slice.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'Slice from', vmType: vmTypes.number },
    { uiTitle: 'Slice to', vmType: vmTypes.number }
  ]
};

export const pop = (arr) => (isArr(arr) ? arr.pop() : arr);
pop.vmFunctionMetaData = {
  returnType: vmTypes.any,
  argsInfo: [{ uiTitle: 'Input Array', vmType: vmTypes.array }]
};
export const someValue = (arr, val) => arr?.some((el) => el === val);
someValue.vmFunctionMetaData = {
  returnType: vmTypes.boolean,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'value to check', vmType: vmTypes.any }
  ]
};
export const some = (arr, func = (el) => el) => (isArr(arr) ? arr.some(func) : false);
some.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'Test function', vmType: vmTypes.func }
  ]
};

export const every = (arr, func = (el) => el) => (isArr(arr) ? arr.every(func) : false);
every.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'Test function', vmType: vmTypes.func }
  ]
};

export const includes = (arr, el) => (isArr(arr) ? arr.includes(el) : arr);
includes.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'Element', vmType: vmTypes.any }
  ]
};

export const filter = (arr, func = (el) => !!el) => (isArr(arr) ? arr.filter(func) : arr);
filter.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'Test function', vmType: vmTypes.func }
  ]
};

export const join = (arr, separator) => (isArr(arr) ? arr.join(separator) : '');
join.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'Separator', vmType: vmTypes.string }
  ]
};

export const concat = (...args) => args.reduce((acc, el) => (isArr(el) ? [...acc, ...el] : acc), []);
concat.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Input Array', vmType: vmTypes.array },
    { uiTitle: 'List of elements', vmType: vmTypes.any }
  ]
};

export const length = (arr) => (isArr(arr) ? arr.length : 0);
length.vmFunctionMetaData = {
  returnType: vmTypes.array,
  argsInfo: [{ uiTitle: 'Input Array', vmType: vmTypes.array }]
};

export const isStrInArr = (arr, str) => arr.includes(str);
export const isKeyInArrNull = (arr, key, defaultValue) => {
  if (!isArr(arr)) return defaultValue;
  let foundValue = defaultValue;
  arr.every((el) => {
    if (el[key]) {
      foundValue = el[key];
      return false;
    }

    return true;
  });
  return foundValue;
};
export const removePastDates = (arr) => {
  // this is use for news call (that have ends_at key in the obj)
  if (!isArr(arr)) return [];
  const now = Date.now();
  // TODO: this is expected to return exception if the el has a wrong string format!
  return arr?.filter((el) => now <= new Date(el).getTime());
};

export const filterBasedOnId = (arr, id, negate = false) => (isArr
  ? arr?.filter((el) => (negate ? el.id !== parseInt(id, 10) : el.id === parseInt(id, 10)))
  : []);
export const filterBasedOnAny = (arr, key, value) => (isArr(arr) ? arr?.filter((el) => el?.[key] === value) : []);

/** allow for mapping elements of an array
 * @description We use our standard mapping procedure: `mapData`. Also, I filter
 * out undefined elements from the array just to prevent unnecesery crashing.
 */
export const mapArrayElements = (arr, dataMap) => {
  if (!isArr(arr) || !isObj(dataMap)) return [];
  return arr
    .map((el) => mapData(
      { el, vmFunctions },
      // @callbackFn - the dataMap.vmType of the second argument passed to mapArrayElements uses vmTypes.mapArrayElements.
      // REASON: the mapData starts with leafes, that means, by the time the mapArrayElements dataMap is processed,
      // where we pass element {el} to dataObj, we have values already replaced with 'undefined' (coz dataObj.el do not exist).
      // To prevent dataMap from evaluating mapArrayElements dataMap, we give id distinct vmType: vmTypes.mapArrayElements,
      // that we replace with vmTypes.dataMapping only when we are ready to process this map.
      // NOTE: vmTreeMap goes deep into the tree however we need to check only the first
      //   We could vrite: vmTreeMap(dataMap, (el2) => (el2 === vmTypes.mapArrayElements ? vmTypes.dataMapping : el2))
      //   hoever if vmPath would contain response from API, it would mean traversing whole lengthy API response.
      dataMap?.vmType === vmTypes.mapArrayElements
        ? { ...dataMap, vmType: vmTypes.dataMapping }
        : Object.keys(dataMap).reduce((acc, dataMapKey) => {
          if (acc[dataMapKey]?.vmType === vmTypes.mapArrayElements) {
            return vmTreeSet(
              acc,
              [dataMapKey, 'vmType'],
              vmTypes.dataMapping
            );
          }
          return acc;
        }, dataMap)
    ))
    .filter((el) => el !== undefined);
};
mapArrayElements.vmFunctionMetaData = {
  returnVmType: getArrayVmTypeOf(vmTypes.any),
  argsInfo: [
    { uiTitle: 'Input array', vmType: getArrayVmTypeOf(vmTypes.any) },
    { uiTitle: 'Array element map', vmType: vmTypes.propToPropMap }
  ]
};

export const map2 = (arrMap, funcMap, options) => {
  const { dataMappingVmType = vmTypes.dataMapping } = options ?? {};
  const mapDataOptions = { dataMappingVmType };
  const arr = mapData({ vmFunctions }, arrMap, null, mapDataOptions);
  const func = (el) => mapData({ el, vmFunctions }, funcMap, null, mapDataOptions);
  return isArr(arr) ? arr.map(func) : [];
};
export const every2 = (arrMap, funcMap, options) => {
  const { dataMappingVmType = vmTypes.dataMapping } = options ?? {};
  const mapDataOptions = { dataMappingVmType };
  const arr = mapData({ vmFunctions }, arrMap, null, mapDataOptions);
  const func = (el) => mapData({ el, vmFunctions }, funcMap, null, mapDataOptions);
  return isArr(arr) ? arr.every(func) : [];
};
export const some2 = (arrMap, funcMap, options) => {
  const { dataMappingVmType = vmTypes.dataMapping } = options ?? {};
  const mapDataOptions = { dataMappingVmType };
  const arr = mapData({ vmFunctions }, arrMap, null, mapDataOptions);
  const func = (el) => mapData({ el, vmFunctions }, funcMap, null, mapDataOptions);
  return isArr(arr) ? arr.some(func) : [];
};

export const filter2 = (arrMap, funcMap, options) => {
  const { dataMappingVmType = vmTypes.mapArrayElements } = options ?? {};
  const mapDataOptions = { dataMappingVmType };
  const arr = mapData({ vmFunctions }, arrMap, null, mapDataOptions);
  const func = (el) => mapData({ el, vmFunctions }, funcMap, null, mapDataOptions);
  return isArr(arr) ? arr.filter(func) : [];
};

export const flatMap = (arrMap, funcMap, options) => {
  const { dataMappingVmType = vmTypes.mapArrayElements } = options ?? {};
  const mapDataOptions = { dataMappingVmType };
  const arr = mapData({ vmFunctions }, arrMap, null, mapDataOptions);
  const func = (el) => mapData({ el, vmFunctions }, funcMap, null, mapDataOptions);
  return isArr(arr) ? arr.flatMap(func) : [];
};

export const sortByTwoParams = (arr, paramOne, paramTwo) => {
  if (!arr.length || !paramOne || !paramTwo) return arr ?? [];
  return arr?.sort((a, b) => {
    if (a?.[paramOne] === b?.[paramTwo]) {
      return a?.[paramTwo] < b?.[paramTwo] ? -1 : 1;
    }
    return a?.[paramOne] < b?.[paramOne] ? -1 : 1;
  });
};

sortByTwoParams.vmFunctionMetaData = {
  returnVmType: vmTypes.array,
  argsInfo: [
    { uiTitle: 'Array', vmType: vmTypes.array },
    { uiTitle: 'First param to sort by', vmType: vmTypes.string },
    { uiTitle: 'Second param to sort by', vmType: vmTypes.string }
  ]
};

export const toggleArrValue = (arr, value) => {
  // Array of strings/numbers only
  if (!isArr(arr)) return arr;
  return arr.includes(value)
    ? arr.filter((el) => el !== value)
    : [...arr, value];
};

export const getItemByIndex = (arr, idx) => (isArr(arr) ? arr[idx] : arr);
getItemByIndex.vmFunctionMetaData = {
  returnVmType: vmTypes.any,
  argsInfo: [
    { uiTitle: 'Array', vmType: vmTypes.array },
    { uiTitle: 'Index from the array', vmType: vmTypes.number }
  ]
};
export const deleteByIdx = (arr, idx) => (isArr(arr) ? arr.filter((_, idx2) => idx !== idx2) : arr);

export const filterAndSortArray = (props) => {
  const { array, filterBy, sortBy } = props;

  // Fallback: Return an empty array if input array is null or empty
  if (!isArr(array)) {
    console.error('Invalid input: "array" must be an array.');
    return [];
  }

  // Validate filterBy configuration
  const isValidFilterBy = isArr(filterBy) && filterBy.every((filterItem) => {
    if (typeof filterItem !== 'object' || Object.keys(filterItem).length !== 1) {
      console.error('Invalid filterBy configuration: Each filter should be an object with one key.');
      return false;
    }
    const [key, condition] = Object.entries(filterItem)[0];
    if (typeof condition !== 'object' || (!condition.include && !condition.exclude)) {
      console.error(`Invalid filterBy configuration for key "${key}": Must include "include" or "exclude".`);
      return false;
    }
    return true;
  });

  // Fallback: If filterBy is not provided or empty or invalid, use an empty array
  const filters = isValidFilterBy ? filterBy : [];

  // Validate sortBy configuration
  const isValidSortBy = sortBy && typeof sortBy === 'object'
    && typeof sortBy.property === 'string'
    && ['ascending', 'descending', 'random'].includes(sortBy.value);

  // Log error message if sortBy is invalid
  if (!isValidSortBy) {
    console.error('Invalid sortBy configuration: "sortBy" should be an object with "property" (string) and "value" (ascending, descending, or random).');
  }

  // Fallback for sortBy if configuration is invalid or empty
  const sorting = isValidSortBy ? sortBy : { property: null, value: 'ascending' };

  // Function to apply filters
  const applyFilters = (item) => filters.every((filterItem) => {
    const [key, condition] = Object.entries(filterItem)[0];
    if (condition.include) {
      return condition.include.includes(item[key]);
    }
    if (condition.exclude) {
      return !condition.exclude.includes(item[key]);
    }
    return true;
  });

  // Filter the array
  const filteredArray = filters.length > 0 ? array.filter(applyFilters) : array;

  // Function to sort the array
  const sortArray = (a, b) => {
    const { property, value } = sorting;
    if (!property) return 0; // No sorting if no property is specified
    if (value === 'ascending') {
      return a[property] > b[property] ? 1 : -1;
    }
    if (value === 'descending') {
      return a[property] < b[property] ? 1 : -1;
    }
    if (value === 'random') {
      return Math.random() - 0.5;
    }
    return 0;
  };

  // Sort the filtered array without mutating the original
  const sortedArray = sorting.property ? [...filteredArray].sort(sortArray) : filteredArray;

  return sortedArray;
};
