import PropTypes from 'prop-types';
import {
  componentsPropType,
  stylesPropType,
  vmPropTypes as vmTypes,
} from '../../../global-prop-types';
import { isArr } from '../../../global-utils/globalUtils';
import ComponentsArrRenderer from '../../core/ComponentsArrRenderer';

const DataGrid = (props) => {
  const {
    dataGridData,
    components,
    parent,
    variant,
    variantItem,
    styles,
    ...restProps
  } = props;

  if (!isArr(dataGridData)) return null;

  return (
    <div
      className={variant || ''}
      style={{ ...styles?.wrapper }}
    >
      {dataGridData.map((el, idx) => (
        <div
          className={variantItem || ''}
          key={el.id || idx}
          style={{
            flexGrow: 1,
            ...styles?.item
          }}
        >
          <ComponentsArrRenderer
            {...restProps}
            components={components}
            componentItemData={el}
            parent={[...parent, idx]}
          />
        </div>
      ))}
    </div>
  );
};

DataGrid.propTypes = {
  variant: PropTypes.string,
  variantItem: PropTypes.string,
  parent: PropTypes.array,
  dataGridData: PropTypes.array,
  components: componentsPropType,
  styles: stylesPropType,
};

DataGrid.defaultProps = {
  variant: '',
  dataGridData: [],
  components: [],
  styles: {
    wrapper: {},
    item: {},
  },
};

DataGrid.vmPropTypes = {
  variant: vmTypes.className,
  variantItem: vmTypes.string,
  dataGridData: vmTypes.array,
  components: vmTypes.components,
  styles: {
    wrapper: vmTypes.styles,
    item: vmTypes.styles,
  },
};

export default DataGrid;
