import { createInstance } from '@datapunt/matomo-tracker-react';

const defaultInstanceParams = {
  urlBase: 'https://stats.virtual-meeting.net/',
  trackerUrl: 'https://stats.virtual-meeting.net/matomo.php',
  siteId: 42,
  configurations: { disableCookies: true }
};

const getInstanceParams = () => {
  // Matomo module configuration is special and is saved in
  // in window object injected there by createIndexFile() on server:
  const { isActive, siteId } = window.vmRevolutionCoreValue?.tracking?.matomo ?? {};

  if (!isActive) {
    return defaultInstanceParams;
  }

  return {
    ...defaultInstanceParams,
    siteId
  };
};

// eslint-disable-next-line import/prefer-default-export
export const getInstance = () => createInstance(getInstanceParams());
