import PropTypes from 'prop-types';
import { stylesPropType, vmPropTypes } from '../../global-prop-types';

const ImageComp = (props) => {
  const {
    src, styles, alt, variant, componentsFallbackImage
  } = props;

  const handleImgError = (e) => {
    const { currentTarget } = e;
    if (currentTarget.src === src && componentsFallbackImage) {
      // it's the original image, change it:
      // AND if we have a fallback image.. change it
      currentTarget.src = componentsFallbackImage || '';
      return;
    }
    // else hide image
    // it's already the fallback image...
    currentTarget.onerror = null;
    currentTarget.src = '';
    currentTarget.style.visibility = 'hidden';
  };

  return (
    <img
      src={src}
      className={`imageComp-wrapper ${variant || ''}`}
      onError={handleImgError}
      alt={alt}
      style={styles?.wrapper}
    />
  );
};

ImageComp.propTypes = {
  variant: PropTypes.string,
  src: PropTypes.string,
  componentsFallbackImage: PropTypes.string,
  alt: PropTypes.string,
  styles: stylesPropType
};

ImageComp.defaultProps = {
  variant: '',
  src: 'https://www.fazemag.de/wp-content/uploads/2019/04/acid-smiley.png',
  componentsFallbackImage:
    'https://dnvp9c1uo2095.cloudfront.net/cms-content/Thumbnail (7)_1661530468777.png',
  alt: 'img-comp',
  styles: {
    wrapper: {}
  }
};

ImageComp.vmPropTypes = {
  variant: vmPropTypes.className,
  src: vmPropTypes.image,
  componentsFallbackImage: vmPropTypes.image,
  alt: vmPropTypes.string,
  styles: { wrapper: vmPropTypes.styles }
};

export default ImageComp;
