import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeAvatar = (componentStyles) => `
  .jme-avatar-wrapper {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-avatar-wrapper img {
    vertical-align: middle;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-avatar-wrapperInitials {
    border: 2px solid;
    font-weight: 700;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F1F1;
    ${componentStylesToCss(componentStyles?.wrapperInitials)}
  }
`;

export default stylesJmeAvatar;
