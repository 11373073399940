import vmFunctions from '..';

export const generateFacebookLink = () => `https://www.facebook.com/sharer/sharer.php?u=${vmFunctions.window.href()}`;

export const generateTwitterLink = (title) => {
  if (!title) return null;
  return `https://twitter.com/intent/tweet?text=${title}&url=${vmFunctions.window.href()}`;
};

export const generateLinkedinLink = (title) => {
  if (!title) return null;
  return `https://www.linkedin.com/shareArticle?mini=true&url=${vmFunctions.window.href()}&title=${title}`;
};

export const generateMailLink = (title, subject = '', body = '', preHref = 'Link zur Seite:') => {
  if (!title) return null;
  return `mailto:?subject=${subject}&body=${body}${body && ':'} ${title}%0D%0A${preHref} ${vmFunctions.window.href()}`;
};
