import { componentStylesToCss } from '../componentStylesUtils';

const stylesMediaSearchPanel = (componentStyles, theme) => `
  .MediaSearchPanel-wrapper {
    min-height: 172px;
    height: fit-content;
    margin-bottom: 10px;
    padding: 30px 32px 30px 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    background-color: ${theme.palette.secondaryColor2};
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .MediaSearchPanel-inputSection {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .MediaSearchPanel-row {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    .MediaSearchPanel-row {
      flex-wrap: wrap;
    }
  }

  .MediaSearchPanel-input {
    height: 40px;
    border: 1px solid;
    height: 40px;
    border-radius: 0px;
    flex-grow: 1;
    border-color: ${theme.palette.body};
    background-color: ${theme.palette.backgroundText};
  }

  .MediaSearchPanel-inputQuery {
    flex-grow: 3;
    position: relative;
  }
  .mediasearchpanel-inputQueryIcon {
    background-color: #FFFFFF;
    padding: 3px;
    position: absolute;
    right: 10px;
    top: 7px;
    line-height: 20px;
    font-size: 16px;
  }

  .MediaSearchPanel-inputDate {
    width: fit-content;
    position: relative;
  }

  .MediaSearchPanel-inputDate i {
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 20px;
    font-size: 16px;
  }

  .MediaSearchPanel-button {
    border: 1px solid;
    border-color: ${theme.palette.primaryColor1};
    border-radius: 4px;
    padding: 10px 34px;
    height: 38px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    border-radius: 24px 0px;
  }

  .MediaSearchPanel-resetButton {
    margin-left: auto;
    background-color: ${theme.palette.backgroundText};
    color: ${theme.palette.primaryColor1};
  }

  .MediaSearchPanel-searchButton {
    background-color: ${theme.palette.primaryColor1};
    color: ${theme.palette.backgroundText};
  }

  .MediaSearchPanel-appliedFiltersWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  .MediaSearchPanel-appliedFilters {
    margin-bottom: 16px;
    margin-top: 16px;
    display: flex;
    width: 100%;
  }

  .MediaSearchPanel-appliedFiltersTitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    color: ${theme.palette.primaryColor1};
  }

  .MediaSearchPanel-appliedFiltersButton {
    border: 2px solid;
    padding: 7px 16px;
    align-items: center;
    display: flex;
    justify-items: center;
    width: fit-content;
    border-radius: 4px;
    background-color: ${theme.palette.secondaryColor2};
    color: ${theme.palette.body};
    border-color: ${theme.palette.body};
    position: relative;
  }

  .MediaSearchPanel-appliedFiltersCross {
    background-color: ${theme.palette.secondaryColor2};
    color: ${theme.palette.body};
    border-color: ${theme.palette.body};
    position:  absolute;
    right: -8px;
    top: -8px;
    backgroundColor: #FFFFFF;
    fontSize: 20px
  }

`;

export default stylesMediaSearchPanel;
