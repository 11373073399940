import jsonToJsxComponent from '../jsonToJsxComponent';
import CourseModuleButtonJson from './CourseModuleButton.json';
import CourseDetailsInformationJson from './CourseDetailsInformation.json';
import CourseButtonRegistrationJson from './CourseButtonRegistration.json';
import CoursePageJson from './CoursePage.json';
import CourseSponsorSectionJson from './CourseSponsorSection.json';
import CourseSponsorDescriptionJson from './CourseSponsorDescription.json';
import CourseContentJson from './CourseContent.json';

const CourseModuleButton = jsonToJsxComponent(CourseModuleButtonJson);
const CourseDetailsInformation = jsonToJsxComponent(CourseDetailsInformationJson);
const CourseButtonRegistration = jsonToJsxComponent(CourseButtonRegistrationJson);
const CoursePage = jsonToJsxComponent(CoursePageJson);
const CourseSponsorSection = jsonToJsxComponent(CourseSponsorSectionJson);
const CourseSponsorDescription = jsonToJsxComponent(CourseSponsorDescriptionJson);
const CourseContent = jsonToJsxComponent(CourseContentJson);

export {
  CourseModuleButton,
  CourseDetailsInformation,
  CourseButtonRegistration,
  CoursePage,
  CourseSponsorSection,
  CourseSponsorDescription,
  CourseContent,
};
