import PropTypes from 'prop-types';

const iconPropType = PropTypes.oneOfType([PropTypes.string,
  PropTypes.shape({
    name: PropTypes.string,
    iconType: PropTypes.oneOf(['fa', 'mui']),
    isOutlined: PropTypes.bool, // works only with mui
    iconColor: PropTypes.string,
    iconSize: PropTypes.string,
    link: PropTypes.shape({
      inactive: PropTypes.string,
      active: PropTypes.string
    })
  })]);

export default iconPropType;
