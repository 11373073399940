import { componentStylesToCss } from '../componentStylesUtils';

const stylesNavigationItem = (componentStyles, theme) => `
  .navigationItem-wrapper {
    margin: 10px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .navigationItem-linkItem {
    cursor: pointer;
    text-decoration: none !important;
    font-weight: bold;
    text-transform: capitalize;
    position: relative;
    ${componentStylesToCss(componentStyles?.linkItem)}
  }
  .navigationItem-linkItem:hover {
    ${componentStylesToCss(componentStyles?.linkItemOnHover)}
  }
  .navigationItem-linkItem:visited {
    color: inherit;
  }
  .navigationItem-linkItem:link {
    color: inherit;
  }
  .navigationItem-linkItemActive::after {
    content: '';
    border-bottom: 7px dotted;
    padding-bottom: 3px;
    width: 28px;
    height: 1px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: -10px;
    ${componentStylesToCss(componentStyles?.linkItemActive)}
  }
  .menuBox-dropDown {
    float: left;
    position: relative,
    display: inline-block;
    cursor: pointer;
    outline: none;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }
  .navigationItem-menuBox {
    display: block;
    position: absolute;
    padding: 0px;
    background-color: #fff;
    width: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 11;
    overflow-y: scroll;
    height: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ${componentStylesToCss(componentStyles?.menuBox)}
  }
  .navigationItem-menuBox::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .navigationItem-menuBox {
      display: none;
    }
  }
  .navigationItem-menuBoxItem {
    font-size: 14px;
    text-decoration: none;
    ${componentStylesToCss(componentStyles?.menuBoxItem)}
  }
`;

export default stylesNavigationItem;
