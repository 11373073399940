import React from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import PageRenderer from '../../components/core/PageRenderer';
import { routPropType, componentsPropType } from '../../global-prop-types';

const SponsorProfilePage = (props) => (
  <PageRenderer
    urlParams={useParams()}
    {...props}
  />
);

SponsorProfilePage.propTypes = {
  title: PropTypes.string,
  route: routPropType,
  components: componentsPropType,
};

// Default props ARE the default when building a page
SponsorProfilePage.defaultProps = {
  title: 'Sponsor Profile Page Page',
  components: [
    {
      componentName: 'JmeText',
      elemType: 'h1',
      text: 'Sponsor Profile Page Title'
    }
  ],
  // Rout will be extracted to the settings
  route: {
    path: '/sponsors/profile',
    exact: true
  },
};

export default SponsorProfilePage;
