import writeStyleToHead from '../global-utils/writeStylesToHead';
import * as stylesIndex from './stylesIndex';

const processComponentStyles = (componentStyles, theme) => Object.keys(stylesIndex).reduce(
  (acc, componentStyleKey) => acc
      + stylesIndex[componentStyleKey]?.(
        componentStyles?.[componentStyleKey],
        theme
      ),
  ''
);

/* This function is used in styleComponentName.js files, to convert js obj to css compliant string.
For example for a compoentStyleObj: { color: red, border-color: green } it produces
string: `color: red;\n border-color: green;` */
const componentStylesToCss = (componentStylesObj) => Object.keys(componentStylesObj || {})
  .map((el) => `${el}: ${componentStylesObj[el]};\n`.trim())
  .join('\n');

export const getStylesString = (
  clsName,
  elClass,
  theme,
  usingMinWidthBreakpoint
) => {
  let customClassesString = '';
  customClassesString += `${clsName}{ ${componentStylesToCss(
    elClass?.styles?.wrapper
  )} }`;

  // We need to sort the order we push media-query into the styls.
  // the lower number means, this media query is written first
  // It might be that dependin on usingMinWidthBreakpoint we might
  // need to change the order.
  const sortOrder = {
    xs: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4
  };
  Object.keys(elClass?.MediaQueries ?? {})
    .sort((el1, el2) => sortOrder[el1] - sortOrder[el2])
    .forEach((mediaClass) => {
      customClassesString += `@media screen and (${
        usingMinWidthBreakpoint ? 'min-width' : 'max-width'
      }: ${theme.breakpoints.values[mediaClass]}${theme.breakpoints.unit}){
    ${clsName}{ ${componentStylesToCss(
  elClass?.MediaQueries[mediaClass]?.wrapper
)}}
    }`;
    });

  // states are ":hover", ":focus", ":visited", ":active", ":focus-within", ":focus-visible", ":target"
  Object.keys(elClass?.states ?? {}).forEach((classState) => {
    if (elClass?.states[classState]) {
      customClassesString += `${clsName}:${classState}{ ${componentStylesToCss(
        elClass?.states[classState]?.wrapper
      )}}`;
    }
  });
  return customClassesString;
};

const setCustomClasses = (customStyles, theme) => {
  let customClassesString = '';

	// freestyling has to be first, in order to make the @import the first part of the <style> tag
  customClassesString += customStyles.freeStyling
    ? customStyles.freeStyling
    : '';

  customStyles.classes.forEach((elClass) => {
    const clsName = `${elClass.shouldRemoveClassDot ? '' : '.'}${
      elClass.className
    }`;
    customClassesString += getStylesString(
      clsName,
      elClass,
      theme,
      customStyles?.usingMinWidthBreakpoint
    );
    customClassesString += customStyles.extraCssToClass
      ? customStyles.extraCssToClass
      : '';
  });

  writeStyleToHead(customClassesString);
};

export { processComponentStyles, componentStylesToCss, setCustomClasses };
