import store from '../../../global-state/redux';
import { MODULE_NAME } from './constants';
import { getLoginInfo } from '../../../global-utils/loginInfoUtils';
import { selectIsActive, selectSettings } from '../../hooks';
import storageAndCookie from '../../../global-utils/storage-and-cookies';

const { getState } = store;

export const getManageConfig = () => selectSettings(MODULE_NAME)(getState());

export const getIsActive = () => selectIsActive(MODULE_NAME)(getState());

export const getUserId = () => getLoginInfo(getState())?.manage_api_user?.id;

export const getAccessToken = () => getLoginInfo(getState())?.manage_api_user?.token;

export const getInstance = () => selectSettings(MODULE_NAME)(getState())?.instance;

export const getLanguage = () => selectSettings(MODULE_NAME)(getState())?.language;

// VSX-939 remove once we find where it is used in JSONs!
export const getShort = () => selectSettings(MODULE_NAME)(getState())?.eventShort;

export const getEventShort = () => selectSettings(MODULE_NAME)(getState())?.eventShort;
export const getSocietyShort = () => selectSettings(MODULE_NAME)(getState())?.societyShort;

export const getEventId = () => selectSettings(MODULE_NAME)(getState())?.eventId;

export const getApiBaseUrl = () => selectSettings(MODULE_NAME)(getState())?.apiBaseUrl;

export const getShowHcpPopUp = () => selectSettings(MODULE_NAME)(getState())?.showHcpPopup;

export const withAccessToken = (manageConfig) => {
  if (!manageConfig) return manageConfig;

  const { instance } = manageConfig ?? {};
  const instanceUser = `${instance}.user`;

  try {
    return {
      ...manageConfig,
      accessToken: JSON.parse(storageAndCookie.get(instanceUser))?.accessToken
    };
  } catch {
    return manageConfig;
  }
};

const withShortByContext = (config) => {
  const {
    context, eventShort, profileShort, societyShort
  } = config ?? {};

  let short;

  if (context === 'Event') short = eventShort;
  if (context === 'Profile') short = profileShort;
  if (context === 'Society') short = societyShort;

  return {
    ...config,
    short
  };
};

export const withManageConfig = (moduleConfig) => {
  const manageConfig = getManageConfig();
  const isManageActive = getIsActive();

  if (!isManageActive) return moduleConfig;

  // we extend by starting with keys from amangeConfig. This way we have
  // all the manage parameters
  const config = Object.entries(manageConfig).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: moduleConfig?.[key] || val // this uses boolean asertion. Mind, that this makes sens only for strings!
    }),
    moduleConfig
  );

  return withShortByContext(withAccessToken(config));
};
