/**
 * This file was created before we new we can use store direcly in vmFunctions.
 * loginInfoUtils uses complicated mechanism to get information via dataBank, what is purely unnecesery.
 *
 * The login information should be retirved from loginInfo module. This module should be used
 * to get only personal loged-in user data such as name, surname etc.
 *
 * Some logic such as hasUserAccess still needs to have single point of truth
 * and is used in both, source code and in React components (which need to use hooks to triger
 * rerenders). I do not have yet plan how to neatly solve this problem.
*/

import { vmTypes } from '../../../global-prop-types';
import * as loginInfoUtils from '../../loginInfoUtils';

export const getLoggedInUser = (dataObj) => () => loginInfoUtils.getLoggedInUser(dataObj?.store);
getLoggedInUser.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.object
};

// TODO: refactor to use state.getState() instead of vmFunction that uses dataObj.
export const isUserLoggedIn = (dataObj) => () => loginInfoUtils.isUserLoggedIn(dataObj?.store);
isUserLoggedIn.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.bool
};

export const userIsNotLoggedIn = (dataObj) => () => !loginInfoUtils.isUserLoggedIn(dataObj?.store);
userIsNotLoggedIn.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.bool
};

export const getLoggedInUserFirstname = (dataObj) => () => loginInfoUtils.getLoggedInUserFirstname(dataObj?.store);
getLoggedInUserFirstname.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.string
};

export const getLoggedInUserSurname = (dataObj) => () => loginInfoUtils.getLoggedInUserSurname(dataObj?.store);
getLoggedInUserSurname.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.string
};

export const getLoggedInUserAcademicTitle = (dataObj) => () => loginInfoUtils.getLoggedInUserAcademicTitle(dataObj?.store);
getLoggedInUserAcademicTitle.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.string
};

export const hasUserAccess = (dataObj) => () => loginInfoUtils.hasUserAccess(dataObj?.store);
hasUserAccess.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.bool
};

// DEPRICATED - use loginInfo module
export const getLoggedInUserId = (dataObj) => () => loginInfoUtils.getLoggedInUserId(dataObj?.store);
getLoggedInUserId.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.number
};

// DEPRICATED - use loginInfo module
export const getLoggedInUserToken = (dataObj) => () => loginInfoUtils.getLoggedInUserToken(dataObj?.store);
getLoggedInUserToken.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.string
};
