/* eslint-disable no-tabs */
import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeSlider = (componentStyles, theme) => `
  .jme-slider-wrapper {
		transition-property: left, right, top, bottom;
		transition-duration: 1s;
    position: absolute;
    background: #232470;
    height: auto;
    z-index: 10000;
    padding: 33px;
    top: 0;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) {
    .jme-slider-wrapper {
      width: 100% !important;
    }
  }
`;

export default stylesJmeSlider;
