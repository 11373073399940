import { apiRequest } from '../../api-request';
import { dispatchVmEvents } from '../../vmEvents';
import {
  apiRequestStarted,
  apiRequestFinished,
  apiRequestError
} from '../../../global-state/redux/actions';
import store from '../../../global-state/redux';
import { getServerAddr } from '../core';

const { dispatch, getState } = store;

export const getUrl = ({ pathname }) => {
  const serverAddr = getServerAddr();
  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = pathname;
  return url;
};

export const checkApiError = (res) => {
  // this is collection of possible ways Chen communicates error in his API response:
  if (res?.data?.error_code || res?.data?.error_message || res?.data?.error || res.data === null) {
    throw new Error(res?.data?.error_message ?? res?.data?.error ?? 'No error message in API call', { cause: res });
  }
  return res;
};

// This is unfortunate copy of the same function as in useApiDataHook, with some modifications adjusted
// to make API calls in networking module
export function ifToDebounceApiCall(args) {
  const { storeMask, skipDebounce, refreshRate = 5 * 60 * 1000 } = args ?? {}; // default debounce interval : 5min
  const apiStatus = getState().data[storeMask];
  const { isLoading, isError, timestamp = 0 } = apiStatus ?? {};
  const timeFromLastCallInMs = Date.now() - timestamp;

  // ATTENTION - ORDER OF IF CALLS MATTERS!
  if (skipDebounce) return false;
  if (!apiStatus) return false; // Call has not been done yet (continue with API call)
  if (isLoading) return true; // still loading (debounce API call)
  if (isError && timeFromLastCallInMs > 10000) return false; // Last call was error but at least 10 sec ago (continue with API call)
  if (timeFromLastCallInMs < refreshRate) return true; // last call was made less then refreshRate (default 5min) (debounce API call)

  return false; // no reasons to stop call (continue with API call)
}

export const myNetworkingApiCall = (props = {}) => {
  const {
    storeMask,
    pathname,
    method,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce,
    refreshRate
  } = props;

  const url = getUrl({ pathname });

  // if call was debounced, resolve promise with responce from the previous call:
  if (ifToDebounceApiCall({ refreshRate, storeMask, skipDebounce })) {
    const res = {
      ...getState().data[storeMask],
      isDebounced: true
    };
    dispatchVmEvents(onSuccessVmEventList, { dataBankExtend: { res } });
    return Promise.resolve(res);
  }

  dispatch(apiRequestStarted(storeMask));

  // returning promiss allows for custom response or error processing in individual calls:
  return apiRequest({ url, body, method })
    .then(checkApiError)
    .then((res) => {
      dispatch(apiRequestFinished(storeMask, { ...res }));
      dispatchVmEvents(onSuccessVmEventList, { dataBankExtend: { res } });
      return res;
    })
    .catch((error) => {
      dispatch(apiRequestError(storeMask, error.message));
      dispatchVmEvents(onErrorVmEventList, { dataBankExtend: { error } });
      throw error;
    });
};

export const myNetworkingApiStatus = (storeMask) => getState().data?.[storeMask];
