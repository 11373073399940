import { getMyNetworkingByKey } from '../../myNetworkingSelectors';
import { appendId } from '../../myNetworkingUtils';
import {
  CHATGROUPREQUESTS_SELECTOR_KEY,
  CHATGROUPREQUESTS_BY_CHATGROUP_SELECTOR_KEY,
  CHATGROUPREQUESTS_STATE_CREATED,
  CHATGROUPREQUESTS_STATE_ACCEPTED,
  CHATGROUPREQUESTS_STATE_REJECTED
} from './chatgrouprequestConstants';

export const getChatgrouprequests = () => getMyNetworkingByKey(CHATGROUPREQUESTS_SELECTOR_KEY) ?? [];
export const isInChatgrouprequests = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const chatgrouprequests = getChatgrouprequests();
  return chatgrouprequests.some((el) => el.chatgroup.id === groupId);
};

export const getChatgrouprequestsByChatgroupId = (id) => {
  const chatgrouprequests = getMyNetworkingByKey(
    appendId(CHATGROUPREQUESTS_BY_CHATGROUP_SELECTOR_KEY, id)
  ) ?? [];

  // By default return only in state created:
  return chatgrouprequests.filter(
    (el) => el.state === CHATGROUPREQUESTS_STATE_CREATED
  );
};

const isInState = (chatgrouprequestIdStr, chatgroupIdStr, state) => {
  const chatgroupId = parseInt(chatgroupIdStr, 10);
  const chatgrouprequestId = parseInt(chatgrouprequestIdStr, 10);
  const chatgrouprequests = getChatgrouprequestsByChatgroupId(chatgroupId);

  return !!chatgrouprequests.find(
    (el) => el.id === chatgrouprequestId && el.state === state
  );
};

export const wasChatgrouprequestAccepted = (chatgrouprequestId, chatgroupId) => isInState(chatgrouprequestId, chatgroupId, CHATGROUPREQUESTS_STATE_ACCEPTED);

export const wasChatgrouprequestRejected = (chatgrouprequestId, chatgroupId) => isInState(chatgrouprequestId, chatgroupId, CHATGROUPREQUESTS_STATE_REJECTED);

export const wasChatgrouprequestNotAcceptedNotRejected = (
  chatgrouprequestId,
  chatgroupId
) => !isInState(
  chatgrouprequestId,
  chatgroupId,
  CHATGROUPREQUESTS_STATE_REJECTED
)
  && !isInState(chatgrouprequestId, chatgroupId, CHATGROUPREQUESTS_STATE_ACCEPTED);

// operatoins as asked from the point of a loged in user:
export const isChatgrouprequestPendingByGroupId = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  return getChatgrouprequests().find(
    (el) => el.chatgroup.id === groupId
      && el.state === CHATGROUPREQUESTS_STATE_CREATED
  );
};
