import React from 'react';
import PropTypes from 'prop-types';
import PageRenderer from '../components/core/PageRenderer';
import { routPropType, componentsPropType } from '../global-prop-types';

const ImpressumPage = (props) => (
  <PageRenderer {...props} />
);

ImpressumPage.propTypes = {
  title: PropTypes.string,
  components: componentsPropType,
  route: routPropType,
};

ImpressumPage.defaultProps = {
  title: 'Impressum Page',
  components: [
    {
      componentName: 'JmeText',
      text: 'Impressum',
      elemType: 'h4',
    }
  ],
  route: {
    path: '/impressum',
    exact: true
  },
};

export default ImpressumPage;
