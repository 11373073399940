import { useSelector } from 'react-redux';
import ComponentsArrRenderer from './ComponentsArrRenderer';
import {
  useIsActive as useIsPagePreviewActive,
  useIfHideAppFooter
} from '../../modules/page-preview';

const AppFooter = (props) => {
  const { isActive: isAppFooterActive, components } = useSelector(
    (state) => state.appState.settings?.appFooter
  );
  const isPagePreviewActive = useIsPagePreviewActive();
  const ifHideAppFooter = useIfHideAppFooter();
  const { pathname, search, hash } = window.location;
  const windowLocationRelative = `${pathname}${search}${hash}`;

  if (!isAppFooterActive) return null;
  if (isPagePreviewActive && ifHideAppFooter(windowLocationRelative)) return null;

  return <ComponentsArrRenderer {...props} components={components} />;
};

export default AppFooter;
