import store from '../../../../global-state/redux';
import { setMyNetworking } from '../../../../global-state/redux/actions';
import { coerceToObj } from '../myNetworkingUtils';
import { apiGetUserSettings, apiSetUserSettings } from './userApi';
import {
  USER_SETTINGS,
  VISIBILITY_SETTINGS,
  apiGetAgreements,
  apiSetAgreementsUserSettings,
  apiSetAgreementsVisibilitySettings
} from './agreementApi';
import { apiSetFilesuploads } from './filesuploadsApi';
import { SETTINGS_KEY } from './settingsConstants';
import { dispatchVmEvents } from '../../../vmEvents';
import { getSettings } from './settingsSelectors';

const { dispatch } = store;

export const apiGetSettings = (props) => {
  const { onSuccessVmEventList, onErrorVmEventList, skipDebounce } = coerceToObj(props);

  return Promise.all([
    apiGetUserSettings({ skipDebounce }),
    apiGetAgreements({ skipDebounce, agreementType: USER_SETTINGS }),
    apiGetAgreements({ skipDebounce, agreementType: VISIBILITY_SETTINGS })
  ])
    .then((values) => {
      // we save pure API responses. The selectors are responsible for processing data:
      if (values.some((el) => !el.isDebounced)) {
        const settingsObj = {
          user: values[0].data,
          agreements: values[1].data,
          networkingSettings: values[2].data
        };
        dispatch(setMyNetworking(SETTINGS_KEY, settingsObj));
        dispatchVmEvents(onSuccessVmEventList);
      }
      return values;
    })
    .catch((err) => {
      dispatchVmEvents(onErrorVmEventList);
      return Promise.reject(
        new Error('Could not fetch networking settings', {
          cause: err
        })
      );
    });
};

const shouldSetFilesuploads = (settingsNew) => getSettings().path !== settingsNew.path;

const shouldSetAgreementsUserSettings = (settingsNew) => {
  const { communicable } = getSettings();
  const { communicable: communicableNew } = settingsNew;

  return communicableNew !== communicable;
};

const shouldSetAgreementsVisibilitySettings = (settingsNew) => {
  const keyList = ['biography', 'email', 'workplace'];
  const settingsOld = getSettings();

  return keyList.some((el) => settingsOld[el] !== settingsNew[el]);
};

const shouldSetUser = (settingsNew) => {
  const { tags } = getSettings();
  const { tagIds: tagIdsNew } = settingsNew;

  return (
    shouldSetFilesuploads(settingsNew)
    || tags
      .map((el) => el.id)
      .sort()
      .join() !== tagIdsNew.sort().join()
  );
};

export const apiSetSettings = (props) => {
  const { onSuccessVmEventList, onErrorVmEventList, settings } = coerceToObj(props);

  const skipDebounce = true;
  return Promise.resolve()
    .then((val) => (shouldSetFilesuploads(settings)
      ? apiSetFilesuploads({ ...settings, skipDebounce })
      : val))
    .then((res) => (shouldSetUser(settings)
      ? apiSetUserSettings({
        ...settings,
        filesuploadItem: res?.data,
        skipDebounce
      })
      : res))
    .then((res) => (shouldSetAgreementsUserSettings(settings)
      ? apiSetAgreementsUserSettings({ ...settings, skipDebounce })
      : res))
    .then((res) => (shouldSetAgreementsVisibilitySettings(settings)
      ? apiSetAgreementsVisibilitySettings({ ...settings, skipDebounce })
      : res))
    .then(() => apiGetSettings({ onSuccessVmEventList, skipDebounce: true }))
    .catch((error) => {
      dispatchVmEvents(onErrorVmEventList, { dataBankProps: { error } });
      console.error(error);
    });
};
