import { useAddonSettings } from '../../global-hooks';

const COMMUNICABLE_ONLY = 'communicableOnly';

/* eslint-disable import/prefer-default-export */
export const useCheckCommunicableOnlyAccessUrl = () => {
  const urlsForCommunicableOnly = useAddonSettings(COMMUNICABLE_ONLY)?.urlsForCommunicableOnly;

  // if urlsForCommunicableOnly is undefined or empty, the url is not on the list:
  if (!urlsForCommunicableOnly?.length) return () => false;

  // TODO: The returned function func should be memorized.
  const func = (url) => urlsForCommunicableOnly.some((el) => {
    const pageUrlToLowerCase = el?.pageUrl?.toLowerCase();
    const urlToLowerCase = url?.toLowerCase();
    return el?.isExactPath
      ? pageUrlToLowerCase === urlToLowerCase
      : new RegExp(pageUrlToLowerCase).test(urlToLowerCase);
  });

  return func;
};
