import { componentStylesToCss } from '../componentStylesUtils';

const stylesNavigationWrapper = (componentStyles, theme) => `
  .navigationWrapper-wrapper {
    display: flex;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (min-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .navigationWrapper-wrapper {
      margin-right: auto;
    }
  }
  .navigationWrapper-menuIcon {
    border: none;
    color: inherit;
    background-color: transparent;
    margin: 1rem;
    ${componentStylesToCss(componentStyles?.menuIcon)}
  }
  .navigationWrapper-grow {
    flex-grow: 1;
    ${componentStylesToCss(componentStyles?.grow)}
  }
`;

export default stylesNavigationWrapper;
