import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const RedirectToNoAccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/noAccess', { replace: true });
  }, []);

  return null;
};

export default RedirectToNoAccess;
