import { formatInTimeZone } from 'date-fns-tz';
import { vmTypes } from '../../../global-prop-types';
import { isDate } from '../../globalUtils';

// eslint-disable-next-line import/prefer-default-export
export const appTimezonedDate = (dataObj) => (timestamp) => {
  const { appTimezone } = dataObj.store.user;
  if (!appTimezone) return '';
  const now = isDate(timestamp) ? new Date(timestamp) : Date.now();
  return formatInTimeZone(now, appTimezone, 'yyyy-MM-dd');
};
appTimezonedDate.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.string
};

export const appTimezonedTimestamp = (dataObj) => (timestamp) => {
  const { appTimezone } = dataObj.store.user;
  if (!appTimezone) return '';
  const now = isDate(timestamp) ? new Date(timestamp) : Date.now();
  return formatInTimeZone(now, appTimezone, "yyyy-MM-dd'T'HH:mm:ss");
};
appTimezonedTimestamp.vmFunctionMetaData = {
  usesDataObj: true,
  returnVmType: vmTypes.string
};
