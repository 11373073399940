import { isObj, isStr } from '../../../global-utils';

function applyDefaults(config_) {
  const config = {};

  config.enableForLoggedInUser = config_.enableForLoggedInUser === true; // deault flase
  config.enableInAppContainer = config_.enableInAppContainer === true; // deault flase
  config.enableInPreview = config_.enableInPreview === true; // deault flase
  config.guardedUrl = isStr(config_.guardedUrl) ? config_.guardedUrl : '.*'; // default all
  config.holdingPageUrl = isStr(config_.holdingPageUrl)
    ? config_.holdingPageUrl
    : '/holding';
  config.isAppContainer = config_.isAppContainer === true; // deault flase
  config.isLoggedIn = config_.isLoggedIn === true; // deault flase
  config.isPreview = config_.isPreview === true; // deault flase
  try {
    config.passThroughStartsAt = new Date(
      config_.passThroughStartsAt
    ).valueOf();
  } catch {
    config.passThroughStartsAt = Number.Infinity;
  }
  try {
    config.passThroughEndsAt = new Date(config_.passThroughEndsAt).valueOf();
  } catch {
    config.passThroughEndsAt = Number.Infinity;
  }

  return config;
}

export const checkRedirectToHolding = (config_) => {
  const config = isObj(config_) ? config_ : {};
  const {
    enableForLoggedInUser,
    enableInAppContainer,
    enableInPreview,
    guardedUrl,
    holdingPageUrl,
    isAppContainer,
    isLoggedIn,
    isPreview,
    passThroughStartsAt,
    passThroughEndsAt
  } = applyDefaults(config);

  return (urlStr) => {
    const now = Date.now();
    const guardedUrlRe = new RegExp(guardedUrl);
    const holdingPageUrlRe = new RegExp(holdingPageUrl);

    if (passThroughStartsAt <= now && now <= passThroughEndsAt) return false;
    if (isPreview && !enableInPreview) return false;
    if (isAppContainer && !enableInAppContainer) return false;
    if (isLoggedIn && !enableForLoggedInUser) return false;
    if (holdingPageUrlRe.test(urlStr)) {
      return false;
    }

    return guardedUrlRe.test(urlStr);
  };
};
