// import { useSelector } from 'react-redux';
// import { useAddonSettings } from '../../global-hooks';
import { useAddonSettings } from '../../global-hooks';
import useShallowEqualSelector from '../../components/core/useShallowEqualSelector';

const MANAGE_MEMBERSHIP_ACCESS_ACTIVE = 'active';
const ADDONON_KEY = 'membershipOnly';

// functions possible to port to vmFunctions:
export const isMembershipActive = (store) => store?.user?.login_info?.manage_membership?.member_status
  === MANAGE_MEMBERSHIP_ACCESS_ACTIVE;

// This is the current logic to determine if the user is a memeber.
// We simply check if the status is active. For the moment we do not consider other membership states:
export const isUserAMember = isMembershipActive;

// Hooks
export const useIsUserAMember = () => {
  const membershipStatus = useShallowEqualSelector(
    (store) => store?.user?.login_info?.manage_membership?.member_status
  );
  return membershipStatus === MANAGE_MEMBERSHIP_ACCESS_ACTIVE;
};

export const useCheckMemberOnlyAccessUrl = () => {
  const urlsForMembersOnly = useAddonSettings(ADDONON_KEY)?.urlsForMembersOnly;

  // if urlsForMembersOnly is undefined or empty, the url is not on the list:
  if (!urlsForMembersOnly?.length) return () => false;

  // TODO: The returned function func should be memorized.
  const func = (url) => urlsForMembersOnly.some((el) => {
    const pageUrlToLowerCase = el?.pageUrl?.toLowerCase();
    const urlToLowerCase = url?.toLowerCase();
    return el?.isExactPath
      ? pageUrlToLowerCase === urlToLowerCase
      : new RegExp(pageUrlToLowerCase).test(urlToLowerCase);
  });

  return func;
};
