// https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
// This hook is to make sure that we listen to a paremeter that is changing

import { useEffect, useRef } from 'react';

export default function (value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
