/* eslint-disable import/prefer-default-export */
export const checkIfDateNestedInBody = (apiRes) => {
  // when calling /articles.json (landing page) the date prop is in first level
  // when calling /media_items context: Article (news page) the date prop nested inside body

  if (!apiRes) return null;
  let date;
  if (apiRes?.body?.date) date = apiRes.body.date;
  if (apiRes?.date) date = apiRes.date;
  return date;
};

export const extractId = (apiRes) => {
  if (!apiRes) return null;
  if (apiRes?.favouriteable_id) return apiRes.favouriteable_id;
  if (apiRes?.body?.id) return apiRes.body.id;
  return apiRes?.id;
};

export const extractArticleTypeId = (apiRes) => {
  // article type can come from /favourites.json which will be nested inside favouriteable
  if (!apiRes) return null;
  if (apiRes?.favouriteable?.articletype?.id) return apiRes.favouriteable.articletype.id;
  if (apiRes?.articletype?.id) return apiRes.articletype.id;
  return null;
};

export const extractSlugFromArticle = (apiRes) => {
  if (!apiRes) return null;
  if (apiRes?.favouriteable?.slug) return apiRes?.favouriteable?.slug;
  if (apiRes?.body?.slug) return apiRes?.body?.slug;
  return apiRes?.slug;
};

export const postProcessApiResponse = () => (res) => ({
  ...res,
  data: res?.data?.filter((el) => el?.is_published)
});
