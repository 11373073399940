import { stylesPropType, vmPropTypes } from '../../global-prop-types';

const Loading = (props) => {
  const { styles } = props;

  return (
    <div className="d-flex justify-content-center" style={styles?.wrapper}>
      <div className="spinner-border m-5" role="status" style={styles?.spinner}>
        <span className="sr-only loading-icon" style={styles?.icon} />
      </div>
    </div>
  );
};

Loading.propTypes = {
  styles: stylesPropType
};

Loading.vmPropTypes = {
  styles: {
    wrapper: vmPropTypes.styles,
    spinner: vmPropTypes.styles,
    icon: vmPropTypes.styles,
  }
};

export default Loading;
