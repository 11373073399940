import useShallowEqualSelector from '../../components/core/useShallowEqualSelector';
import { isUserAMember } from '../membershipUtils';

export const getLoginInfo = (store) => store?.user?.login_info;
export const isUserLoggedIn = (store) => !!store?.user?.login_info?.user?.id;

export const getLoggedInUserToken = (store) => store?.user?.login_info?.access_token;

export const getLoggedInUser = (store) => store?.user?.login_info?.user;
export const getLoggedInUserId = (store) => store?.user?.login_info?.user?.id;
export const getLoggedInUserFirstname = (store) => store?.user?.login_info?.user?.firstname;
export const getLoggedInUserSurname = (store) => store?.user?.login_info?.user?.surname;
export const getLoggedInUserAcademicTitle = (store) => store?.user?.login_info?.user?.academic_title;

export const hasUserAccess = (store) => isUserLoggedIn(store) && isUserAMember(store);

// Hooks:
export const useUserId = () => useShallowEqualSelector(getLoggedInUserId);
export const useUserAccessToken = () => useShallowEqualSelector(getLoggedInUserToken);
export const useLoginInfo = () => useShallowEqualSelector(getLoginInfo);
export const useLoggedInUser = () => useShallowEqualSelector(getLoggedInUser);
export const useIsUserLoggedIn = () => useShallowEqualSelector(isUserLoggedIn);
export const useHasUserAccess = () => useShallowEqualSelector(hasUserAccess);

export const useIsAcesssTokenExpired = () => {
  const { expires_at: expiresAt } = useLoginInfo();
  try {
    return new Date(expiresAt).getTime() <= Date.now();
  } catch {
    return null;
  }
};
