export const isPagePreview = (config) => {
  const { previewPageUrl } = config;
  const previewPageUrlRe = new RegExp(previewPageUrl);

  return (urlStr) => {
    // At first catch 99% of requests that are not for preview:
    if (!previewPageUrlRe.test(urlStr)) return false;

    return new URL(urlStr, window.location.origin).searchParams.get('preview') === 'true';
  };
};
