import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeCard = (componentStyles) => `
  .jme-card-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-cardContent-wrapper {
    ${componentStylesToCss(componentStyles?.cardContent)}
  }
  .jme-cardActions-wrapper {
    ${componentStylesToCss(componentStyles?.cardActions)}
  }
  .jme-cardActionArea-wrapper {
    ${componentStylesToCss(componentStyles?.cardActionArea)}
  }
  .jme-cardMedia-wrapper {
    ${componentStylesToCss(componentStyles?.cardMedia)}
  }
`;

export default stylesJmeCard;
