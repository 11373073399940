import { getArrayVmTypeOf, vmTypes } from '../../../global-prop-types/vmTypes';
import { isStr } from '../../globalUtils';

export const windowLocationUrlSearchParamsSet = (urlSearchParams) => {
  if (isStr(urlSearchParams)) window.location.search = urlSearchParams;
};

windowLocationUrlSearchParamsSet.vmFunctionMetaData = {
  argsInfo: [
    {
      uiTitle: 'String of the url search parameters',
      vmType: getArrayVmTypeOf(vmTypes.string)
    }
  ]
};

export const getPathname = () => window.location.pathname;
getPathname.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const getPathnameAndSearch = () => window.location.pathname + window.location.search;
getPathnameAndSearch.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const getHref = () => window.location.href;
getHref.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const getHash = () => window.location.hash;
getHash.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const getHost = () => window.location.host;
getHost.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const getHostname = () => window.location.hostname;
getHostname.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const getOrigin = () => window.location.origin;
getOrigin.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const getPort = () => window.location.port;
getPort.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const getProtocol = () => window.location.protocol;
getProtocol.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};

export const replaceHref = (href) => window.location.replace(href);
