import { componentStylesToCss } from '../componentStylesUtils';

const stylesJMEAddToFavourites = (componentStyles) => `
  .JMEAddToFavourites-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    border: none;
    background: transparent;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .JMEAddToFavourites-icon {
    font-size: 1rem;
    margin-right: 5px;
  }
`;

export default stylesJMEAddToFavourites;
