import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeOnClick = (componentStyles) => `
  .jmeOnClick-wrapper {
    width: fit-content;
    background-color: unset;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
`;

export default stylesJmeOnClick;
