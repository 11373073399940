/** Small set of tools related to references used on app side */
import { v4 as uuid } from 'uuid';
import { isStr, isObj } from '../globalUtils';

const VM_REF_DEFAULT_ID_PREFIX = 'vmRef';

/**  Utils to act or create reference id */
// valid id containe alpha-numeric chars and dash. No other chars are alowed
export const isVmRefId = (str) => isStr(str) && /^[-\w]+$/.test(str);

// generate valid reference id. If valid prefix is given then use it
export const generateVmRefId = (prefix) => {
  // Use || instead of ?? to protect from empty strings and 0.
  const vmRefId = `${prefix || VM_REF_DEFAULT_ID_PREFIX}_${uuid()}`;
  return isVmRefId(vmRefId) ? vmRefId : generateVmRefId(VM_REF_DEFAULT_ID_PREFIX);
};

/** Utils that act on reference library */
export const isVmRefLib = (obj) => isObj(obj);

/** Utils that act on reference definition */
// Prototype used in creating reference definition. This creates interface
// to the reference definition that can be used in vm2 app and vm2Admin.
// Please, always get value, mapper and uiTitle using the prototype functions
const vmRefDefPrototype = {
  // Getters:
  getVal() {
    return this.vmRefVal;
  },
  getMapper() {
    return this.vmRefMapper;
  }
};
export const vmRefDefFactory = (obj = {}) => Object.assign(Object.create(vmRefDefPrototype), obj);

/** Utils that ast on objects that are pointers */
export const isVmRefPtr = (obj) => isVmRefId(obj?.vmRefPtrId);
export const getVmRefPtrId = (obj) => obj?.vmRefPtrId;
