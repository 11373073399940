import {
  CLEAR_CHANNEL_NOTIFICATIONS,
  CLEAR__CHAT_NOTIFICATIONS,
  RAISE_CHAT_NOTIFICATIONS,
  INCREASE_CHANNEL_NOTIFICATIONS,
  IS_SOCKET_OPEN, IS_SUBSCRIBED, DECREASE_CHANNEL_NOTIFICATIONS, SET_CHANNEL_NOTIFICATIONS
} from '../constants/websocket-constants';

// Socket
export const updateIsSocketOpen = (bool) => ({ type: IS_SOCKET_OPEN, payload: bool });
export const updateIsSubscribed = (bool) => ({ type: IS_SUBSCRIBED, payload: bool });

// Channel Messages
export const increaseWsChannelNotifications = (notification) => ({ type: INCREASE_CHANNEL_NOTIFICATIONS, payload: notification }); // single notification
export const decreaseWsChannelNotifications = (notifications) => ({ type: DECREASE_CHANNEL_NOTIFICATIONS, payload: notifications }); // all notifications
export const clearWsNotifications = () => ({ type: CLEAR_CHANNEL_NOTIFICATIONS });
export const setWsChannelMessages = (notifications) => ({ type: SET_CHANNEL_NOTIFICATIONS, payload: notifications });

// chat
export const raiseWsChatNotification = () => ({ type: RAISE_CHAT_NOTIFICATIONS });
export const clearWsChatNotification = () => ({ type: CLEAR__CHAT_NOTIFICATIONS });
