import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeCarouselData = (componentStyles, theme) => `
  .jme-carousel-data {
    position: relative;
    overflow: hidden;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${
  theme.breakpoints.unit
}) {
    .jme-carousel-data{
      overflow-x: scroll;
    }
    .jme-carousel-data::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .jme-carousel-data {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  .jme-carousel-data-outLayer {
    position: relative;
  }
  .jme-carousel-data-outLayer-fullWidth {
    position: relative;
    overflow: hidden;
    width: 100vw;
    right: 50%;
    left: 50%;
    margin-right:-50vw;
    margin-left: -50vw;
  }
  .jme-carousel-data-indicator-wrapper {
    position: absolute;
    display: flex;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, -5px);
  }
  .jme-carousel-data-items-wrapper {
    position: relative;
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    transition: 0.4s ease;
    height:100%;
    left: 0px; /* this needed in order to make slide smoothly on first click*/
  }
  .jme-carousel-data-indicator {
    margin-right: 5px;
  }
  .jme-carousel-data-indicator.active {
    color: white;
    border: 1px solid black;
    border-radius: 50%;
  }
`;

export default stylesJmeCarouselData;
