import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiManualDataUpdate } from '../../global-state/redux/actions';
import { useModuleSettingsByName } from '../../global-hooks/useModuleSettings';
import { useIsUserLoggedIn } from '../../global-utils';
import { apiFavourtesGet } from './favourites-api';

const GetAllFavourites = () => {
  const { callName } = useModuleSettingsByName('addToFavouritesModule') ?? {};
  const isUserLoggedIn = useIsUserLoggedIn();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUserLoggedIn) return;

    apiFavourtesGet().then(({ data }) => {
      dispatch(apiManualDataUpdate(callName, data));
    });
  }, [isUserLoggedIn]);

  return null;
};

export default GetAllFavourites;
