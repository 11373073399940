import { setMyNetworking } from '../../../../global-state/redux/actions';
import store from '../../../../global-state/redux';
import { getEventId } from '../../core';
import { getAccessToken, getUserId } from '../../loginInfo';
import {
  getUrl,
  checkApiError,
  myNetworkingApiCall
} from '../myNetworkingApiUtils';
import { appendId, coerceToObj } from '../myNetworkingUtils';
import { QUESTIONSETS_SELECTOR_KEY } from './questionsetsConstants';
import { apiRequest } from '../../../api-request';
import { isArr } from '../../vmArray';

const { dispatch } = store;

const STORE_MASK_UPDATE_QUESTION_SETS = 'myNetworking/questionsets/put';
const STORE_MASK_GET_QUESTION_SETS = 'myNetworking/questionsets/get';

export const apiCreateQuestionSets = async (props) => {
  const { questions } = coerceToObj(props);

  if (!isArr(questions)) {
    return Promise.reject(
      new Error('Wrong function arguments. ', { cause: { questions } })
    );
  }

  const allInOne = {
    scene_mtalk_id: `Community_${+new Date()}`,
    questionsettype_id: 6, // always 6 (Chen's instructions)
    event_id: getEventId(),
    questions // questiontype_id: 1, // for single choice, 2 = multiple choice
  };

  const body = {
    all_in_one: JSON.stringify(allInOne)
  };

  return apiRequest({
    url: getUrl({ pathname: 'v1/questionsets.json' }),
    method: 'POST',
    body
  }).then(checkApiError);
};

export const apiUpdateQuestionSets = async (props) => {
  const {
    questionsetsId: questionsetsIdstr,
    chatgroupcommentId: chatgroupcommentIdStr
  } = coerceToObj(props);

  const questionsetsId = parseInt(questionsetsIdstr, 10);
  const chatgroupcommentId = parseInt(chatgroupcommentIdStr, 10);

  if (!questionsetsId || !chatgroupcommentId) {
    return Promise.reject(
      new Error('Wrong function arguments. ', {
        cause: { questionsetsId, chatgroupcommentId }
      })
    );
  }

  return apiRequest({
    url: getUrl({ pathname: `v1/questionsets/${questionsetsId}.json` }),
    method: 'PUT',
    body: { chatgroupcomment_id: chatgroupcommentId }
  }).then(checkApiError);
};

export const apiGetQuestionsets = (props) => {
  const {
    questionsetIds,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);

  if (!questionsetIds) return Promise.resolve({});

  const body = {
    access_token: getAccessToken(),
    event_id: getEventId(),
    questionset_ids: questionsetIds
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(STORE_MASK_GET_QUESTION_SETS, questionsetIds),
    pathname: `v1/users/${getUserId()}/questionsets.json`,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      const selectorKey = appendId(QUESTIONSETS_SELECTOR_KEY, questionsetIds);
      dispatch(setMyNetworking(selectorKey, res));
    }
    return res;
  });
};

export const apiSelectAnswer = (props) => {
  const {
    questionsetsId,
    questionId,
    answersIds,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);

  if (!questionsetsId || !questionId || !answersIds.length) return Promise.resolve({});

  const body = {
    access_token: getAccessToken(),
    event_id: getEventId(),
    all_in_one: JSON.stringify([
      {
        id: questionsetsId,
        questions: [
          {
            id: questionId,
            answers: answersIds.map((answer) => ({ id: answer }))
          }
        ]
      }
    ])
  };
  // return of the call same as questionsets.json
  return myNetworkingApiCall({
    method: 'POST',
    storeMask: [
      STORE_MASK_UPDATE_QUESTION_SETS,
      questionsetsId,
      questionId,
      answersIds.join('/')
    ].join('/'),
    pathname: `v1/users/${getUserId()}/questionsets.json`,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => res);
};
