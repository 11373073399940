import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../components';
import storageAndCookie from '../global-utils/storage-and-cookies';
import { logoutUser } from '../global-state/redux/actions';

const LogoutPage = () => {
  const { redirectAfterLogout } = useSelector((state) => state.appState?.settings?.appSettings) ?? {};
  const location = useLocation();
  const dispatch = useDispatch();
  const prevLoacation = redirectAfterLogout ?? storageAndCookie.get('previousLocation') ?? '/';
  const params = new URLSearchParams(location.search);
  const externalUrl = params.get('externalUrl');
  const decodedExternalUrl = externalUrl
    ? decodeURIComponent(externalUrl)
    : null;

  useEffect(() => {
    function deleteAllCookies() {
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      }
    }

    deleteAllCookies();
    localStorage.clear();
    dispatch(logoutUser());

    if (decodedExternalUrl) {
      window.location.href = decodedExternalUrl;
    } else {
      window.location = prevLoacation;
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2
      }}
    >
      <Loading />
    </div>
  );
};

export default LogoutPage;
