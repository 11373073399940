import base64 from 'base-64';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequestProcessor } from '../global-state/redux/actions';
import { useLoginInfo } from '../global-utils';
import { isArr } from '../global-utils/globalUtils';
import { reWriteProps } from '../global-utils/map-data-to-props';
import vmFunctions from '../global-utils/vmFunctions';

/**
 * @summary Create short access mask.
 * @description The apiDataInfo has to have modelName and apiEndpointName members. These fields have to
 * agree with derinitions in adminModels.
 * @param {object} apiDataInfoItme - API data info.
 * @returns {string} Mask to acces data via apiData property.
 * @example
 * getPropsMask({modelName: "news", apiEndpointName: "single"})
 * // returns 'news_single'
 */
export function getPropsMask(apiDataInfoItem) {
  const { uiTitle, modelName, apiEndpointName } = apiDataInfoItem;
  return uiTitle || `${modelName}_${apiEndpointName}`;
}
export const sortObj = (obj2sort) => (!obj2sort ? {} : Object.keys(obj2sort).sort().reduce(
  (obj, key) => {
    const extendedObj = { ...obj };
    extendedObj[key] = obj2sort[key];
    return extendedObj;
    // Here is an example doing it in one line (for Ksawery)
    // return {...obj, [key]: body[key]};
  },
  {}
));

/**
 * @description All the variables of an API call are contained in url and body. Use it to create unique ASCI string and use this string as a mask in store.data. This method is UTF8 safe.
 * @summary Create unique ASCI string from apiDataInfoItem properties, that can change between calls.
 * @param {object} apiDataInfoItme - API data info.
 * @returns {string} ASCI string
 * @example
 * getStoreMask({url: "v1/guidelines/23.json", body: {include_sponsors: 1})
 * // returns similar to: history_L3YxL2d1aWRlbGluZXMvMjEuanNvbnsiZXZlbnRfaWQiOjMyMywiaW5
 */
export function getStoreMask(apiDataInfoItem) {
  const {
    url, body, headers, headersAppend
  } = apiDataInfoItem;
  // I sort the body object, to avoid different values for
  // { sort_by: 'title', app_ids: 101 }
  // { app_ids: 101, sort_by: 'title' }
  const orderedBody = sortObj(body);
  const orderedHeaders = sortObj(headers);

  return `history_${base64.encode(
    JSON.stringify({
      // Filds that we assume, might be changed between calls (due to useage of dataMaps):
      url,
      body: orderedBody,
      headers: orderedHeaders,
      headersAppend
    })
  )}`;
}

const resolveMakeApiCallIf = (makeApiCallIf) => {
  if (makeApiCallIf === undefined) return true;
  if (!isArr(makeApiCallIf)) return false;
  return makeApiCallIf.every((el) => !!el);
};

/**
 * Debounce API call if:
 * a) we have data not older then refreshRate,
 * b) we are curently loading this API request.
 * We pass API request if: a) there is not history of this call, b) the data is older then refreshRate.
 * @summary Prevent API call if we have available up-to-date data at hand.
 * @param {array} dataInfo - history of the previous API calls
 * @param {object} apiDataInfoItem - array of API request information
 * @returns {boolean}
 */
export function ifToDebounceApiCall(dataInfo, apiDataInfoItem) {
  const { storeMask, makeApiCallIf, refreshRate = 5 * 60 * 1000 } = apiDataInfoItem; // default debounce interval : 5min
  // It is important that resolving API call-if is done before we check for mask
  // pressent. Otherwise we would make empty call.
  if (!resolveMakeApiCallIf(makeApiCallIf)) return true;
  if (!dataInfo?.[storeMask]) return false;
  if (dataInfo?.[storeMask].isLoading) return true;
  if (dataInfo?.[storeMask].isError && Date.now() - dataInfo[storeMask]?.timestamp > 10000) return false;
  return Date.now() - dataInfo[storeMask]?.timestamp < refreshRate;
}

/**
 * @summary Bring data from API calls, one dataset per apiDataInfo item.
 * @param {array} apiDataInfo - array of API reqest information
 * @param {object} urlParams - current url parasm
 * @returns {array} set of API responses based on apiDataInfo array
 */
const useAPIDataHook = (apiDataInfo = [], urlParams, urlSearchParams, options = {}) => {
  const [apiData, setApiData] = useState({});
  const store = useSelector((state) => state);
  const dataInfo = useSelector((state) => state.data);
  // TODO: Ksawery - this might be mistake and cause double API calls. On the other hand we need to reload
  // API calls after a user got logged in, coz some API calls should come after v0/logins/jwt - COMMENT OUT OD DATE :)
  // THIS SHOULD BE REFACTORED IN FAVOR OF USING appState.isAuthLoading!!!
  const userLoginInfo = useLoginInfo();

  const dispatch = useDispatch();
  const { dataBank = {} } = options;

  // useEffect together with useState solve the problem:
  // Warning: Cannot update a component (`DynamicCompRenderer`) while rendering a different component (`PageRenderer`)
  // To follow the stack: https://reactjs.org/link/setstate-in-render
  useEffect(() => {
    const apiDataNew = apiDataInfo.reduce((acc, entry) => {
      const entryReplaced = reWriteProps(
        {
          vmFunctions,
          urlParams,
          store,
          urlSearchParams: urlSearchParams
            ? Object.fromEntries(new URLSearchParams(urlSearchParams))
            : {},
          ...dataBank
        },
        entry
      );
      entryReplaced.propsMask = getPropsMask(entryReplaced);
      entryReplaced.storeMask = getStoreMask(entryReplaced);
      if (ifToDebounceApiCall(dataInfo, entryReplaced)) {
        return {
          ...acc,
          [entryReplaced.propsMask]: dataInfo[entryReplaced.storeMask]
        };
      }
      // TODO: something is missing here, I think that debouncing do not prevent call from happening
      dispatch(apiRequestProcessor(entryReplaced));
      return acc;
    }, {});
    // set new state only if any of the api responses are new:
    if (Object.keys(apiDataNew).some((el) => apiData[el] !== apiDataNew[el])) {
      setApiData(apiDataNew);
    }
  }, [apiDataInfo, urlParams, urlSearchParams, dataInfo, userLoginInfo]);

  return apiData;
};

export default useAPIDataHook;
export { useAPIDataHook as useApiDataHook };
