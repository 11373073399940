/* eslint-disable radix */
import { v4 as uuid } from 'uuid';
import { isArr, isInt, isObj } from '../globalUtils';
import {
  isVmPath,
  vmPathGetParent,
  vmPathGetParentChild,
} from '../vmPath/vmPath';
import {
  vmTreeFindVmPath,
  vmTreeGet,
  vmTreeSet,
  insertKey,
  vmTreeFilter
} from './vmTreeCore';

/**
 * @description Delete element pointed by vmPath.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {Array} vmPath - path to the element
 * @returns {Object|Array} deep copy of the vmTree until the level of the element
 * pointed by vmPath. Following applies:
 *   - vmPath is not a vmPath or empty array returns original vmTree
 *   - is vmPath points to non existing element return deep copy of the vmTree
 *   - if vmPath points to element in an array, filter out this element
 *   - if vmPath points to an key in an object, delete this key
 * After deletition the empty array or the empy objects can be left behind.
 */
export const vmTreeDelete = (vmTree, vmPath) => {
  if (!isVmPath(vmPath) || vmPath.length === 0) return vmTree;
  const [parentPath, childPath] = vmPathGetParentChild(vmPath);

  const obj = vmTreeGet(vmTree, parentPath);
  if (isArr(obj)) {
    return vmTreeSet(
      vmTree,
      parentPath,
      obj.filter((_, idx) => idx !== childPath)
    );
  }
  if (isObj(obj)) {
    const { [childPath]: unused, ...restObj } = obj;
    return vmTreeSet(vmTree, parentPath, restObj);
  }
  return vmTree;
};

/**
 * @description Copy element pointed by vmPathSrc to vmPathDest.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {Array} vmPathSrc - path to the element to copy
 * @param {Array} vmPathDest - path where to paste copied element
 * @returns {Object|Array} deep copy of the vmTree until the level of the element
 */
export const vmTreeCopy = (vmTree, vmPathSrc, vmPathDest, options = {}) => {
  if (!isVmPath(vmPathSrc) || !isVmPath(vmPathDest)) return vmTree;
  const { ifOverwrite = false } = options;

  const objSrc = vmTreeGet(vmTree, vmPathSrc);
  const objDest = vmTreeGet(vmTree, vmPathDest);
  if (objDest === undefined || ifOverwrite) {
    return vmTreeSet(vmTree, vmPathDest, objSrc);
  }
  return vmTree;
};

/**
 * @description Insert element into the place pointed by vmPath.
 * The insert operation makes sense only when vmPath points to array. If
 * vmPath points to a key in an object, you can use vmTreeSet.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {Array} vmPath - path where to insert element, last element must be valid index
 * @param {any} obj - object to insert
 * @returns {Object|Array} deep copy of the vmTree until the level of the
 * parent of the element. Then an array with inserted obj.
 */
export const vmTreeInsert = (vmTree, vmPath, obj) => {
  if (!isVmPath(vmPath)) return vmTree;
  const [parentPath, idx] = vmPathGetParentChild(vmPath);

  // since we will modify the array in place, we need to copy it first:
  const objParent = vmTreeGet(vmTree, parentPath);
  if (isArr(objParent) && isInt(idx)) {
    const objParentNew = [...objParent];

    // assigment can happen in any place of the array:
    if (idx >= objParentNew.length) objParentNew[idx] = obj;
    else objParentNew.splice(idx, 0, obj);

    return vmTreeSet(vmTree, parentPath, objParentNew);
  }
  // if we got a path that points to the parent.
  // like adding a new page. it will add to the array.
  const originalPathObj = vmTreeGet(vmTree, vmPath);
  if (isArr(originalPathObj)) {
    return vmTreeSet(vmTree, vmPath, [...originalPathObj, obj]);
  }

  return vmTree;
};

/**
 * @description Push element into the end of array pointed by vmPath.
 * The insert operation makes sense only when vmPath points to array. If
 * vmPath points to a key in an object, you can use vmTreeSet.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {Array} vmPath - path where to insert element, last element must be valid index
 * @param {any} obj - object to insert
 * @returns {Object|Array} deep copy of the vmTree until the level of the
 * parent of the element. Then an array with pushed obj.
 */
export const vmTreePush = (vmTree, vmPath, obj) => {
  if (!isVmPath(vmPath)) return vmTree;
  const objParent = vmTreeGet(vmTree, vmPath);
  if (isArr(objParent)) {
    return vmTreeSet(vmTree, vmPath, [...objParent, obj]);
  }
  return vmTree;
};

// This will do the duplicate
export const vmTreeDuplicate = (vmTree, vmPath) => {
  if (!isVmPath(vmPath)) return vmTree;
  const [parentPath, idx] = vmPathGetParentChild(vmPath);

  const myItem = vmTreeGet(vmTree, vmPath);

  const newVmTree = vmTreeInsert(
    vmTree,
    [...parentPath, idx + 1],
    insertKey(myItem)
  );

  return newVmTree;
};

/**
 * @description Move element pointed by vmPathSrc to vmPathDest.
 * if vmPathDest points to an index in an array, the element is put just
 * before this index. This is how the native js splice works.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {Array} vmPathSrc - path to the element to move
 * @param {Array} vmPathDest - path where to move target element
 * @returns {Object|Array} deep copy of the vmTree until the level of the element
 */
export const vmTreeMove = (vmTree, vmPathSrc, vmPathDest, options = {}) => {
  if (!isVmPath(vmPathSrc) || !isVmPath(vmPathDest)) return vmTree;

  // if pats are the same, effectivly we do not move anything:
  if (JSON.stringify(vmPathSrc) === JSON.stringify(vmPathDest)) return vmTree;

  // we need to mark the value to delete before we do any other manipulations.
  // This is because the idx of arrays can change if we move elements within
  // the same array. we replce velue to delete with a pair uuid <=> value:
  const objSrc = { key: uuid(), value: vmTreeGet(vmTree, vmPathSrc) };
  // and assign it to the vmPathSrc. This way we can find element to delete
  // via the uuid.
  let vmTreeNew = vmTreeSet(vmTree, vmPathSrc, objSrc);

  // if the parent of the vmPathdest is an array insert the moved
  // element to an array. Otherwise, we insert a key-value pair in
  // an object:
  const objParentDest = vmTreeGet(vmTree, vmPathGetParent(vmPathDest));
  if (isArr(objParentDest)) {
    vmTreeNew = vmTreeInsert(vmTreeNew, vmPathDest, objSrc.value);
  } else {
    const { ifOverwrite = false } = options;
    const objDest = vmTreeGet(vmTreeNew, vmPathDest);
    if (objDest === undefined || ifOverwrite) vmTreeNew = vmTreeSet(vmTreeNew, vmPathDest, objSrc.value);
  }

  // find source marked element and delete it
  const vmPathToDelete = vmTreeFindVmPath(
    vmTreeNew,
    (el) => el?.key === objSrc.key
  );
  return vmTreeDelete(vmTreeNew, vmPathToDelete);
};

/**
 * return paths
 */
export const vmTreeDiff = (vmTreeFirst, vmTreeSecond) => {
  // console.log('vmTree1, vmTree2', vmTreeFirst, vmTreeSecond);
  if (!vmTreeFirst || !vmTreeSecond) return undefined;

  const tmpErrsPath = {};
  const collectErrs = (vmTree1, vmTree2) => {
    vmTreeFilter(vmTree1, (obj, path) => {
      // console.log('IN FOLLOW', path, obj);
      const tmpRemoteValue = vmTreeGet(vmTree2, path);
      if (typeof obj === 'object' && obj !== null) {
        // it can be an array or an object.
        if (!isArr(obj)) {
          // it's an object. let's test the component name
          if (obj.componentName) {
            // it's a component. let's test the component name
            if (
              !tmpRemoteValue
              || (tmpRemoteValue
                && obj.componentName !== tmpRemoteValue.componentName)
            ) {
              // it's a component that has changed. let's add it to the list of errors.
              tmpErrsPath[path.join('.')] = 1;
              return true;
            }
          } else if (
            !tmpRemoteValue
            || (tmpRemoteValue && obj.length !== tmpRemoteValue.length)
          ) {
            // it's an array that has changed. let's add it to the list of errors.
            tmpErrsPath[path.join('.')] = 1;
            return true;
          }
        }
      } else if (typeof tmpRemoteValue === 'undefined' || (tmpRemoteValue && tmpRemoteValue !== obj)) {
        // it's not an object. it's premitive - let's compare
        if (path[path.length - 1] === 'id') {
          // we skip comparing ids
          return false;
        }
        tmpErrsPath[path.join('.')] = 1;
        return true;
      }
      return false;
    });
  };

  // start way with the first tree
  collectErrs(vmTreeFirst, vmTreeSecond);
  // start way with the second tree
  collectErrs(vmTreeSecond, vmTreeFirst);

  // convert tmpErrsPath to an array
  // eslint-disable-next-line no-restricted-globals
  const returnArr = Object.keys(tmpErrsPath)
    .map((key) => key.split('.'))
    // eslint-disable-next-line no-restricted-globals
    .map((key) => key.map((k) => (isNaN(k) ? k : parseInt(k))));
  // console.log('returnArr', returnArr);
  return returnArr;
  // return tmpErrsPath;
};

export const vmTreeComparePathArrays = (arr1, arr2) => {
  for (let i = 0; i < arr1.length && i < arr2.length; i++) {
    if (arr1[i] !== arr2?.[i]) {
      // if the entries are not identical
      return false;
    }
    if (((i + 1) === arr2.length || (i + 1) === arr1.length) && arr1[i] === arr2?.[i]) {
      // if we are at the end of the array
      // so we got far - and they MAY be identical
      return true;
    }
  }
  return true;
};
