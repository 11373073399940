import storageAndCookie from './storage-and-cookies';
import { isInsideAppContainer } from './vmFunctions/others';

// eslint-disable-next-line import/prefer-default-export
export const postToContainer = (e, href) => {
  console.log('postToContainer', e, href);
  if (isInsideAppContainer()) {
    // this handle download in container
    e.preventDefault();
    const message = 'pdf-download';
    const messageObj = { message, content: href };
    const stringifiedMessageObj = JSON.stringify(messageObj);
    window.webkit.messageHandlers.cordova_iab.postMessage(
      stringifiedMessageObj
    );
    storageAndCookie.set(
      'lastLocationBeforeLeavingApp',
      `${window.location.pathname}${window.location.search}${window.location.hash}`
    );
  }
};
