// Here we will put our API calls, and returns
import {
  API_REQUEST_STARTED,
  API_REQUEST_FINISHED,
  API_REQUEST_ERROR,
  API_MANUAL_DATA_UPDATE,
  API_REQUEST_DELETE_DATA,
  API_REQUEST_RESET_ALL,
} from '../constants';

export const initialData = {
  translations: {}, // This will be our SETTINGS API CALL !!!
};

const apiReducer = (state = initialData, action) => {
  const newState = {
    ...state,
  };
  switch (action.type) {
    case API_REQUEST_RESET_ALL:
      return initialData;
    case API_REQUEST_DELETE_DATA:
      delete newState[action.payload.parent];
      return newState;
    case API_REQUEST_STARTED:
      return {
        ...state,
        [action.payload.parent]: {
          data: state[action.payload.parent]?.data || [],
          headers: action.payload.headers || {},
          isReady: false,
          isLoading: true,
          isError: false,
        },
      };
    case API_REQUEST_FINISHED:
      return {
        ...state,
        [action.payload.parent]: {
          ...state[action.payload.parent],
          data: action.payload.data,
          headers: action.payload.headers,
          isReady: true,
          isLoading: false,
          timestamp: Date.now(),
        },
      };
    case API_MANUAL_DATA_UPDATE:
      return {
        ...state,
        [action.payload.parent]: {
          ...state[action.payload.parent] || {},
          data: action.payload.data,
          // isReady: true,
          isUpdated: true, // I'm not sure if i'll ever use it....
          isLoading: false
        },
      };
    case API_REQUEST_ERROR:
      return {
        ...state,
        [action.payload.parent]: {
          ...state[action.payload.parent],
          error: action.payload.err,
          isReady: false,
          isLoading: false,
          isError: true,
          timestamp: Date.now(),
        },
      };
    default:
      return state;
  }
};

export default apiReducer;
