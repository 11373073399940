import store from '../../../../../global-state/redux';
import { appendId, coerceToObj } from '../../myNetworkingUtils';
import { setMyNetworking } from '../../../../../global-state/redux/actions';
import { getEventId } from '../../../core';
import { myNetworkingApiCall } from '../../myNetworkingApiUtils';
import { getAccessToken, getUserId } from '../../../loginInfo/loginInfo';
import { COMMUNITYUSER_REQUEST_SELECTOR_KEY } from './communityUserRequestConstants';
import { FILESUPLOAD_TYPE_BANNER_IMAGE } from '../../settings/settingsConstants';
import { isChatRequestPending } from '../chatUtils';

// Constants used to store results of the API response in store:
const STORE_MASK_COMMUNITYUSER_REQUEST_GET = 'myNetworking/chats/requests/get';
const STORE_MASK_COMMUNITYUSER_REQUEST_CREATE = 'myNetworking/chats/requests/create';

const { dispatch } = store;

const PATHNAME = '/v1/chats.json';

export const apiGetCommunityUserRequests = (props) => {
  const { onSuccessVmEventList, onErrorVmEventList, skipDebounce } = coerceToObj(props);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    event_id: getEventId(),
    include_blocked: 1,
    include_deleted: 1, // we need to make some decisions when the chat is deleted by the partner
    include_partner_membership: 1,
    include_partner_filesupload_items: 1,
    include_partner_filesupload_items_filesupload_type: FILESUPLOAD_TYPE_BANNER_IMAGE,
    include_partner_tags: 1
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_COMMUNITYUSER_REQUEST_GET,
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      // save to relevant myNetworking location in redux:
      const requests = res.data.filter(isChatRequestPending);
      dispatch(setMyNetworking(COMMUNITYUSER_REQUEST_SELECTOR_KEY, requests));
    }

    return res;
  });
};

export const apiCommunityUserRequestCreate = (props) => {
  const {
    partnerId: partnerIdStr,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);

  const partnerId = parseInt(partnerIdStr, 10);

  if (!partnerId) return;

  const body = {
    per_page: 999,
    user_id: getUserId(),
    access_token: getAccessToken(),
    event_id: getEventId(),
    partner_user_id: partnerId,
    partner_user_blocked: 1,
    partner_user_deleted: 0
  };

  myNetworkingApiCall({
    method: 'POST',
    storeMask: appendId(STORE_MASK_COMMUNITYUSER_REQUEST_CREATE, partnerId),
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (res.isDebounced) return;

    apiGetCommunityUserRequests({ skipDebounce: true });
  });
};
