const cutHeaders = (res) => {
  const headersObj = {};
  res.headers.forEach((val, key) => {
    headersObj[key] = val;
  });
  return headersObj;
};

export const bodyToUrl = (body) => {
  let resStr = '';
  Object.keys(body).forEach((key) => {
    if (resStr.length === 0) {
      resStr += '?';
    } else {
      resStr += '&';
    }
    resStr += `${key}=${body[key]}`;
  });
  return resStr;
};

const defaultApiHeaders = ({ isFormData = false }) => {
  // Collection of default headers:
  const headers = {
    Authorization: `Basic ${btoa('m-events:kims')}`,
    'cache-control': 'no-cache'
  };

  // Image upload sends body as FormData, and fro these POST calls we shouldnot set
  // Content-Type as application/json:
  if (!isFormData) {
    headers['Content-Type'] = 'application/json';
  }
  return headers;
};

export const apiRequest = ({
  url = '',
  body = {},
  method = 'get',
  headers,
  headersAppend,
  signal
}) => {
  const isFormData = body instanceof FormData;

  let finalUrl = url;
  const sendParams = {
    method,
    headers: {
      ...(headers ?? defaultApiHeaders({ isFormData })),
      ...headersAppend
    },
    signal
  };
  if (method.toLocaleLowerCase() === 'get') {
    finalUrl += bodyToUrl(body);
  } else {
    // this could be PUT/POST/DELETE
    sendParams.body = isFormData ? body : JSON.stringify(body);
  }

  return fetch(finalUrl, sendParams).then((res) => res.json().then((result) => ({
    headers: cutHeaders(res),
    data: result
  })));
};

export const canNotCallApiRequestByTimeInterval = ({
  apiTimestamp = 0,
  timeInterval = 300,
  logFunc = (f) => f
}) => {
  /* Determin, if enought time passed from last call.

  Args:
    apiTimestamp(in milisec): if undefined we coerce to 0, which makes the last call apear to
      be done on 01.01.1970.
    timeInterval(in sec): default to 5 min.

  Because of async calls, the call to this function can happen just before or just after the
  the expected timeInterval. We allow for some variance by using jitter variable.

  This is aspecially true for periodig API calls. `setInterval(() => {}, timeInterval)`
  will trigger calls very close to expected timeInterval. However the time passed between
  this function call and last API call timestamp can be less or more then timeInterval.
  If we do not allow for jitter, then it might happen, that periodic calls will skip once
  in a while and effective call will happen at interval equal to 2*timeInterval. */
  const jitter = 0.95; // In case we are calling 5% before the nomina timeInterval

  // Params check:
  if (!Number.isInteger(apiTimestamp) || !Number.isInteger(timeInterval)) {
    logFunc('Wrong arguments:', { apiTimestamp, timeInterval });
    return false;
  }

  const timeSinceLastApiCall = Date.now() - apiTimestamp;
  const refreshTimeout = timeInterval * 1000;
  const canNotCall = jitter * refreshTimeout > timeSinceLastApiCall;

  logFunc(canNotCall ? 'Calling API too early' : 'Calling API', {
    timeSinceLastApiCall,
    refreshTimeout
  });
  return canNotCall;
};

// export default apiRequest;
