// keeps the module config. This can be
// safely saved in module, coz module config
// does not change within app lifetime (no need for reactivity)
let config = null;

function setConfig(cfg) {
  config = cfg;
}

function getConfig() {
  return config;
}

export { setConfig, getConfig };
