import { COMMUNITYUSER_REQUEST_SELECTOR_KEY } from './communityUserRequestConstants';
import { getMyNetworkingByKey } from '../../myNetworkingSelectors';
import { isChatRequestPending } from '../chatUtils';

export const getCommunityUserRequests = () => getMyNetworkingByKey(COMMUNITYUSER_REQUEST_SELECTOR_KEY)?.map((el) => {
  // we need to add chat array to partner, because many tempates which accept user object, expect the
  // information about user connections inside the userObj.chat[0] element, however this information is not in chat.partner object:
  const { partner, ...restEl } = el;
  return { ...el, partner: { ...partner, chat: [restEl] } };
}) ?? [];

export const isUserRequested = (partnerIdStr) => {
  const partnerId = parseInt(partnerIdStr, 10);
  const requests = getCommunityUserRequests();
  return requests.some(
    (el) => el.partner.id === partnerId && isChatRequestPending(el)
  );
};
