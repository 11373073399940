import { selectSettings } from './selectors';
import { buildRequest, canBuildeRequest } from './utils';
import { SET_USER_PROFILE_DATA } from './constants';
import { dispatchVmEvents } from '../../global-utils';

export const setUserProfileData = (val) => ({
  type: SET_USER_PROFILE_DATA,
  payload: val
});

export const fetchUserComplete = (props) => (dispatch, getState) => {
  const { onSuccess } = props ?? {};
  const configModule = selectSettings(getState());

  if (!canBuildeRequest(configModule)) {
    dispatch(
      setUserProfileData({
        apiStatus: 'error',
        error: { reason: configModule, message: 'Cannot parse URL' }
      })
    );
    return;
  }

  dispatch(setUserProfileData({ apiStatus: 'loading' }));
  fetch(buildRequest(configModule))
    .then((res) => res.json(res))
    .then((res) => res.filter((el) => el.name === 'UserComplete'))
    .then((res) => {
      dispatch(
        setUserProfileData({
          apiStatus: 'ready',
          isCompleted: res.length === 0
        })
      );
      dispatchVmEvents(onSuccess, { dataBankExtend: { res } });
    })
    .catch((error) => {
      dispatch(setUserProfileData({ apiStatus: 'error', error }));
    });
};
