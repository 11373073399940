/**
 * Set of functions that manipulate with API calls from:
 *   - v1/courses.json
 *   - v1/courses/{id}.json
 */
import { isNumber } from '../../globalUtils';
import { genericItemToMediaCard } from './apiMediaItems';

/** this constant exist, because the access to the medable_type is only in the
 * media_items.json call, however this constant is refered also in other context:
 */
const COURSE_MEDIABLE_TYPE = 'Course';

/**
 * Accept a course object and convert to object expected by MediaCard vmTemplate.
 * @param {Object} courseItem DB entity of a single course
 */
function courseToMediaCard(courseItem = {}, options = {}) {
  const { defaultImage, themeColor, uiTitle } = options;
  return {
    ...genericItemToMediaCard(courseItem),
    href: `/courses/${courseItem.id}`,
    mediableTypeUiTitle: uiTitle,
    mediableTypeColor: themeColor,
    image: courseItem.preview_url ?? defaultImage,
    tags: courseItem?.tags?.map((el) => el?.tag).join(', ')
  };
}

function coursesToMediaCard(courses = [], options = {}) {
  return courses
    .filter((el) => isNumber(el.id)) // this rejects elements with id === null or undefined
    .map((el) => courseToMediaCard(el, options));
}

function filterCoursesBySearchParams(courses = [], searchParamKey = '') {
  const paramsSearchValues = new URLSearchParams(window.location.search).get(searchParamKey);
  return paramsSearchValues ? courses?.filter((el) => paramsSearchValues.includes(el?.coursecategories?.[0]?.id)) : courses;
}

export {
  courseToMediaCard, coursesToMediaCard, filterCoursesBySearchParams, COURSE_MEDIABLE_TYPE
};
