import { isArr, isObj } from '../globalUtils';
import {
  vmRefDefFactory,
  getVmRefPtrId,
  isVmRefPtr,
  isVmRefLib
} from './vmRefUtils';

// CONSTANTS
export const VM_REF_WHITE = 'VM_REF_WHITE';
export const VM_REF_GRAY = 'VM_REF_GRAY';
export const VM_REF_BLACK = 'VM_REF_BLACK';
export const VM_REF_MAPPER_OBJ = 'VM_REF_MAPPER_OBJ';

// UTILS FUNCTIONS:
/** Generate objects with vmRefId as a key and props used in dept-first-search algo */
const initMarkers = (vmRefLib) => Object.keys(vmRefLib).reduce(
  (acc, vmRefId) => ({
    ...acc,
    [vmRefId]: {
      color: VM_REF_WHITE,
      discovered: -1,
      finished: -1,
      pi: null
    }
  }),
  {}
);

const depthFirstSearch = (vmRefLib) => {
  // CONFIG:
  let time = 0;
  const markers = initMarkers(vmRefLib);

  // If we enter a reference definition, mark the discover time
  // Once we exit traversing thie reference definition, mark the finished time
  // and set color to black (what means this definition is done).
  const visitVmRefDef = (vmRefId) => {
    time += 1;
    markers[vmRefId].discovered = time;
    markers[vmRefId].color = VM_REF_GRAY;

    const recurse = (obj) => {
      if (isVmRefPtr(obj)) {
        const vmRefPtrId = getVmRefPtrId(obj);
        if (markers[vmRefPtrId]?.color === VM_REF_WHITE) {
          markers[vmRefPtrId].pi = vmRefId;
          visitVmRefDef(vmRefPtrId);
        }
      }
      if (isArr(obj)) {
        obj.forEach((el) => recurse(el));
      }
      if (isObj(obj)) {
        Object.keys(obj).forEach((key) => recurse(obj[key]));
      }
    };

    recurse(vmRefLib[vmRefId]);

    time += 1;
    markers[vmRefId].finished = time;
    markers[vmRefId].color = VM_REF_BLACK;
  };

  Object.entries(markers).forEach(([vmRefId, marker]) => {
    if (marker.color === VM_REF_WHITE) visitVmRefDef(vmRefId);
  });

  return markers;
};

const topologicalSort = (vmRefLib) => {
  const markers = depthFirstSearch(vmRefLib);
  return Object.keys(markers).sort(
    (el1, el2) => markers[el1].finished - markers[el2].finished
  );
};

const defaultMappers = {
  [VM_REF_MAPPER_OBJ]: (vmRefPtr, vmRefDef, resolve) => {
    const { vmRefPtrId, ...restVmRefPtr } = vmRefPtr;
    const vmRefVal = vmRefDef.getVal();
    return Object.keys(restVmRefPtr).length
      ? {
        ...vmRefVal,
        ...resolve(restVmRefPtr) // properties pased to obj can also include references
      }
      : vmRefVal;
  }
};

/**
 * IMPORTANT - vmRefMap contains already resolved reference definitions!
 * This function is called in two different contextes:
 *   1. we call resolvePointers within getVmRefMap, then each
 *      itteration of forEach adds new resolved defintion. Remember that the
 *      forEach already iterates in sorted order!
 *   2. if we call it on arbitrary object, the reference library map is
 *      already resolved
 */
const resolvePointers = (objInit, vmRefMap) => {
  const recurse = (obj) => {
    if (isVmRefPtr(obj)) {
      const vmRefDef = vmRefMap.get(getVmRefPtrId(obj));

      // Rather unfortunate error - reference defintion doesn't exist!
      if (!vmRefDef) return undefined;

      const vmRefMapper = vmRefDef.getMapper();
      if (vmRefMapper) {
        const mapperFn = defaultMappers[vmRefMapper];
        return mapperFn(obj, vmRefDef, recurse);
      }
      return vmRefDef.getVal();
    }

    if (isArr(obj)) {
      // Few things to watch out for in following reduce:
      // 1. we start with original object, this is because we if the
      //    array do not contain any references, we need to return obj
      //    strict equal to original
      // 2. if recurse returned new obj we need to put it in relevant place
      //    that is why we track idx
      return obj.reduce((acc, el, idx) => {
        const ret = recurse(el);
        return ret !== el
          ? acc.map((el2, idx2) => (idx2 === idx ? ret : el2))
          : acc;
      }, obj);
    }

    if (isObj(obj)) {
      return Object.entries(obj).reduce((acc, [key, val]) => {
        const ret = recurse(val);
        return ret !== val ? { ...acc, [key]: ret } : acc;
      }, obj);
    }

    return obj;
  };

  return recurse(objInit);
};

const getVmRefMap = (vmRefLib) => {
  const vmRefMap = new Map();

  // We resolve pointers within the values of definitions in sorted order
  // already (after calling topologicalSort)! After each iteration vmRefMap
  // has one more resolve definition, that can be used by subsequent
  // reference definitions.
  topologicalSort(vmRefLib).forEach((vmRefId) => {
    // Create reference definiton (call factory function) from resolved
    // reference from vmRefLib
    const vmRefDefResolved = vmRefDefFactory(
      resolvePointers(vmRefLib[vmRefId], vmRefMap)
    );

    // And put it in the reference library map:
    vmRefMap.set(vmRefId, vmRefDefResolved);
  });
  return vmRefMap;
};

// We use factory function for creating reference processor because
// we prefere to keep vmRefMap as a state plus we need few helper functions
const vmRefProcessorFactory = () => {
  let vmRefMap = new Map();

  return {
    updateDefinitions(vmRefLib) {
      if (isVmRefLib(vmRefLib)) {
        vmRefMap = getVmRefMap(vmRefLib);
      }
      return this;
    },
    resolve: (obj) => resolvePointers(obj, vmRefMap),
    toString: () => JSON.stringify(Object.fromEntries(vmRefMap), null, 4)
  };
};

export {
  vmRefProcessorFactory,
  // We only export these for testting. No other place should use these functions
  // Inparticular they are not re-exported in index
  depthFirstSearch,
  topologicalSort
};
