import { lazy } from 'react';

export const JmtPermissionsCheck = lazy(() => import('./JmtPermissionCheck'));
// export const JmeIfElse = lazy(() => import('./JmeIfElse'));
export const JmeTimeout = lazy(() => import('./JmeTimeout'));
// export const ComponentsRouter = lazy(() => import('./ComponentsRouter'));
export const JmeMobileNavbarLogic = lazy(() => import('./JmeMobileNavbarLogic'));

export { default as JmeIfElse } from './JmeIfElse';
// export { default as JmeTimeout } from './JmeTimeout';
export { default as ComponentsRouter } from './ComponentsRouter';
// export { default as JmeMobileNavbarLogic } from './JmeMobileNavbarLogic';
