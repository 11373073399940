import PropTypes from 'prop-types';
// import componentsPropType from './componentsArrPropType';
import propToPropMapPropType from './propToPropMapPropType';
import stylesPropType from './stylesPropType';

const componentPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  componentName: PropTypes.string.isRequired,
  content: PropTypes.string,
  isDisabled: PropTypes.bool,
  hideWhen: PropTypes.array,
  showIf: PropTypes.array,
  note: PropTypes.string,
  styles: stylesPropType,
  // components: componentsPropType,
  propToPropMap: propToPropMapPropType,
  // It is obvious, that contentComponents should be defined as
  // PropTypes.objectOf(componentsPropType) - but this creates circular dependency,
  // so we settle on PropTypes.array
  contentComponents: PropTypes.objectOf(PropTypes.array)
});

export default componentPropType;
