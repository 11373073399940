import { vmRefProcessorFactory, VM_REF_MAPPER_OBJ } from './vmRefProcessor';

// Initiate reference processor to use when resolving pointers in pages
// We keep only one instance of this object across the vm2 application
const vmRefProcessorApp = vmRefProcessorFactory();

export * from './vmRefUtils';
export {
  VM_REF_MAPPER_OBJ,
  vmRefProcessorApp
};
