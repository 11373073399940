import { dataInsightTrack } from './utils';

export { trackPage, trackPageProgram, trackPageProfile } from './utils';

// DEPRICATED
// This function exists for backward compatibility.
// Possible projects that could use this vmFunction are: DGN, ERA, KOK
export const trackingEvent = (
  sourceApplicationAction,
  sourceApplication,
  sourceApplicationContext,
  entityId,
  pageTitle = '',
  sourceApplicationUrl = '',
  enable
) => dataInsightTrack({
  sourceApplicationAction,
  sourceApplication,
  sourceApplicationContext,
  entityId,
  pageTitle,
  sourceApplicationUrl: sourceApplicationUrl || undefined, // compatibility sends this as an empth string
  enable // not n use
});
