import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeNavbar = (componentStyles) => `
  .jme-navbar-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-navbar-items-wrapper .nav-item:first-child > a {
    padding-left: 0;
  }
`;

export default stylesJmeNavbar;
