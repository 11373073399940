import { clearWsChatNotification, clearWsNotifications } from '../../../global-state/redux/actions';
import store from '../../../global-state/redux';
import { navigate } from '../navigate';

const { getState, dispatch } = store;

export const getWs = () => getState()?.ws ?? {};

export const getWsNotificationsCount = () => getWs()?.channelMessageNotifications?.count;
export const getWsNotifications = () => getWs()?.channelMessageNotifications;

export const handleNotificationsClick = (navigateTo) => {
  // no need to get notifications in here because we are getting them on new websocket message
  dispatch(clearWsNotifications());
  if (navigateTo) navigate(navigateTo);
};

// Chat
export const isChatNotificationsOn = () => getWs()?.isChatNotifications;
export const removeChatNotification = () => dispatch(clearWsChatNotification());
