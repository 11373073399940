import { selectSettings as selectSettingsByName } from '../hooks';
import { withManageConfig } from '../manage-config';
import { MODULE_NAME } from './constants';

export const selectData = (store) => store?.appState.userProfileData;

export const selectSettings = (store) => {
  const moduleConfig = selectSettingsByName(MODULE_NAME)(store);
  return withManageConfig(moduleConfig);
};
