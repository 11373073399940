import { getUserId } from '../../../loginInfo';
import { isCommunityAdmin } from '../../../loginInfo/manageAdminUserRoles';
import { getMyNetworkingByKey } from '../../myNetworkingSelectors';
import { appendId } from '../../myNetworkingUtils';
import { USER_TYPE_MODERATOR, USER_TYPE_OWNER } from '../constants';
import {
  CHATGROUPS_SELECTOR_KEY,
  CHATGROUPS_SELECTOR_KEY_BY_ID
} from './chatgroupConstants';

const isUserTypeAdmin = (userType) => [USER_TYPE_OWNER, USER_TYPE_MODERATOR].includes(userType);

export const getChatgroups = () => getMyNetworkingByKey(CHATGROUPS_SELECTOR_KEY) ?? [];

export const getChatgroupById = (id) => {
  const selectorKey = appendId(CHATGROUPS_SELECTOR_KEY_BY_ID, id);
  return getMyNetworkingByKey(selectorKey) ?? {};
};

export const isGroupPublic = (groupId) => getChatgroupById(groupId)?.is_public;

export const getChatgroupModerators = (groupId) => getChatgroupById(groupId)
  ?.active_chatgroup_users?.filter((el) => isUserTypeAdmin(el.user_type))
  .map((el) => el.user);

export const isGroupMember = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const chatgroups = getChatgroups();
  return chatgroups.some((el) => el.id === groupId);
};
export const isNotGroupMember = (groupId) => !isGroupMember(groupId);

export const isGroupAdmin = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const chatgroups = getChatgroups();
  // TODO: This logic is not enought when a group can have more then one admin.
  const me = chatgroups
    .find((el) => el.id === groupId)
    ?.active_chatgroup_users?.find((el) => el.user.id === getUserId()) ?? {};

  return isUserTypeAdmin(me.user_type);
};

export const isNotGroupAdmin = (groupIdStr) => !isGroupAdmin(groupIdStr);

// Conditions to be able to invite people to groups:
//   1. be group member
//   2. group has to be open
export const canMemberInvitePeople = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const userId = getUserId();
  const {
    active_chatgroup_users: activeChatgroupUsers = [],
    is_public: isPublic
  } = getChatgroupById(groupId) ?? {};

  const isMember = activeChatgroupUsers.some((el) => el.user.id === userId);
  return isMember && isPublic;
};

export const isActiveChatgroupUser = (userIdStr, groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const userId = parseInt(userIdStr, 10);
  const { active_chatgroup_users: activeChatgroupUsers = [] } = getChatgroupById(groupId) ?? {};

  return activeChatgroupUsers.some((el) => el.user.id === userId);
};

export const isNotActiveChatgroupUser = (userId, groupId) => !isActiveChatgroupUser(userId, groupId);

export const canAccessGroupDetails = (groupId) => isGroupMember(groupId) || isCommunityAdmin();
