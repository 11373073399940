export const extractCategories = (apiRes) => {
  if (!apiRes) return null;
  if (apiRes?.favouriteable?.categories) return apiRes.favouriteable?.categories;
  if (apiRes?.categories) return apiRes.categories;
  return null;
};

export const extractId = (apiRes) => {
  if (!apiRes) return null;
  if (apiRes?.favouriteable?.id) return apiRes.favouriteable?.id;
  if (apiRes?.body?.id) return apiRes.body.id;
  if (apiRes?.id) return apiRes.id;
  return null;
};

export const extractSlugFromGuidelines = (apiRes) => {
  if (!apiRes) return null;
  if (apiRes?.favouriteable?.slug) return apiRes.favouriteable.slug;
  if (apiRes?.body?.slug) return apiRes?.body?.slug;
  return apiRes?.slug;
};
