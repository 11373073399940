import { getAccessToken } from '../../../loginInfo';
import { myNetworkingApiCall } from '../../myNetworkingApiUtils';
import { coerceToObj } from '../../myNetworkingUtils';
import { apiGetChatgroupById } from '../chatgroups';
import { USER_TYPE_MODERATOR } from '../constants';

const STORE_MASK_CHATGROUPUSER_UPDATE = 'myNetworking/chatgroupusers/update';
const getPathname = (id) => `/v1/chatgroup_users/${id}.json`;

const apiChatgroupusersUpdate = (props) => {
  const {
    chatgroupUserId, groupId, userType, ...restProps
  } = coerceToObj(props);

  const body = {
    id: chatgroupUserId,
    access_token: getAccessToken(),
    user_type: userType
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: `${STORE_MASK_CHATGROUPUSER_UPDATE}_${chatgroupUserId}`,
    pathname: getPathname(chatgroupUserId),
    body,
    ...restProps
  }).then((res) => {
    if (res.debounce) return;

    apiGetChatgroupById({ groupId, skipDebounce: true });
  });
};

export const apiChatgroupusersSetModerator = (props) => apiChatgroupusersUpdate({ userType: USER_TYPE_MODERATOR, ...props });
export const apiChatgroupusersUnsetModerator = (props) => apiChatgroupusersUpdate({ userType: '', ...props });
