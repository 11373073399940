import { componentStylesToCss } from '../componentStylesUtils';

const stylesCarouselButtons = (componentStyles, theme) => `
  .carouselButtons-wrapper {
    color: ${theme.colors.primary?.main};
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .carouselButtons-buttonGroups {
    position: absolute;
    width: 100%;
    height: auto;
  }
  .carouselButtons-buttonGroupUp {
    top: 0px;
  }
  .carouselButtons-buttonGroupDown {
    bottom: 50px;
  }
  .carouselButtons-arrows {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    width: 50px;
    height: 50px;
    transition: 0.3s;
    opacity: 0.6;
    padding: 10px;
    background-color: #7070709e;
    cursor: pointer;
  }
  .carouselButtons-arrows:hover {
    background-color: #707070e0;
    opacity: 1;
  }
  .carouselButtons-arrowRight {
    right: 0;
    z-index: 1;
    color: white;
  }
  .carouselButtons-arrowLeft {
    left: 0;
    z-index: 1;
    color: white;
  }
`;

export default stylesCarouselButtons;
