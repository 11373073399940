import jsonToJsxComponent from '../jsonToJsxComponent';
import ContentsessionPageJson from './ContentsessionPage.json';
import ContentsessionTitleAndDescriptionJson from './ContentsessionTitleAndDescription.json';
import AddToCalendarButtonJson from './AddToCalendarButton.json';

const ContentsessionPage = jsonToJsxComponent(ContentsessionPageJson);
const ContentsessionTitleAndDescription = jsonToJsxComponent(ContentsessionTitleAndDescriptionJson);
const AddToCalendarButton = jsonToJsxComponent(AddToCalendarButtonJson);

export {
  ContentsessionPage,
  ContentsessionTitleAndDescription,
  AddToCalendarButton
};
