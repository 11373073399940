import jsonToJsxComponent from '../jsonToJsxComponent';
import MediaCardOneJson from './MediaCardOne.json';
import MediaPurchaseCardJson from './MediaPurchaseCard.json';
import MediaCardThreeJson from './MediaCardThree.json';
import MediaCardFourJson from './MediaCardFour.json';
import MediaCardActionBottomJson from './MediaCardActionBottom.json';
import MediaUsertJson from './MediaUser.json';
import MediaCourseBoxJson from './MediaCourseBox.json';
import MediaContentBoxJson from './MediaContentBox.json';
import MediaHeaderJson from './MediaHeader.json';
import MediaKeyInfoCardJson from './MediaKeyInfoCard.json';
import MediaMembersCollapseCardJson from './MediaMembersCollapseCard.json';
// Presentations
import PresentationPageNoAccessJson from './presentation/PresentationPageNoAccess.json';
import PresentationPageAccessJson from './presentation/PresentationPageAccess.json';
import PresentationSessionBoxJson from './presentation/PresentationSessionBox.json';
import MediaContentSessionJson from './presentation/MediaContentSession.json';
// Webinars
import WebinarPageAccessJson from './WebinarPageAccess.json';
import WebinarPageNoAccessJson from './WebinarPageNoAccess.json';
import MediaSectionTitleJson from './MediaSectionTitle.json';
import MediaButton1Json from './MediaButton1.json';
import MediaButton2Json from './MediaButton2.json';
import MediaButton2ContainedJson from './MediaButton2Contained.json';
import MediaButtonContainedOutlinedJson from './MediaButtonContainedOutlined.json';
import MediaHorizontalDisplayJson from './MediaHorizontalDisplay.json';
import MediaItemTitleJson from './MediaItemTitle.json';
import MediaUsersListJson from './MediaUsersList.json';
import MediaWebinarSessionJson from './MediaWebinarSession.json';
import MediaPlayerJson from './MediaPlayer.json';
import MediaTypeChipJson from './MediaTypeChip.json';
import MediaFilterPageJson from './MediaFilterPage.json';
import MediaRecommendedForYouJson from './MediaRecommendedForYou.json';
// Abstract
import AbstractPageJson from './AbstractPage.json';
import ResourceType3PageAccessJson from './ResourceType3PageAccess.json';
import ResourceType4PageAccessJson from './ResourceType4PageAccess.json';

const MediaCardOne = jsonToJsxComponent(MediaCardOneJson);
const MediaPurchaseCard = jsonToJsxComponent(MediaPurchaseCardJson);
const MediaCardThree = jsonToJsxComponent(MediaCardThreeJson);
const MediaCardFour = jsonToJsxComponent(MediaCardFourJson);
const MediaCardActionBottom = jsonToJsxComponent(MediaCardActionBottomJson);
const MediaUser = jsonToJsxComponent(MediaUsertJson);
const MediaCourseBox = jsonToJsxComponent(MediaCourseBoxJson);
const MediaContentBox = jsonToJsxComponent(MediaContentBoxJson);
const MediaHeader = jsonToJsxComponent(MediaHeaderJson);
const MediaKeyInfoCard = jsonToJsxComponent(MediaKeyInfoCardJson);
const MediaMembersCollapseCard = jsonToJsxComponent(MediaMembersCollapseCardJson);
// Presentations
const PresentationPageNoAccess = jsonToJsxComponent(PresentationPageNoAccessJson);
const PresentationPageAccess = jsonToJsxComponent(PresentationPageAccessJson);
const PresentationSessionBox = jsonToJsxComponent(PresentationSessionBoxJson);
const MediaContentSession = jsonToJsxComponent(MediaContentSessionJson);
// Webinars
const WebinarPageAccess = jsonToJsxComponent(WebinarPageAccessJson);
const WebinarPageNoAccess = jsonToJsxComponent(WebinarPageNoAccessJson);
const MediaSectionTitle = jsonToJsxComponent(MediaSectionTitleJson);
const MediaButton1 = jsonToJsxComponent(MediaButton1Json);
const MediaButton2 = jsonToJsxComponent(MediaButton2Json);
const MediaButton2Contained = jsonToJsxComponent(MediaButton2ContainedJson);
const MediaButtonContainedOutlined = jsonToJsxComponent(MediaButtonContainedOutlinedJson);
const MediaHorizontalDisplay = jsonToJsxComponent(MediaHorizontalDisplayJson);
const MediaItemTitle = jsonToJsxComponent(MediaItemTitleJson);
const MediaUsersList = jsonToJsxComponent(MediaUsersListJson);
const MediaWebinarSession = jsonToJsxComponent(MediaWebinarSessionJson);
const MediaPlayer = jsonToJsxComponent(MediaPlayerJson);
const MediaTypeChip = jsonToJsxComponent(MediaTypeChipJson);
const MediaFilterPage = jsonToJsxComponent(MediaFilterPageJson);
const MediaRecommendedForYou = jsonToJsxComponent(MediaRecommendedForYouJson);
// Abstract
const AbstractPage = jsonToJsxComponent(AbstractPageJson);
const ResourceType3PageAccess = jsonToJsxComponent(ResourceType3PageAccessJson);
const ResourceType4PageAccess = jsonToJsxComponent(ResourceType4PageAccessJson);

export {
  MediaCardOne,
  MediaPurchaseCard,
  MediaCardThree,
  MediaCardFour,
  MediaCardActionBottom,
  MediaUser,
  MediaCourseBox,
  MediaContentBox,
  MediaHeader,
  MediaKeyInfoCard,
  MediaMembersCollapseCard,
  PresentationPageNoAccess,
  PresentationPageAccess,
  PresentationSessionBox,
  WebinarPageAccess,
  WebinarPageNoAccess,
  MediaSectionTitle,
  MediaButton1,
  MediaButton2,
  MediaButton2Contained,
  MediaButtonContainedOutlined,
  MediaHorizontalDisplay,
  MediaItemTitle,
  MediaUsersList,
  MediaWebinarSession,
  MediaPlayer,
  MediaTypeChip,
  MediaFilterPage,
  MediaRecommendedForYou,
  AbstractPage,
  ResourceType3PageAccess,
  ResourceType4PageAccess,
  MediaContentSession
};
