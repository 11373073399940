import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSettings } from '../model/hooks';

const RedirectToEventsAirAuth = () => {
  const { eventsAirPageUrl } = useSettings() ?? {};
  const navigate = useNavigate();

  useEffect(() => {
    navigate(eventsAirPageUrl);
  }, []);

  return null;
};

export default RedirectToEventsAirAuth;
