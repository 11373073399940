import debounce from 'debounce';
import {
  getEventId,
  getServerAddr
} from '../../../global-utils/vmFunctions/core';
import { getUserId } from '../../../global-utils/vmFunctions/loginInfo';

const m = new Map();

function trackingInit(playerSettings) {
  const videosStatus = {};
  let duration = -60;
  let trackingStatus = '';
  let lastSync = new Date();
  let playbackId = null;
  let videos;
  const { id: videoId, sessionRecording } = playerSettings ?? {};

  const averageDurationVideoTrackingDuration = () => {
    const now = new Date().getTime();
    let differenceMs = now - lastSync.getTime();
    differenceMs /= 1000;
    if (!getUserId()) return differenceMs;

    duration += 60;

    if (duration > 0 && differenceMs > 59) {
      lastSync = new Date();
      const headers = {
        Authorization: `Basic ${btoa('m-events:kims')}`
      };
      const url = `https://${getServerAddr()}/v1/playbacks/${playbackId}.json?duration_seconds=${duration}`;
      const config = {
        method: 'PUT',
        headers
      };
      fetch(url, config)
        .then((result) => result.data)
        .catch((error) => error);
    }

    if (trackingStatus === 'start') {
      setTimeout(() => {
        averageDurationVideoTrackingDuration();
      }, 60000);
    }

    return null;
  };

  function posterDurationHandler(params) {
    switch (params) {
      case '25%':
        break;
      case '50%':
        break;
      case '75%':
        break;
      case '100%':
        trackingStatus = 'stop';
        break;
      default:
        break;
    }
  }

  function posterPlayEventHandler(e) {
    switch (e.type) {
      case 'click':
        trackingStatus = 'start';
        averageDurationVideoTrackingDuration();
        break;
      default:
        break;
    }
  }

  function posterPauseEventHandler(e) {
    switch (e.type) {
      case 'click':
        trackingStatus = 'stop';
        break;
      default:
        break;
    }
  }

  function eventHandler(e) {
    // This is the funcion that is gonna handle the event sent by the player listeners
    // This event type is sent everytime the player updated it's current time,
    // We're using for the % of the video played.
    if (e.type === 'timeupdate') {
      // Let's set the save the current player's video time in our status object
      videosStatus[e.target.id].current = Math.round(e.target.currentTime);
      // We just want to send the percent events once
      const pct = Math.floor(
        (100 * videosStatus[e.target.id].current) / e.target.duration
      );
      // TODO: check if for is really needed
      for (const j in videosStatus[e.target.id]._progress_markers) {
        if (pct >= j && j > videosStatus[e.target.id].greatest_marker) {
          videosStatus[e.target.id].greatest_marker = j;
        }
      }
      // current bucket hasn't been already sent to GA?, let's push it to GTM
      if (
        videosStatus[e.target.id].greatest_marker
        && !videosStatus[e.target.id]._progress_markers[
          videosStatus[e.target.id].greatest_marker
        ]
      ) {
        videosStatus[e.target.id]._progress_markers[
          videosStatus[e.target.id].greatest_marker
        ] = true;
      }
    } else if (e.type === 'play') {
      trackingStatus = 'start';
      averageDurationVideoTrackingDuration();
    } else if (e.type === 'pause') {
      trackingStatus = 'stop';
    } else if (e.type === 'ended') {
      trackingStatus = 'stop';
      // If the user ends playing the video, an Finish video will be pushed
      // ( This equals to % played = 100 )
    }
  }

  const readyTrackingForJMEPlayer = () => {
    const divisor = 25;

    if (videos?.[0]) {
      // TODO: check if for is really needed
      for (let i = 0; i < videos.length; i += 1) {
        // In order to have some id to reference in our status object,
        // we are adding an id to the video objects
        // that don't have an id attribute.
        let videoTagId;
        if (!videos[i].getAttribute('id')) {
          // Generate a random alphanumeric string to use is as the id
          videoTagId = `html5_video_${Math.random().toString(36).slice(2)}`;
          videos[i].setAttribute('id', videoTagId);
        } else {
          // Current video has alredy a id attribute, then let's use it
          videoTagId = videos[i].getAttribute('id');
        }
        // Video Status Object declaration
        videosStatus[videoTagId] = {};
        // save the highest percent mark played by the user in the current video.
        videosStatus[videoTagId].greatest_marker = 0;
        // set the progress markers, so we can know afterwards which ones have been already sent.
        videosStatus[videoTagId]._progress_markers = {};
        for (let j = 0; j < 100; j += 1) {
          // TODO: check if for is really needed
          videosStatus[videoTagId].progress_point = divisor * Math.floor(j / divisor);
          videosStatus[videoTagId]._progress_markers[
            videosStatus[videoTagId].progress_point
          ] = false;
        }
        // On page DOM, all players currentTime is 0
        videosStatus[videoTagId].current = 0;
        //  setting the event listeners.
        videos[i].addEventListener('play', eventHandler, false);
        videos[i].addEventListener('pause', eventHandler, false);
        videos[i].addEventListener('ended', eventHandler, false);
        videos[i].addEventListener('timeupdate', eventHandler, false);
      }
    } else if (
      document.getElementsByClassName('mEventsPlayer-container-eposter')[0]
      || document.getElementsByClassName('mEventsPlayer-container-poster')[0]
    ) {
      trackingStatus = 'start';
      averageDurationVideoTrackingDuration();
    } else {
      document
        .getElementsByClassName('mEventsPlayer-play')[0]
        .addEventListener('click', posterPlayEventHandler, false);
      document
        .getElementsByClassName('mEventsPlayer-pause')[0]
        .addEventListener('click', posterPauseEventHandler, false);
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutationRecord) => {
          const el = document.getElementsByClassName(
            'mEventsPlayer-progressbar-actual'
          );
          posterDurationHandler(el[0].style.width);
        });
      });
      const target = document.getElementsByClassName(
        'mEventsPlayer-progressbar-actual'
      )[0];
      observer.observe(target, {
        attributes: true,
        attributeFilter: ['style'],
        childList: true,
        characterData: true
      });
    }
  };

  function averageDurationVideoTrackingInit() {
    if (getUserId() && videoId) {
      const headers = {
        Authorization: `Basic ${btoa('m-events:kims')}`
      };
      const params = sessionRecording
        ? {
          contentsession_manage_id: parseInt(videoId, 10),
          user_id: getUserId(),
          event_id: getEventId(),
          session_id: 'recording'
        }
        : {
          resource_id: parseInt(videoId, 10),
          user_id: getUserId(),
          session_id: null,
          event_id: getEventId()
        };
      const url = `https://${getServerAddr()}/v1/playbacks.json`;
      const formData = new FormData();
      // Dynamically append each key-value pair from the params object to formData
      Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value !== null ? value : ''); // Convert null values to an empty string if needed
      });

      const config = {
        method: 'POST',
        headers,
        body: formData
      };

      fetch(url, config)
        .then((result) => result.json())
        .then((res) => {
          playbackId = res.id;
          readyTrackingForJMEPlayer();
        })

        .catch((error) => console.log(error));
    }
    return null;
  }

  let ret;
  if (m.has(videoId)) ret = m.get(videoId);
  else {
    // @high debounce time - we initiate only once, should be boolean switch but
    // I realized it too late.
    ret = debounce(averageDurationVideoTrackingInit, 1000000, { immediate: true });
    ret.setVideos = (videos_) => {
      videos = videos_;
    };
    m.set(videoId, ret);
  }

  return ret;
}

const deleteTracking = (id) => {
  m.get(id)?.clear();
  m.delete(id);
};

export { deleteTracking, trackingInit };
