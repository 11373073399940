import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModuleSettingsByName } from '../../global-hooks';
import { raiseWsChatNotification } from '../../global-state/redux/actions/websocket-actions';
import { getUserId } from '../../global-utils/vmFunctions/loginInfo';
import useWebSocket from './useWebsocket';
import { apiGetNotifications } from '../../global-utils/vmFunctions/myNetworking/notifications';

const WebsocketManager = () => {
  // baseApi and channelName exists only when the module isActive: true
  const {
    baseApi,
    channelName,
    maxAttemptsToConnect,
    reconnectInterval
  } = useModuleSettingsByName('websocketModule') ?? {};
  const { isSubscribed } = useSelector((state) => state?.ws) ?? {};
  const userId = getUserId();
  const dispatch = useDispatch();

  const onNewMessage = (msgObj) => {
    if (msgObj?.message?.channel_message?.message_type === 'User') {
      // make get notifications call and increase notifications
      apiGetNotifications({ skipDebounce: true });
    }
    if (msgObj?.message?.channel_message?.message_type === 'Chat') {
      dispatch(raiseWsChatNotification());
    }
  };

  const {
    isSocketOpened, isCommunicable, subscribe, unsubscribe
  } = useWebSocket(
    baseApi,
    onNewMessage,
    channelName,
    maxAttemptsToConnect,
    reconnectInterval
  );

  useEffect(() => {
    if (
      isSocketOpened
      && userId
      && baseApi
      && channelName
      && !isSubscribed
      && isCommunicable) {
      subscribe(channelName);
    }
    if (!isCommunicable && isSocketOpened && isSubscribed) unsubscribe();
  }, [isSocketOpened, subscribe, userId, baseApi, channelName, isSubscribed, isCommunicable]);

  return null;
};

export default WebsocketManager;
