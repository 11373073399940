import { coerceToObj } from '../myNetworkingUtils';
import { getEventId } from '../../core';
import { myNetworkingApiCall } from '../myNetworkingApiUtils';
import { getAccessToken, getUserId } from '../../loginInfo/loginInfo';
import { SETTING_COMMUNICABLE_TITLE, SETTING_DECLINE_COMMUNICABLE_TITLE, SETTING_EMAILABLE_TITLE } from './settingsConstants';
import { getAgreements, getNetworkingSettings } from './settingsSelectors';
import { apiStatusIsLoading } from '../../vmApi/apiUtils';

const STORE_MASK_SETTINGS_AGREEMENTS_GET = 'myNetworking/settings/agreements/get';
const STORE_MASK_SETTINGS_AGREEMENTS_SET = 'myNetworking/settings/agreements/set';
export const USER_SETTINGS = 'user_settings';
export const VISIBILITY_SETTINGS = 'visibility_settings';

const PATHNAME = '/v1/agreements.json';
const getPathname = (str) => `/v1/agreements/${str}.json`;

export const apiGetAgreements = (props) => {
  const { skipDebounce, agreementType } = coerceToObj(props);
  const body = {
    access_token: getAccessToken(),
    user_id: getUserId(),
    event_id: getEventId(),
    agreement_type: agreementType
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_SETTINGS_AGREEMENTS_GET,
    pathname: PATHNAME,
    body,
    skipDebounce
  });
};

export const getApiGetAgreementsIsLoading = () => apiStatusIsLoading(STORE_MASK_SETTINGS_AGREEMENTS_GET);

export const apiSetAgreementsUserSettings = (props) => {
  const {
    skipDebounce,
    communicable,
    emailable,
    onSuccessVmEventList
  } = coerceToObj(props);

  const agreementIds = [];
  const agreements = getAgreements();

  // This one is complicated, because for the API POST call we have to send ids. However the IDs change
  // with event_id, so first we have to find it by title:
  // if communicable set to false, send Decline-communicable id and Communicable will become false on Chen's side
  // if communicable set to true, send Communicable id and Decline-communicable will become false on Chen's side
  const communicableId = agreements.find(
    (el) => el.title === (communicable ? SETTING_COMMUNICABLE_TITLE : SETTING_DECLINE_COMMUNICABLE_TITLE)
  )?.id;
  const emailableId = agreements.find((el) => (el.title === SETTING_EMAILABLE_TITLE))?.id;
  if (emailable && emailableId) agreementIds.push(emailableId);
  if (communicableId) agreementIds.push(communicableId);

  const body = {
    access_token: getAccessToken(),
    user_id: getUserId(),
    event_id: getEventId(),
    agreement_ids: agreementIds.join(),
    agreement_type: USER_SETTINGS
  };

  return myNetworkingApiCall({
    method: 'POST',
    onSuccessVmEventList,
    storeMask: STORE_MASK_SETTINGS_AGREEMENTS_SET,
    pathname: getPathname('user_agreements'),
    body,
    skipDebounce
  });
};

export const apiSetAgreementsVisibilitySettings = (props) => {
  const {
    skipDebounce, biography, email, workplace
  } = coerceToObj(props);

  const networkingSettings = getNetworkingSettings();
  const getAgreementId = (title) => networkingSettings.find((el) => el.title === title)?.id;

  const agreementIds = [];
  if (biography !== '') agreementIds.push(getAgreementId(biography));
  if (workplace !== '') agreementIds.push(getAgreementId(workplace));
  if (email !== '') agreementIds.push(getAgreementId(email));

  const body = {
    access_token: getAccessToken(),
    user_id: getUserId(),
    event_id: getEventId(),
    agreement_ids: agreementIds.join(','),
    agreement_type: VISIBILITY_SETTINGS
  };

  return myNetworkingApiCall({
    method: 'POST',
    storeMask: STORE_MASK_SETTINGS_AGREEMENTS_SET,
    pathname: getPathname('user_agreements'),
    body,
    skipDebounce
  });
};
