import { useEffect, useMemo, useState } from 'react';
import retry from 'retry';
import { MODULE_NAME } from './constants';
import {
  useIsActive as useIsActiveModules,
  useSettings as useSettingsModules
} from '../../hooks';
import { dataInsightSchema } from './schema';

export const useIsActive = () => useIsActiveModules(MODULE_NAME);
export const useSettings = () => useSettingsModules(MODULE_NAME);

export const useConfig = () => {
  const settings = useSettings() ?? {};
  const config = useMemo(() => dataInsightSchema.parse(settings || {}), []);
  return config;
};

/* The data-insight module makes JS imports. The code in the JS imported script
assignes object to the window.DataInsightTracking. By checking
   window.DataInsightTracking !== undefined
we can conclude, if the loading of the JS has finished, and we can continue with the
initialization of the tracking. useDIT listens to the window.DataInsightTracking
and returns it when it is ready. */
export const useDIT = () => {
  const [dit, setDit] = useState();

  useEffect(() => {
    const op = retry.operation();
    op.attempt(() => {
      const tempDit = window.DataInsightTracking;
      if (tempDit) {
        setDit(tempDit);
      } else {
        op.retry(true);
      }
    });

    return () => {
      op.reset();
    };
  }, []);

  return dit;
};
