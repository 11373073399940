import React from 'react';
import PropTypes from 'prop-types';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../../global-prop-types';
import GridColumn from './GridColumn';

const JmeColumnsInARow = (props) => {
  const {
    nbOfColumns,
    parent,
    variant,
    styles,
    components,
    ...restProps
  } = props;

  const numberOfColumnsMapper = () => (
    (nbOfColumns && Object.keys(nbOfColumns).length)
      ? Object.keys(nbOfColumns)
        // ?.map((el) => (nbOfColumns[el] ? `row-cols-${el === 'xs' ? '' : el}-${nbOfColumns[el]}` : ''))
        // eslint-disable-next-line prefer-template
        ?.map((el) => (nbOfColumns[el] ? ('row-cols-' + (el === 'xs' ? '' : (el + '-')) + nbOfColumns[el]) : ''))
        ?.join(' ')
      : ''
  );

  return (
    <div
      className={`row ${numberOfColumnsMapper()} ${variant || ''}`}
      style={{ rowGap: 32, ...styles?.row }}
    >
      {components.map((comp) => (
        <GridColumn
          key={comp.id}
          components={[comp]}
          {...restProps}
          parent={[...parent, 'JmeColumnsInARow']}
          styles={{ wrapper: styles?.column }}
        />
      ))}
    </div>
  );
};

JmeColumnsInARow.propTypes = {
  nbOfColumns: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
  }),
  variant: PropTypes.string,
  parent: PropTypes.array,
  styles: stylesPropType,
  components: componentsPropType,
};

JmeColumnsInARow.vmPropTypes = {
  nbOfColumns: vmPropTypes.columnSizesPerBreakpoint,
  variant: vmPropTypes.string,
  styles: {
    row: vmPropTypes.styles,
    column: vmPropTypes.styles
  },
  components: vmPropTypes.components,
};

JmeColumnsInARow.defaultProps = {
  variant: '',
  nbOfColumns: {},
  styles: {
    row: {},
    column: {},
  },
  components: [],
};

JmeColumnsInARow.vmPreview = {
  styles: { wrapper: { border: '2px solid black' } },
  components: [
    {
      componentName: 'JmeText',
      text: 'Grid Row display, number of columns can be changed'
    }
  ]
};

export default JmeColumnsInARow;
