import {
  selectIsActive as selectIsActiveFactory,
  selectSettings as selectSettingsFactory
} from '../../hooks';

import { MODULE_NAME } from './constants';

const selectIsActive = selectIsActiveFactory(MODULE_NAME);

const selectSettings = selectSettingsFactory(MODULE_NAME);

const selectConfirmationEmail = (state) => selectSettings(state).groups?.confirmationEmail;

export { selectIsActive, selectSettings, selectConfirmationEmail };
