import PropTypes from 'prop-types';
import { getVmTypeByKey } from '../global-prop-types';

function jsonToJsxComponent(jsonComponent) {
  /* Converts json component to reflect jsx components.

    ComponentFromJson is a function component with features of the jsx component:
    vmPropTypes and defaultProps. If jsx component has new feaure, they should be
    added here as well. */
  const { componentName, vmPropTypes, defaultProps } = jsonComponent;

  const ComponentFromJson = (props) => {
    const { allComps } = props;
    const Comp = allComps?.[componentName];
    return Comp ? <Comp {...props} /> : null;
  };
  ComponentFromJson.propTypes = {
    allComps: PropTypes.object,
  };
  ComponentFromJson.vmPropTypes = getVmTypeByKey(vmPropTypes);
  ComponentFromJson.defaultProps = defaultProps;
  ComponentFromJson.isTemplate = true;
  return ComponentFromJson;
}

export default jsonToJsxComponent;
