import { apiRequest } from '../../../../api-request';
import { setMyNetworking } from '../../../../../global-state/redux/actions';
import {
  checkApiError,
  getUrl,
  myNetworkingApiCall
} from '../../myNetworkingApiUtils';
import { appendId, coerceToObj } from '../../myNetworkingUtils';
import store from '../../../../../global-state/redux';
import { STORE_MASK_CHATGROUPS_FILES_LIST_GET } from './filesuploadItemsConstants';
import { getAccessToken, getUserId } from '../../../loginInfo';
import { isStr } from '../../../..';

const { dispatch } = store;

export const apiGetFilesList = (props) => {
  const {
    groupId, onSuccessVmEventList, onErrorVmEventList, skipDebounce
  } = coerceToObj(props);

  if (!groupId) {
    return Promise.reject(
      new Error('Wrong function arguments. ', { cause: props })
    );
  }

  const body = {
    per_page: 50,
    user_id: getUserId(),
    access_token: getAccessToken()
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(STORE_MASK_CHATGROUPS_FILES_LIST_GET, groupId),
    pathname: `/v1/chatgroups/${groupId}/chatgroupcomment_filesupload_items.json`,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      const selectorKey = appendId(
        STORE_MASK_CHATGROUPS_FILES_LIST_GET,
        groupId
      );
      dispatch(setMyNetworking(selectorKey, res.data));
    }

    return res;
  });
};

export const apiFilesuploadItems = (props) => {
  const { filesuploadId, filesuploadableType, filesuploadableId } = coerceToObj(props);

  if (
    !parseInt(filesuploadId, 10)
    || !parseInt(filesuploadableId, 10)
    || !isStr(filesuploadableType)
  ) {
    return Promise.reject(
      new Error('Wrong function arguments. ', {
        cause: { filesuploadId, filesuploadableId, filesuploadableType }
      })
    );
  }

  const body = {
    filesupload_id: filesuploadId,
    filesuploadable_type: filesuploadableType,
    filesuploadable_id: filesuploadableId
  };

  return apiRequest({
    method: 'POST',
    url: getUrl({ pathname: 'v1/filesupload_items.json' }),
    body
  }).then(checkApiError);
};
