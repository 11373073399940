import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import PageRenderer from '../components/core/PageRenderer';
import { componentsPropType } from '../global-prop-types';

const CoursePage = (props) => (
  <PageRenderer urlParams={useParams()} {...props} />
);

CoursePage.propTypes = {
  components: componentsPropType,
  apiDataInfo: PropTypes.array,
};

CoursePage.defaultProps = {
  components: [],
};
export default CoursePage;
