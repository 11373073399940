import useShallowEqualSelector from '../components/core/useShallowEqualSelector';
import { generateThemeFromSettings } from './themeUtil';

const useTheme = (defaultSettings = {}) => {
  let usingThemeSettings;
  try {
    const { themeSettings } = useShallowEqualSelector((state) => state?.appState?.settings) ?? {};
    usingThemeSettings = themeSettings;
    if (!themeSettings) {
      // Allow to use the theme in the admin :)
      usingThemeSettings = defaultSettings;
    }
  } catch (e) {
    usingThemeSettings = defaultSettings;
  }

  return generateThemeFromSettings(usingThemeSettings);
};

export default useTheme;
