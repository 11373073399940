/**
 * This module provides only basic information for login.
 * Information about logged-in user should come from another module.
 */

import store from '../../../global-state/redux';

// local helper function to get core object:
const getLoginInfo = () => store.getState().user?.login_info;

export const getAccessToken = () => getLoginInfo()?.access_token;
export const getUserId = () => getLoginInfo()?.user_id;
export const getUserObj = () => getLoginInfo()?.user;
export const getManageAdminUserRoles = () => getLoginInfo()?.manage_admin_user_roles ?? [];
export const getManageUserId = () => getLoginInfo().user?.partner_id;
export const getUserFullName = () => {
  const { firstname, surname } = getUserObj() ?? {};
  return `${firstname} ${surname}`;
};

export const isMySelf = (userIdStr) => getUserId() === parseInt(userIdStr, 10);
export const isNotMySelf = (userId) => !isMySelf(userId);

export const isUserLoggedIn = () => !!getUserId();
