import { useEffect } from 'react';
import useTheme from '../global-hooks/useTheme';
import useShallowEqualSelector from '../components/core/useShallowEqualSelector';
import writeStyleToHead from '../global-utils/writeStylesToHead';
import { processComponentStyles, setCustomClasses } from './componentStylesUtils';

const StylesBuilder = () => {
  const { componentStyles = {}, customStyles } = useShallowEqualSelector((state) => state.appState.settings);
  const theme = useTheme();

  useEffect(() => {
    // Bring styles from the layoutSettings.themSettings:
    const cssText = processComponentStyles(componentStyles, theme);
    writeStyleToHead(cssText);

    // For the customeStyle to take precedence over the hardcoded styles, this has to be injected
    // to the head after the processComponentStyles
    if (customStyles?.isActive) {
      setCustomClasses(customStyles, theme);
    }
  }, [componentStyles, customStyles]);

  return null;
};

export default StylesBuilder;
