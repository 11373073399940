import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useDispatchVmEventList } from '../../global-utils';
import { IconComp } from '../Layout';
import { setClientData } from '../../global-state/redux/actions';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import vmFunctions from '../../global-utils/vmFunctions';

const JmeOverlayer = (props) => {
  const {
    styles, jmeIsOpen, forceOpen, vmOnClick, showCloseBtnInMs, variant, ...restProps
  } = props;
  const isOpen = useSelector((store) => store.appState.clientData[jmeIsOpen]);
  const store = useState((state) => state);
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const dispatch = useDispatch();
  const dispatchVmEventList = useDispatchVmEventList();
  const dataBank = { vmFunctions, props, store };

  const handleCloseClick = () => {
    dispatch(setClientData(jmeIsOpen, false));
    dispatchVmEventList(vmOnClick, dataBank);
  };

  useEffect(() => {
    const timer = Number(showCloseBtnInMs) || 0;
    const timeout = setTimeout(() => {
      setShowCloseBtn(true);
    }, timer);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!isOpen && !forceOpen) return null;
  return (
    <div className={`jme-overlayer-wrapper ${variant || ''}`} style={styles?.wrapper}>
      <div className="jme-overlayer-modal" style={styles?.modal}>
        {showCloseBtn && (
          <div
            className="jme-overlayer-closeButtonWrapper"
            style={styles?.closeButtonWrapper}
          >
            <div
              role="button"
              onClick={handleCloseClick}
              onKeyPress={handleCloseClick}
              tabIndex={0}
              className="jme-overlayer-button"
              style={styles?.closeButton}
            >
              <IconComp icon={{ iconType: 'fa', name: 'times' }} />
            </div>
          </div>
        )}
        <ComponentsArrRenderer {...restProps} />
      </div>
    </div>
  );
};

JmeOverlayer.propTypes = {
  variant: PropTypes.string,
  styles: stylesPropType,
  jmeIsOpen: PropTypes.string,
  vmOnClick: PropTypes.array,
  showCloseBtnInMs: PropTypes.number,
  components: componentsPropType,
  forceOpen: PropTypes.bool,
};

JmeOverlayer.defaultProps = {
  variant: '',
  showCloseBtnInMs: 0,
  components: [],
  vmOnClick: [],
  styles: {
    wrapper: {},
    modal: {},
    closeButtonWrapper: {},
    closeButton: {}
  },
  forceOpen: false
};
JmeOverlayer.vmPropTypes = {
  variant: vmPropTypes.className,
  vmOnClick: vmPropTypes.array,
  components: vmPropTypes.components,
  styles: {
    wrapper: vmPropTypes.styles,
    modal: vmPropTypes.styles,
    closeButtonWrapper: vmPropTypes.styles,
    closeButton: vmPropTypes.styles
  },
  jmeIsOpen: vmPropTypes.string,
  showCloseBtnInMs: vmPropTypes.number,
  forceOpen: vmPropTypes.boolean
};

export default JmeOverlayer;
