import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeBox = (componentStyles) => `
  .mediableTypeSwitch-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }

  .mediableTypeSwitch-title {
    margin-left: 26px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .mediableTypeSwitch-track {
    width: 42px;
    height: 12px;
    border-radius: 16px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .mediableTypeSwitch-track-on {
     background-color: #6F0035;
  }

  .mediableTypeSwitch-track-off {
    background-color: #AAAAAA;
  }

  .mediableTypeSwitch-thumb {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    top: calc(50% - 20px/2);
    
    /* Backgorund/Text */
    background: #FCFCFC;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
  }

  .mediableTypeSwitch-thumb-on {
    right: calc(-42px + 20px);
  }

  .mediableTypeSwitch-thumb-off {
    right: 0px;
  }
`;

export default stylesJmeBox;
