import store from '../../../../global-state/redux';
import { apiRequest } from '../../../api-request';
import {
  setMyNetworking,
  apiRequestStarted,
  apiRequestFinished,
  apiRequestError
} from '../../../../global-state/redux/actions';
import { isObj } from '../../../globalUtils';
import {
  STORE_MASK_GROUPS_POST_CREATE,
  STORE_MASK_GROUPS_POST_DELETE,
  STORE_MASK_GROUPS_POST_LIST_GET,
  STORE_MASK_GROUPS_POST_PINNED,
  STORE_MASK_GROUPS_POST_LIST_PINNED,
  STORE_MASK_GROUPS_POST_GET,
  STORE_MAST_GROUPS_POST_COMMENT_GET
} from './constants';
import { appendId } from '../myNetworkingUtils';
import { ifToDebounceApiCall } from '../myNetworkingApiUtils';
import { MY_NETWORKING_COMMENT_TYPE } from '../myNetworkingConstants';
import { apiGetGrouppostByPostId, apiGetGroupposts, apiGetPinnedGroupposts } from '../groupposts';
import { apiGetPinboardPosts, PIN_BOARD_COMMENT_TYPE } from '../pinboard';

const { dispatch, getState } = store;

// Create a post inside the group
export const apiCreateGroupPost = (props) => {
  const { appState, user } = getState();
  const { server_addr: serverAddr } = appState.core;
  const { access_token: accessToken, user_id: userId } = user.login_info;
  const {
    message, groupId, parentId
  } = isObj(
    props
  )
    ? props
    : {};
  const storeMask = appendId(STORE_MASK_GROUPS_POST_CREATE, parentId);

  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = '/v1/communities.json';

  const body = {
    user_id: userId,
    access_token: accessToken,
    commentid: groupId,
    commenttype: MY_NETWORKING_COMMENT_TYPE,
    comment: message
    // context: JSON.stringify({ body: message })
  };

  if (parentId !== undefined || parentId !== null) {
    body.parent_id = parentId;
  }

  if (ifToDebounceApiCall({ storeMask })) return;
  dispatch(apiRequestStarted(storeMask));
  apiRequest({ url, method: 'POST', body })
    .then((res) => {
      if (res.data?.error_code || res.data.error_message || res.data.error) {
        throw new Error(res.data.error_message ?? res.data.error);
      }
      return res.data;
    })
    .then((groupPost) => {
      dispatch(setMyNetworking('myGroupPost', groupPost));
      dispatch(apiRequestFinished(storeMask, 'Check appState.myNetworking'));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e.message));
    });
};

// After entering the group, get all the posts
export const apiGetGroupPostList = (props) => {
  const { appState, user } = getState();
  const { server_addr: serverAddr } = appState.core;
  const { user_id: userId, event_id } = user.login_info;
  const {
    groupId
  } = isObj(
    props
  )
    ? props
    : {};
  const storeMask = STORE_MASK_GROUPS_POST_LIST_GET;

  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = '/v1/communities.json';

  const body = {
    user_id: userId,
    event_id,
    chatgroup_id: groupId,
    commenttype: MY_NETWORKING_COMMENT_TYPE,
    use_cache: 0
  };

  if (ifToDebounceApiCall({ storeMask })) return;
  dispatch(apiRequestStarted(storeMask));
  apiRequest({ url, method: 'GET', body })
    .then((res) => {
      if (res.data?.error_code || res.data.error_message || res.data.error) {
        throw new Error(res.data.error_message ?? res.data.error);
      }
      return res.data;
    })
    .then((groupPost) => {
      dispatch(setMyNetworking('myGroupPost', groupPost));
      dispatch(apiRequestFinished(storeMask, 'Check appState.myNetworking'));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e.message));
    });
};

// Delete the post with is_hidden, PUT call
export const apiDeleteGroupPost = (props) => {
  const { appState, user } = getState();
  const { server_addr: serverAddr } = appState.core;
  const { access_token: accessToken, user_id: userId } = user.login_info;
  const { postId } = isObj(props) ? props : {};
  const storeMask = STORE_MASK_GROUPS_POST_DELETE;

  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = `/v1/communities/${postId}.json`;

  const body = {
    is_hidden: 1,
    user_id: userId,
    access_token: accessToken,
  };

  if (ifToDebounceApiCall({ storeMask })) return;
  dispatch(apiRequestStarted(storeMask));
  apiRequest({ url, method: 'PUT', body })
    .then((res) => {
      if (res.data?.error_code || res.data.error_message || res.data.error) {
        throw new Error(res.data.error_message ?? res.data.error);
      }
      return res.data;
    })
    .then((groupPost) => {
      dispatch(setMyNetworking('myGroupPost', groupPost));
      dispatch(apiRequestFinished(storeMask, 'Check appState.myNetworking'));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e.message));
    });
};

export const apiGetPinnedGroupPostList = (props) => {
  const { appState, user } = getState();
  const { server_addr: serverAddr } = appState.core;
  const { user_id: userId, event_id } = user.login_info;
  const {
    groupId
  } = isObj(
    props
  )
    ? props
    : {};
  const storeMask = STORE_MASK_GROUPS_POST_LIST_PINNED;

  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = '/v1/communities.json';

  const body = {
    user_id: userId,
    event_id,
    is_pinned: 1,
    chatgroup_id: groupId,
    commenttype: MY_NETWORKING_COMMENT_TYPE,
    use_cache: 0
  };

  if (ifToDebounceApiCall({ storeMask })) return;
  dispatch(apiRequestStarted(storeMask));
  apiRequest({ url, method: 'GET', body })
    .then((res) => {
      if (res.data?.error_code || res.data.error_message || res.data.error) {
        throw new Error(res.data.error_message ?? res.data.error);
      }
      return res.data;
    })
    .then((groupPost) => {
      dispatch(setMyNetworking('myGroupPost', groupPost));
      dispatch(apiRequestFinished(storeMask, 'Check appState.myNetworking'));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e.message));
    });
};

// pinned or unpinned a post, based on the prop "isPinned"
export const apiPinGroupPost = (props) => {
  const { appState, user } = getState();
  const { server_addr: serverAddr } = appState.core;
  const { user_id: userId, access_token: accessToken } = user.login_info;
  const { postId, isPinned } = isObj(props) ? props : {};
  const storeMask = appendId(STORE_MASK_GROUPS_POST_PINNED, `${postId}_${isPinned}`);

  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = `/v1/communities/${postId}.json`;

  const body = {
    is_pinned: isPinned ? 1 : 0,
    user_id: userId,
    access_token: accessToken,
  };

  if (ifToDebounceApiCall({ storeMask })) return;
  dispatch(apiRequestStarted(storeMask));
  apiRequest({ url, method: 'PUT', body })
    .then((res) => {
      if (res.data?.error_code || res.data.error_message || res.data.error) {
        throw new Error(res.data.error_message ?? res.data.error);
      }
      return res.data;
    })
    .then((groupPost) => {
      dispatch(setMyNetworking('myGroupPost', groupPost));
      dispatch(apiRequestFinished(storeMask, 'Check appState.myNetworking'));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e.message));
    });
};

// After click on the post, get all the comment from the post
export const apiCommentGroupPost = (props) => {
  const { appState, user } = getState();
  const { server_addr: serverAddr } = appState.core;
  const { user_id: userId, event_id } = user.login_info;
  const {
    postId
  } = isObj(
    props
  )
    ? props
    : {};
  const storeMask = STORE_MAST_GROUPS_POST_COMMENT_GET;

  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = '/v1/communities.json';

  const body = {
    user_id: userId,
    event_id,
    chatgroup_id: groupId,
    commenttype: MY_NETWORKING_COMMENT_TYPE,
    id: postId,
    include_children: 1
  };

  if (ifToDebounceApiCall({ storeMask })) return;
  dispatch(apiRequestStarted(storeMask));
  apiRequest({ url, method: 'GET', body })
    .then((res) => {
      if (res.data?.error_code || res.data.error_message || res.data.error) {
        throw new Error(res.data.error_message ?? res.data.error);
      }
      return res.data;
    })
    .then((groupPost) => {
      dispatch(setMyNetworking('myGroupPost', groupPost));
      dispatch(apiRequestFinished(storeMask, 'Check appState.myNetworking'));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e.message));
    });
};

export const apiGetGroupPost = (props) => {
  const { appState, user } = getState();
  const { server_addr: serverAddr } = appState.core;
  const { user_id: userId, event_id } = user.login_info;
  const {
    postId, groupId
  } = isObj(
    props
  )
    ? props
    : {};
  const storeMask = STORE_MASK_GROUPS_POST_GET;

  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = '/v1/communities.json';

  const body = {
    user_id: userId,
    event_id,
    chatgroup_id: groupId,
    commenttype: MY_NETWORKING_COMMENT_TYPE,
    id: postId,
    include_children: 1,
    use_cache: 0
  };

  if (ifToDebounceApiCall({ storeMask })) return;
  dispatch(apiRequestStarted(storeMask));
  apiRequest({ url, method: 'GET', body })
    .then((res) => {
      if (res.data?.error_code || res.data.error_message || res.data.error) {
        throw new Error(res.data.error_message ?? res.data.error);
      }
      return res.data;
    })
    .then((groupPost) => {
      dispatch(setMyNetworking('myGroupPost', groupPost));
      dispatch(apiRequestFinished(storeMask, 'Check appState.myNetworking'));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e.message));
    });
};

export const apiLikeComment = (props) => {
  const { appState, user } = getState();
  const { server_addr: serverAddr } = appState.core;
  const { user_id: userId, access_token: accessToken } = user.login_info;
  const {
    commentId,
    postId, // for individual post page
    groupId,
    isLiked,
    commenttype // Chatgroupcomment / Newsfeed
  } = isObj(props) ? props : {};

  const isPinboard = commenttype === PIN_BOARD_COMMENT_TYPE;

  const url = new URL(`${window.location.protocol}//${serverAddr}`);
  url.pathname = '/v1/comment_likes.json';

  const body = {
    commentid: commentId,
    commenttype,
    user_id: userId,
    access_token: accessToken,
  };

  // we need to make all group posts api calls just if it is not pinboard
  // otherwise making call to get pinboard posts
  apiRequest({ url, method: isLiked ? 'DELETE' : 'POST', body })
    .then(!isPinboard && apiGetGroupposts({ groupId, skipDebounce: true }))
    .then(!isPinboard && apiGetPinnedGroupposts({ groupId, skipDebounce: true }))
    .then(apiGetGrouppostByPostId({ postId, skipDebounce: true }))
    .then(isPinboard && apiGetPinboardPosts({ skipDebounce: true }));
};
