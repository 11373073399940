import PropTypes from 'prop-types';
import { vmPropTypes } from '../../../global-prop-types';

const CarouselButtons = ({
  next,
  previous,
  goToSlide,
  arrowsBottom,
  variant,
  ...restProps
}) => {
  const {
    carouselState: { currentSlide },
  } = restProps;
  return (
    <div
      className={`b-none d-md-block carouselButtons-buttonGroups ${
        arrowsBottom
          ? 'carouselButtons-buttonGroupDown'
          : 'carouselButtons-buttonGroupUp'
      } ${variant || ''}`}
    >
      <div
        onClick={previous}
        onKeyPress={previous}
        role="button"
        tabIndex={0}
        className={`${
          currentSlide === 0 ? 'disable' : ''
        } carouselButtons-arrows carouselButtons-arrowLeft`}
      >
        <i className="fas fa-chevron-left" />
      </div>
      <div
        onClick={next}
        onKeyPress={next}
        role="button"
        tabIndex={0}
        className="carouselButtons-arrows carouselButtons-arrowRight"
      >
        <i className="fas fa-chevron-right" />
      </div>
    </div>
  );
};

CarouselButtons.propTypes = {
  variant: PropTypes.string,
  next: PropTypes.func,
  previous: PropTypes.func,
  goToSlide: PropTypes.func,
  arrowsBottom: PropTypes.bool,
};

CarouselButtons.defaultProps = {
  variant: '',
  next: () => console.log('default props log: next'),
  previous: () => console.log('default props log: previous'),
  goToSlide: () => console.log('default props log: goToSlide'),
  arrowsBottom: false,
};

CarouselButtons.vmPropTypes = {
  variant: vmPropTypes.className,
  arrowsBottom: vmPropTypes.boolean,
};

export default CarouselButtons;
