/** Manipulates data in layoutSettings */
import { mapData } from './dataMapping';
import { isObj, isArr, isStr } from './globalUtils';

/** regExp expressions to capture placeholder
 * regExp explanation:
 * \$\( and \) - are opening and closing markers of the placeholder
 * ([\w_]+) - single unit of a path can contain alpha-num and underscore, at least one char
 * (\([\w_,]*\))? - parenthese part of a call to a function, func(arg1, arg2). This part is optional `?`.
 *   Function args are or arent present.
 * \.? - any part of the path can be followed by zero or one dot
 */
const placeholderRegExp = new RegExp(/\$\((((([\w_]+)(\([\w_,]*\))?)\.?)+)\)/);
const functionCallRegExp = new RegExp(/^([\w_]+)\(([\w_,]*)\)$/);

const hasOldPlaceholders = (obj) => {
  if (isArr(obj)) return obj.some((el) => hasOldPlaceholders(el));
  if (isObj(obj)) return Object.keys(obj).some((el) => hasOldPlaceholders(obj[el]));
  if (isStr(obj)) return placeholderRegExp.test(obj);
  return false;
};
/**
 * @summary Test if the access path is valid dataBank path.
 * @description For the moment valid dataBank access points are: props, store, urlParams.
 * @str {string} - placeholder path with access to data.
 * @returns True if str starts with placeholder mark followed by one of the dataBank access points. Otherwise false.
 * @example
 * isDataBankAccessPath('$(abc)); // expected false
 * isDataBankAccessPath('$(store)); // expected false, no path follows the store
 * isDataBankAccessPath('$(store.appState)); // expected true
 */
function isDataBankAccessPath(placeholder) {
  const [, pathToValue] = placeholderRegExp.exec(placeholder);
  return /^(props|store|urlParams|vmFunctions)\./.test(pathToValue);
}

/**
 * @summary Test if the str contains at least one placeholder. All palaveholsers have to have valid access paths.
 * @param {string} str
 * @returns {boolean} True if str containes at least one valid acess path to data bank.
 * @example
 * validateDataBankAccessPaths('/v1/courses/124.json'); // expected false, no placeholder
 * validateDataBankAccessPaths('/v1/courses/$(urlParams.id)/$(Props.name)'); // expected false, one placeholder is not a path to data bank
 * validateDataBankAccessPaths('/v1/courses/$(urlParams.id)/$(props.name)'); // expected true
 */
export function validateDataBankAccessPaths(str = '') {
  if (!isStr(str)) return false;
  const regExp = new RegExp(placeholderRegExp.source, 'g');
  const placeholderList = str?.match(regExp);
  return placeholderList?.length
    ? placeholderList.every((placeholder) => isDataBankAccessPath(placeholder))
    : false;
}

/**
 * @summary Recognize function call pattern in string str and return result of the function call
 * @param {string} str - a string with function call eg func(1,2)
 * @param {object} vmFunctions - object of functions by key
 * @description At the moment all the args passed to function call are treated as strings.
 * @returns result of the function call
 * @example
 * consf vmFunctions = {func1: (str) => str.toUpperCase()}
 * getValueFromFunctionCall('func1(abc), vmFunctions)
 * // expected 'ABC'
 */
function getValueFromFunctionCall(str, vmFunctions) {
  // split str into function name and function arguments
  const [, funcName, funcArgs] = functionCallRegExp.exec(str);
  // if path leads to funciton call it and return result of the call
  const func = vmFunctions?.[funcName];
  return typeof func === 'function' ? func(...funcArgs?.split(',')) : undefined;
}

/**
 * @summary Given object dataObj, retrive value by given path.
 * @param {string} pathToValue - dot-seperated path to a value
 * @param {(object|array)} dataObj - object or array to travers
 * @example
 * getValueByPath("a.0.c", {a: [{c: 2}, null]});
 * // expected: 2
 */
export function getValueByPath(pathToValue, dataObj) {
  if (!isStr(pathToValue) || (!isObj(dataObj) && !isArr(dataObj))) { return undefined; }

  const value = pathToValue.split('.').reduce((acc, key) => {
    // TODO: eliminate stringified settings from API responses
    if (key === 'settings' && isStr(acc?.[key])) return JSON.parse(acc[key]);
    // determine if the key is a call to a function
    if (functionCallRegExp.test(key)) return getValueFromFunctionCall(key, acc);
    // otherwise normal value
    return acc?.[key];
  }, dataObj);

  return value;
}
/**
 * @summary Replace VM placholders embeded in a string str with a value from dataObj.
 * @description String str can be a string that conatins paths to data from dataObj.
 * This function finds the placeholdes in str and replaces them with relevant value
 * taken from dataObj.
 *
 * @param {string} str - string with or without placeholders
 * @param {(object|array)} dataObj - object with values to replace
 * @returns {string} string with placeholders replaced by values.
 * If str is not a string, returns directly str.
 * If dataObj is not an obj or array returns undefined.
 * @example
 * replacePlaceholders("user: $(user.name) is $(user.status)", dataObj = {name: "John", status: "meta"})
 * // expected output: "user John is meta"
 */
export function replacePlaceholders(str, dataObj = {}) {
  if (!isStr(str)) return str;
  if (!isObj(dataObj) && !isArr(dataObj)) return undefined;

  const [match, pathToValue] = placeholderRegExp.exec(str) || []; // regExp.exec returns null when pattern not found
  if (!match) return str;
  if (match === str) return getValueByPath(pathToValue, dataObj);
  return replacePlaceholders(
    str.replace(placeholderRegExp, (_, pathToValue2) => getValueByPath(pathToValue2, dataObj) || ''),
    dataObj
  );
}

/**
 * @summary Recursively travers key of dataMap and replace any placeholder with relevant data from dataObj.
 * @param {(object|array)} dataObj - source of data
 * @param {(object)} dataMap - object (can be nesed) with placeholders instead of values
 * @returns {object} with values
 * @example
 * reWriteProps({user: {name: "John", age: 4}}, {age: "$(user.age)", course: {author: "$(user.name)"}})
 * // expected outcome: {age: 4, course: {author: "John"}}
 */
export function reWriteProps(dataObj = {}, dataMap = {}) {
  return Object.keys(dataMap).reduce((acc, key) => {
    let valueReplaced;
    // This is ugly hack to the problem of using placeholders.
    // to make makeApiCallIf work:
    if (key === 'makeApiCallIf') {
      valueReplaced = mapData(dataObj, dataMap[key]);
    } else if (dataMap[key]?.vmType) { // For some reason we have encounter in the app situation that the dataMap has props with valur undefined, such as {somePorp: undefined}
      valueReplaced = mapData(dataObj, dataMap[key]);
    } else {
      if (isObj(dataMap[key])) { return { ...acc, [key]: reWriteProps(dataObj, dataMap[key]) }; }
      if (isArr(dataMap[key])) { return { ...acc, [key]: mapData(dataObj, dataMap[key]) }; } // can't use placeholders inside an array!
      valueReplaced = replacePlaceholders(dataMap[key], dataObj);
      // console.log('TODO: This part of reWriteProps should be replaced with mapData (', { key, valueReplaced }, ')');
    }
    if (valueReplaced !== undefined && valueReplaced !== null) { acc[key] = valueReplaced; }
    return acc;
  }, {});
}

/* This function, will generate an prop object from api data:
 Example:
  apiData: {name: "Guy", "details":{"age": 12}}
  dataToComp: {
   dataMask - Which of the information of the parent should the information be?
   dataToProps = [{ "title": "$(name)", "subtitle": "$(details.age)" }]

 will result in object {"title": "Guy", "subtitle": 12}
*/

const apiDataToProps = (apiData, dataToComp) => {
  if (!isObj(apiData) || !isArr(dataToComp)) return {};

  let obj2props = {};
  dataToComp.forEach((d2pItem) => {
    if (apiData && apiData[d2pItem.dataMask]) {
      // If There is data
      const currentDataEntry = apiData[d2pItem.dataMask].data;

      if (currentDataEntry) {
        // So we're in - because we have data
        if (d2pItem.dataToProps === undefined) {
          // By default - if exists entry, but no mapping - return all of data.
          if (!d2pItem.dataMask) {
            // Map me !

            // TODO: I believe the folowin line should do the job... check it.
            obj2props = {
              ...obj2props,
              ...reWriteProps(currentDataEntry, d2pItem),
            };
            Object.keys(d2pItem).forEach((entry) => {
              obj2props[d2pItem[entry]] = replacePlaceholders(
                entry,
                currentDataEntry
              );
            });
          } else {
            // obj2props.apiData = currentDataEntry;
            obj2props.data = currentDataEntry;
          }
        } else {
          obj2props = {
            ...obj2props,
            ...reWriteProps(currentDataEntry, d2pItem.dataToProps),
          };

          // let mapItems = d2pItem;
          if (d2pItem.dataToProps) {
            const mapItems = Object.keys(d2pItem.dataToProps);
            const isAll = mapItems.indexOf('*');
            if (isAll > 0 - 1) {
              obj2props[d2pItem.dataToProps[mapItems[isAll]]] = currentDataEntry;
            }
          }
        }
      }
    }
  });
  return obj2props;
};

const componentItemDataToProps = (componentItemData, dataToProp) => {
  if (!isObj(componentItemData) || !isObj(dataToProp)) return {};

  return reWriteProps(componentItemData, dataToProp);
};

const mapPropToProp = (dataObj, propToPropMap) => (isObj(propToPropMap) && isObj(dataObj)
  ? reWriteProps(dataObj, propToPropMap)
  : {});

export {
  apiDataToProps, componentItemDataToProps, mapPropToProp, hasOldPlaceholders
};
