import { CookieConsent } from 'react-cookie-consent';
import { useModuleSettingsByName } from '../../global-hooks';

const CookieBanner = (props) => {
  const moduleSettings = useModuleSettingsByName('cookieBanner');
  if (moduleSettings === null) {
    return null;
  }

  const { expires, cookieName, customization = {} } = moduleSettings;

  const {
    generalText, acceptBtnText, optionalDeclineBtnText, wrapperStyles, buttonStyles
  } = customization;

  return (
    <CookieConsent
      location="bottom"
      buttonText={acceptBtnText || 'Accept'}
      enableDeclineButton={!!optionalDeclineBtnText}
      declineButtonText={optionalDeclineBtnText}
      cookieName={cookieName || 'vsx_cookie'}
      style={wrapperStyles?.wrapper}
      buttonStyle={buttonStyles?.wrapper}
      expires={expires || 150}
    >
      {generalText || 'This website uses cookies to enhance the user experience.'}
    </CookieConsent>
  );
};
export default CookieBanner;
