import { getUserId } from '../../../loginInfo';
import { USER_TYPE_MODERATOR } from '../constants';

export const amIGroupOwner = (aGroup) => {
  const groupOwnerId = aGroup?.user_id; // this should be the group ovner id
  const myUserId = getUserId();
  return groupOwnerId === myUserId;
};

export const isChatgroupUserModerator = (aChatgroupUser) => aChatgroupUser?.user_type === USER_TYPE_MODERATOR;
export const isNotChatgroupUserModerator = (aChatgroupUser) => !isChatgroupUserModerator(aChatgroupUser);
