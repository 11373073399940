import { useState } from 'react';
import PropTypes from 'prop-types';
import { getValueByVmPath } from '../../global-utils/vmPath';
import { mapData } from '../../global-utils/dataMapping';
import vmFunctions from '../../global-utils/vmFunctions';
import {
  stylesPropType,
  componentsPropType,
  vmPropTypes
} from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';

/**
 * Returns ususal html div tag. The JmeBox can accept onMOuseEnter and onMuseLeave ecents.
 * Example uage:
 * "onMouseEnter": [ { "vmType": "VM_TYPE_DATA_MAPPING", "vmPath": [ "vmFunctions", "components", "setProp" ], "args": [ "isHovered", true ] } ],
 * "onMouseLeave": [ { "vmType": "VM_TYPE_DATA_MAPPING", "vmPath": [ "vmFunctions", "components", "setProp" ], "args": [ "isHovered", false ] } ],
 */
const JmeBox = (props) => {
  const {
    styles, variant, onMouseEnter, onMouseLeave, ...restProps
  } = props;
  const [eventProps, setEventProps] = useState({});
  const dataBank = { props, vmFunctions };

  // on hover effect, might set additional props. We know if new prop is set by rproperty
  // of the function object
  const handleMouseEnter = () => onMouseEnter?.forEach((el) => {
    const func = getValueByVmPath(dataBank, el.vmPath);
    if (func?.setsComponentProperties) setEventProps(mapData(dataBank, el));
  });

  // on hover effect, might set additional props. We know if new prop is set by rproperty
  // of the function object
  const handleMouseLeave = () => onMouseLeave?.forEach((el) => {
    const func = getValueByVmPath(dataBank, el.vmPath);
    if (func?.setsComponentProperties) setEventProps(mapData(dataBank, el));
  });

  return (
    <div
      className={`jmeBox-wrapper ${variant || ''}`}
      style={styles?.wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ComponentsArrRenderer {...restProps} {...eventProps} />
    </div>
  );
};

JmeBox.propTypes = {
  styles: stylesPropType,
  onMouseEnter: PropTypes.array,
  onMouseLeave: PropTypes.array,
  variant: PropTypes.string,
  components: componentsPropType
};

JmeBox.defaultProps = {
  variant: '',
  styles: {
    wrapper: { }
  },
  components: []
};

JmeBox.vmPreview = {
  styles: { wrapper: { border: '2px solid black' } },
  components: [
    {
      componentName: 'JmeText',
      text: 'Jmebox display'
    }
  ]
};

JmeBox.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  variant: vmPropTypes.className,
  components: vmPropTypes.components
};

JmeBox.processPropVmTypes = (props) => {
  const {
    styles, variant, onMouseEnter, onMouseLeave, ...restProps
  } = props;

  return ComponentsArrRenderer.processPropVmTypes(restProps);
};

export default JmeBox;
