import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { componentsPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';

/**
 * @example
 * {
          "componentName": "ComponentsRouter",
          "componentsRoutes": [
            {
              "routeMatchList": [
                {
                  "pathname": "guidelines",
                  "search": "type",
                  "locationKeyOperator": "or"
                },
                { "pathname": "course" }
              ],
              "components": [
                {
                  "componentName": "JmeText",
                  "text": "guidelines with type or course"
                }
              ]
            }
          ]
        }
 */
const ComponentsRouter = (props) => {
  const { componentsRoutes, defaultComponents, ...restProps } = props;
  const location = useLocation();

  // find components array for the first componentsRoute that match routeMatch:
  const { components = defaultComponents } = componentsRoutes.find((el) => {
    // routeMatchLIst contains a list of routes to match against. Return true if any of the
    // routes definitions in the routeMatchList matches:
    const { routeMatchList = [] } = el;
    return routeMatchList.some((routeMatch) => {
      // routeMatchList element has one metadata sewithch: locationKeyOperator. The rest are
      // leys as defined in the windo.location js object. The locationKeyOperator can be 'or' or 'and'
      // and determines logic applied between window.location keys values.
      // IMPORTANT: if we properties to routeMatchList element that do not belong to window.location
      //   we need to remove it from the ...locationKeyList! Otherwise the bechaviour might appear random.
      const { locationKeyOperator = 'and', ...locationKeyList } = routeMatch;
      switch (locationKeyOperator) {
        case 'and':
          return Object.keys(locationKeyList).every((locationKey) => new RegExp(routeMatch[locationKey]).test(location[locationKey]));
        case 'or':
          return Object.keys(locationKeyList).some((locationKey) => new RegExp(routeMatch[locationKey]).test(location[locationKey]));
        default:
          return false;
      }
    });
  }) ?? {};

  return <ComponentsArrRenderer components={components} {...restProps} />;
};

ComponentsRouter.propTypes = {
  componentsRoutes: PropTypes.array,
  defaultComponents: componentsPropType
};

ComponentsRouter.defaultProps = {
  componentsRoutes: [],
  defaultComponents: []
};

ComponentsRouter.vmPropTypes = {
  componentsRoutes: vmPropTypes.array,
  defaultComponents: vmPropTypes.components
};

export default ComponentsRouter;
