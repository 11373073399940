import store from '../../../../../global-state/redux';
import { setMyNetworking } from '../../../../../global-state/redux/actions';
import { myNetworkingApiCall } from '../../myNetworkingApiUtils';
import { getEventId } from '../../../core';
import { getAccessToken, getUserId } from '../../../loginInfo/loginInfo';
import { appendId, coerceToObj } from '../../myNetworkingUtils';
import {
  CHATGROUPINVITATIONS_GET_SELECTOR_KEY_BY_ID,
  CHATGROUPINVITATIONS_SELECTOR_KEY,
  CHATGROUPINVITATIONS_SELECTOR_KEY_BY_ID,
  CHATGROUPINVITATIONS_STATE_ACCEPTED,
  CHATGROUPINVITATIONS_STATE_CREATED,
  CHATGROUPINVITATIONS_STATE_REJECTED
} from './chatgroupinvitationConstants';
import {
  getChatgroupinvitations,
  getChatgroupinvitationsByGroupId
} from './chatgroupinvitationSelectors';
import { apiGetChatgroupById, apiGetChatgroups } from '../chatgroups';
import { apiGetCommunityUsersByGroupId } from '../../users';

const { dispatch } = store;

// Constants used to store results of the API response in store:
const STORE_MASK_CHATGROUPINVITATIONS_GET = 'myNetworking/chatgroups/invitations/get';
const STORE_MASK_CHATGROUPINVITATIONS_CREATE = 'myNetworking/chatgroups/invitations/create';
const STORE_MASK_CHATGROUPINVITATIONS_SET_STATE = 'myNetworking/chatgroups/invitations/setState';

const PATHNAME = '/v1/chatgroupinvitations.json';
const getPathname = (id) => `/v1/chatgroupinvitations/${id}.json`;

export const apiGetChatgroupinvitations = (props) => {
  const { onSuccessVmEventList, onErrorVmEventList, skipDebounce } = coerceToObj(props);

  const body = {
    per_page: 999, // get all invitations of the user
    to_user_id: getUserId(),
    access_token: getAccessToken(),
    event_id: getEventId(),
    state: CHATGROUPINVITATIONS_STATE_CREATED,
    chatgroup_is_approved: 1 // get only approved groups
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_CHATGROUPINVITATIONS_GET,
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      // save to relevant myNetworking location in redux:
      dispatch(setMyNetworking(CHATGROUPINVITATIONS_SELECTOR_KEY, res.data));
    }

    return res;
  });
};

export const apiChatgroupinvitationCreate = (props) => {
  const {
    groupId: groupIdStr,
    toUserId: toUserIdStr,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);
  const toUserId = parseInt(toUserIdStr, 10);

  if (!groupId || !toUserId) return Promise.resolve(null);

  const body = {
    from_user_id: getUserId(),
    access_token: getAccessToken(),
    to_user_id: toUserId,
    chatgroup_id: groupId
  };

  return myNetworkingApiCall({
    method: 'POST',
    storeMask: appendId(STORE_MASK_CHATGROUPINVITATIONS_CREATE, `${groupId}_${toUserId}`),
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (res.isDebounced) return res; // Do not perform data updates on debounced API calls

    // add created invitation to array of invitations:
    const chatgroupinvitations = getChatgroupinvitationsByGroupId(groupId);
    const chatgroupinvitation = res.data;

    dispatch(
      setMyNetworking(
        appendId(CHATGROUPINVITATIONS_SELECTOR_KEY_BY_ID, groupId),
        [chatgroupinvitation, ...chatgroupinvitations]
      )
    );
    return res;
  });
};

// here we are calling apiChatgroupinvitationCreate with calling get users by group id in
// order to update the chatgroup invitations in the invite modal for members
export const apiInviteChatPartner = (props) => {
  const { onSuccessVmEventList, onErrorVmEventList, ...restProps } = props;
  return apiChatgroupinvitationCreate(restProps)
    .then(() => apiGetCommunityUsersByGroupId({ skipDebounce: true, ...props }));
};

const apiChatgroupinvitationSetState = (props) => {
  const {
    groupId: groupIdStr,
    state,
    onErrorVmEventList,
    onSuccessVmEventList
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  // find nvitation id:
  const chatgroupinvitations = getChatgroupinvitations();
  const chatgroupinvitationId = chatgroupinvitations.find(
    (el) => el.chatgroup.id === groupId
  )?.id;

  if (!chatgroupinvitationId) return Promise.resolve({});

  const body = {
    to_user_id: getUserId(),
    access_token: getAccessToken(),
    id: chatgroupinvitationId,
    state
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(
      STORE_MASK_CHATGROUPINVITATIONS_SET_STATE,
      chatgroupinvitationId
    ),
    pathname: getPathname(chatgroupinvitationId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return res; // Do not perform data updates on debounced API calls

    // Delete from invitations array:
    const chatgroupinvitationsNew = chatgroupinvitations.filter(
      (el) => el.id !== chatgroupinvitationId
    );
    dispatch(
      setMyNetworking(
        CHATGROUPINVITATIONS_SELECTOR_KEY,
        chatgroupinvitationsNew
      )
    );

    return res;
  });
};

export const apiAcceptChatgroupinvitation = (props) => apiChatgroupinvitationSetState({
  state: CHATGROUPINVITATIONS_STATE_ACCEPTED,
  ...props
}).then((res) => {
  if (res.isDebounced) return; // Do not perform data updates on debounced API calls

  // When an invitation gets accepted, following arrays need an update:
  //   * getChatgroupinvitationsByChatgroupId - this is alredy done by apiChatgroupinvitationSetStatus
  //   * chatgroupById - we need to update members by new member and members count
  //   * getChatgroups - update members array and members count so we get correct number on myNetworkin carousels

  // Updare state of the users chatgroups:
  apiGetChatgroups({ skipDebounce: true });

  // Just in case user was previewing the same group before, we need to update
  // data for this group:
  apiGetChatgroupById({ groupId: res.data.chatgroup.id, skipDebounce: true });
});

export const apiRejectChatgroupinvitation = (props) => apiChatgroupinvitationSetState({
  state: CHATGROUPINVITATIONS_STATE_REJECTED,
  ...props
});

export const apiGetChatgroupinvitationsFromUserId = (props) => {
  const {
    groupId,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);

  if (!groupId) return Promise.reject(new Error('No groupId provided.'));

  const body = {
    per_page: 999,
    event_id: getEventId(),
    chatgroup_is_approved: 1, // get only approved groups
    access_token: getAccessToken(),
    from_user_id: getUserId(),
    chatgroup_id: groupId
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(STORE_MASK_CHATGROUPINVITATIONS_GET, groupId),
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      // save to relevant myNetworking location in redux:
      dispatch(
        setMyNetworking(
          appendId(CHATGROUPINVITATIONS_GET_SELECTOR_KEY_BY_ID, groupId), res.data
        )
      );
    }

    return res;
  });
};
