import { componentStylesToCss } from '../componentStylesUtils';

const stylesExternalHref = (componentStyles) => `
  .externalHref-wrapper {
    text-decoration: none;
    color: inherit;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .externalHref-wrapper:hover {
    text-decoration: none;
    color: inherit
  }
`;

export default stylesExternalHref;
