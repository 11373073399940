const getDeviceConfig = (width, themeBreakpoints = {}) => {
  let { values, unit } = themeBreakpoints;
  // bootsrap library default breakpoints values
  if (!values || Object.keys(values)?.length < 5) {
    values = {
      xs: 575,
      sm: 767,
      md: 991,
      lg: 1199,
      xl: 1399,
    };
  }
  if (!['px', 'rem'].includes(unit)) {
    unit = 'px';
  }

  if (width <= values.xs) {
    return 'xs';
  }
  if (width > values.xs && width <= values.sm) {
    return 'sm';
  }
  if (width > values.sm && width <= values.md) {
    return 'md';
  }
  if (width > values.md && width <= values.lg) {
    return 'lg';
  }
  if (width > values.lg && width <= values.xl) {
    return 'xl';
  }
  return 'xl';
};

export default getDeviceConfig;
