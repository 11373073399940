import { componentStylesToCss } from '../componentStylesUtils';

const stylesTableData = (componentStyles) => `
  .tableData-wrapper {
    width: 100%;
    border-spacing: 0px;
    border-bottom: solid;
    text-align: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .tableData-headline {
    font-weight: bold;
    ${componentStylesToCss(componentStyles?.headline)}
  }
  .tableData-headline th {
    height: 50px;
    padding: 0px 5px 0px 5px;
  }
  .tableData-headline th:first-child {
    border-top-left-radius: 5px;
  }
  .tableData-headline th:last-child {
    border-top-right-radius: 5px;
  }
  .tableData-body {
    ${componentStylesToCss(componentStyles?.body)}
  }
  .tableData-body tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  .tableData-body td {
    border-bottom: 1px solid #c7c7c7;
    height: 50px;
    padding: 0px 5px 0px 5px;
  }
  .jme-default-table-class {
    width: 100%;
  }
`;

export default stylesTableData;
