import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsUserLoggedIn } from '../../global-utils';
import { selectData } from './selectors';
import { fetchUserComplete } from './actions';
import { useIsActive as useIsActiveByName, useSettings as useSettingsByName } from '../hooks';
import { MODULE_NAME } from './constants';

export const useSettings = () => useSettingsByName(MODULE_NAME);
export const useIsActive = () => useIsActiveByName(MODULE_NAME);

export const useData = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectData) ?? {};
  const isUserLoggedIn = useIsUserLoggedIn();
  const isActive = useIsActive();
  useEffect(() => {
    const { apiStatus } = userData;
    if (!isUserLoggedIn) return;
    if (!isActive) return;
    if (apiStatus) return; // this means, the call is either on it's way or was already done.

    dispatch(fetchUserComplete());
  }, [isUserLoggedIn]);

  return userData;
};
