import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  setAppReady,
  setComponentsBuildingPreview,
  setCoreData,
  setPageParams,
  setPagePreview,
} from './global-state/redux/actions';
import { vmRefProcessorApp } from './global-utils';

const IframeCommunicator = (props) => {
  const { isInit } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isInit) {
      window.parent.postMessage({ action: 'preview_mode_ready', data: 'this is ready' }, '*');
    }
  }, []);

  const handleIframeTask = (e) => {
    // console.log('iframe got message !', e);
    if (e?.data?.action) {
      // we have action
      switch (e.data.action) {
        case 'new_core':
          dispatch(setCoreData(e.data.data));
          if (e.data.currentVm) {
            dispatch(setAppReady(true, e.data.currentVm));
          }
          window.parent.postMessage({ action: 'core_recieved', data: 'core is recieved' }, '*');
          break;

        case 'new_settings':
          dispatch(setAppReady(true, e.data.data));
          break;

        case 'page_preview':
          dispatch(setComponentsBuildingPreview(null));
          dispatch(setPagePreview(e.data.data));
          break;

        case 'components_building':
          dispatch(setComponentsBuildingPreview(e.data.data));
          break;

        case 'page_params':
          dispatch(setPageParams(e.data.data));
          break;
        case 'vmRefApp_updateDefinitions':
          // Just to make sure - this message has to be called
          // befor any other messages from admin that need to
          // render components. Otherwise the ref. library might
          // not contain all needed ref. definitions
          vmRefProcessorApp.updateDefinitions(e.data.data);
          break;

        case 'ACTION':
          // it's redux.. ignore
          break;

        default:
          if (typeof e.data.action.lengh < 40) { // if its bigger - probably redux
            console.log('unknown iframe action command', typeof e.data.action, e.data.action, e);
          }
      }
    } else if (e?.data?.source?.indexOf('react') === -1) {
      // console.log('unknown iframe command', e.data);
    }
  };

  useEffect(() => {
    // This listener is to enable controlling our App using the admin interface.
    // window.removeEventListener('message', handleIframeTask);
    window.addEventListener('message', handleIframeTask);

    return () => {
      // This is listener remover.
      window.removeEventListener('message', handleIframeTask);
    };
  }, [dispatch]);
  return null;
};

IframeCommunicator.propTypes = {
  isInit: PropTypes.bool,
};
IframeCommunicator.defaultProps = {
  isInit: false,
};

export default IframeCommunicator;
