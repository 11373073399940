import storageAndCookie from '../../storage-and-cookies';

export const handleProductModal = (params) => {
  const {
    leadscallResult,
    companyAccountId,
    pageToShowLeads
  } = params;
  const acceptedLeads = JSON.parse(storageAndCookie.get(`exhibitorLeadsAccepted${companyAccountId}`));

  if (leadscallResult?.Enabled && !acceptedLeads && window.location.pathname === pageToShowLeads) {
    return false;
  }

  return true;
};

export const handleProductLeadsModal = (params) => {
  const {
    leadscallResult,
    companyAccountId,
    pageToShowLeads
  } = params;
  const acceptedLeads = JSON.parse(storageAndCookie.get(`exhibitorLeadsAccepted${companyAccountId}`));

  if (leadscallResult?.Enabled && !acceptedLeads && window.location.pathname === pageToShowLeads) {
    return true;
  }

  return false;
};
