import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as allPages from '../../pages';
import { useMatomoIsActive as useIstrackingActive } from '../../modules/matomo';

const DynamicPageRenderer = (props) => {
  // make sure page exists:
  const { trackPageView } = useMatomo();
  const { pageName } = props;
  const isTrackingActive = useIstrackingActive();
  const PageComp = allPages[pageName];

  useEffect(() => {
    if (isTrackingActive) {
      trackPageView();
    }
  }, []);

  if (!PageComp) {
    console.error("Page Doesn't exists", props);
    // TODO: redirect to 404
    return 'Page Not Found 404';
  }
  return <PageComp {...props} />;
};

DynamicPageRenderer.propTypes = {
  pageName: PropTypes.string.isRequired // Must get a page name
};

export default DynamicPageRenderer;
