import { isArr, isObj } from '../../globalUtils';
import { vmTypes } from '../../../global-prop-types';
import { forceCloseSplashScreen } from '../../../global-state/redux/actions';
import storageAndCookie from '../../storage-and-cookies';

/** fallback icon set in the prop by default but can also be set in appSettings */
export const convertIdToIcon = (
  icons = [],
  fallbackIcon = { iconType: 'mui', name: 'Edit' },
  id
) => icons?.find((icon) => icon?.id === id)?.icon ?? fallbackIcon;

convertIdToIcon.vmFunctionMetaData = {
  returnVmType: vmTypes.icon,
  argsInfo: [
    { uiTitle: 'icons', vmType: vmTypes.array },
    { uiTitle: 'id', vmType: vmTypes.number }
  ]
};

export const convertMeetingsToMarkers = (apiResponse, path = '/') => {
  if (!apiResponse) return [];
  const markers = [];
  const isSingle = !isArr(apiResponse) && isObj(apiResponse);

  if (isSingle) {
    if (apiResponse?.body?.location && !apiResponse?.body?.location?.isHidden) {
      // the location can be nested inside the body when do call to /media_items.json
      markers.push({
        label: apiResponse.body.location.label,
        color: apiResponse.body.location.color,
        cordinates: (apiResponse.body.location?.cordinates?.latitude && apiResponse.body.location?.cordinates?.longitude)
          ? [apiResponse.body.location?.cordinates?.latitude, apiResponse.body.location?.cordinates?.longitude].map(Number)
          : []
      });
    }
    if (apiResponse?.location && !apiResponse?.location?.isHidden) {
      // the location can be first level when doing call to /meetings.json
      markers.push({
        label: apiResponse.location.label,
        color: apiResponse.location.color,
        cordinates: (apiResponse.location?.cordinates?.latitude && apiResponse.location?.cordinates?.longitude)
          ? [apiResponse.location?.cordinates?.latitude, apiResponse.location?.cordinates?.longitude].map(Number)
          : []
      });
    }
  } else {
    apiResponse.forEach((el) => {
      if (el?.body?.location && !el?.body?.location?.isHidden) {
        // the location can be nested inside the body when do call to /media_items.json
        markers.push({
          link: `${path}/${el.body.id}`,
          label: el.body.location?.label,
          color: el.body.location?.color,
          cordinates: (el.body.location?.cordinates?.latitude && el.body.location?.cordinates?.longitude)
            ? [el.body.location?.cordinates?.latitude, el.body.location?.cordinates?.longitude].map(Number)
            : []
        });
      }
      if (el?.location && !el?.location?.isHidden) {
        // the location can be first level when doing call to /meetings.json
        markers.push({
          link: `${path}/${el.id}`,
          label: el.location?.label,
          color: el.location?.color,
          cordinates: el?.location?.cordinates?.latitude && el?.location?.cordinates?.longitude
            ? [el.location?.cordinates?.latitude, el.location?.cordinates?.longitude].map(Number)
            : []
        });
      }
    });
  }
  return markers;
};

convertMeetingsToMarkers.vmFunctionMetaData = {
  returnVmType: vmTypes.array,
  argsInfo: [{ uiTitle: 'apiResponse', vmType: vmTypes.array }]
};

// TODO: the urlSearchParams functions should got to seperate file: vmUrlSearchParams
export const setUrlSearchParams = (name, value, isSingular = false) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const paramsValue = params.get(name);

  if (name && (value === '' || value === '#')) {
    params.delete(name);
    return `?${params.toString()}`;
  }

  if (isSingular) {
    if (paramsValue === value.toString()) {
      params.delete(name);
    } else {
      params.set(name, value);
    }
  } else {
    const valueStr = value.toString();
    const values = params?.getAll(name)?.[0]?.split(',') ?? [];

    const newValues = values.includes(valueStr)
      ? values.filter((el) => el !== valueStr)
      : values.concat(valueStr);
    if (newValues.length) {
      params.set(name, newValues);
    } else params.delete(name);
  }

  return `?${params.toString()}`;
};

// TODO: the urlSearchParams functions should got to seperate file: vmUrlSearchParams
export const isValExistsInUrlSearchParams = (name, val) => {
  if (!name || !val) return false;
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const paramsValue = params?.getAll(name)?.[0]?.split(',') ?? [];

  if (!!paramsValue.length && paramsValue.includes(val?.toString())) return true;
  return false;
};

export const srollToTop = () => window.scrollTo(0, 0);

export const isClassNameExist = (className) => !!document.getElementsByClassName(className)?.length;

export const getElementByClassName = (className) => document.getElementsByClassName(className)[0];

export const extractKeyFromfilesupload = (filesuploadItems = [], filesuploadType, keyToExtract) => {
  // filesuploadItems = apiRes.filesupload
  // filesuploadType = apiRes.filesupload[i].filesupload_type (header_image/preview_image)
  // keyToExtact = apiRes.filesupload[i].filesupload.keyToExtract ex: copyrights/file_path
  if (!isArr(filesuploadItems) || !filesuploadType || !keyToExtract) return null;
  return filesuploadItems.find((el) => el?.filesupload_type === filesuploadType)?.filesupload?.[keyToExtract];
};

export const copyToClipboard = (val) => {
  navigator.clipboard.writeText(val)
    .then(() => console.log('copyToClipboard VM: success'))
    .catch((err) => console.log('copyToClipboard VM error: ', err));
};

export const isImage = (src) => /\.(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(src);
export const isVideo = (src) => /\.(wmv|WMV|mpg|MPG|webm|WEBM|ogv|OGV|mob|MOV|asx|ASX|mpeg|MPEG|mp4|MP4|m4v|M4V|avi|AVI)$/.test(src);
export const isPdf = (src) => /\.(pdf|PDF|ppt|PPT|pptx|PPTX)$/.test(src);

export const closeSplashScreen = () => forceCloseSplashScreen();
closeSplashScreen.vmFunctionMetaData = {
  isReduxAction: true,
};
// need to move to it's own container folder but didn't manage to do so for now
// added entry to override the app container to test in the browser
export const isInsideAppContainer = () => !!(window?.webkit?.messageHandlers?.cordova_iab || storageAndCookie.get('app-container-in-browser') || navigator.userAgent.toLowerCase().includes('wv') || navigator.userAgent.toLowerCase().includes('webview'));
