import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeFormInputAndSubmit = (componentStyles) => `
.inputFormAndButton-wrapper {
    display: flex;
    justify-content: space-around;
    ${componentStylesToCss(componentStyles?.wrapper)}
}

.inputBox-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 2rem;
    margin: .5rem 0;
}

.inputBox-title {
    margin-bottom: 3px;
}
.inputBox-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    background:#13202A1F;
}
.inputBox-input:focus {
    background:#CEECF9;
    outline:none;
}
.submitButton-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
`;

export default stylesJmeFormInputAndSubmit;
