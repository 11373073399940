// Here we will put our USER INFO
import {
  LOGIN_USER,
  LOGOUT_USER,
  APP_SET_READY
} from '../constants';

export const initialUser = {
  login_info: {
    id: undefined,
    token: undefined,
    tickets: [] // Will be used for permissions
  },
  currentLocale: null,
  appTimezone: null
  // There will be probably more stuff to do with the user...
  // Such as favorites, chair.. etc
};

const userReducer = (state = initialUser, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        login_info: {
          ...action.payload
        }
      };
    case LOGOUT_USER:
      return initialUser;
    case APP_SET_READY: {
      const { timezone, locale } = action.payload.settings?.appSettings ?? {};
      return {
        ...state,
        appTimezone: timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
        currentLocale: locale?.currentLocale?.toLowerCase() ?? 'en'
      }; }
    default:
      return state;
  }
};

export default userReducer;
