import { getEventId } from '../../core';
import { parsePost } from '../groupposts/grouppostUtils';
import { getUrl, myNetworkingApiCall } from '../myNetworkingApiUtils';
import { coerceToObj } from '../myNetworkingUtils';
import {
  COMMUNITIES_PATH,
  NEWS_PATH,
  PIN_BOARD_COMMENT_TYPE
} from './pinboardConstants';
import { setPinboard, setPinboardId } from './pinboardOperations';
import { getPinboardId } from './pinboardSelectors';

const STORE_MASK_GROUPPOSTS_GET_PIN_BOARD = 'myNetworking/grouposts/get/pinBoard';

export const apiGetPinboardPosts = (props) => {
  const { comment, ...restProps } = coerceToObj(props);

  const body = {
    event_id: getEventId(),
    news_id: getPinboardId(),
    commenttype: PIN_BOARD_COMMENT_TYPE,
    use_cache: 0,
    include_children: 1
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_GROUPPOSTS_GET_PIN_BOARD,
    pathname: COMMUNITIES_PATH,
    body,
    ...restProps
  })
    .then((res) => {
      const parsedPosts = res.data?.map((el) => parsePost(el));
      // Some of the posts in the pinboard could be a pointer to
      // the other post. If so we have to fetch them first:
      const getSharedId = (el) => el.comment?.body.text?.sharedPost?.id;

      const ids = parsedPosts.reduce((acc, el) => {
        const sharedId = getSharedId(el);
        return sharedId && !acc.includes(sharedId) ? [...acc, sharedId] : acc;
      }, []);

      if (ids.length) {
        const url = getUrl({ pathname: COMMUNITIES_PATH });
        url.searchParams.set('ids', String(ids));
        return fetch(url, {
          headers: {
            Authorization: `Basic ${btoa('m-events:kims')}`,
            'cache-control': 'no-cache'
          }
        })
          .then((resp) => resp.json())
          .then((resp) => resp?.map((el) => parsePost(el)))
          .then((fetchedPosts) => parsedPosts.reduce((acc, el) => {
            const sharedId = getSharedId(el);
            // 1. see if the post in parsedPosts is a sharedPost
            // 2. it can happen, that we found shared post in parsedPosts,
            //    but API did not find it in the DB, then we do not include it in the
            //   carousel
            if (sharedId) {
              const post = fetchedPosts.find(
                (el2) => el2.id === getSharedId(el)
              );
              return post ? [...acc, post] : acc;
            }
            return [...acc, el];
          }, []));
      }

      return Promise.resolve(parsedPosts);
    })
    .then((data) => {
      setPinboard(data);
    });
};

export const apiGetPinBoardId = () => {
  const body = {
    title: 'Pin board', // should not be changed
    event_id: getEventId()
  };
  return myNetworkingApiCall({
    method: 'GET',
    pathname: NEWS_PATH,
    body
  }).then((res) => {
    setPinboardId(res?.data?.[0]?.id);
    apiGetPinboardPosts();
  });
};
