import { STATUS_ERROR, STATUS_LOADING, STATUS_READY } from '../../constants';

export const SET_HEAD_TAGS = 'modules/head-tags/set';

// setters:
const actionFactory = (status) => (id) => ({
  type: SET_HEAD_TAGS,
  payload: { id, status }
});

export const setLoading = actionFactory(STATUS_LOADING);
export const setReady = actionFactory(STATUS_READY);
export const setError = actionFactory(STATUS_ERROR);

export const reducer = (state, payload) => {
  const { id, status } = payload;

  if (!id) return state;

  return {
    ...state,
    [id]: { status }
  };
};
