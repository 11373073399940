import useSWR from 'swr';
import { MODULE_NAME } from './constants';
import {
  useIsActiveByName,
  useSettingsByName,
} from '../../hooks';
import { checkRedirectToEventsAirSync } from './utils';
import { fetchBookedTickets } from './api';
import { getManageConfig, useSettingsWithManage } from '../../manage-config';

export const useIsActive = () => useIsActiveByName(MODULE_NAME);
export const useSettings = () => useSettingsByName(MODULE_NAME);

export const useCheckRedirectToEventsAir = () => {
  const settings = useSettings() ?? {};
  const { accessToken } = useSettingsWithManage(MODULE_NAME) ?? { accessToken: '' };
  return checkRedirectToEventsAirSync({
    ...settings,
    accessToken
  });
};

export const useBookedTicketsForEventsAir = () => {
  const moduleSettings = getManageConfig();
  const { accessToken } = useSettingsWithManage(MODULE_NAME) ?? { accessToken: '' };

  const isActive = useIsActive();
  const { data, error, isLoading } = useSWR(
    () => (isActive && accessToken ? 'externalAuthorizationCode' : null),
    () => fetchBookedTickets(moduleSettings, { accessToken, isActive }),
    {
      revalidateOnFocus: true, // SWR option: refetch on window focus
      shouldRetryOnError: true, // SWR option: retry on errors
    }
  );

  return {
    data: data?.data ?? null,
    statusCode: data?.statusCode ?? null,
    error,
    isLoading,
  };
};
