// This is our store
import { createStore, compose } from 'redux';
import rootReducer from './reducers/index';
import middleware from './middleware/middleware';

const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;

const enhancer = composeEnhancers(
  middleware,
  // other store enhancers if any
);

const store = createStore(rootReducer, {}, enhancer);

export default store;
