import { getUserId } from '../../loginInfo';
import { isArr } from '../../vmArray';
import { strToSlug } from '../../vmString';

export const userToSlug = (aUser) => strToSlug(`${aUser?.firstname} ${aUser?.surname}`);

export const userToSearchableString = (aUser) => `${aUser?.firstname} ${aUser?.surname}`;

export const filterOutSelf = (userList) => (isArr(userList) ? userList.filter((el) => el.id !== getUserId()) : []);

export const userToFullName = (aUser) => `${aUser?.firstname || ''} ${aUser?.surname || ''}`;
