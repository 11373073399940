import { getAccessToken, getUserId } from '../../loginInfo';
import { myNetworkingApiCall } from '../myNetworkingApiUtils';
import { MY_NETWORKING_COMMENT_TYPE } from '../myNetworkingConstants';
import { appendId, coerceToObj } from '../myNetworkingUtils';

const STORE_MASK_COMMENT_REPORT_CREATE = 'myNetworking/commentreports/create';

const PATHNAME = '/v1/comment_reports.json';

// eslint-disable-next-line import/prefer-default-export
export const apiCommentReportCreate = (props) => {
  const { commentId: commentIdStr, ...restProps } = coerceToObj(props);

  const commentId = parseInt(commentIdStr, 10);

  if (!commentId) {
    return Promise.reject(
      new Error('Wrong function arguments. ', { cause: props })
    );
  }

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    commentid: commentId,
    commenttype: MY_NETWORKING_COMMENT_TYPE
  };

  return myNetworkingApiCall({
    method: 'POST',
    storeMask: appendId(STORE_MASK_COMMENT_REPORT_CREATE, commentId),
    pathname: PATHNAME,
    body,
    ...restProps
  });
};
