import PropTypes from 'prop-types';

const apiDataPropType = PropTypes.shape({
  data: PropTypes.oneOf([
    PropTypes.shape({
      headers: PropTypes.shape({}),
      data: PropTypes.oneOf([PropTypes.shape({}), PropTypes.array]),
    }),
    PropTypes.array
  ])
});

export default apiDataPropType;
