import { useSelector } from 'react-redux';
import { useIsActive as useIsActiveModules } from '../../hooks';
import { MODULE_NAME } from './constants';

export const useIsActive = () => useIsActiveModules(MODULE_NAME);

export const useGetPage = () => {
  const { pages = [] } = useSelector((state) => state.appState.settings);
  return (path) => pages.find((el) => el.route?.path === path);
};
