import { componentStylesToCss } from '../componentStylesUtils';

const stylesStaging = (componentStyles) => `
  .staging-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: black;
    width: 20px;
    height: 20px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .staging-hover,
  .staging-hover:active {
    left: 2px;
    top: 2px;
    width: 300px;
    height: 50px;
    border-radius: 5px;
  }
  .staging-text {
    color: white;
  }
  .staging-btn {
    background: transparent;
    color: white;
    text-decoration: underline;
  }
`;

export default stylesStaging;
