import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeCarousel = (componentStyles, theme) => `
  .jme-caorusel {
    position: relative;
    overflow: hidden;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-caorusel-smoothScroll {
    position: relative;
    overflow: hidden;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${
  theme.breakpoints.unit
}) {
      .jme-caorusel-smoothScroll{
        overflow-x: scroll;
      }
      .jme-caorusel-smoothScroll::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
      .jme-caorusel-smoothScroll {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }

  .jme-carousel-outLayer {
    position: relative;
  }
  .jme-carousel-outLayer-fullWidth {
    position: relative;
    overflow: hidden;
    width: 100vw;
    right: 50%;
    left: 50%;
    margin-right:-50vw;
    margin-left: -50vw;
  }

  .jme-carousel-indicator-wrapper {
    position: absolute;
    display: flex;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -5px);
  }
  .jme-carousel-items-wrapper {
    position: relative;
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    transition: 0.4s ease;
    height:100%;
    left: 0px; // this needed in order to make slide smoothly on first click
  }
  .jme-carousel-indicator {
    margin-right: 5px;
  }
  .jme-carousel-indicator.active {
    color: white;
    border: 1px solid black;
    border-radius: 50%;
  }
`;

export default stylesJmeCarousel;
