/**
 * @description select specific path from the API response. If this point do not parse
 * well, return empth array
 * @param {Object} res API response object
 * @returns modified API respnse
 */
// eslint-disable-next-line import/prefer-default-export
export const postProcessApiResponse = () => (res) => ({
  ...res,
  data: JSON.parse(res?.data?.[0]?.comment?.body?.text ?? '[]')?.map(
    (el, idx) => ({ id: `vmAutoGen_${idx}`, ...el })
  )
});
