const storageAndCookie = {
  storageInUse() {
    // https://coderwall.com/p/4rsq1w/check-your-html5-local-storage-usage
    let data = '';
    function addToData(key) {
      data += window.localStorage[key];
      // console.log(`${key} = ${((window.localStorage[key].length * 16) / (8 * 1024)).toFixed(2)} KB`);
    }
    Object.keys(window.localStorage).map((key) => (
      window.localStorage[key] ? addToData(key) : null
    ));

    // console.log(data
    //   ? `Total space used: ${((data.length * 16) / (8 * 1024)).toFixed(2)} KB`
    //   : 'Empty (0 KB)');
    // console.log(data
    //   ? `Approx. space remaining: ${(5120 - ((data.length * 16) / (8 * 1024)).toFixed(2))} KB`
    //   : '5 MB');
    return parseInt(((data.length * 16) / (8 * 1024)).toFixed(2), 10); // spaece used
  },
  canSaveToStorage(value) {
    if (!value) {
      console.error("localStorageSizeLeft didn't got value");
      return false;
    }
    const maxStorageSize = 5000;
    const stringify = JSON.stringify(value);
    const valueInBites = stringify.length * 2;
    const valueInKb = parseInt((valueInBites / 1024).toFixed(2), 10);
    const storageInUse = this.storageInUse();
    if ((storageInUse + valueInKb) > maxStorageSize) {
      console.log(`Not enough space in local storage.
      \nMax available: ${maxStorageSize}KB
      \nIn use: ${storageInUse}KB
      \nFree space: ${maxStorageSize - storageInUse}KB
      \nYou want to save: ${valueInKb}KB`, 'color: #ff0000');
      return false;
    }
    return true;
  },
  localStoreSupport() {
    try {
      return 'localStorage' in window && window.localStorage !== null && this.isLocalStorageNameSupported();
    } catch (e) {
      return false;
    }
  },
  isLocalStorageNameSupported() {
    const testKey = 'test'; const
      storage = window.sessionStorage;
    try {
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      // return localStorageName in win && win[localStorageName];
      return true;
    } catch (error) {
      return false;
    }
  },
  set(name, value, days, toCookie = false) {
    let expires;
    let date = new Date();
    if (days) {
      if (typeof days === 'object') { // means we sent a date already;
        date = days;
      } else {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      }
      // console.warn("Set storage: date", date)
      expires = `; expires=${date.toGMTString()}`;
    } else {
      expires = '';
    }
    if (this.localStoreSupport() && !toCookie) {
      if (this.canSaveToStorage(value)) {
        localStorage.setItem(name, value);
        return true;
      }
      return false;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
    return true;
  },
  get(name, fromCookie = false) {
    let ret = localStorage.getItem(name);
    if (this.localStoreSupport() && !fromCookie) {
      // cl(typeof ret);
      switch (ret) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return ret;
      }
    } else {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
          ret = c.substring(nameEQ.length, c.length);
          switch (ret) {
            case 'true':
              return true;
            case 'false':
              return false;
            default:
              return ret;
          }
        }
      }
      return null;
    }
  },
  del(name, fromCookie = false) {
    if (this.localStoreSupport() && !fromCookie) {
      localStorage.removeItem(name);
    } else {
      this.set(name, '', -1, fromCookie);
    }
  },
};

export default storageAndCookie;
