import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { mapData } from '../../global-utils/dataMapping';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import {
  stylesPropType,
  componentsPropType,
  vmPropTypes
} from '../../global-prop-types';
import vmFunctions from '../../global-utils/vmFunctions';
import useShallowEqualSelector from '../core/useShallowEqualSelector';
import { useDispatchVmEventList } from '../../global-utils';
// import { replacePlaceholders } from '../../global-utils/map-data-to-props';

/* Example:
  {
    "componentName": "JmeOnClick",
  "jmeOnClick": [
    {
      "vmType": "VM_TYPE_DATA_MAPPING",
      "vmPath": [
        "vmFunctions",
        "setClientData"
      ],
      "args": [
        "webinarMediaPlayerFolder2Show",
        {
         "vmType":"VM_TYPE_DATA_MAPPING",
         "vmPath": ["props","componentItemData","path"]
        }
      ]
    }
  ],
  }
*/
const JmeOnClick = (props) => {
  const {
    styles,
    jmeOnClick, // DEPRICATED, please use vmOnClick
    vmOnClick,
    variant,
    modelName,
    closeModal,
    stopPropagation,
    ...restProps
  } = props;
  const store = useShallowEqualSelector((state) => state);
  const dispatch = useDispatch();
  const dispatchVmEventList = useDispatchVmEventList();
  const dataBank = { vmFunctions, props, store };

  const handleClick = (ev) => {
    if (stopPropagation) ev.stopPropagation();
    dispatchVmEventList(vmOnClick, dataBank);
    // !!! Check if works with ectrims and replace placeholders
    jmeOnClick?.forEach((dataMap) => {
      const val = mapData(dataBank, dataMap);
      // The vmFunctions have now vmFunctionMetaData which should hold the switch isReduxAction
      // Note, that ?? is here crucial instead of ||, coz we want to descend to func.isReduxAction,
      // only when it is undefine dand not when it is false :)
      if (
        val?.func?.vmFunctionMetaData?.isReduxAction
        ?? val?.func?.isReduxAction
      ) {
        dispatch(val.func(...val.args));
      }
    });
  };

  const handleKeyDown = (ev) => {
    if (stopPropagation) ev.stopPropagation();
    if (ev.keyCode === 13) {
      // Enter
      handleClick();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={`jmeOnClick-wrapper ${variant || ''}`}
      style={styles?.wrapper}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      data-toggle={modelName ? 'modal' : ''}
      data-target={modelName ? `#${modelName}` : ''}
      data-dismiss={closeModal ? 'modal' : ''}
    >
      <ComponentsArrRenderer {...restProps} />
    </div>
  );
};

JmeOnClick.propTypes = {
  styles: stylesPropType,
  jmeOnClick: PropTypes.array,
  components: componentsPropType,
  modelName: PropTypes.string,
  variant: PropTypes.string,
  closeModal: PropTypes.bool,
  vmOnClick: PropTypes.array,
  stopPropagation: PropTypes.bool
};

JmeOnClick.defaultProps = {
  variant: '',
  modelName: '',
  styles: { wrapper: {} },
  components: [
    {
      componentName: 'JmeText',
      text: 'Button'
    }
  ],
  closeModal: false
};

JmeOnClick.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  modelName: vmPropTypes.string,
  closeModal: vmPropTypes.boolean,
  components: vmPropTypes.components,
  variant: vmPropTypes.className,
  vmOnClick: vmPropTypes.array,
  stopPropagation: vmPropTypes.boolean
};

export default JmeOnClick;
