import { componentStylesToCss } from '../componentStylesUtils';

const stylesSidebarMenu = (componentStyles, theme) => `
  .sidebarMenu-wrapper div {
    transition: 0.5s;
  }
  .sidebarMenu-wrapper {
    height: 100%;
    width: 75%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #052E42;
    overflow-x: hidden;
    transition: 0.5s;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.xs}${theme.breakpoints.unit}) {
    .sidebarMenu-wrapper {
      width: 100%;
    }
  }
  .sidebarMenu-box {
    height: auto;
    width: auto;
    flex-grow: inherit;
    background-color: inherit;
    color: ${theme.colors.secondary?.main};
    ${componentStylesToCss(componentStyles?.box)}
  }
  .sidebarMenu-menuList {
    padding-bottom: ${theme.spacing(8)}!important;
    ${componentStylesToCss(componentStyles?.menuList)}
  }
  .sidebarMenu-closeIcon {
    justify-content: flex-end;
  }
`;

export default stylesSidebarMenu;
