import { decreaseWsChannelNotifications, increaseWsChannelNotifications, setMyNetworking } from '../../../../../global-state/redux/actions';
import store from '../../../../../global-state/redux';
import { NOTIFICATIONS } from '../constants';
import { getNotifications } from './channelMessagesSelectors';

const { dispatch, getState } = store;

const tryParse = (val) => {
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
};

export const processChannelMessages = (res) => {
  const resContent = res?.map((el) => {
    const parsedChannelMessage = { ...el, content: tryParse(el.content) };
    if (!el?.opened) dispatch(increaseWsChannelNotifications(parsedChannelMessage));
    return parsedChannelMessage;
  });
  if (!res.isDebounced) {
    dispatch(setMyNetworking(NOTIFICATIONS, resContent));
  }
  return resContent;
};

const handleDecreaseNotification = (res) => {
  const messages = getState()?.ws?.channelMessageNotifications?.messages;
  const idx = messages.findIndex((el) => el.id === res.id);
  if (idx > -1) {
    dispatch(decreaseWsChannelNotifications(messages.toSpliced(idx, 1)));
  }
};

export const markChannelMessageAsOpened = (notification) => {
  const resClone = { ...notification, content: tryParse(notification.content) };
  const updatedChannelMessageNotifications = getNotifications()?.map((el) => ((el.id === resClone?.id) ? resClone : el));
  dispatch(setMyNetworking(NOTIFICATIONS, updatedChannelMessageNotifications));
  handleDecreaseNotification(resClone);
};
