import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import PageRenderer from '../components/core/PageRenderer';
import { componentsPropType } from '../global-prop-types';

const NewsPage = (props) => <PageRenderer urlParams={useParams()} {...props} />;

NewsPage.propTypes = {
  components: componentsPropType,
  apiDataInfo: PropTypes.array,
};

NewsPage.defaultProps = {
  apiDataInfo: [],
  components: [],
};
export default NewsPage;
