import { componentStylesToCss } from '../componentStylesUtils';

const stylesAppWrapper = (componentStyles, theme) => `
  .blur-mode {
    filter: blur(8px);
  }

  .appWrapper-wrapper {
    font-family: ${theme.fonts.family};
    flex-grow: 1;
    background-color: ${theme.colors.background?.main};
    overflow-x: hidden;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .appWrapper-wrapper {
      padding: 0px ${theme.spacing(2)};
      /* 56px - size of the bottom navbar as defined in MUI,
      18px - is the bottom padding,
      5px - is for nicer feel */
      margin-bottom: calc(56px - 18px + 5px);
    }
  }
  .jme-container {
    background-color: inherit;
  }
  @media (max-width: 991px) {
    .jme-container {
      padding-top: 0px !important;
    }
  }
`;

export default stylesAppWrapper;
