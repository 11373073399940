export const SETTINGS_AGREMMENTS_KEY = 'settings/agreements';
export const SETTINGS_KEY = 'settings/profile/myNetworking';

export const SETTING_EMAILABLE_TITLE = 'Emailable';
export const SETTING_COMMUNICABLE_TITLE = 'Communicable';
export const SETTING_DECLINE_COMMUNICABLE_TITLE = 'Decline-communicable';

// file type to save in filesuploads:
export const FILESUPLOAD_TYPE_BANNER_IMAGE = 'banner_image';

export const WORKPLACE_ALL_VISIBLE = 'workplace_all_visible';
export const WORKPLACE_CHAT_VISIBLE = 'workplace_chat_visible';
export const BIOGRAPHY_ALL_VISIBLE = 'biography_all_visible';
export const BIOGRAPHY_CHAT_VISIBLE = 'biography_chat_visible';
export const EMAIL_ALL_VISIBLE = 'email_all_visible';
export const EMAIL_CHAT_VISIBLE = 'email_chat_visible';
