export const extractSlugFromMeeting = (apiRes) => {
  if (!apiRes) return null;
  if (apiRes?.favouriteable?.slug) return apiRes.favouriteable.slug;
  if (apiRes?.body?.slug) return apiRes.body.slug;
  return apiRes?.slug;
};

export const extractId = (apiRes) => {
  if (!apiRes) return null;
  if (apiRes?.favouriteable_id) return apiRes.favouriteable_id;
  if (apiRes?.body?.id) return apiRes.body.id;
  return apiRes?.id;
};
