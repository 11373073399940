import {
  getMyNetworkingByKey,
  selectMyNetworking
} from '../myNetworkingSelectors';
import { USER_SETTINGS } from './agreementApi';
import {
  FILESUPLOAD_TYPE_BANNER_IMAGE,
  SETTINGS_KEY,
  WORKPLACE_ALL_VISIBLE,
  WORKPLACE_CHAT_VISIBLE,
  BIOGRAPHY_ALL_VISIBLE,
  BIOGRAPHY_CHAT_VISIBLE,
  EMAIL_ALL_VISIBLE,
  EMAIL_CHAT_VISIBLE,
  SETTING_COMMUNICABLE_TITLE,
  SETTING_EMAILABLE_TITLE
} from './settingsConstants';

export const getSettingsStore = () => getMyNetworkingByKey(SETTINGS_KEY) ?? {};

export const getSettings = () => {
  const {
    user = {},
    agreements = [],
    networkingSettings = []
  } = getSettingsStore();

  // We need to destruct properties of the settgins to an
  // object understood by layout settings:

  // Settings of the agreements:
  const communicable = !!agreements?.find(
    (el) => el.agreement_type === USER_SETTINGS && el.title === SETTING_COMMUNICABLE_TITLE
  )?.agreed;

  const emailable = !!agreements?.find(
    (el) => el.agreement_type === USER_SETTINGS && el.title === SETTING_EMAILABLE_TITLE
  )?.agreed;

  // Settings of the user:
  const { tags, filesupload_items: filesuploadItems = [] } = user;
  const path = filesuploadItems.find(
    (el) => el.filesupload_type === FILESUPLOAD_TYPE_BANNER_IMAGE
  )?.filesupload?.file_path;

  const getSettingsByTitle = (settings, title) => settings.find((el) => el.title === title);

  const workplace = (() => {
    if (getSettingsByTitle(networkingSettings, WORKPLACE_ALL_VISIBLE)?.agreed) return WORKPLACE_ALL_VISIBLE;
    if (getSettingsByTitle(networkingSettings, WORKPLACE_CHAT_VISIBLE)?.agreed) return WORKPLACE_CHAT_VISIBLE;
    return '';
  })();

  const biography = (() => {
    if (getSettingsByTitle(networkingSettings, BIOGRAPHY_ALL_VISIBLE)?.agreed) return BIOGRAPHY_ALL_VISIBLE;
    if (getSettingsByTitle(networkingSettings, BIOGRAPHY_CHAT_VISIBLE)?.agreed) return BIOGRAPHY_CHAT_VISIBLE;
    return '';
  })();

  const email = (() => {
    if (getSettingsByTitle(networkingSettings, EMAIL_ALL_VISIBLE)?.agreed) return EMAIL_ALL_VISIBLE;
    if (getSettingsByTitle(networkingSettings, EMAIL_CHAT_VISIBLE)?.agreed) return EMAIL_CHAT_VISIBLE;
    return '';
  })();

  return {
    communicable,
    tags,
    path,
    workplace,
    biography,
    email,
    emailable
  };
};

export const getAgreements = () => getSettingsStore().agreements ?? [];

export const getNetworkingSettings = () => getSettingsStore().networkingSettings ?? [];

export const isCommunicable = () => getSettings().communicable;
export const isEmailable = () => getSettings().emailable;

// Redux selectors:
export const selectSettings = (state) => selectMyNetworking(state)?.[SETTINGS_KEY];
export const selectIsCommunicable = (state) => selectSettings(state)?.agreements?.find(
  (el) => el.agreement_type === USER_SETTINGS && el.title === SETTING_COMMUNICABLE_TITLE
)?.agreed;
