/**
 * Core functionality of the vmTree manipulation. The functions from this module are well tested and are
 * here because they appear in other vmTree convienince functions.
 *
 * Rules of this module:
 *   - do not add dependencies on other vmTree functions. That means no vmTree functions imports here
 *   - these are not functions for convienience.
 */
import { v4 as uuid } from 'uuid';
import { isArr, isInt, isObj } from '../globalUtils';
import { isVmPath } from '../vmPath/vmPath';

const KEY = 'id'; // just in case we will decide to use key instead of id

/** This should replace the old insertKey funciton! */
// const insertKey = (obj) => traverseTree(obj, (obj2, objKey) => (obj2.componentName || obj2.pageName || objKey === 'columns'
//   ? { ...obj2, [KEY]: uuid() }
//   : obj2));

/** This function should be replaced with the function above (using traverseTree), but it's not a good time now to do it :) (09.02.2022) */
export const insertKey = (obj, objKey) => {
  if (isArr(obj)) {
    return obj.map((el) => insertKey(el, objKey));
  }
  if (isObj(obj)) {
    const objClone = Object.keys(obj).reduce((acc, key) => {
      acc[key] = insertKey(obj[key], key);
      return acc;
    }, {});
    if (objClone.componentName || objClone.pageName || objKey === 'columns') {
      objClone[KEY] = uuid();
    }
    return objClone;
  }
  return obj;
};

/**
 * @description retrive element from vmTree
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {Array} vmPath - path to requested element
 * @returns {*} if value under the vmPath exists, returns this value,
 * otherwise undefined.
 */
export const vmTreeGet = (vmTree, vmPath) => {
  // Assure that path is expected vmPath array
  if (!isVmPath(vmPath)) return undefined;

  const recurse = (vmTree2, vmPath2) => {
    // Destruct vmTree2 until vmPath2 it's empty array,
    // then return vmTree2 obj.
    if (vmPath2?.length) {
      const [key, ...restVmPath] = vmPath2;
      return recurse(vmTree2?.[key], restVmPath);
    }
    return vmTree2; // vmPath is empty array
  };

  return recurse(vmTree, vmPath);
};

/**
 * @description Assigns value to element under vmPath.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {Array} vmPath - path to requested element
 * @param {*} value - value to assing to element pointed by vmPath
 * @returns {Object|Array} deep copy of the vmTree until the level of the element
 * pointed by vmPath. Following happens:
 *   - if vmPath do not pass vmPath test return vmTree
 *   - if vmPath is empth, returns value
 *   - if vmPath points to non existing element, returns deep copy till the
 *     last existing vmPath element
 *   - in other cases, assigns value to the element pointed by vmPath
 */
export const vmTreeSet = (vmTree, vmPath, value) => {
  if (!isVmPath(vmPath)) return vmTree;

  // Deep copy vmTree while navigating to the element pointed by vmPath2,
  // then assign value to element and break recursion and deep copying.
  const recurse = (vmTree2, vmPath2) => {
    if (vmPath.length) {
      const [key, ...restVmPath2] = vmPath2;
      if (isArr(vmTree2) && isInt(key) && key >= 0) {
        const vmTree2Clone = [...vmTree2];
        vmTree2Clone[key] = restVmPath2.length
          ? recurse(vmTree2Clone[key] ?? [], restVmPath2, value)
          : value;
        return vmTree2Clone;
      }
      if (isObj(vmTree2)) {
        const vmTree2Clone = { ...vmTree2 };
        vmTree2Clone[key] = restVmPath2.length
          ? recurse(vmTree2Clone[key] ?? {}, restVmPath2, value)
          : value;
        return vmTree2Clone;
      }
      return undefined; // we cannot further itterate
    }
    return value; // if vmPath is an empth array, assign value to the root
  };

  return recurse(vmTree, vmPath);
};

/**
 * The following functions accept specific vmTree callback function iwth consistent
 * API. This is the def of the API of the vmTree callback function.
 *
 * @callback vmTreeCallback
 * @param {Object|Array} vmTree - usually a vmTree but can be also any other value
 * @param {Array} [vmPath = []] - path to the current node ie vmTree
 */

/**
 * Call callbackFn for each node of the vmTree.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {vmTreeCallback} callbackFn - function to run on each node
 */
export const vmTreeForEach = (vmTree, callbackFn = () => null) => {
  const recurse = (vmTree2, vmPath = []) => {
    callbackFn(vmTree2, vmPath);
    if (isArr(vmTree2)) {
      vmTree2.forEach((el, idx) => {
        recurse(el, [...vmPath, idx]);
      });
    }
    if (isObj(vmTree2)) {
      Object.keys(vmTree2).forEach((key) => {
        recurse(vmTree2[key], [...vmPath, key]);
      });
    }
  };
  recurse(vmTree);
};

/**
 * Call callbackFn for each node of the vmTree. Stops traversing the vmTree
 * on the first node that callbackFn returns truthy value.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {vmTreeCallback} callbackFn - function to run on each node. returned
 * value is checked for it;s truthines.
 * @returns {*} found element or undefined
 */
export const vmTreeFind = (vmTree, callbackFn = () => false) => {
  let elFound;
  const recurse = (vmTree2, vmPath = []) => {
    if (callbackFn(vmTree2, vmPath)) {
      elFound = vmTree2;
      return true;
    }
    if (isArr(vmTree2)) {
      return vmTree2.some((el, idx) => recurse(el, [...vmPath, idx]));
    }
    if (isObj(vmTree2)) {
      return Object.keys(vmTree2).some((key) => recurse(vmTree2[key], [...vmPath, key]));
    }
    return false;
  };

  recurse(vmTree);
  return elFound;
};

/**
 * Find vmPath to the first element for which callbackFn returns true.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {vmTreeCallback} callbackFn - function to run on each node. Returned
 * value is checked for it's truthines.
 * @returns {Array} vmPatht to found element or undefined if no match found.
 */
export const vmTreeFindVmPath = (vmTree, callbackFn = () => null) => {
  let elFound;
  const recurse = (vmTree2, vmPath = []) => {
    if (callbackFn(vmTree2, vmPath)) {
      elFound = vmPath;
      return true;
    }
    if (isArr(vmTree2)) {
      return vmTree2.some((el, idx) => recurse(el, [...vmPath, idx]));
    }
    if (isObj(vmTree2)) {
      return Object.keys(vmTree2).some((key) => recurse(vmTree2[key], [...vmPath, key]));
    }
    return false;
  };

  recurse(vmTree);
  return elFound;
};

/**
 * Find vmPath to all the element for which callbackFn returns true.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {vmTreeCallback} callbackFn - function to run on each node. Returned
 * value is checked for it's truthines.
 * @returns {Array} array of vmPaths to found element or empth array if no match found.
 */
export const vmTreeFindAllVmPath = (vmTree, callbackFn = () => null) => {
  const vmPathList = [];
  const recurse = (vmTree2, vmPath = []) => {
    if (callbackFn(vmTree2, vmPath)) {
      vmPathList.push(vmPath);
    }
    if (isArr(vmTree2)) {
      vmTree2.forEach((el, idx) => recurse(el, [...vmPath, idx]));
    }
    if (isObj(vmTree2)) {
      Object.keys(vmTree2).forEach((key) => recurse(vmTree2[key], [...vmPath, key]));
    }
    return false;
  };

  recurse(vmTree);
  return vmPathList;
};

/**
 * Call callbackFn for each node of the vmTree and assign to this node value
 * returned from callbackFn. The traversing here starts from the last element
 * in a branch (leaf).
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {vmTreeCallback} callbackFn - function to run on each node
 * @returns {*} Deep copy of the vmTree with values as returned by callbackFn
 */
export const vmTreeMap = (vmTree, callbackFn = () => undefined) => {
  const recurse = (vmTree2, vmPath = []) => {
    if (isArr(vmTree2)) {
      const vmTree2Clone = vmTree2.map((el, idx) => recurse(el, [...vmPath, idx]));
      return callbackFn(vmTree2Clone, vmPath);
    }
    if (isObj(vmTree2)) {
      const vmTree2Clone = Object.keys(vmTree2).reduce((acc, key) => {
        acc[key] = recurse(vmTree2[key], [...vmPath, key]);
        return acc;
      }, {});
      return callbackFn(vmTree2Clone, vmPath);
    }
    return callbackFn(vmTree2, vmPath);
  };

  return recurse(vmTree);
};

/**
 * Call callbackFn for each node of the vmTree. Keeps these objects for which the
 * callback returns true. Traversing do not descend down the object when callbackFn
 * returns true.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {vmTreeCallback} callbackFn - function to run on each node
 * @returns {*} These parts of the vmTree where callbackFn is truthly. If no
 * element is truthy, returns undefined.
 */
export const vmTreeFilter = (vmTree, callbackFn = () => false) => {
  // This constant is important. Intuitivly we would return undefined,
  // however, if we use undefined, we could not filter all elements
  // that satisfy condition callbackFn = (el) => el === undefined
  const VM_TREE_FILTER_NOT_FOUND = 'VM_TREE_FILTER_NOT_FOUND';

  const recurse = (vmTree2, vmPath = []) => {
    // Checking if callbackFn returns true at the begining of the function
    // assures we stop recursion for objects and arrays.
    if (callbackFn(vmTree2, vmPath)) return vmTree2;

    if (isArr(vmTree2)) {
      const vmTree2Clone = vmTree2.reduce((acc, el, idx) => {
        const ret = recurse(el, [...vmPath, idx]);
        return ret !== VM_TREE_FILTER_NOT_FOUND ? [...acc, ret] : acc;
      }, []);
      // If the array is empty, do not keep it but mark the value as not found constant
      return vmTree2Clone.length ? vmTree2Clone : VM_TREE_FILTER_NOT_FOUND;
    }

    if (isObj(vmTree2)) {
      const vmTree2Clone = Object.keys(vmTree2).reduce((acc, key) => {
        const ret = recurse(vmTree2[key], [...vmPath, key]);
        if (ret !== VM_TREE_FILTER_NOT_FOUND) acc[key] = ret;
        return acc;
      }, {});
      // If the object is empty, do not keep it but mark the value as not found constant
      return Object.keys(vmTree2Clone).length
        ? vmTree2Clone
        : VM_TREE_FILTER_NOT_FOUND;
    }

    return VM_TREE_FILTER_NOT_FOUND;
  };

  const ret = recurse(vmTree);
  return ret === VM_TREE_FILTER_NOT_FOUND ? undefined : ret;
};

/**
 * @description Follow the vmPath and call callbackFn on each node.
 * @param {Object|Array} vmTree - usually a vmTree but can be also other value
 * @param {Array} vmPath - path to requested element
 * @param {vmTreeCallback} callbackFn - function to run on each node. It accepts
 *   args: obj - object of the current node, and vmPath - path to currnet node
 */
export const vmTreeFollow = (vmTree, vmPath, callbackFn = () => null) => {
  // Assure that path is expected vmPath array
  if (!isVmPath(vmPath)) return;

  /** vmPathIn is the path to currnet node, it is no the same as vmPath2, since vmPath2 is
   * restVmPath on each step while vmPathIn has key added on each step
   */
  const recurse = (vmTree2, vmPath2, vmPathIn = []) => {
    callbackFn(vmTree2, vmPathIn);
    if (vmPath2?.length) {
      const [key, ...restVmPath] = vmPath2;
      recurse(vmTree2?.[key], restVmPath, [...vmPathIn, key]);
    }
  };

  recurse(vmTree, vmPath);
};
