import { isStr, isArr, isObj } from './globalUtils';

/**
 * @summary Given object or array obj, retrive value by given vmPath.
 * @param {array} vmPath - vmPath to value
 * @param {(object|array)} obj - object or array to travers
 * @example
 * getValueByVmPath({a: [{b: 2}, null]}, ['a',0,'b']);
 * // expected: 2
 */
// eslint-disable-next-line import/prefer-default-export
export function getValueByVmPath(obj, vmPath) {
  if (!isArr(vmPath) || !(isObj(obj) || isArr(obj))) return undefined;
  return vmPath?.reduce((acc, key) => {
    // TODO: eliminate stringified settings from API responses
    if (key === 'settings' && isStr(acc?.[key])) return JSON.parse(acc[key]);
    return acc?.[key];
  }, obj);
}
