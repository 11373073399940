import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeApiCall } from '../../global-utils/vmFunctions/vmApi/apiUtils';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import {
  apiDataInfoPropType,
  componentsPropType,
  stylesPropType,
  vmPropTypes
} from '../../global-prop-types';
import { Loading } from '../global';
import { mapData } from '../../global-utils/dataMapping';
import vmFunctions from '../../global-utils/vmFunctions';

const InfiniteScroll = (props) => {
  const {
    apiDataInfo,
    components,
    infiniteScrollApiData,
    styles,
    variant,
    textXSingleResult,
    textXResults,
    textNoResults,
    ...restProps
  } = props;
  const { data = [], headers = {}, isReady } = infiniteScrollApiData;
  const {
    'x-total': xTotal,
    'x-page': xPage,
    'x-next-page': xNextPage
  } = headers;
  const [allData, setAllData] = useState([]);
  const boxRef = useRef();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const dataBank = { store, props, vmFunctions };
  const debounceInterval = 30000;

  useEffect(() => {
    // when we have new search criteria or we reset search we should
    // reset accumulated data from previous infinite scrolls

    // New search criteria
    if (Number(xPage) === 1) {
      setAllData(data);
      // Was else if(xNextPage) - revented from adding the last page into data. e.g. xPage = 4
      // xNextPage = undefined then the result of the new page will not be added to allData
      // we remove else if condition to allowed last page to add into allData
    } else {
      setAllData((x) => [...x, ...data]);
    }
  }, [xNextPage, xPage, data]);

  const isAtTheBottom = () => {
    const ifDebounce = Date.now() - infiniteScrollApiData.timestamp < debounceInterval;
    if (
      Math.floor(boxRef?.current?.getBoundingClientRect().bottom)
        <= Math.ceil(window.innerHeight)
      && xNextPage
      && !ifDebounce
    ) {
      const apiDataInfoClone = mapData(dataBank, apiDataInfo, []).map(
        (apiDataInfoItem) => ({
          ...apiDataInfoItem,
          body: {
            ...apiDataInfoItem.body,
            page: xNextPage
          }
        })
      );
      dispatch(makeApiCall(apiDataInfoClone));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', isAtTheBottom);
    return () => {
      window.removeEventListener('scroll', isAtTheBottom);
    };
  }, [infiniteScrollApiData, dataBank, apiDataInfo]);

  if (!isReady) return <Loading />;
  if (!allData.length) {
    return textNoResults;
  }
  return (
    <div className={`${variant || ''}`} style={styles?.wrapper} ref={boxRef}>
      {xTotal && (
        <div className="body3" style={styles?.header}>
          {`${xTotal} ${
            xTotal === '1' ? textXSingleResult : textXResults
          }`}
        </div>
      )}
      <ComponentsArrRenderer
        components={components}
        {...restProps}
        infiniteScrollData={allData}
      />
    </div>
  );
};

InfiniteScroll.propTypes = {
  variant: PropTypes.string,
  styles: stylesPropType,
  apiDataInfo: apiDataInfoPropType,
  infiniteScrollApiData: PropTypes.shape({
    headers: PropTypes.shape({}),
    data: PropTypes.array,
    isReady: PropTypes.bool,
    timestamp: PropTypes.number
  }),
  components: componentsPropType,
  textXSingleResult: PropTypes.string,
  textXResults: PropTypes.string,
  textNoResults: PropTypes.string
};

InfiniteScroll.defaultProps = {
  variant: '',
  apiDataInfo: [],
  components: [],
  infiniteScrollApiData: {},
  textXSingleResult: 'Result',
  textXResults: 'Results',
  textNoResults: 'No results for this search.'
};

InfiniteScroll.vmPropTypes = {
  variant: vmPropTypes.className,
  components: vmPropTypes.components,
  textXSingleResult: vmPropTypes.string,
  textXResults: vmPropTypes.string,
  textNoResults: vmPropTypes.string
};

export default InfiniteScroll;
