import { isObj, isStr } from '../../..';
import { getUserId } from '../../loginInfo';

export const parseText = (text) => {
  // it can happen that text is either a pure text comment or an object:
  if (isObj(text)) return text;
  if (isStr(text)) {
    try {
      return JSON.parse(text);
    } catch (e) {
      return { content: String(text) };
    }
  }
  return {};
};

export const parsePost = (post) => {
  const postNew = {
    ...post,
    comment: {
      ...post.comment,
      body: {
        ...post.comment.body,
        text: parseText(post.comment.body.text)
      }
    }
  };
  return postNew;
};

export const isPinable = (postObj) => !postObj?.has_parent;

export const isPost = (postObj) => !postObj?.has_parent;
export const isComment = (postObj) => postObj?.has_parent;

export const isPinned = (postObj) => postObj?.is_pinned;
export const isNotPinned = (postObj) => !isPinned(postObj);

const isCommunityElCreator = (aCommunity) => aCommunity?.comment?.user?.id === getUserId();
export const isPostCreator = isCommunityElCreator;
export const isCommentCreator = isCommunityElCreator;
