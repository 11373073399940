import PropTypes from 'prop-types';
import { componentsPropType } from '../../global-prop-types';
import ComponentsArrRenderer from './ComponentsArrRenderer';

// This component is resolving a page from JSON.
const ResolvedAdminJsonRenderer = (props) => {
  const { data } = props;
  if (!data?.content) {
    console.log('ResolvedAdminJsonRenderer ERROR ', { data });
    return null;
  }
  return (
    <ComponentsArrRenderer {...props} components={data.content} />
  );
};

ResolvedAdminJsonRenderer.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array, // for default on empty mapping
    PropTypes.shape({
      content: componentsPropType
    })
  ])
};

export default ResolvedAdminJsonRenderer;
