import { componentStylesToCss } from '../componentStylesUtils';

const stylesTopNavigation = (componentStyles, theme) => `
  .topNavigation-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .topNavigation-wrapper {
    }
  }
`;

export default stylesTopNavigation;
