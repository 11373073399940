import { useState } from 'react';
import { useSelector } from 'react-redux';
import storageAndCookie from '../../global-utils/storage-and-cookies';

const Staging = () => {
  const [isHover, serIsHover] = useState(false);
  const eventId = useSelector((state) => state?.appState?.core?.event_id);

  const handleTurnOff = () => {
    storageAndCookie.del(`JmeENV_${eventId}`);
    window.location.pathname = '/';
  };

  return (
    <>
      <div
        className={`staging-wrapper ${isHover ? 'staging-hover' : ''}`}
        onMouseEnter={() => serIsHover(true)}
        onMouseLeave={() => serIsHover(false)}
        style={{ zIndex: 101 }}
      >
        {isHover && (
        <div className="d-flex align-items-center justify-content-center h-100 staging-text">
          <p className="m-0">Staging mode.</p>
          <button className="m-0 staging-btn" onClick={handleTurnOff} type="button">Turn off staging</button>
        </div>
        )}
      </div>
    </>
  );
};

export default Staging;
