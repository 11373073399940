import { useSelector } from 'react-redux';
import ComponentsArrRenderer from './ComponentsArrRenderer';
import {
  useIsActive as useIsPagePreviewActive,
  useIfHideAppTop
} from '../../modules/page-preview';

const AppTop = (props) => {
  const { isActive: isAppTopActive, components } = useSelector(
    (state) => state.appState?.settings?.appTop
  ) ?? {};
  const isPagePreviewActive = useIsPagePreviewActive();
  const ifHideAppTop = useIfHideAppTop();
  const { pathname, search, hash } = window.location;
  const windowLocationRelative = `${pathname}${search}${hash}`;

  if (!isAppTopActive) return null;
  if (isPagePreviewActive && ifHideAppTop(windowLocationRelative)) return null;

  return <ComponentsArrRenderer {...props} components={components} />;
};

export default AppTop;
