import { vmTypes } from '../../../global-prop-types';
import {
  setClientData as setClientDataAction,
  deleteClientData as deleteClientDataAction
} from '../../../global-state/redux/actions';
import store from '../../../global-state/redux';
import { isArr } from '../../globalUtils';

const { getState } = store;

export const setClientData = (key, data) => setClientDataAction(key, data);
setClientData.vmFunctionMetaData = {
  isReduxAction: true,
  argsInfo: [
    { uiTitle: 'Keyword', vmType: vmTypes.string },
    { uiTitle: 'Value', vmType: vmTypes.any }
  ]
};

export const deleteClientData = (keyList) => deleteClientDataAction(keyList);
deleteClientData.vmFunctionMetaData = {
  isReduxAction: true,
  argsInfo: [{ uiTitle: 'Key list', vmType: { type: vmTypes.array } }]
};

export const getClientData = (dataObj) => (key) => dataObj?.store?.appState?.clientData?.[key];
getClientData.vmFunctionMetaData = {
  usesDataObj: true,
  argsInfo: [{ uiTitle: 'Key', vmType: { type: vmTypes.string } }],
  returnVmType: vmTypes.any
};

export const getClientData2 = (key) => getState().appState?.clientData?.[key];

export const clientDataToUrlSearch = (dataObj) => (keyList) => {
  const url = new URL(window.location.href);
  const urlSearch = new URLSearchParams(url.search);

  // Convert clientData urlSearchParams to a query string:
  if (isArr(keyList)) {
    const clientData = dataObj?.store?.appState?.clientData ?? {};
    // each urlSearchParams in clinet data is an array of objects [{value: "ePoster"},{"value: "talk"}].
    // We concat these values to query string:
    keyList.forEach((clientDataKey) => {
      // First delete all the url keys that we are responsible for:
      urlSearch.delete(clientDataKey);
      // Then conditionally add only those url search params that have value:
      const str = clientData[clientDataKey]
        ?.map((el2) => el2?.value)
        ?.join(',');
      if (str) urlSearch.set(clientDataKey, str);
    });
  }

  return urlSearch.toString();
};
clientDataToUrlSearch.vmFunctionMetaData = {
  usesDataObj: true,
  argsInfo: [{ uiTitle: 'Key list', vmType: { type: vmTypes.array } }]
};
