import jsonToJsxComponent from '../jsonToJsxComponent';
import CourseCardJson from './CourseCard.json';
import CoursesSearchButtonGroup1Json from './CoursesSearchButtonGroup1.json';
import CoursesSearchButtonGroup2Json from './CoursesSearchButtonGroup2.json';
import CoursesHorizontalScrollableJson from './CoursesHorizontalScrollable.json';
import CoursesSearchPanelJson from './CoursesSearchPanel.json';
import CoursesCategsButtonGroupJson from './CoursesCategsButtonGroup.json';
import CoursesTopicsButtonGroupJson from './CoursesTopicsButtonGroup.json';

const CourseCard = jsonToJsxComponent(CourseCardJson);
const CoursesSearchButtonGroup1 = jsonToJsxComponent(CoursesSearchButtonGroup1Json);
const CoursesSearchButtonGroup2 = jsonToJsxComponent(CoursesSearchButtonGroup2Json);
const CoursesHorizontalScrollable = jsonToJsxComponent(CoursesHorizontalScrollableJson);
const CoursesSearchPanel = jsonToJsxComponent(CoursesSearchPanelJson);
const CoursesCategsButtonGroup = jsonToJsxComponent(CoursesCategsButtonGroupJson);
const CoursesTopicsButtonGroup = jsonToJsxComponent(CoursesTopicsButtonGroupJson);

export {
  CourseCard,
  CoursesSearchButtonGroup1,
  CoursesSearchButtonGroup2,
  CoursesHorizontalScrollable,
  CoursesSearchPanel,
  CoursesCategsButtonGroup,
  CoursesTopicsButtonGroup,
};
