import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class AnyErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      // errorInfo: {},
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      // errorInfo,
    });
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const {
      children, variant, text, errors
    } = this.props;

    if (error) {
      // You can render any custom fallback USI
      return (
        <div
          className={`${variant || ''}`}
          style={{ textAlign: 'center' }}
        >
          {errors?.errorText
            ? <div className={`${variant || ''}`} dangerouslySetInnerHTML={{ __html: errors.errorText }} />
            : <div>We have an update for you</div>}

          <button
            type="button"
            className="body2"
            style={{ color: '#323470' }}
            onKeyUp={() => window.location.reload(true)}
            onClick={() => window.location.reload(true)}
          >
            {errors?.reloadButton || text || 'Click to reload'}

          </button>
        </div>
      );
    }

    return children;
  }
}

const mapStateToProps = (state) => ({
  errors: state?.appState?.settings?.appSettings?.errors
});

AnyErrorBoundary.propTypes = {
  errors: PropTypes.shape({
    errorText: PropTypes.string,
    reloadButton: PropTypes.string,
  }),
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  text: PropTypes.string,
};

export default connect(mapStateToProps)(AnyErrorBoundary);
