import store from '../../../../../global-state/redux';
import { setMyNetworking } from '../../../../../global-state/redux/actions';
import { appendId, coerceToObj } from '../../myNetworkingUtils';
import { myNetworkingApiCall } from '../../myNetworkingApiUtils';
import { getAccessToken, getUserId } from '../../../loginInfo/loginInfo';
import { getEventId } from '../../../core';
import {
  CHATGROUPREQUESTS_BY_CHATGROUP_SELECTOR_KEY,
  CHATGROUPREQUESTS_SELECTOR_KEY,
  CHATGROUPREQUESTS_STATE_ACCEPTED,
  CHATGROUPREQUESTS_STATE_CREATED,
  CHATGROUPREQUESTS_STATE_REJECTED
} from './chatgrouprequestConstants';
import {
  apiGetChatgroupById,
  CHATGROUPS_SELECTOR_KEY,
  CHATGROUPS_SELECTOR_KEY_BY_ID,
  getChatgroupById,
  getChatgroups
} from '../chatgroups';
import {
  getChatgrouprequests,
  getChatgrouprequestsByChatgroupId
} from './chatgrouprequestSelectors';
import { clearGroupRequests } from '../../notifications';

const { dispatch } = store;

// Constants used to store results of the API response in store:
const STORE_MASK_CHATGROUPREQUESTS_GET = 'myNetworking/chatgroups/requests/get';
const STORE_MASK_CHATGROUPREQUESTS_CREATE = 'myNetworking/chatgroups/requests/create';
const STORE_MASK_CHATGROUPREQUESTS_SET_STATE = 'myNetworking/chatgroups/requests/setState';
const STORE_MASK_CHATGROUPREQUESTS_GET_BY_CHATGROUP_ID = 'myNetworking/chatgroups/requests/getByGroupId';

const PATHNAME = '/v1/chatgrouprequests.json';
const getPathname = (id) => `/v1/chatgrouprequests/${id}.json`;

export const apiGetChatgrouprequest = (props) => {
  const { onSuccessVmEventList, onErrorVmEventList } = coerceToObj(props);

  const body = {
    per_page: 999, // get all requests of the user
    from_user_id: getUserId(),
    access_token: getAccessToken(),
    event_id: getEventId(),
    state: CHATGROUPREQUESTS_STATE_CREATED // Show only not deleted groups
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_CHATGROUPREQUESTS_GET,
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (!res.isDebounced) {
      // save to relevant myNetworking location in redux:
      dispatch(setMyNetworking(CHATGROUPREQUESTS_SELECTOR_KEY, res.data));
    }

    return res;
  });
};

export const apiGetChatgrouprequestsByChatgroupId = (props) => {
  const {
    groupId: groupIdStr,
    onSuccessVmEventList,
    onErrorVmEventList
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  if (!groupId) return;

  const body = {
    per_page: 999, // get all requests of the user
    event_id: getEventId(),
    access_token: getAccessToken(),
    chatgroup_id: groupId,
    state: CHATGROUPREQUESTS_STATE_CREATED // Show only not deleted groups
  };

  myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(
      STORE_MASK_CHATGROUPREQUESTS_GET_BY_CHATGROUP_ID,
      groupId
    ),
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return; // Do not perform data updates on debounced API calls

    dispatch(
      setMyNetworking(
        appendId(CHATGROUPREQUESTS_BY_CHATGROUP_SELECTOR_KEY, groupId),
        res.data
      )
    );
  });
};

export const apiChatgrouprequestCreate = (props) => {
  const {
    groupId: groupIdStr,
    onSuccessVmEventList,
    onErrorVmEventList
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  const body = {
    from_user_id: getUserId(),
    access_token: getAccessToken(),
    chatgroup_id: groupId
  };

  return myNetworkingApiCall({
    method: 'POST',
    storeMask: appendId(STORE_MASK_CHATGROUPREQUESTS_CREATE, groupId),
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return res; // Do not perform data updates on debounced API calls

    // different actions are performed when the request was immediatelly accepted or not:
    const isAccepted = res.data.state === CHATGROUPREQUESTS_STATE_ACCEPTED;

    if (isAccepted) {
      // if accepted, add group to my groups:
      const chatgroups = getChatgroups();
      const { chatgroup } = res.data;
      dispatch(
        setMyNetworking(CHATGROUPS_SELECTOR_KEY, [chatgroup, ...chatgroups])
      );
      // also refresh the group information:
      const { id } = chatgroup;
      return apiGetChatgroupById({ groupId: id, skipDebounce: true });
    }
    // add to requests:
    const groupRequests = getChatgrouprequests();
    const groupRequestNew = res.data;
    dispatch(
      setMyNetworking(CHATGROUPREQUESTS_SELECTOR_KEY, [
        groupRequestNew,
        ...groupRequests
      ])
    );
    return res;
  });
};

/**
 * Accepting or rejecting group request is done by group admin
 * On the other hand creating chatgrouprequest is done by user who want to join the group, not group admin
 *
 * This is helper function and is not intented for export or use in JSONS. See dedicated functions to
 * accept and reject below.
 * */
const apiChatgrouprequestSetState = (props) => {
  const {
    chatgrouprequestId: chatgrouprequestIdStr,
    state,
    onErrorVmEventList,
    onSuccessVmEventList
  } = coerceToObj(props);
  const chatgrouprequestId = parseInt(chatgrouprequestIdStr, 10);

  const body = {
    access_token: getAccessToken(),
    state
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(
      STORE_MASK_CHATGROUPREQUESTS_SET_STATE,
      chatgrouprequestId
    ),
    pathname: getPathname(chatgrouprequestId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return res; // Do not perform data updates on debounced API calls

    const chatgroupId = res.data.chatgroup.id;

    // clear channelMessages (notifications):
    clearGroupRequests(chatgroupId);

    // After successful update of chatgrouprequest, we save it in  the group requests array:
    const chatgrouprequestsOld = getChatgrouprequestsByChatgroupId(chatgroupId);
    const chatgrouprequestsNew = chatgrouprequestsOld.map((el) => (el.id === chatgrouprequestId ? { ...el, state } : el));
    dispatch(
      setMyNetworking(
        appendId(CHATGROUPREQUESTS_BY_CHATGROUP_SELECTOR_KEY, chatgroupId),
        chatgrouprequestsNew
      )
    );

    return res;
  });
};

export const apiAcceptChatgrouprequest = (props) => apiChatgrouprequestSetState({
  state: CHATGROUPREQUESTS_STATE_ACCEPTED,
  ...props
}).then((res) => {
  if (res.isDebounced) return; // Do not perform data updates on debounced API calls

  // When a request gets accepted, following arrays need an update:
  //   * getChatgrouprequestsByChatgroupId - this is alredy done by apiChatgrouprequestSetStatus
  //   * chatgroupById - we need to update members by new member and members count
  //   * getChatgroups - update members array and members count so we get correct number on myNetworkin carousels

  // Updare state of the chatgroup:
  const fromUser = res.data.from_user;
  const chatgroupId = res.data.chatgroup.id;
  // group object from redux:
  const chatgroupOld = getChatgroupById(chatgroupId);
  const activeChatgroupUsersNew = [
    ...chatgroupOld.active_chatgroup_users,
    { user: fromUser } // the users in the requests array are nesed in the object {is: 123, user: {}}
  ];

  const chatgroupNew = {
    ...chatgroupOld,
    active_chatgroup_users: activeChatgroupUsersNew,
    active_chatgroup_users_count: activeChatgroupUsersNew.length
  };
    // update state in redux:
  const selectorKey = appendId(CHATGROUPS_SELECTOR_KEY_BY_ID, chatgroupId);
  dispatch(setMyNetworking(selectorKey, chatgroupNew));

  // Update chatgroups array of the user (makes sens only whn user is an admin):
  const chatgroups = getChatgroups();
  const chatgroupsNew = chatgroups.map((el) => (el.id === chatgroupId ? chatgroupNew : el));

  dispatch(setMyNetworking(CHATGROUPS_SELECTOR_KEY, chatgroupsNew));
});

export const apiRejectChatgrouprequest = (props) => apiChatgrouprequestSetState({
  state: CHATGROUPREQUESTS_STATE_REJECTED,
  ...props
});
