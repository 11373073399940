import { getArrayVmTypeOf, vmTypes } from '../../../global-prop-types/vmTypes';
import { isArr } from '../../globalUtils';

export const vmUrlSearchParamsDelSet = (delList, setList) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (isArr(delList)) delList.forEach((el) => urlSearchParams.delete(el));
  if (isArr(setList)) setList.forEach(({ key, value }) => urlSearchParams.set(key, value));

  return urlSearchParams.toString();
};

vmUrlSearchParamsDelSet.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    {
      uiTitle: 'Array of keys to delete from url search parmas',
      vmType: getArrayVmTypeOf(vmTypes.string)
    },
    {
      uiTitle: 'Array of objects with {key, value}',
      vmType: getArrayVmTypeOf({
        key: vmTypes.string,
        value: vmTypes.string
      })
    }
  ]
};

export const vmUrlSearchParamsDelete = (keyList) => {
  const url = new URL(window.location);
  const urlSearchParams = new URLSearchParams(url.search);
  if (isArr(keyList)) {
    keyList.forEach((urlSearchParamKey) => urlSearchParams.delete(urlSearchParamKey));
  }

  return urlSearchParams.toString();
};

vmUrlSearchParamsDelete.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [{ uiTitle: 'Key list', vmType: { type: vmTypes.array } }]
};

export const vmUrlSearchParamsGet = (key) => new URLSearchParams(window.location.search).get(key);
vmUrlSearchParamsGet.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'url search param key', vmType: { type: vmTypes.string } }
  ]
};

// Used to pass on the search params from one page to another via links
export const vmUrlSearchParamsGetAll = () => new URLSearchParams(window.location.search).toString();
vmUrlSearchParamsGetAll.vmFunctionMetaData = {
  returnVmType: vmTypes.string
};
