import React from 'react';
import PropTypes from 'prop-types';
import {
  iconPropType,
  stylesPropType,
  vmPropTypes
} from '../../global-prop-types';
import SvgComp from './SvgComp';

const IconComp = (props) => {
  /*
    This component can recieve 3 types of icons:
    1. Material UI icon - (we have to send type + name) if there is two names in the icon like AddCircle we should send it as add_circle
    2. FontAwesome icon - (we have to send type + name) all solid icons (fas fa-....)
    3. Link - (we don't need to send name + type)

    We should send the icon object like this:
    icon: {
      name: name of the icon from the library (mui/fontaswome)
      type: 'fa'/'mui
      link: {
        active: "https://d2t0unnjxes6lt.cloudfront.net/data/ntw/general-vm/icons/home-active.svg",
        inactive: "https://d2t0unnjxes6lt.cloudfront.net/data/ntw/general-vm/icons/home-inactive.svg"
      }
    }
  */
  const {
    icon: gotIcon,
    faType,
    active,
    styles,
    className = '',
    variant
  } = props;
  let icon = gotIcon;
  if (typeof gotIcon === 'string') {
    try {
      icon = JSON.parse(gotIcon);
    } catch (e) {
      // TODO: Clean this up once we made sure we ALWAYS send an icon as an object
      icon = {
        iconType: 'mui',
        name: gotIcon
      };
    }
  }
  if (!icon) return null;

  const { name, iconType, link } = icon;

  if (!iconType && !link) return null;

  if (link) {
    // external link
    const activeLinkExist = !!link?.active;
    const inactiveLinkExist = !!link?.inactive;

    if (activeLinkExist || inactiveLinkExist) {
      const extension = activeLinkExist
        ? link?.active?.split('.').pop()
        : link?.inactive?.split('.').pop();

      if (extension === 'svg') {
        const iconRenderer = () => {
          if (active && activeLinkExist) {
            return link.active;
          }
          return link.inactive || link.active;
        };

        return (
          <SvgComp
            styles={{ wrapper: styles?.wrapper }}
            variant={`${className || ''} ${variant || ''}`}
            icon={iconRenderer()}
          />
        );
      }
      // No svg extension
      return (
        <img
          src={link?.active || link?.inactive}
          className={`iconComp-imgWrapper ${className || ''} ${variant || ''}`}
          alt="icon"
        />
      );
    }
  }
  if (!name || !iconType) return null;
  // No external link (mui/fontfa icon)

  return (
    <>
      {iconType === 'fa' ? (
        <div
          className={`iconComp-faWrapper ${className || ''} ${variant || ''}`}
          style={styles?.wrapper}
        >
          <i className={`${faType} fa-${name}`} style={styles?.awesome} />
        </div>
      ) : (
        <span
          className={`iconComp-muiWrapper material-icons${icon?.isOutlined ? '-outlined' : ''} ${className || ''} ${variant || ''}`}
          style={styles?.wrapper}
        >
          {name
            ?.split(/(?=[A-Z])/)
            .join('_')
            .toLowerCase()}
        </span>
      )}
    </>
  );
};

IconComp.propTypes = {
  variant: PropTypes.string,
  icon: iconPropType,
  faType: PropTypes.oneOf(['fa', 'fas', 'fab', 'far']),
  className: PropTypes.string,
  active: PropTypes.bool,
  styles: stylesPropType,
};

IconComp.defaultProps = {
  variant: '',
  icon: {
    iconType: 'fa',
    isOutlined: false, // isOutlined is only for iconType mui
    name: 'circle-notch',
    iconColor: '#000000',
    iconSize: ''
  },
  faType: 'fas',
  styles: {
    wrapper: {},
    awesome: {},
  },
  className: ''
};

IconComp.vmPropTypes = {
  variant: vmPropTypes.className,
  active: vmPropTypes.boolean,
  icon: vmPropTypes.icon,
  faType: vmPropTypes.string,
  styles: {
    wrapper: vmPropTypes.styles,
    awesome: vmPropTypes.styles,
  },
  className: vmPropTypes.string
};

export default IconComp;
