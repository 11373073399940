import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { isArr } from '../../global-utils/globalUtils';
import { componentsPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from './ComponentsArrRenderer';

const DynamicDataLooper = (props) => {
  const {
    data, parent, emptyDataComponents, sortBy, ...restProps
  } = props;
  if (!isArr(data)) return null;

  const shouldSort = () => {
    if (!sortBy) return data;
    return [...data].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      }
      if (a[sortBy] > b[sortBy]) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <>
      {data?.length > 0 ? (
        shouldSort().map((dataEntry, idx) => (
          <ComponentsArrRenderer
            {...restProps}
            parent={[...parent, idx]}
            key={dataEntry?.id || uuid()}
            componentItemData={dataEntry}
            componentItemDataIdx={idx}
            componentItemDataLength={data.length}
          />
        ))
      ) : (
        <ComponentsArrRenderer
          // Order of props here matters!
          {...restProps}
          components={emptyDataComponents}
          parent={[...parent]}
        />
      )}
    </>
  );
};

DynamicDataLooper.propTypes = {
  parent: PropTypes.array,
  data: PropTypes.array,
  components: componentsPropType,
  sortBy: PropTypes.string,
  emptyDataComponents: componentsPropType
};

DynamicDataLooper.defaultProps = {
  data: [],
  components: [],
  emptyDataComponents: [],
  sortBy: ''
};

DynamicDataLooper.vmPropTypes = {
  data: vmPropTypes.array,
  components: vmPropTypes.components,
  emptyDataComponents: vmPropTypes.components,
  sortBy: vmPropTypes.string
};

DynamicDataLooper.processPropVmTypes = (props) => {
  const {
    data, parent, emptyDataComponents, sortBy, ...restProps
  } = props;

  return ComponentsArrRenderer.processPropVmTypes({
    ...restProps,
    componentItemData: data?.arrayItemVmType // It's OK if it is undefined
  });
};

export default DynamicDataLooper;
