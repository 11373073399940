const styleJmeBreadcrumbs = () => `
.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
}
.jmeBreadcrumbs-items-container {
    padding-left:0;
    background:transparent;
}
`;

export default styleJmeBreadcrumbs;
