import { vmTypes } from '../../../global-prop-types';

export const addIdToPageHref = (state, apiResEntry = {}) => {
  const { mediable_type: mediableType } = apiResEntry;
  let href;
  const allPages = state.store.appState.settings.pages ?? [];
  allPages.find((page, pageIdx) => {
    page?.apiDataInfo?.forEach((apiInfo) => {
      if (
        apiInfo?.modelName
          ?.toLowerCase()
          ?.indexOf(
            mediableType?.toLowerCase() === 'resourcegroup'
              ? 'resource'
              : mediableType.toLowerCase()
          ) === 0
        && apiInfo.apiEndpointName === 'single'
      ) {
        href = `${allPages?.[pageIdx]?.route?.path.split(':')?.[0]}${
          mediableType?.toLowerCase() === 'resourcegroup'
            ? apiResEntry?.body?.resources?.find((el) => el?.type === 'Content')
              ?.slug
            : apiResEntry?.body?.id
        }`;
      }
    });
    return href;
  });
  return href;
};

const extractType = (apiResEntry) => {
  let type;
  const { mediable_type: mediableType } = apiResEntry;
  if (mediableType.toLowerCase() === 'resourcegroup') {
    type = 'Resource';
  } else {
    type = mediableType;
  }
  return type;
};

const extractTitle = (apiResEntry) => {
  let title;
  const { mediable_type: mediableType } = apiResEntry;
  if (mediableType.toLowerCase() === 'resourcegroup') {
    title = apiResEntry?.body?.resources?.find(
      (el) => el?.type === 'Content'
    )?.title;
  } else if (apiResEntry?.body?.title) {
    title = apiResEntry.body.title;
  } else if (apiResEntry?.title) {
    title = apiResEntry.title;
  }
  return title;
};

/**
 * @description select specific path from the API response. If this point do not parse
 * well, return empth array
 * @param {Object} res API response object
 * @returns modified API respnse
 */
// eslint-disable-next-line import/prefer-default-export
export const postProcessApiResponse = (dataObj) => () => (res) => ({
  ...res,
  data: res?.data?.map((el) => ({
    ...el,
    vmHref: addIdToPageHref(dataObj, el),
    vmTitle: extractTitle(el),
    vmFavType: extractType(el)
  }))
});

postProcessApiResponse.vmFunctionMetaData = {
  usesDataObj: true,
  argsInfo: [{ uiTitle: 'Res', vmType: { type: vmTypes.array } }],
  returnVmType: vmTypes.any
};
