import { componentStylesToCss } from '../componentStylesUtils';

const stylesPageTitle = (componentStyles) => `
  .pageTitle-wrapper {
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
`;

export default stylesPageTitle;
