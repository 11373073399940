import React from 'react';
import PropTypes from 'prop-types';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../../global-prop-types';
import ComponentsArrRenderer from '../../core/ComponentsArrRenderer';

const GridColumn = (props) => {
  const {
    sizes,
    parent,
    variant,
    styles,
    ...restProps
  } = props;

  const sizesMapper = () => (
    (sizes && Object.keys(sizes).length)
      ? Object.keys(sizes)
        // ?.map((el) => (sizes[el] ? `col-${el === 'xs' ? '' : el}-${sizes[el]}` : ''))
        // eslint-disable-next-line prefer-template
        ?.map((el) => (sizes[el] ? ('col-' + (el === 'xs' ? '' : (el + '-')) + sizes[el]) : ''))
        ?.join(' ')
      : ''
  );

  return (
    <div
      className={`col ${sizesMapper()} ${variant || ''}`}
      style={styles?.wrapper}
    >
      <ComponentsArrRenderer
        {...restProps}
        parent={[...parent, 'GridColumn']}
      />
    </div>
  );
};

GridColumn.propTypes = {
  sizes: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
  }),
  parent: PropTypes.array,
  variant: PropTypes.string,
  styles: stylesPropType,
  components: componentsPropType,
};

GridColumn.vmPropTypes = {
  sizes: vmPropTypes.columnSizesPerBreakpoint,
  variant: vmPropTypes.className,
  styles: { wrapper: vmPropTypes.styles },
  components: vmPropTypes.components,
};

GridColumn.defaultProps = {
  variant: '',
  sizes: {},
  styles: {
    wrapper: {}
  },
  components: [
    {
      componentName: 'JmeBox',
      components: []
    }
  ],
};

GridColumn.vmPreview = {
  styles: { wrapper: { border: '2px solid black' } },
  components: [
    {
      componentName: 'JmeText',
      text: 'Row Grid display'
    }
  ]
};

export default GridColumn;
