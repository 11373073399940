import React from 'react';
import ComponentsArrRenderer from './ComponentsArrRenderer';
import useShallowEqualSelector from './useShallowEqualSelector';

const OfflinePageRenderer = (props) => {
  const { offlineUsage } = useShallowEqualSelector(
    (state) => state.appState.settings
  );
  if (
    (offlineUsage
      && offlineUsage.isActive
      && offlineUsage.pageComponents?.length)
  ) {
    // if exists, active and has componentsArray
    return (
      <ComponentsArrRenderer
        {...props}
        parent={['PAGE']}
        components={offlineUsage.pageComponents}
      />
    );
  }
  return 'You’re offline. Sie sind offline.';
};
export default OfflinePageRenderer;
