import { useSelector } from 'react-redux';

export const selectModule = (moduleName) => (state) => state.appState.settings?.modules?.[moduleName];
export const selectSettings = (moduleName) => (state) => selectModule(moduleName)(state)?.settings;
export const selectIsActive = (moduleName) => (state) => selectModule(moduleName)(state)?.isActive;

export const useSettingsByName = (moduleName) => useSelector(selectSettings(moduleName));
export const useIsActiveByName = (moduleName) => useSelector(selectIsActive(moduleName));

export {
  // Depricated - please use useSettingsByName/useIsActiveByName instead
  useSettingsByName as useSettings,
  useIsActiveByName as useIsActive,
};
