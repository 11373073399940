import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import {
  stylesPropType,
  componentsPropType,
  vmPropTypes
} from '../../global-prop-types';
import vmFunctions from '../../global-utils/vmFunctions';
import { replacePlaceholders } from '../../global-utils/map-data-to-props';
import { useDispatchVmEventList } from '../../global-utils';
import useShallowEqualSelector from '../core/useShallowEqualSelector';

const JmeButton = (props) => {
  const {
    styles,
    handlerList,
    onClick, // DEPRICATED, stayes here becaues of KOK
    jmeOnClick, // DEPRICATED, use vmOnClick
    vmOnClick,
    variant,
    onHoldVariant,
    color,
    ...restProps
  } = props;

  const [isClicked, setIsClicked] = useState(false);
  const dispatch = useDispatch();
  const dispatchVmEventList = useDispatchVmEventList();
  const store = useShallowEqualSelector((state) => state);
  const dataBank = { vmFunctions, props, store };

  const handleClick = () => {
    dispatchVmEventList(vmOnClick, dataBank);
    // This is old system to manage on clicks. Used in filters on courses.
    // Maybe the concept of handlerList should be replaced by a vmFunction
    // with an example name setUrlSearchParams ???
    if (onClick && handlerList?.[onClick]) {
      handlerList[onClick]();
      setIsClicked((x) => !x);
    }
    jmeOnClick.forEach((el) => {
      dispatch(
        vmFunctions[el.vmFunctionsKey]?.(
          ...el.args.map((el2) => replacePlaceholders(el2, { props, vmFunctions }))
        )
      );
    });
  };

  return (
    <button
      type="button"
      className={`jme-button-wrapper btn ${
        (isClicked ? onHoldVariant : variant) || ''
      }`}
      style={styles?.wrapper}
      onClick={handleClick}
    >
      <ComponentsArrRenderer {...restProps} />
    </button>
  );
};

JmeButton.propTypes = {
  styles: stylesPropType,
  onClick: PropTypes.string,
  jmeOnClick: PropTypes.array,
  components: componentsPropType,
  handlerList: PropTypes.shape({}),
  variant: PropTypes.string,
  onHoldVariant: PropTypes.string,
  color: PropTypes.string,
  vmOnClick: PropTypes.array
};

JmeButton.defaultProps = {
  variant: 'jme-button-primary',
  styles: { wrapper: {} },
  onHoldVariant: 'jme-button-white',
  components: [
    {
      componentName: 'JmeText',
      text: 'Button'
    }
  ],
  jmeOnClick: []
};

JmeButton.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  components: vmPropTypes.components,
  variant: vmPropTypes.className,
  onHoldvariant: vmPropTypes.className,
  jmeOnClick: vmPropTypes.eventsArray,
  vmOnClick: vmPropTypes.array
};

export default JmeButton;
