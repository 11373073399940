import { componentStylesToCss } from '../componentStylesUtils';

const stylesStickyBottomIcons = (componentStyles) => `
  .sticky-bottom-icons-wrapper {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .sticky-bottom-icons-nav {
    display: flex;
    justify-content: space-evenly;
    background-color: #fff;
    -webkit-box-pack: center;
  }
  .sticky-bottom-icons-navbarItems {
    min-width: fit-content;
    padding: 0;
    padding-top: 0;
    ${componentStylesToCss(componentStyles?.navbarItems)}
  }
  .sticky-bottom-icons-selectedItem {
    color: rgba(0, 0, 0, 0.6);
    ${componentStylesToCss(componentStyles?.selectedItem)}
  }
  .sticky-bottom-icons-actionItemStyles {
    min-width: fit-content !important;
    padding: 0 !important;
    ${componentStylesToCss(componentStyles?.actionItemStyles)}
  }
  .sticky-bottom-icons-actionItemStyles:selected {
    ${componentStylesToCss(componentStyles?.actionItemStylesSelected)}
  }
`;

export default stylesStickyBottomIcons;
