import { isFunc } from '../../globalUtils';

let navigateFn = () => null;

// Needs to be called by AppWrapper to enable navigate function in JSONs:
export const setVmFunctionNavigate = (func) => { if (isFunc(func)) { navigateFn = func; } };

// function to use in json:
export const navigate = (to) => {
  navigateFn(to);
};
