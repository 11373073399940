let tickets = [];
let accessMatrix = null;

export const setTickets = (t) => {
  tickets = t;
};
export const getTickets = () => tickets;

export const getTicket = () => tickets[0];

export const setAccessMatrix = (ac) => {
  accessMatrix = ac;
};
export const getAccessMatrix = () => accessMatrix;
