const fetchVersion = async () => {
  try {
    const response = await fetch(`/static/version.json?_=${new Date().getTime()}`);
    const contentType = response.headers.get('content-type');

    if (response.ok && contentType.includes('application/json')) {
      const { Version } = await response.json();
      return Version || null;
    }
    return null;
  } catch (error) {
    console.error('Error fetching /static/version.json:', error);
    return null;
  }
};
const chunkLoadErrorHandler = async () => {
  const version = await fetchVersion();
  localStorage.setItem('appVersion', version);
  if (!version) {
    console.warn('No /static/version.json was found');
  }

  window.addEventListener('error', async (event) => {
    const savedVersion = localStorage.getItem('appVersion');
    if (event.message && event.message.includes('Loading chunk') && event.message.includes('Unexpected token \'<\'') && savedVersion) {
      const newVersion = await fetchVersion();
      if (newVersion !== savedVersion) {
        localStorage.setItem('appVersion', version);
        window.location.reload();
      }
    }
  });
};

export default chunkLoadErrorHandler;
