import store from '../../../global-state/redux';
import {
  MY_NETWORKING_SELECTOR_STATUS,
  MY_NETWORKING_STATUS_ERROR,
  MY_NETWORKING_STATUS_LOADING,
  MY_NETWORKING_STATUS_READY
} from './myNetworkingConstants';
import { isCommunicable } from './settings';

const { getState } = store;

export const isMyNetworkingInUse = () => isCommunicable();

// All networking data that needs to by dynamically adjusted or modified are kept
// in appState in myNetworking object. Please keep this object one level deep,
// so it is easy to retrive information in data maps.
export const getMyNetworking = () => getState()?.appState?.myNetworking ?? {};
export const getMyNetworkingByKey = (key) => getState().appState.myNetworking?.[key];

// Status getters:
export const getStatus = () => getMyNetworkingByKey(MY_NETWORKING_SELECTOR_STATUS) ?? {};

export const isStatusInitReady = () => getStatus().init === MY_NETWORKING_STATUS_READY;
export const isStatusInitLoading = () => getStatus().init === MY_NETWORKING_STATUS_LOADING;
export const isStatusInitError = () => getStatus().init === MY_NETWORKING_STATUS_ERROR;

// Redux selectors:
export const selectMyNetworking = (state) => state.appState.myNetworking;
