import React from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import PageRenderer from '../components/core/PageRenderer';
import { routPropType, componentsPropType } from '../global-prop-types';

const GuidLinePage = (props) => (
  <PageRenderer
    urlParams={useParams()}
    {...props}
  />
);

GuidLinePage.propTypes = {
  title: PropTypes.string,
  route: routPropType,
  components: componentsPropType,
};

// Default props ARE the default when building a page
GuidLinePage.defaultProps = {
  title: 'Guidline Page',
  components: [
    {
      componentName: 'JmeText',
      elemType: 'h1',
      title: 'Guidline Page Title'
    }
  ],
  // Rout will be extracted to the settings
  route: {
    path: '/guidlines/:id',
    exact: true
  },
};

export default GuidLinePage;
