import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useShallowEqualSelector from '../../core/useShallowEqualSelector';
import {
  stylesPropType,
  componentsPropType,
  vmPropTypes
} from '../../../global-prop-types';
import ComponentsArrRenderer from '../../core/ComponentsArrRenderer';

const JmeGrid = (props) => {
  const {
    styles,
    data,
    components,
    reverseWhen,
    ifIterateData,
    parent,
    variant,
    variantAtPreview,
    ...restProps
  } = props;

  const { gridWithSidePreview, currentBreakpoint } = useShallowEqualSelector(
    (state) => state.appState
  );
  const { isActive: isPreviewActive } = gridWithSidePreview;

  const [reversedRootGrid, setReversedRootGrid] = useState(false);

  useEffect(() => {
    if (reverseWhen?.length > 0) {
      if (reverseWhen.includes(currentBreakpoint)) {
        setReversedRootGrid(true);
      } else {
        setReversedRootGrid(false);
      }
    }
  }, [currentBreakpoint]);

  return (
    <div
      className={`${
        (isPreviewActive ? variantAtPreview || variant : variant) || 'col'
      }`}
      style={{
        flexDirection: reversedRootGrid ? 'column-reverse' : undefined,
        ...styles?.wrapper
      }}
    >
      {ifIterateData ? (
        data?.map((dataItem, idx) => (
          <ComponentsArrRenderer
            key={dataItem.id}
            components={components}
            componentItemData={dataItem}
            parent={[...parent, 'JmeGrid', idx]}
          />
        ))
      ) : (
        <ComponentsArrRenderer
          components={components}
          {...restProps}
          data={data} // in this case the data is not used byt this component; pass it on
          parent={[...parent, 'JmeGrid', 'components']}
        />
      )}
    </div>
  );
};

JmeGrid.propTypes = {
  parent: PropTypes.array,
  styles: stylesPropType,
  components: componentsPropType,
  data: PropTypes.array,
  reverseWhen: PropTypes.array,
  ifIterateData: PropTypes.bool,
  variant: PropTypes.string,
  variantAtPreview: PropTypes.string
};

JmeGrid.defaultProps = {
  variant: '',
  styles: { wrapper: {} },
  components: [],
  reverseWhen: []
};

JmeGrid.vmPreview = {
  styles: { wrapper: { border: '2px solid black' } },
  components: [
    {
      componentName: 'JmeText',
      text: 'Grid disaply column wise, if addition data passed in the grid will map and display the data'
    }
  ]
};

JmeGrid.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  components: vmPropTypes.components,
  reverseWhen: vmPropTypes.array,
  variant: vmPropTypes.className,
  variantAtPreview: vmPropTypes.className
};

export default JmeGrid;
