import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSettings } from '../model/hooks';
import { setPreviousLocation } from '../model/state';

/* When I used navigate programatically in the if condition in the PageRenderer (simplified code):
```
if (true) {
  navigate('/holding);
}
```
we get warning:
  utils.ts:900 You should call navigate() in a React.useEffect(), not when your component is first rendered.

This is why we have a component that PageRenderer renders and which in turn redirects to holding page.
*/

const RedirectToHolding = (props) => {
  const { previousLocation } = props;
  const { holdingPageUrl } = useSettings() ?? {};
  const navigate = useNavigate();

  useEffect(() => {
    setPreviousLocation(previousLocation);
    navigate(holdingPageUrl);
  }, []);

  return null;
};

RedirectToHolding.propTypes = {
  previousLocation: PropTypes.string
};

export default RedirectToHolding;
