import { componentStylesToCss } from '../componentStylesUtils';

const stylesLoading = (componentStyles, theme) => `
  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .loading-icon {
    color: ${theme.colors.primary.main}
  }
`;

export default stylesLoading;
