import PropTypes from 'prop-types';
import {
  stylesPropType,
  componentsPropType,
  vmPropTypes
} from '../../../global-prop-types';
import ComponentsArrRenderer from '../../core/ComponentsArrRenderer';

const JmeCard = (props) => {
  const { styles, variant, ...restProps } = props;
  return (
    <div
      className={`card jme-card-wrapper ${variant || ''}`}
      style={styles?.wrapper}
    >
      <ComponentsArrRenderer {...restProps} />
    </div>
  );
};

JmeCard.propTypes = {
  styles: stylesPropType,
  variant: PropTypes.string,
  components: componentsPropType
};

JmeCard.defaultProps = {
  variant: '',
  styles: { wrapper: {} },
  components: []
};

JmeCard.vmPreview = {
  components: [
    {
      componentName: 'JmeCardMedia'
    },
    {
      componentName: 'JmeCardContent',
      components: [
        {
          componentName: 'JmeText',
          text: 'Card Content'
        }
      ]
    }
  ]
};

JmeCard.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  variant: vmPropTypes.className,
  components: vmPropTypes.components
};

export default JmeCard;
