import dayjs from 'dayjs';
import {
  getDenyAllowPoliciesForResource,
  handleHcp,
  handleIncludes,
  handleLoggedIn,
  handleTicketCondition
} from './utils';

function formatTicketDate(date) {
  return dayjs(date);
}

const evalPolicy = (policy, params) => {
  const { user, resourceId, effect } = params;
  let result = false;
  const evaluatedPermissionsArray = [];
  const accessDenyReasons = [];

  // eslint-disable-next-line no-constant-condition
  if (false) {
    // TODO: if (store.getters['instancesState/getSuperUser'])
    // result = true;
  } else {
    policy.Requirements.forEach((r) => {
      const context = {
        requirement: r,
        accessDenyReasons,
        evaluatedPermissionsArray
      };
      switch (r.PrincipalField) {
        case 'SocietyMemberType':
          handleIncludes({
            userField: user.societyMemberType,
            ...context
          });
          break;
        case 'EventParticipantType':
          handleIncludes({
            userField: user.participantType,
            ...context
          });
          break;
        case 'ParticipantTypeCheck':
          handleIncludes({
            userField: user.participantTypeValid,
            ...context
          });
          break;
        case 'UserCountry':
          handleIncludes({
            userField: user.country,
            ...context
          });
          break;
          // case 'TimeAccess':
          //   dayjs.tz.setDefault(timezone);
          //   serverTime = store.getters['serverTime/getTime'];
          //   if (serverTime) {
          //     serverTime = dayjs.tz(dayjs(serverTime), timezone);
          //   } else {
          //     serverTime = dayjs().tz();
          //   }
          //   if (effect === 'Allow') {
          //     if (!dayjs.tz(r.Values[0], timezone).isAfter(serverTime)) {
          //       accessDenyReasons.push(r.PrincipalField);
          //     }
          //   }
          //   if (effect === 'Deny') {
          //     if (dayjs.tz(r.Values[0], timezone).isAfter(serverTime)) {
          //       accessDenyReasons.push(r.PrincipalField);
          //     }
          //   }
          //
          //   arr.push(dayjs.tz(r.Values[0], timezone).isAfter(serverTime));
          //
          //   break;

        // case 'ExhibitorExclusive':
        //   const symposiaList = store.getters['ILPSymposiaList/list'];
        //   const currentSession = symposiaList.find(
        //     (item) => item.Id === Number(checkId)
        //   );
        //   const { CompanyAccountId } = currentSession;
        //   const { tickets } = user;
        //   const isInvitedByCurrentExhibitor = tickets.some(
        //     (item) => item.CompanyAccountId === CompanyAccountId
        //   );
        //   if (!isInvitedByCurrentExhibitor) {
        //     accessDenyReasons.push(r.PrincipalField);
        //   }
        //   arr.push(isInvitedByCurrentExhibitor);
        //
        //   break;
        case 'EventTicketPaid':
          handleTicketCondition({
            userTickets: user.tickets,
            conditionFn: (ticket) => ticket.IsPayed,
            ...context
          });
          break;
        case 'Hcp':
        case 'UserHcp':
          handleHcp(user, r, accessDenyReasons, evaluatedPermissionsArray);
          break;
        case 'UserLoggedIn':
          handleLoggedIn(user, r, accessDenyReasons, evaluatedPermissionsArray);
          break;
        case 'EventTicketNameValid':
          handleTicketCondition({
            userTickets: user.tickets.filter((ticket) => ticket.IsValid),
            conditionFn: (ticket) => r.Values.includes(ticket.TicketTypeName),
            ...context
          });
          break;
        case 'EventTicketNamePaid':
          handleTicketCondition({
            userTickets: user.tickets.filter((ticket) => ticket.IsPayed),
            conditionFn: (ticket) => r.Values.includes(ticket.TicketTypeName),
            ...context
          });
          break;
        case 'EventTicketNameForComments':
          handleTicketCondition({
            userTickets: user.tickets.filter((ticket) => ticket.IsPayed),
            conditionFn: (ticket) => r.Values.includes(ticket.TicketTypeName),
            ...context
          });
          break;
        case 'EventTicketNameForMessages':
          handleTicketCondition({
            userTickets: user.tickets.filter((ticket) => ticket.IsPayed),
            conditionFn: (ticket) => r.Values.includes(ticket.TicketTypeName),
            ...context
          });
          break;
        case 'EventTicketNetworking':
          handleTicketCondition({
            userTickets: user.tickets.filter((ticket) => ticket.IsPayed),
            conditionFn: (ticket) => r.Values.includes(ticket.TicketTypeName),
            ...context
          });
          break;
        case 'EventTicketNamePaidAndTime':
          handleTicketCondition({
            userTickets: user.tickets.filter(
              (ticket) => ticket.IsPayed && ticket.TicketFrom && ticket.TicketTo
            ),
            conditionFn: (ticket) => {
              const dateNow = dayjs();
              const ticketFrom = formatTicketDate(ticket.TicketFrom);
              const ticketTo = formatTicketDate(ticket.TicketTo);
              return (
                r.Values.includes(ticket.TicketTypeName)
                && dateNow.diff(ticketFrom) >= 0
                && dateNow.diff(ticketTo) <= 0
              );
            },
            ...context
          });
          break;
        case 'VisitorIdIncluded':
          handleTicketCondition({
            userTickets: user.tickets.filter((ticket) => ticket.VisitorId),
            conditionFn: (ticket) => r.Values.includes(ticket.VisitorId),
            ...context
          });
          break;
        default:
          console.warn(`Unhandled PrincipalField: ${r.PrincipalField}`);
          break;
      }
    });
    // console.log(
    //   'evaluatedPermissionsArray for',
    //   policy.Name,
    //   policy.Effect,
    //   policy.ConditionType,
    //   policy.Requirements.map((r) => r.PrincipalField),
    //   evaluatedPermissionsArray
    // );
    switch (policy.ConditionType) {
      case 'All':
        if (!evaluatedPermissionsArray.includes(false)) {
          result = true;
        }
        break;
      case 'Any':
        if (evaluatedPermissionsArray.includes(true)) {
          result = true;
        }
        break;
      default:
        throw new Error(
          `Unknown policy condition type: ${policy.ConditionType}`
        );
    }
  }
  // accessDenyReasonsObj[policy.Name] = accessDenyReasons;
  // localStorage.setItem(
  //   'accessDenyReasons',
  //   JSON.stringify(accessDenyReasonsObj)
  // );
  return result;
};

export const accessControl = (resourceType) => (resourceId, accessMatrix, user) => {
  const renamedMatrix = {
    resources: accessMatrix.policies,
    policies: accessMatrix.policies_groups
  };

  const { allow: allowPolicies, deny: denyPolicies } = getDenyAllowPoliciesForResource(resourceType, resourceId, renamedMatrix);
  if (denyPolicies.length === 0 && allowPolicies.length === 0) {
    return { result: true, cause: 'No rules assigned' };
  }

  if (denyPolicies && denyPolicies.length > 0) {
    // TODO: check if for is really needed
    for (let i = 0, len = denyPolicies.length; i < len; i += 1) {
      if (
        evalPolicy(denyPolicies[i], {
          resourceId,
          user,
          effect: denyPolicies[i].Effect
        })
      ) {
        return {
          result: false,
          cause: denyPolicies[i].Name
        };
      }
    }
  }

  if (denyPolicies.length > 0 && allowPolicies.length === 0) {
    return { result: true, cause: 'No allow rules assigned' };
  }

  if (allowPolicies && allowPolicies.length > 0) {
    // TODO: check if for is really needed
    for (let i = 0, len = allowPolicies.length; i < len; i += 1) {
      if (
        evalPolicy(allowPolicies[i], {
          user,
          resourceId,
          effec: allowPolicies[i].Effect
        })
      ) {
        return { result: true, cause: allowPolicies[i].Name };
      }
    }
  }

  // denyPolicies.forEach((policy) => {
  //   console.log(`policy: ${policy.Name}`, `, version: ${policy.Version}`);
  // });
  // allowPolicies.forEach((policy) => {
  //   console.log(`policy: ${policy.Name}`, `, version: ${policy.Version}`);
  // });
  // console.log('Access Control: denied');
  return { result: false, cause: 'General denied' };
};

export const accessControlSession = accessControl('Session');

export const accessControlPage = accessControl('Page');

export const accessControlUrl = accessControl('Url');

export const accessControlKey = accessControl('Key');
