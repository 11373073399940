import PropTypes from 'prop-types';
import { stylesPropType, vmPropTypes } from '../../global-prop-types';

const Video = (props) => {
  const {
    src, autoPlay, muted, styles
  } = props;
  if (!src) return null;
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video src={src} style={styles?.wrapper} width="100%" height="100%" controlsList="nodownload" controls autoPlay={autoPlay} muted={muted} />
  );
};

Video.propTypes = {
  styles: stylesPropType,
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
  src: PropTypes.string
};

Video.defaultProps = {
  styles: {
    wrapper: { }
  },
  src: '',
  autoPlay: false,
  muted: false
};

Video.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  src: vmPropTypes.string,
  autoPlay: vmPropTypes.boolean,
  muted: vmPropTypes.boolean
};

export default Video;
