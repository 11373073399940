import {
  getMyNetworkingByKey,
  selectMyNetworking
} from '../../myNetworkingSelectors';
import { NOTIFICATIONS } from '../constants';
import store from '../../../../../global-state/redux';

const { getState } = store;

// Selectors:
export const selectUnopened = (state) => selectMyNetworking(state)?.[NOTIFICATIONS]?.filter((el) => !el.opened) ?? [];
export const selectUnopenedCount = (state) => selectUnopened(state).length;

// Getters:
export const getNotifications = () => getMyNetworkingByKey(NOTIFICATIONS);
export const getUnopened = () => selectUnopened(getState());
