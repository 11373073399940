import { coerceToObj } from '../myNetworkingUtils';
import { getEventId } from '../../core';
import { myNetworkingApiCall } from '../myNetworkingApiUtils';
import { getAccessToken, getUserId } from '../../loginInfo';

const STORE_MASK_SETTINGS_FILESUPLOADS_SET = 'myNetworking/settings/filesuploads/set';

const getPathname = (str) => `/v1/filesuploads/${str}.json`;

const extractFileExtension = (filename) => {
  const regExp = /^.+\.([^.]+)$/;
  return regExp.exec(filename)?.[1] ?? '';
};

// eslint-disable-next-line import/prefer-default-export
export const apiSetFilesuploads = (props) => {
  const { skipDebounce, path, fileName } = coerceToObj(props);

  if (!path) {
    return Promise.reject(
      new Error('Missing path to filesuploads', { cause: path })
    );
  }

  const body = {
    access_token: getAccessToken(),
    user_id: getUserId(),
    event_id: getEventId(),
    title: fileName ?? new Date(),
    file_type: extractFileExtension(path),
    path
  };

  return myNetworkingApiCall({
    method: 'POST',
    storeMask: STORE_MASK_SETTINGS_FILESUPLOADS_SET,
    pathname: getPathname('record_only'),
    body,
    skipDebounce
  });
};
