import store from '../../../../../global-state/redux';
import { appendId, coerceToObj } from '../../myNetworkingUtils';
import { setMyNetworking } from '../../../../../global-state/redux/actions';
import { getEventId } from '../../../core';
import { myNetworkingApiCall } from '../../myNetworkingApiUtils';
import { getAccessToken, getUserId } from '../../../loginInfo/loginInfo';
import { COMMUNITYUSER_INVITATION_SELECTOR_KEY } from './communityUserInvitationConstants';
import { apiGetCommunityUsers } from '../communityUsers';
import { FILESUPLOAD_TYPE_BANNER_IMAGE } from '../../settings/settingsConstants';
import { isChatInvitationPending } from '../chatUtils';
import { clearFriendRequests } from '../../notifications';

// Constants used to store results of the API response in store:
const STORE_MASK_COMMUNITYUSER_INVINVITATION_GET = 'myNetworking/chats/invitations/get';
const STORE_MASK_COMMUNITYUSER_INVINVITATION_ACCEPT = 'myNetworking/chats/invitations/accept';
const STORE_MASK_COMMUNITYUSER_INVINVITATION_DECLINE = 'myNetworking/chats/invitations/decline';

const { dispatch } = store;

const PATHNAME = '/v1/chats.json';
const getPathname = (id) => `/v1/chats/${id}.json`;

export const apiGetCommunityUserInvitations = (props) => {
  const { onSuccessVmEventList, onErrorVmEventList, skipDebounce } = coerceToObj(props);

  const body = {
    per_page: 999, // get all
    user_id: getUserId(),
    access_token: getAccessToken(),
    event_id: getEventId(),
    include_blocked: 1,
    include_partner_membership: 1,
    include_partner_filesupload_items: 1,
    include_partner_filesupload_items_filesupload_type:
      FILESUPLOAD_TYPE_BANNER_IMAGE,
    include_partner_tags: 1
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_COMMUNITYUSER_INVINVITATION_GET,
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      // save to relevant myNetworking location in redux:
      const invitations = res.data.filter(isChatInvitationPending);
      dispatch(
        setMyNetworking(COMMUNITYUSER_INVITATION_SELECTOR_KEY, invitations)
      );
    }
    return res;
  });
};

export const apiCommunityUserInvitationAccept = (props) => {
  const {
    chatId: chatIdStr,
    onErrorVmEventList,
    onSuccessVmEventList,
    skipDebounce,
    refreshRate
  } = coerceToObj(props);
  const chatId = parseInt(chatIdStr, 10);
  if (!chatId) return Promise.resolve({});

  const body = {
    access_token: getAccessToken(),
    id: chatId,
    user_id: getUserId(),
    myself_blocked: 0,
    myself_deleted: 0
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_COMMUNITYUSER_INVINVITATION_ACCEPT, chatId),
    pathname: getPathname(chatId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce,
    refreshRate
  }).then((res) => {
    if (res.isDebounced) return res;
    const partnerId = res.data.partner.id;

    Promise.all([
      clearFriendRequests(partnerId), // clear channelMessages:
      apiGetCommunityUsers({ skipDebounce: true }),
      apiGetCommunityUserInvitations({ skipDebounce: true })
    ]);

    return res;
  });
};

export const apiCommunityUserInvitationDecline = (props) => {
  const {
    chatId: chatIdStr,
    onErrorVmEventList,
    onSuccessVmEventList
  } = coerceToObj(props);
  const chatId = parseInt(chatIdStr, 10);
  if (!chatId) return Promise.resolve({});

  const body = {
    access_token: getAccessToken(),
    id: chatId,
    user_id: getUserId(),
    myself_deleted: 1,
    myself_blocked: 0
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_COMMUNITYUSER_INVINVITATION_DECLINE, chatId),
    pathname: getPathname(chatId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return res;

    const partnerId = res.data.partner.id;
    Promise.all([
      clearFriendRequests(partnerId), // clear chanelMessages:
      apiGetCommunityUserInvitations({ skipDebounce: true })
    ]);

    return res;
  });
};
