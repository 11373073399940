// The bussines logic for webinar evaluation is in https://jmarquardt.atlassian.net/browse/JMT-10687?filter=17470.

// x-filter in header comes as stringified JSON object:
const getXFilter = (apiDataItem) => {
  let xFilter;
  try {
    xFilter = JSON.parse(apiDataItem?.headers?.['x-filter']);
  } catch {
    console.log('VM2 - cannot parse x-filter in isErrorNotEnoughtTime from: ', {
      apiDataItem
    });
  }

  return xFilter;
};

// Implements bussines logic to check if there are any unanswered questions.
export const hasUnfinishedEvaluation = (apiDataItem) => {
  const result = getXFilter(apiDataItem)?.result ?? {};

  // We must check for equality to 0 to avoid confusion if the `done` is undefined.
  // In such case missing done would be interpreted as the evaluation wad be done.
  return Object.values(result).some(({ done }) => done === 0);
};

// Test if the text of error in header x-Filter signals an error:
// RegExp test function can accept any type of variable, so no need to safeguard argument.
// This is according to the ticket https://jmarquardt.atlassian.net/browse/JMT-10687?filter=17470
export const isErrorNotEnoughtTime = (apiDataItem) => /^no enough time/.test(getXFilter(apiDataItem)?.error);
