import React from 'react';
import PropTypes from 'prop-types';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import AnyErrorBoundary from '../global/AnyErrorBoundary';

const Navbar = (props) => {
  const {
    styles, components, variant, ...restProps
  } = props;

  return (
    <AnyErrorBoundary>
      <nav
        className={`container-fluid px-lg-0 navbar navbar-expand-lg navbar-light p-0 ${variant || ''}`}
        style={{
          backgroundColor: '#fff',
          ...styles?.wrapper
        }}
      >
        <div className="container" style={styles?.toolbar}>
          <ComponentsArrRenderer components={components} {...restProps} parent={['Navbar']} />
        </div>
      </nav>
    </AnyErrorBoundary>
  );
};

Navbar.propTypes = {
  variant: PropTypes.string,
  styles: stylesPropType,
  components: componentsPropType,
};

Navbar.defaultProps = {
  variant: '',
  styles: {
    wrapper: {},
    toolbar: {},
  },
  components: [],
};

Navbar.vmPreview = {
  components: [
    {
      componentName: 'JmeText',
      text: 'Navbar display'
    }
  ]
};

Navbar.vmPropTypes = {
  variant: vmPropTypes.className,
  styles: {
    wrapper: vmPropTypes.styles,
    toolbar: vmPropTypes.styles,
  },
  components: vmPropTypes.components
};

export default Navbar;
