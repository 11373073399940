import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeGrid = (componentStyles) => `
  .jme-grid-container {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .jme-grid-item {
  }
  
  .jme-grid-list {
  }
  
  .jme-grid-preview {
    display: block;
    background-color: #fff;
    border: solid 1px;
    overflow-y: auto;
    height: 45rem;
    padding-bottom: 2rem;
  }

  .jme-grid-preview-toolbar {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .jme-grid-preview-content {
    text-align: initial;
  }
  `;

export default stylesJmeGrid;
