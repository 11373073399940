import { componentStylesToCss } from '../componentStylesUtils';

const stylesClickHref = (componentStyles) => `
  .clickHref-wrapper {
    text-decoration: none !important;
    display: block;
    color: inherit;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .clickHref-wrapper:hover {
    text-decoration: none;
    color: inherit
  }

  .clickHref-wrapper.vmDisablePointerEvents {
    pointer-events: none;
  }
`;

export default stylesClickHref;
