import storageAndCookie from '../../storage-and-cookies';
import { vmTypes } from '../../../global-prop-types';

export const setCookie = (name, days) => () => storageAndCookie.set(
  name,
  `${window.location.pathname}${window.location.search}`,
  days
);

export const getCookie = (name) => storageAndCookie.get(name, true);

getCookie.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'Cookie name', vmType: vmTypes.string }
  ]
};
