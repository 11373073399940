import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeDownload = (componentStyles) => `
  .jme-download-wrapper {
    text-decoration: none;
    color: inherit;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .jme-download-wrapper:hover {
    text-decoration: none;
    color: inherit
  }
`;

export default stylesJmeDownload;
