import { getMyNetworkingByKey } from '../myNetworkingSelectors';
import { getStoreKey } from '../myNetworkingUtils';
import { GROUPPOSTS_SELECTOR_KEY } from './grouppostConstants';

// Posts per group:
export const getGroupposts = (groupIdStr, isPinned) => {
  const groupId = parseInt(groupIdStr, 10);
  const selectorKey = getStoreKey(GROUPPOSTS_SELECTOR_KEY, groupId);
  if (isPinned === true) return getMyNetworkingByKey(selectorKey)?.filter((el) => el.is_pinned) ?? [];
  if (isPinned === false) return getMyNetworkingByKey(selectorKey)?.filter((el) => !el.is_pinned) ?? [];

  return getMyNetworkingByKey(selectorKey) ?? [];
};

export const hasGroupposts = (groupId) => getGroupposts(groupId).length;
export const hasNotGroupposts = (groupId) => !hasGroupposts(groupId);

// Pinned posts per group:
export const getPinnedGroupposts = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const selectorKey = getStoreKey(GROUPPOSTS_SELECTOR_KEY, groupId, 'pinned');
  return getMyNetworkingByKey(selectorKey)?.slice(0, 3) ?? [];
};
export const hasPinnedGroupposts = (groupId) => getPinnedGroupposts(groupId).length;

export const hasNotPinnedGroupposts = (groupId) => !hasPinnedGroupposts(groupId);

export const isPostPinned = (postId) => getPinnedGroupposts().some((el) => el.id === postId);

export const getGrouppostByPostId = (postIdStr) => {
  const postId = parseInt(postIdStr, 10);
  const selectorKey = getStoreKey(GROUPPOSTS_SELECTOR_KEY, 'postId', postId);
  return getMyNetworkingByKey(selectorKey) ?? {};
};
