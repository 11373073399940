import { componentStylesToCss } from '../componentStylesUtils';

const stylesSponsorsProfile = (componentStyles, theme) => `
  .sponsorsProfile-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) {
    .sponsorsProfile-imageBox,
    .sponsorsProfile-descriptionBox {
      min-height: 200px !important;
    }
  }
`;

export default stylesSponsorsProfile;
