import { getNotifications } from './channelMessages';

const getPostTimestamp = (post) => post?.content?.community?.posted_at;

const sortByLastActivity = (a, b) => {
  const postTimestampA = getPostTimestamp(a);
  const postTimestampB = getPostTimestamp(b);
  if ((postTimestampA - postTimestampB) < 0) return 1;
  if ((postTimestampA - postTimestampB) > 0) return -1;
  return 0;
};

// eslint-disable-next-line import/prefer-default-export
export const getSortedNotifications = () => {
  const postNotifications = getNotifications() ?? [];
  const invitationsNotifications = [];
  return [...postNotifications, ...invitationsNotifications].sort(sortByLastActivity);
};
