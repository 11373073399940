import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
// import { Loading } from '..';
import { componentsPropType, vmPropTypes } from '../../global-prop-types';
import DynamicCompRenderer from './DynamicCompRenderer';
import AnyErrorBoundary from '../global/AnyErrorBoundary';

const EmptyLoading = () => null;

const ComponentsArrRenderer = (props) => {
  /* Comment: We seperate the components from the rest of the props
  in order to stop propagating components array to children. Passing
  components from parent to children overwrite defaultProps as set
  in our  components. */
  const { components, parent = [], ...restProps } = props;
  if (!parent) {
    console.warn('ComponentsArrRenderer parent', parent);
  }
  return (
    <>
      <AnyErrorBoundary>
        <Suspense fallback={(<EmptyLoading />)}>
          {components?.map((comp, idx) => (
          // comp.id || idx - in admin panel we pass single jsx component to ComponentArrRenderer
          // as eg. components=[JMEText]. This components Array has `comp.id` undefined.
            <DynamicCompRenderer key={comp?.id || idx} parent={[...parent, 'components', idx]} {...restProps} {...comp} />
          ))}
        </Suspense>
      </AnyErrorBoundary>
    </>
  );
};

ComponentsArrRenderer.propTypes = {
  components: componentsPropType,
  parent: PropTypes.array,
};

ComponentsArrRenderer.defaultProps = {
  components: []
};

ComponentsArrRenderer.vmPropTypes = {
  components: vmPropTypes.components,
  apiData: vmPropTypes.apiData,
};

ComponentsArrRenderer.processPropVmTypes = (props) => {
  const { components, ...restProps } = props;
  return DynamicCompRenderer.processPropVmTypes(restProps);
};

export default ComponentsArrRenderer;
