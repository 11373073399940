import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeAccordion = (componentStyles, theme) => `
  .jme-accordionGroup-wrapper {
    ${componentStylesToCss(componentStyles?.groupWrapper)}
  }
  .jme-accordionItem-wrapper {
    ${componentStylesToCss(componentStyles?.itemWrapper)}
  }
  .jme-accordionItem-headline {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin: ${theme.spacing(0)} ${theme.spacing(0)};
  }
  .jme-accordionItem-headline button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-shadow: none!important;
  }
  .jme-accordionItem-headline-icon {
    transition: all 0.3s ease;
    font-size: 22px;
  }
  .jme-accordionItem-content {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  .jme-accordionItem-gridContainer {
    display: grid;
    grid-gap: .25rem;
    grid-template-columns: repeat( auto-fill, minmax(30%, 1fr) );
  }
  @media screen and (min-width: ${theme.breakpoints.values.xs}${theme.breakpoints.unit}) {
    .jme-accordionItem-content {
      grid-template-columns: repeat( auto-fill, minmax(8.438rem, 1fr) );
    }
  }
  @media screen and (min-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) {
    .jme-accordionItem-gridContainer {
      grid-gap: 1rem;
      grid-template-columns: repeat( auto-fill, minmax(15%, 1fr) );
    }
  }
  .jme-accordionItem-headline {
    text-transform: uppercase;
  }
  @media screen and (min-width: ${theme.breakpoints.values.xs}${theme.breakpoints.unit}) {
    .jme-accordionItem-headline {
      font-size: .875rem;
    }
  }
  @media screen and (min-width: ${theme.breakpoints.values.sm}${theme.breakpoints.unit}) {
    .jme-accordionItem-headline {
      font-size: 1rem;
    }
  }
`;

export default stylesJmeAccordion;
