import { useMemo } from 'react';
import { MODULE_NAME } from './constants';
import {
  useIsActive as useIsActiveByName,
  useSettings as useSettingsByName
} from '../../hooks';
import { withAccessToken, withManageConfig } from './utils';

export const useIsActive = () => useIsActiveByName(MODULE_NAME);

export const useSettings = () => {
  const settings = useSettingsByName(MODULE_NAME);
  const ret = useMemo(() => withAccessToken(settings), [settings]);
  return ret;
};

export const useSettingsWithManage = (moduleName) => {
  const moduleConfig = useSettingsByName(moduleName);
  const isModuleActive = useIsActiveByName(moduleName);

  if (!isModuleActive) return null;

  return withManageConfig(moduleConfig);
};
