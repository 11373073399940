import {
  CLEAR_CHANNEL_NOTIFICATIONS,
  CLEAR__CHAT_NOTIFICATIONS,
  INCREASE_CHANNEL_NOTIFICATIONS,
  DECREASE_CHANNEL_NOTIFICATIONS,
  IS_SOCKET_OPEN,
  IS_SUBSCRIBED,
  RAISE_CHAT_NOTIFICATIONS,
  SET_CHANNEL_NOTIFICATIONS
} from '../constants/websocket-constants';

const notificationsInit = {
  count: 0,
  messages: [],
};

const wsState = {
  isSocketOpen: false,
  isSubscribed: false,
  channelMessageNotifications: notificationsInit,
  isChatNotifications: false
};

const webSocketReducer = (state = wsState, action) => {
  switch (action.type) {
    case IS_SOCKET_OPEN:
      return {
        ...state,
        isSocketOpen: action.payload
      };
    case IS_SUBSCRIBED:
      return {
        ...state,
        isSubscribed: action.payload
      };
    case SET_CHANNEL_NOTIFICATIONS:
      return {
        ...state,
        channelMessageNotifications: {
          count: action.payload.length,
          messages: action.payload
        }
      };
    case INCREASE_CHANNEL_NOTIFICATIONS: {
      // action.payload = single notification
      const messagesNew = [
        ...state.channelMessageNotifications.messages,
        action.payload
      ];
      return {
        ...state,
        channelMessageNotifications: {
          ...state.channelMessageNotifications,
          count: messagesNew.length,
          messages: messagesNew
        }
      };
    }
    case DECREASE_CHANNEL_NOTIFICATIONS:
      // action.payload = all notifications
      return {
        ...state,
        channelMessageNotifications: {
          ...state.channelMessageNotifications,
          count: action.payload.length,
          messages: action.payload
        }
      };
    case CLEAR_CHANNEL_NOTIFICATIONS:
      return {
        ...state,
        channelMessageNotifications: notificationsInit
      };
    case RAISE_CHAT_NOTIFICATIONS:
      return {
        ...state,
        isChatNotifications: true
      };
    case CLEAR__CHAT_NOTIFICATIONS:
      return {
        ...state,
        isChatNotifications: false
      };
    default:
      return state;
  }
};

export default webSocketReducer;
