import PropTypes from 'prop-types';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';

const SplashScreen = (props) => {
  const { styles, variant, ...restProps } = props;
  return (
    <div className={`splashScreen-wrapper ${variant || ''}`} style={styles?.wrapper}>
      <div className="container">
        <ComponentsArrRenderer {...restProps} />
      </div>
    </div>
  );
};

SplashScreen.propTypes = {
  variant: PropTypes.string,
  components: componentsPropType,
  styles: stylesPropType,
};

SplashScreen.defaultProps = {
  variant: '',
  components: [],
  styles: {
    wrapper: {},
  },
};

SplashScreen.vmPropTypes = {
  variant: vmPropTypes.className,
  components: vmPropTypes.components,
  styles: {
    wrapper: vmPropTypes.styles,
  },
};

export default SplashScreen;
