import jsonToJsxComponent from '../jsonToJsxComponent';
import ResourcePageJson from './ResourcePage.json';
import ResourceTitlesJson from './ResourceTitles.json';
import ResourceContentJson from './ResourceContent.json';
import ResourcesPreviewJson from './ResourcesPreview.json';
import ResourceCardJson from './ResourceCard.json';

const ResourcePage = jsonToJsxComponent(ResourcePageJson);
const ResourceTitles = jsonToJsxComponent(ResourceTitlesJson);
const ResourceContent = jsonToJsxComponent(ResourceContentJson);
const ResourcesPreview = jsonToJsxComponent(ResourcesPreviewJson);
const ResourceCard = jsonToJsxComponent(ResourceCardJson);

export {
  ResourcePage,
  ResourceTitles,
  ResourceContent,
  ResourcesPreview,
  ResourceCard
};
