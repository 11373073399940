import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_READY,
  STATUS_UNINITIALIZED
} from '../../constants';
import { MODULE_NAME } from './constants';

export const SET_ACCESS_CONTROL = 'modules/access-control/set';

// Selectors:
export const selectStatus = (state) => state.appState[MODULE_NAME]?.status ?? STATUS_UNINITIALIZED;

// setters:
const actionFactory = (status) => ({
  type: SET_ACCESS_CONTROL,
  payload: { status }
});

export const setLoading = actionFactory(STATUS_LOADING);
export const setReady = actionFactory(STATUS_READY);
export const setError = actionFactory(STATUS_ERROR);
