import { COMMUNITYUSER_INVITATION_SELECTOR_KEY } from './communityUserInvitationConstants';
import { getMyNetworkingByKey } from '../../myNetworkingSelectors';
import { isChatInvitationPending } from '../chatUtils';

export const getCommunityUserInvitations = () => getMyNetworkingByKey(COMMUNITYUSER_INVITATION_SELECTOR_KEY)?.map((el) => {
  // we need to add chat array to partner, because many tempates which accept user object, expect the
  // information about user connections inside the userObj.chat[0] element, however this information is not in chat.partner object:
  const { partner, ...restEl } = el;
  return { ...el, partner: { ...partner, chat: [restEl] } };
}) ?? [];

export const isUserInvited = (partnerIdStr) => {
  const partnerId = parseInt(partnerIdStr, 10);
  const invitations = getCommunityUserInvitations();
  return invitations.some(
    (el) => el.partner.id === partnerId && isChatInvitationPending(el)
  );
};

export const getInvitationsPartnerList = () => getCommunityUserInvitations().map((el) => el.partner);
