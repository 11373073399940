import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeBox = (componentStyles) => `
  .jmeBox-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  
  .jmeBox-bubble {
    position: relative;
  }
  
  .jmeBox-bubble:before {
    content: ' ';
    position: absolute;
    bottom: auto;
    top: 0;
    left: 3%;
    width: 0;
    height: 0;
  }
`;

export default stylesJmeBox;
