import { componentStylesToCss } from '../componentStylesUtils';

const stylesSvgComp = (componentStyles) => `
  .svgComp-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .svgComp-wrapper div {
  }
  .svgComp-wrapper svg {
  }
  .svgComp-wrapper svg * {
    fill: ${componentStyles?.wrapper?.fill};
  }
`;

export default stylesSvgComp;
