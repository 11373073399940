import { componentStylesToCss } from '../componentStylesUtils';

const stylesCarouselComp = (componentStyles) => `
.carouselComp-wrapper {
  ${componentStylesToCss(componentStyles?.wrapper)}
}
.carouselComp-item {
  ${componentStylesToCss(componentStyles?.item)}
  max-width: 100%;
  width: 100%; 
  height: 100%;
}
`;

export default stylesCarouselComp;
