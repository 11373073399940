import React from 'react';
import PropTypes from 'prop-types';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';

const JmeContainer = ({
  className, styles, components, variant, ...restProps
}) => (
  <div
    className={`container-xl jme-container ${className} ${variant || ''}`}
    style={styles?.wrapper}
  >
    <ComponentsArrRenderer
      components={components}
      {...restProps}
    />
  </div>
);
JmeContainer.propTypes = {
  variant: PropTypes.string,
  styles: stylesPropType,
  components: componentsPropType,
  parent: PropTypes.array,
  className: PropTypes.string,
};

JmeContainer.defaultProps = {
  variant: '',
  styles: { wrapper: {} },
  components: [],
  parent: [],
  className: ''
};

JmeContainer.vmPreview = {
  styles: { wrapper: { border: '2px solid black' } },
  components: [
    {
      componentName: 'JmeText',
      text: 'JmeContainer Display'
    }
  ]
};

JmeContainer.vmPropTypes = {
  variant: vmPropTypes.className,
  components: vmPropTypes.components,
  styles: { wrapper: vmPropTypes.styles },
  className: vmPropTypes.string,
};

export default JmeContainer;
