import { componentStylesToCss } from '../componentStylesUtils';

const stylesInputUrlSearchParamQuery = (componentStyles) => `
  .inputUrlSearchParamQuery-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .inputUrlSearchParamQuery-container--searchIcon {
    position: absolute;
    color: gray;
    right: 0.7em;
    font-size: 1.5em;
    ${componentStylesToCss(componentStyles?.searchIconWrapper)}
  }

  .inputUrlSearchParamQuery-container--searchIcon i {
    background-color: #FFFFFF;
    ${componentStylesToCss(componentStyles?.searchIcon)}
  }
`;

export default stylesInputUrlSearchParamQuery;
