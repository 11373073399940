import { componentStylesToCss } from '../componentStylesUtils';

const stylesMediaMetaInfo = (componentStyles) => `
  .mediaMetaInfo-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
`;

export default stylesMediaMetaInfo;
