import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router';
import PageRenderer from '../components/core/PageRenderer';
import { componentsPropType } from '../global-prop-types';

// TODO: Guy make handler list global

// TODO: Guy forward to the page renderer the query string
const DefaultComponentsPage = (props) => {
  const { pageParams } = props;
  const location = useLocation();
  const pageUseParams = useParams();
  return (
    <PageRenderer
      urlParams={{
        ...pageParams,
        ...pageUseParams
      }}
      urlSearchParams={
        location?.search
          ? Object.fromEntries(new URLSearchParams(location.search))
          : {}
      }
      {...props}
    />
  );
};

DefaultComponentsPage.propTypes = {
  components: componentsPropType,
  apiDataInfo: PropTypes.array,
  pageParams: PropTypes.shape({})
};

DefaultComponentsPage.defaultProps = {
  components: [],
  pageParams: {}
};
export default DefaultComponentsPage;
