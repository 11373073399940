import storageAndCookie from '../../../global-utils/storage-and-cookies';

const fetchBookedTickets = async (manageConfig, options) => {
  const { apiBaseUrl, eventId } = manageConfig;
  const { accessToken, isActive } = options;
  const externalAuthorizationCode = storageAndCookie.get('externalAuthorizationCode', true) || '';

  if (!isActive) throw new Error('Module is not active');
  if (!accessToken) throw new Error('Access token is missing');

  const url = `${apiBaseUrl}/api/modules/BookedTickets?eventid=${eventId}&externalAuthorizationCode=${externalAuthorizationCode}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  storageAndCookie.del('externalAuthorizationCode', true);
  if (!response.ok) {
    console.log('Ticketsync Error');
    const error = new Error(`HTTP Error: ${response.status}`);
    error.statusCode = response.status;
    error.statusText = response.statusText;
    throw error; // Throw error for SWR to handle
  }

  const data = await response.json();
  return { data, statusCode: response.status };
};

export { fetchBookedTickets };
