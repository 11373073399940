import { componentStylesToCss } from '../componentStylesUtils';

const stylesYoutube = (componentStyles) => `
  .youtube-wrapper {
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 */
    position: relative;
    height: 0;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .youtube-wrapper iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
`;

export default stylesYoutube;
