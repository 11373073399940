import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeSidePopup = (componentStyles, theme) => `
  .jme-sidepopup-wrapper {
    border:none;
    position: absolute;
    top: 80%;
    display: flex;
    flex-direction: column;
    color: white;
    background: #232470;
    padding:.5rem;
    z-index: 2;
    width: 5rem;
    transition: all 1s linear;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-sidepopup-wrapper--openClose-close {
    align-items:center;
  }
  .jme-sidepopup-wrapper--openClose-open{
    width:20%;
    transition: all 1s linear;
  }
  .jme-sidepopup-x-icon-wrapper {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: end;
    border: none;
    background: transparent;
    color: white;
  }
  .jme-sidepoup-label-wrapper {
    height:90%;
    writing-mode: vertical-rl;
    rotate: 180deg;
    transition: width 1s linear;
  }
  .jme-sidepoup-label-wrapper-open {
    width:100%;
    rotate: 0;
  }
  .jme-sidepoup-videoIcon-wrapper {
    color:white;
    position:absolute;
    top:50%;
    border-radius:50%;
    background:red;
    rotate:270deg;
    padding: .5rem;
  }
  .jme-sidepoup-videoIcon-wrapper-right {
    left:-1.5rem;
  }
  .jme-sidepoup-videoIcon-wrapper-left {
    right:-1.5rem;
  }
  .jme-sidepopup-bottomButton-wrapper {
    position:absolute;
    bottom:0;
    left:0;
    width: 100%;
    border: none;
  }
  .jme-sidepopup-bottomButton-wrapper-button {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius:0;
    background: #0BA1E2;
    color:white;
  }
  @media screen and (max-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit}) {
    .jme-sidepopup-wrapper--openClose-open{
      min-width:25%;
      transition: all 1s linear;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .jme-sidepopup-wrapper--openClose-open{
      min-width:50%;
      transition: all 1s linear;
    }
  }
`;

export default stylesJmeSidePopup;
