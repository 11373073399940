import PropTypes from 'prop-types';
import { useState } from 'react';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import { vmPropTypes } from '../../global-prop-types';
import { isStr, vmTreeSet } from '../../global-utils';

const LocalState = (props) => {
  const {
    initialValue,
    localStateKey: localStateKeyProps,
    ...restProps
  } = props;
  const [localState, setLocalState] = useState(initialValue);

  // the below useEffect creates a problem on page re-renders,
  // coz if the initialValue is an [] or {} it always trigers setLocalState
  // and therefore clears already assigned values to the localState variable.
  // I comment it out and after some time we coudl remove it from the component
  // if no issues are found.
  // useEffect(() => {
  // setLocalState(initialValue);
  // }, [initialValue]);

  const localStateKey = isStr(localStateKeyProps)
    ? localStateKeyProps
    : 'localState';
  const setLocalStateKey = `set${localStateKey
    .charAt(0)
    .toUpperCase()}${localStateKey.slice(1)}`;
  const setByPath = `${setLocalStateKey}ByPath`;

  const localStateProps = {
    [localStateKey]: localState,
    [setLocalStateKey]: setLocalState,
    [setByPath]: (vmPath, value) => setLocalState((prev) => vmTreeSet(prev, vmPath, value))
  };

  return <ComponentsArrRenderer {...localStateProps} {...restProps} />;
};

LocalState.propTypes = {
  localStateKey: PropTypes.string,
  initialValue: PropTypes.any
};

LocalState.vmPropTypes = {
  initialValue: vmPropTypes.any,
  localStateKey: vmPropTypes.string,
  components: vmPropTypes.components
};

export default LocalState;
