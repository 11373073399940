import PropTypes from 'prop-types';
import vmPathPropType from './vmPathPropType';
import { vmTypes } from './vmTypes';

const dataMappingPropType = PropTypes.shape({
  vmType: PropTypes.oneOf([vmTypes.dataMapping]),
  vmPath: vmPathPropType,
  args: PropTypes.array
});

export default dataMappingPropType;
