import PropTypes from 'prop-types';
// import { isObj } from '../global-utils/globalUtils';
// import { validateDataBankAccessPaths } from '../global-utils/map-data-to-props';

import dataMappingPropType from './dataMappingPropType';

// /**
//  * @summary recursively validate values of an object
//  * @description the leaves of an objec can only be strings containing valid placeholders to access data bank.
//  * @param {object} propToPropMap object to recursively chack for placeholders
//  * @returns {boolean} true if all values contain valid placeholders, otherwise false.
//  * @example
//  * validatePropToPropMap(123) // false - arg is not a string
//  * validatePropToPropMap({ styles: {wrapper: {display: 3}}) // false - value for display do not contain placeholder
//  * validatePropToPropMap("$(props.a)") // true - arg contains valid placeholder
//  * validatePropToPropMap({ styles: {wrapper: {display: "$(props.display)"}}) // true - arg is object with valid placeholder
//  */
// function validatePropToPropMap(propToPropMap) {
//   if (isObj(propToPropMap)) {
//     return Object.values(propToPropMap).every((propToPropMapVal) => (propToPropMapVal.vmType
//       ? false
//       : validatePropToPropMap(propToPropMapVal)));
//   }

//   // validatePropToPropMap(propToPropMapVal));

//   // the only other accepted value or propToPropMap in this point of recursion is a string:
//   return validateDataBankAccessPaths(propToPropMap);
// }

// function propToPropMapPropType(props, propName, componentName) {
//   const propToPropMap = props[propName];
//   if (propToPropMap === undefined) return null; // propToPropMap do not have to be defined
//   if (!isObj(propToPropMap) || !validatePropToPropMap(propToPropMap)) {
//     return new Error(
//       `Invalid prop ${propName} passed to ${componentName} (${JSON.stringify(
//         propToPropMap
//       )}). Expected a valid placeholder pattern. Please check data mappings!`
//     );
//   }
//   return null;
// }
const propToPropMapPropType = PropTypes.oneOfType([PropTypes.string, dataMappingPropType]);
export default propToPropMapPropType;
