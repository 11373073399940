import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  componentsPropType,
  stylesPropType,
  vmPropTypes
} from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import { IconComp } from '../Layout';

const JmeSidePopup = (props) => {
  const {
    isStickToTheRight,
    boxHeight,
    label,
    components,
    variant,
    styles,
    showNavigateButton,
    navigateButtonText,
    closeIcon,
    videoIcon,
    internalLink,
    ...restProps
  } = props;
  const [isExtended, setIsExtended] = useState(false);
  const [isShown, setIsShown] = useState(true);

  // TODO: use isExtended, render components, two icons as a prop, animations/transitions

  if (!isShown) {
    return null;
  }

  const generateSide = () => {
    if (isStickToTheRight) {
      return {
        right: '0px',
        borderTopLeftRadius: '1rem',
        borderBottomLeftRadius: '1rem'
      };
    }
    return {
      left: '0px',
      borderTopRightRadius: '1rem',
      borderBottomRightRadius: '1rem'
    };
  };

  return (
    <button
      className={`jme-sidepopup-wrapper jme-sidepopup-wrapper--openClose${
        isExtended ? '-open' : '-close'
      } ${variant || ''}`}
      type="button"
      onClick={() => setIsExtended((prev) => !prev)}
      style={{
        height: `${boxHeight}px`,
        ...styles?.wrapper,
        ...generateSide()
      }}
    >
      <button
        type="button"
        className="jme-sidepopup-x-icon-wrapper"
        onClick={() => setIsShown(false)}
      >
        <IconComp icon={closeIcon} />
      </button>
      <div className={`jme-sidepoup-label-wrapper${isExtended ? '-open' : ''}`}>
        <span>{label}</span>
      </div>
      <div
        className={`jme-sidepoup-videoIcon-wrapper ${
          isStickToTheRight
            ? 'jme-sidepoup-videoIcon-wrapper-right'
            : 'jme-sidepoup-videoIcon-wrapper-left'
        }`}
      >
        <IconComp icon={videoIcon} />
      </div>
      {isExtended && (
        <div style={{ overflow: 'hidden' }}>
          <ComponentsArrRenderer components={components} {...restProps} />
        </div>
      )}
      {isExtended && showNavigateButton && (
        <div className="jme-sidepopup-bottomButton-wrapper">
          <button
            type="button"
            className="btn jme-sidepopup-bottomButton-wrapper-button"
            style={{
              borderBottomLeftRadius: isStickToTheRight ? '1rem' : 0,
              borderBottomRightRadius: isStickToTheRight ? 0 : '1rem'
            }}
          >
            <a href={internalLink} style={{ color: 'white' }}>
              {navigateButtonText}
            </a>
          </button>
        </div>
      )}
    </button>
  );
};

JmeSidePopup.propTypes = {
  styles: stylesPropType,
  boxHeight: PropTypes.number,
  variant: PropTypes.string,
  isStickToTheRight: PropTypes.bool,
  showNavigateButton: PropTypes.bool,
  label: PropTypes.string,
  navigateButtonText: PropTypes.string,
  closeIcon: PropTypes.object,
  videoIcon: PropTypes.object,
  internalLink: PropTypes.string,
  components: componentsPropType
};

JmeSidePopup.defaultProps = {
  styles: {
    wrapper: {}
  },
  boxHeight: 250,
  variant: '',
  isStickToTheRight: true,
  showNavigateButton: true,
  label: 'Wir sind Live !',
  navigateButtonText: 'Sitzung ansehen',
  closeIcon: { name: 'Close', iconType: 'mui' },
  videoIcon: { name: 'playCircle', iconType: 'mui', isOutlined: true },
  internalLink: '/',
  components: []
};

JmeSidePopup.vmPreview = {
  styles: {
    wrapper: { top: '30%' }
  },
  components: [
    {
      componentName: 'JmeText',
      text: 'Here you can render components'
    }
  ]
};

JmeSidePopup.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  boxHeight: vmPropTypes.number,
  variant: vmPropTypes.className,
  showNavigateButton: vmPropTypes.boolean,
  isStickToTheRight: vmPropTypes.boolean,
  label: vmPropTypes.string,
  navigateButtonText: vmPropTypes.string,
  closeIcon: vmPropTypes.icon,
  videoIcon: vmPropTypes.icon,
  internalLink: vmPropTypes.internalHref,
  components: vmPropTypes.components
};

export default JmeSidePopup;
