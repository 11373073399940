import { isArr, isInt, isStr } from '../globalUtils';

/**
 * @description chaeck if argument is a vmPath. Once the vmApp works, we can disable it by
 * chacking NODE_ENV constant.
 * @param {any} vmPath any kind of data
 * @returns {bool} true if vmPath is an array composed only from strings (ie. object keys)
 * and/or integers (array indexes). false othervise
 */
export function isVmPath(vmPath) {
  return isArr(vmPath) && vmPath.every((el) => isInt(el) || isStr(el));
}

/**
 * @description checks if two given arrays, are identical. Used to compare the parentPath
 * @param {*} array1
 * @param {*} array2
 * @returns true/false
 */
export const compareArray = (array1, array2) => array1.length === array2.length
  && array1.every((value, index) => value === array2[index]);

/**
 * @description returns path to the parent element of the element indicated by the vmPath.
 * @param {Array} vmPath
 * @returns {Array} elements of the vmPath from first to the last but one.
 * Following happens:
 *   - if vmPath is do not pass the test for vmPath return undefined
 *   - iv vmPath has zero or one element, returns empty array []
 *   - in all other cases elements of the vmPath from idx = 0 to the vmPath.length -1
 */
export function vmPathGetParent(vmPath) {
  if (!isVmPath(vmPath)) return undefined;
  return vmPath.slice(0, vmPath.length - 1);
}

/**
 * @description returns last element of the vmPath
 * @param {Array} vmPath
 * @returns {String | number} last element of the vmPath. Following happens:
 *   - if vmPath is do not pass the test for vmPath or it's mepty array, returns undefined
 *   - in all other cases the last element of the aray is returned.
 */
export function vmPathGetChild(vmPath) {
  if (!isVmPath(vmPath)) return undefined;
  return vmPath[vmPath.length - 1];
}

export function vmPathGetParentChild(vmPath) {
  return [vmPathGetParent(vmPath), vmPathGetChild(vmPath)];
}
