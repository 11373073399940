import storageAndCookie from '../../storage-and-cookies';
import { vmTypes } from '../../../global-prop-types';
import { vmTreeGet } from '../../vmTree';
import { isVmPath } from '../../vmPath/vmPath';

// eslint-disable-next-line import/prefer-default-export
export const getStorage = (storageKey, vmPathStorage) => {
  const storageVal = storageAndCookie.get(storageKey, false);

  // Some of the values in the storage can be objects or arrays.
  // Second arg allows for descending inside the storageVal object,
  // keep in min that storageVal is stringify
  return isVmPath(vmPathStorage)
    ? vmTreeGet(JSON.parse(storageVal), vmPathStorage)
    : storageVal;
};

getStorage.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'Storage key', vmType: vmTypes.string },
    { uiTitle: 'Path to value', vmType: vmTypes.array }
  ]
};
export const setStorage = (storageKey, storageValue) => {
  if (!storageKey || !storageValue) return null;
  return storageAndCookie.set(storageKey, storageValue);
};

getStorage.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'Storage key', vmType: vmTypes.string },
    { uiTitle: 'Storage value', vmType: vmTypes.string }
  ]
};
