import store from '../../../../global-state/redux';
import { setMyNetworking } from '../../../../global-state/redux/actions';
import { GROUPPOSTS_SELECTOR_KEY } from './grouppostConstants';
import { getStoreKey } from '../myNetworkingUtils';
import { getGroupposts, getPinnedGroupposts } from './grouppostSelectors';
import { parsePost } from './grouppostUtils';

const { dispatch } = store;

export const setGroupposts = (groupId, grouppostsNew) => {
  const selectorKey = getStoreKey(GROUPPOSTS_SELECTOR_KEY, groupId);
  dispatch(
    setMyNetworking(
      selectorKey,
      grouppostsNew.map((el) => parsePost(el))
    )
  );
};

export const addGrouppost = (groupId, grouppostNew) => {
  const groupposts = getGroupposts(groupId);
  setGroupposts(groupId, [grouppostNew, ...groupposts]);
};

export const deleteGrouppost = (groupId, postId) => {
  const groupposts = getGroupposts(groupId);
  setGroupposts(
    groupId,
    groupposts.filter((el) => el.id !== postId)
  );
};

const filterOutComment = (postObj, commentId) => ({
  ...postObj,
  children: postObj.children
    ?.filter((el) => el.id !== commentId)
    ?.map((el) => filterOutComment(el, commentId))
});

export const deleteGrouppostComment = (groupId, commentId) => {
  const groupposts = getGroupposts(groupId);
  const grouppostsNew = groupposts.map((el) => filterOutComment(el, commentId));
  setGroupposts(groupId, grouppostsNew);
};

export const setGrouppost = (postObj) => {
  const { id: groupId } = postObj.comment.chatgroup;
  const groupposts = getGroupposts(groupId);
  setGroupposts(
    groupId,
    groupposts.map((el) => (el.id === postObj.id ? postObj : el))
  );
};

// Pinned group posts:
export const setPinnedGroupposts = (groupId, grouppostsNew) => {
  const selectorKey = getStoreKey(GROUPPOSTS_SELECTOR_KEY, groupId, 'pinned');
  dispatch(
    setMyNetworking(
      selectorKey,
      grouppostsNew.map((el) => parsePost(el))
    )
  );
};

export const setGrouppostByPostId = (postObj) => {
  const selectorKey = getStoreKey(
    GROUPPOSTS_SELECTOR_KEY,
    'postId',
    postObj?.id
  );
  dispatch(setMyNetworking(selectorKey, parsePost(postObj)));
};

export const setIsPinned = (postObj) => {
  const { id: postId, comment } = postObj;
  const groupId = comment.chatgroup.id;

  const grouppostsNews = getGroupposts(groupId).map((el) => (el.id === postId
    ? {
      ...el,
      is_pinned: true
    }
    : el));

  setGroupposts(groupId, grouppostsNews);

  const pinnedGrouppostsNew = [postObj, ...getPinnedGroupposts(groupId)];
  setPinnedGroupposts(groupId, pinnedGrouppostsNew);
};

export const setIsUnpinned = (postObj) => {
  const { id: postId, comment } = postObj;
  const groupId = comment.chatgroup.id;

  const grouppostsNews = getGroupposts(groupId).map((el) => (el.id === postId
    ? {
      ...el,
      is_pinned: false
    }
    : el));

  setGroupposts(groupId, grouppostsNews);

  const pinnedGrouppostsNew = getPinnedGroupposts(groupId).filter(
    (el) => el?.id !== postId
  );
  setPinnedGroupposts(groupId, pinnedGrouppostsNew);
};

export const deletePinnedGrouppost = (groupId, postId) => {
  const groupposts = getPinnedGroupposts(groupId);
  setPinnedGroupposts(
    groupId,
    groupposts.filter((el) => el.id !== postId)
  );
};

export const setPinnedGrouppost = (postObj) => {
  const { id: groupId } = postObj.comment.chatgroup;
  const groupposts = getPinnedGroupposts(groupId);
  setPinnedGroupposts(
    groupId,
    groupposts.map((el) => (el.id === postObj.id ? postObj : el))
  );
};

export const deletePinnedGrouppostComment = (groupId, commentId) => {
  const groupposts = getPinnedGroupposts(groupId);
  const grouppostsNew = groupposts.map((el) => filterOutComment(el, commentId));
  setPinnedGroupposts(groupId, grouppostsNew);
};
