const extractSpeakers = (data) => {
  const tmp = [];
  data?.body?.contentsessions?.[0]?.resourcegroups?.forEach((el2) => {
    el2?.body?.resources?.forEach((el3) => {
      tmp.push(...el3?.authors);
    });
  });

  return tmp?.filter((el, idx, self) => (
    idx === self.findIndex((t) => (
      t.id === el.id
    ))
  ));
};

const postProcessSingleWebinar = (el) => {
  const speakers = extractSpeakers(el);
  return {
    ...el,
    vmPostProcess: {
      speakers,
      numOfSpeakers: speakers?.length
    }
  };
};

// eslint-disable-next-line import/prefer-default-export
export const postProcessApiResponse = () => (res) => ({
  ...res,
  data: res.data?.map((el) => postProcessSingleWebinar(el))
});
