import { isArr } from '../../globalUtils';

function selectFieldsToApiFilterList(selectFields = {}) {
  return Object.values(selectFields)
    .map((el) => el.apiKeyword)
    .join(',');
}

/**
 * Coerce different apiFilters to the same structure: idStr (String), title (String)
 * @param {Object} apiFilters
 * @returns
 */
const organizeApiFilters = (apiFilters) => Object.keys(apiFilters).reduce((acc, apiFilterKey) => {
  const organizeId = (id) => {
    if (isArr(id)) return id.map((el) => el.toString());
    return [id.toString()];
  };
  if (apiFilterKey === 'tag_ids') {
    return {
      ...acc,
      [apiFilterKey]: apiFilters[apiFilterKey].map((item) => ({
        ids: organizeId(item.id),
        idStr: item.id.join(','), // accorgin to Mathias, there cannot be two position with the same combination of ids
        uiTitle: item.tag,
      })),
    };
  }
  if (apiFilterKey === 'event_ids') {
    return {
      ...acc,
      [apiFilterKey]: apiFilters[apiFilterKey].map((item) => ({
        ids: organizeId(item.id),
        idStr: String(item.id),
        uiTitle: item.title,
      })),
    };
  }
  if (apiFilterKey === 'contentsessiontype_ids'
    || apiFilterKey === 'contentsessiongroup_ids'
  ) {
    return {
      ...acc,
      [apiFilterKey]: apiFilters[apiFilterKey].map((item) => ({
        ids: organizeId(item.id),
        idStr: item.id.join(','),
        uiTitle: item.name,
      })),
    };
  }
  if (apiFilterKey === 'contentsession_ids') {
    return {
      ...acc,
      [apiFilterKey]: apiFilters[apiFilterKey].map((item) => ({
        ids: organizeId(item.id),
        idStr: item.id.join(','),
        uiTitle: item.title,
      })),
    };
  }
  return acc;
}, {});

export { selectFieldsToApiFilterList, organizeApiFilters };
