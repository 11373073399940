import { useDispatch } from 'react-redux';
import { mapData } from '../dataMapping';
import { isArr, isObj } from '../globalUtils';
import store from '../../global-state/redux';
import vmFunctions from '../vmFunctions/index';
import { vmTypes } from '../../global-prop-types';

const enrichDataBank = (dataBank, dataBankExtend) => {
  if (isObj(dataBankExtend)) return { ...dataBank, ...dataBankExtend };
  return dataBank;
};

export const dispatchVmEvents = (eventList, options) => {
  if (!isArr(eventList)) return;
  const { dataBankExtend } = options ?? {};

  eventList.forEach((vmEvent) => {
    const val = mapData(
      enrichDataBank({ vmFunctions }, dataBankExtend),
      vmEvent,
      null,
      {
        dataMappingVmType: vmTypes.dataMappingEvent
      }
    );
    if (
      val?.func?.vmFunctionMetaData?.isReduxAction
      ?? val?.func?.isReduxAction
    ) {
      store.dispatch(val.func(...val.args));
    }
  });
};

export const useDispatchVmEvent = () => {
  const dispatch = useDispatch();

  return (vmEvent, dataBank) => {
    const val = mapData(dataBank, vmEvent);
    // If dataMap points to callable function, then mapData already makes a call to this function.
    // If dataMap returns an action, we need to dispatch it:
    if (
      val?.func?.vmFunctionMetaData?.isReduxAction
      ?? val?.func?.isReduxAction
    ) {
      dispatch(val.func(...val.args));
    }
  };
};

export const useDispatchVmEventList = () => {
  const dispatchVmEvent = useDispatchVmEvent();

  return (vmEventList, dataBank) => vmEventList?.forEach((vmEventItem) => dispatchVmEvent(vmEventItem, dataBank));
};
