import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeOverlayer = (componentStyles, theme) => `
  .jme-overlayer-wrapper {
    position: fixed;
    width: 100%;
    height: 100%; 
    top: 0;
    left: 0;
    background-color: #00000063;
    z-index: 10;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .jme-overlayer-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    width: fit-content;
    flex-direction: column;
    background-color: #FFFFFF;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    ${componentStylesToCss(componentStyles?.modal)}
  }
  
  .jme-overlayer-closeButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: end;
    position: absolute;
    top: calc(-48px - 16px);
    ${componentStylesToCss(componentStyles?.closeButtonWrapper)}
  }

  .jme-overlayer-button {
    color: #FFFFFF;
    border-radius: 4px;
    width: 48px;
    height: 48px;
    font-size: 21px;
    border: 3px solid #FFFFFF;
    background-color: #00000000;
    display: flex;
    justify-content: center;
    align-items: center;
    ${componentStylesToCss(componentStyles?.button)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}${
  theme.breakpoints.unit
}) {
      .jme-overlayer-modal {
        width: 100%;
      }
    }
  `;

export default stylesJmeOverlayer;
