import { componentStylesToCss } from '../componentStylesUtils';

const stylesMediaSelect = (componentStyles) => `
  .jmeMediaSelect-wrapper {
    flex-grow: 1;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .jmeMediaSelect-item {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    ${componentStylesToCss(componentStyles?.item)}
  }

  .jmeMediaSelect-item.selected {
    font-weight: 700;
    background-color: #C2C2C2;
    ${componentStylesToCss(componentStyles?.itemSelected)}
  }
`;

export default stylesMediaSelect;
