import { setContrast } from '../../components/componentsUtils';
import { componentStylesToCss } from '../componentStylesUtils';

const stylesCategorySquare = (componentStyles, theme) => `
  .categorySquare-wrapper {
    position: relative;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    padding-bottom: 50%;
    padding-top: 50%;
    border-radius: 8px;
    background-color: pink;
    color: ${setContrast('pink')};
    border: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .categorySquare-squareContent {
    position: absolute;
    ${componentStylesToCss(componentStyles?.squareContent)}
  }
  .categorySquare-iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    ${componentStylesToCss(componentStyles?.iconWrapper)}
  }
  .categorySquare-icon {
    ${componentStylesToCss(componentStyles?.icon)}
  }
  .categorySquare-text {
    padding: ${theme.spacing(2)} ${theme.spacing(2)};
    font-weight: ${theme.fonts.fontWeightMedium};
    word-break: break-word;
    -ms-word-break: break-word;
    font-size: 16px;
    ${componentStylesToCss(componentStyles?.text)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.lg}${theme.breakpoints.unit}) {
    .categorySquare-text {
    }
  }
`;

export default stylesCategorySquare;
