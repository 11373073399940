import PropTypes from 'prop-types';
import { stylesPropType, vmPropTypes } from '../../global-prop-types';

const Audio = (props) => {
  const {
    src, styles
  } = props;
  if (!src) return null;
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio src={src} style={styles?.wrapper} controls controlsList="nodownload" autoPlay />
  );
};

Audio.propTypes = {
  styles: stylesPropType,
  src: PropTypes.string
};

Audio.defaultProps = {
  styles: {
    wrapper: { }
  },
  src: '',
};

Audio.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  src: vmPropTypes.string,
};

export default Audio;
