export const STORE_MASK_GROUPS_POST_CREATE = 'MY_NETWORKING_GROUPS_POST_CREATE';
export const STORE_MASK_GROUPS_POST_DELETE = 'MY_NETWORKING_GROUPS_POST_DELETE';
export const STORE_MASK_GROUPS_POST_LIST_GET = 'MY_NETWORKING_GROUPS_POST_LIST_GET';
export const STORE_MASK_GROUPS_POST_PINNED = 'MY_NETWORKINg_GROUPS_POST_PINNED';
export const STORE_MASK_GROUPS_POST_LIST_PINNED = 'MY_NETWORKINg_GROUPS_POST_LIST_PINNED';
export const STORE_MASK_GROUPS_POST_GET = 'MY_NETWORKINg_GROUPS_POST_GET';
export const STORE_MAST_GROUPS_POST_COMMENT_GET = 'MY_NETWORKINg_GROUPS_POST_COMMENT_GET';

// Types of chatgroup active users:
export const USER_TYPE_OWNER = 'owner';
export const USER_TYPE_MODERATOR = 'moderator';
