import { componentStylesToCss } from '../componentStylesUtils';

const stylesHorizontalScrollable = (componentStyles) => `
  .horizontalScrollable-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .horizontalScrollable-item {
    padding-right: 8px;
    ${componentStylesToCss(componentStyles?.item)}
  }
`;

export default stylesHorizontalScrollable;
