import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeCollapse = (componentStyles, theme) => `
  .jme-collapse-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-collapse-button {
    text-transform: none;
    ${componentStylesToCss(componentStyles?.button)}
  }
  .jme-collapse-button:hover {
    background: inherit;
  }
  .jme-collapse-title {
    ${componentStylesToCss(componentStyles?.title)}
  }
  .jme-collapse-icon {
    margin-right: ${theme.spacing(1)}
    ${componentStylesToCss(componentStyles?.icon)}
  }
  .jme-collapse-content {
    ${componentStylesToCss(componentStyles?.content)}
  }
`;

export default stylesJmeCollapse;
