import React from 'react';
import { componentsPropType } from '../../global-prop-types';
import ComponentsArrRenderer from './ComponentsArrRenderer';

/**
 * This component, is helping modules, to join the core of the app and be everywhere
 */
const RenderCoreComponents = (props) => {
  const { allCompsToCore, ...restProps } = props;
  return (
    <ComponentsArrRenderer {...restProps} components={allCompsToCore} parent={['CORE']} />
  );
};

RenderCoreComponents.propTypes = {
  allCompsToCore: componentsPropType
};

export default RenderCoreComponents;
