import { componentStylesToCss } from '../componentStylesUtils';

const stylesGuidelinesLink = (componentStyles) => `
  .guidelinesLink-wrapper {
    text-decoration: none!important;
    margin-right: 5px;
    padding: 5px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
`;

export default stylesGuidelinesLink;
