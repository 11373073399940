import { componentStylesToCss } from '../componentStylesUtils';

const stylesShowMore = (componentStyles) => `
  .show-more-wrapper {
    position: relative;
    padding: 24px 12px 35px 12px;
    margin-bottom: 24px;
    border: 1px solid #AFB4BB;
    border-radius: 8px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .show-more-box {
    ${componentStylesToCss(componentStyles?.box)}
  }
  .show-more-arrow {
    display: flex;
    width: fit-content;
    z-index: 5;
    padding: 10px 10px;
    border: 1px solid #0BA1E2;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom: 0px;
    background: white;
    transform: translate(-50%, 25px);
    cursor: pointer;
  }
`;

export default stylesShowMore;
