import { componentStylesToCss } from '../componentStylesUtils';

const stylesMediaSearchPanel = (componentStyles) => `
  .InputUrlSearchParamDate-wrapper {
    position: relative;
    width: 100%;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .InputUrlSearchParamDate-input {
    ${componentStylesToCss(componentStyles?.datePicker)}
  }

  .InputUrlSearchParamDate-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 20px;
    font-size: 16px;
    color: #CCCCCC;
  }

  .InputUrlSearchParamDate-calendarClass {
    border: 0px!important;
    background-color: unset!important;
  }

  .InputUrlSearchParamDate-datesHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #0BA1E2;
    justify-content: center;
    height: 100px;
    border-radius: 8px 8px 0px 0px;
  }

  .InputUrlSearchParamDate-datesHeader-text {
    color: #58595B;
    align-items: center;
    margin: 12px;
    justify-content: center;
    background-color: #FCFCFC;
    padding: 7px 22px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    min-width: 150px;
    height: 40px;
    border-radius:4px;
  }

  .InputUrlSearchParamDate-dayStartDay {
    background-color: #0BA1E2;
  }


  /* this is a hack to replace ugly close button of the DatePicker */
  .react-datepicker__close-icon::after {
    right: 10px;
    content: '\\f00d'!important;
    background-color: unset!important;
    font-size: unset!important;
    color: #CCCCCC!important;
  }

  /* Overwrite react-datepicker styles */

  .InputUrlSearchParamDate-calendarClass .react-datepicker__current-month {
    display:none;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__header__dropdown {
    margin-bottom:1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__month-read-view {
    margin-right:3.5rem;
    font-size: 18px;
    font-weight:700;
    color:#58595B;
    visibility:visible !important;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__year-read-view{
    visibility:visible !important;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__year-dropdown-container {
    position:relative;
    margin-left:3.5rem;
    font-size: 18px;
    font-weight:700;
    color:#58595B;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__month-read-view--down-arrow {
    top:7px;
    border-color:#58595B;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__year-read-view--down-arrow {
    top:7px;
    border-color:#58595B;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__month-dropdown {
    position: absolute;
    top:2.5rem;
    left: 1rem;
    background: #FCFCFC;
    border:none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size:14px;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__year-dropdown {
    position: absolute;
    top:2.5rem;
    left: -1.5rem;
    background: #FCFCFC;
    border:none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size:14px;
    min-width:100px;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__month-option {
    padding: 5px 10px;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__year-option {
    padding: 5px 10px;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__month-option:hover{
    background: #0BA1E2;
    color: #FCFCFC;
    font-size:16px;
    font-weight:700;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__year-option:hover{
    background: #0BA1E2;
    color: #FCFCFC;
    font-size:16px;
    font-weight:700;
  }



   .InputUrlSearchParamDate-calendarClass .react-datepicker__header,
   .InputUrlSearchParamDate-calendarClass .react-datepicker__month,
  .InputUrlSearchParamDate-calendarClass .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
     background-color: #FCFCFC;
     border-radius: 0px;
     border: 0px;
     margin: 0px;
     width:400px;
   }


   .InputUrlSearchParamDate-calendarClass .react-datepicker__navigation-icon::before {
    border-color: #0BA1E2;
    top: 16px;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__navigation-icon--previous {
    left:5px;
  }

   .InputUrlSearchParamDate-calendarClass .react-datepicker__day--keyboard-selected {
     background-color: #0BA1E2;
  }

   .InputUrlSearchParamDate-calendarClass .react-datepicker__day--in-selecting-range {
    border-radius: 0px;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__day-names {
    margin:0;
    font-size:18px;
    font-weight:400;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__month {
    font-size:18px;
    font-weight:400;
  }


  .InputUrlSearchParamDate-calendarClass react-datepicker__day--selecting-range-start {
    border-radius: 50% 0 0 50%;
    background-color: #0DB1F8;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__day--in-range {
    background-color: #0BA1E2;
    border-radius: 0px;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__day--range-start {
    border-radius: 50% 0 0 50%;
    background-color: #0DB1F8;
  }

  .InputUrlSearchParamDate-calendarClass .react-datepicker__day--range-end {
    border-radius: 0 50% 50% 0;
    background-color: #0DB1F8;
  }

  .InputUrlSearchParamDate-calendarClass  .react-datepicker__day-name, 
  .InputUrlSearchParamDate-calendarClass .react-datepicker__day, 
  .InputUrlSearchParamDate-calendarClass  .react-datepicker__time-name {
    margin:0;
  }


`;

export default stylesMediaSearchPanel;
