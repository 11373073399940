import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeBackButton = (componentStyles, theme) => `
  .jme-backButton-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: ${theme.fonts?.fontWeightMedium};
    text-transform: none;
    color: ${theme.colors.primary?.main};
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-backButton-wrapper i {
    ${componentStylesToCss(componentStyles?.icon)}
  }
  .jme-backButton-icon {}
`;

export default stylesJmeBackButton;
