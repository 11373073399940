import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoggedInUser } from '../../global-utils';

/* Implementation:
   we should add show if access_for_user in the component level
   and inside the component (ex. PresentationPageAccess) we call this (IsUserLogged) component.

  example:
   {
    "componentName": "PresentationPageAccess",
    "showIf": ["$(props.apiData.medialib_presentation_single.data.access_for_user)"],
   }
   PresentationPageAccess:
   first component will be:
    {
    "componentName": "IsUserLogged",
    "noAccessPath": "/auth/login"
  }
*/

const IsUserLogged = (props) => {
  const { noAccessPath } = props;
  const { id: userId } = useLoggedInUser() ?? {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      navigate(noAccessPath, { replace: true });
    }
  }, []);

  return null;
};

IsUserLogged.propTypes = {
  noAccessPath: PropTypes.string,
};

export default IsUserLogged;
