import { componentStylesToCss } from '../componentStylesUtils';

const stylesImageComp = (componentStyles) => `
  .imageComp-wrapper {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
`;

export default stylesImageComp;
