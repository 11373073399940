import { componentStylesToCss } from '../componentStylesUtils';

const stylesSidebarMenuItem = (componentStyles) => `
  .sidebarMenuItem-wrapper {
    display: flex;
    cursor: pointer;
    padding: .3rem 1rem;
    -webkit-tap-highlight-color: transparent;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .sidebarMenuItem-any {
    color: inherit;
    background-color: transparent;
    text-align: initial;
    text-decoration: none;
    border: none;
    align-items: center;
    display: flex;
  }
  .sidebarMenuItem-summary {
    font-size: 18px;
    ${componentStylesToCss(componentStyles?.summary)}
  }
  .sidebarMenuItem-item {
    font-size: 16px;
    ${componentStylesToCss(componentStyles?.item)}
  }
  .sidebarMenuItem-subItem {
    font-size: 14px;
    ${componentStylesToCss(componentStyles?.subItem)}
  }
  .sidebarMenuItem-extandIcon {
    text-align: end;
    ${componentStylesToCss(componentStyles?.summary)}
  }
  .sidebarMenuItem-disabledItem {
    font-size: 14px;
    ${componentStylesToCss(componentStyles?.disabledItem)}
  }
`;

export default stylesSidebarMenuItem;
