import { isStr, isArr } from '../../globalUtils';
import { vmTypes } from '../../../global-prop-types/vmTypes';

export const getRegisterEventIdFromUrl = () => {
  const id = window.location.pathname.split('/')[2];
  return id;
};

export const split = (str, splitOn) => (isStr(str) && isStr(splitOn) ? str.split(splitOn) : str);
split.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'str', vmType: vmTypes.string },
    { uiTitle: 'splitOn', vmType: vmTypes.string }
  ]
};

export const slice = (str, start, end) => (isStr(str) ? str.slice(start, end) : str);
slice.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'str', vmType: vmTypes.string },
    { uiTitle: 'start', vmType: vmTypes.number },
    { uiTitle: 'end', vmType: vmTypes.number }
  ]
};
export const startsWith = (str, startWith) => (isStr(str) && isStr(startWith) ? str.startsWith(startWith) : str);
startsWith.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'str', vmType: vmTypes.string },
    { uiTitle: 'startWith', vmType: vmTypes.string }
  ]
};
export const toUpperCase = (str) => (isStr(str) ? str.toUpperCase() : str);
toUpperCase.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [{ uiTitle: 'text', vmType: vmTypes.string }]
};

export const toLowerCase = (str) => (isStr(str) ? str.toLowerCase() : str);
toLowerCase.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [{ uiTitle: 'text', vmType: vmTypes.string }]
};

export const replace = (str, strToReplace, strForReplace) => (isStr(str) && isStr(strToReplace) && isStr(strForReplace)
  ? str.replace(strToReplace, strForReplace)
  : str);
replace.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'str', vmType: vmTypes.string },
    { uiTitle: 'String to replace', vmType: vmTypes.string },
    { uiTitle: 'Replace with this string', vmType: vmTypes.string }
  ]
};
export const replaceAll = (str, strToReplace, strForReplace) => (isStr(str) && isStr(strToReplace) && isStr(strForReplace)
  ? str.replaceAll(strToReplace, strForReplace)
  : str);
replaceAll.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'str', vmType: vmTypes.string },
    { uiTitle: 'String to replace', vmType: vmTypes.string },
    { uiTitle: 'Replace with this string', vmType: vmTypes.string }
  ]
};

export const join = (args, seperator = ' ') => args.join(seperator);
join.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'Text list', vmType: vmTypes.array },
    { uiTitle: 'Seperator', vmType: vmTypes.string }
  ]
};

export const joinWithFilter = (args, seperator = ' ') => {
  const filteredArgs = args.filter((item) => item !== '' && item !== undefined && item !== null);
  return filteredArgs.join(seperator);
};
joinWithFilter.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'Text list', vmType: vmTypes.array },
    { uiTitle: 'Seperator', vmType: vmTypes.string }
  ]
};

export const zeroPad = (num, places) => {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
};

export const concat = (strList) => (isArr(strList) ? strList.join('') : '');
concat.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [{ uiTitle: 'Array of strings', vmType: vmTypes.array }]
};

export const equals = (arg1, arg2) => String(arg1) === String(arg2);

equals.vmFunctionMetaData = {
  returnVmType: vmTypes.boolean,
  argsInfo: [
    { uiTitle: 'String 1', vmType: vmTypes.string },
    { uiTitle: 'String 2', vmType: vmTypes.string }
  ]
};

export const notEquals = (arg1, arg2) => String(arg1) !== String(arg2);

export const collapse = (str, maxLength) => (str.length > maxLength ? `${str.substring(0, maxLength)}...` : str);

export const convertStringFromAppSettings = (replacementTexts = [], str) => {
  const newText = replacementTexts.find(
    ({ textToReplace }) => textToReplace === str
  )?.newText;
  return (
    newText
    || replacementTexts.find(({ textToReplace }) => textToReplace === 'News')
      ?.newText
  ); // News is default
};
convertStringFromAppSettings.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [
    { uiTitle: 'replacementTexts', vmType: vmTypes.array },
    { uiTitle: 'str', vmType: vmTypes.string }
  ]
};

export const encodeUri = (str) => encodeURI(str);
encodeUri.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [{ uiTitle: 'string to encode to URI', vmType: vmTypes.string }]
};

export const includes = (str, searchPhrase, isCaseInsensitive) => {
  if (!isStr(str)) return true; // pass all test cases untill it is a string
  if (!isStr(searchPhrase)) return true; // pass all test cases untill it is a string

  return isCaseInsensitive
    ? str.toLowerCase().includes(searchPhrase.toLowerCase())
    : str.includes(searchPhrase);
};

includes.vmFunctionMetaData = {
  returnVmType: vmTypes.boolean,
  argsInfo: [
    {
      uiTitle: 'string to search in',
      vmType: vmTypes.string
    },
    {
      uiTitle: 'string to search for',
      vmType: vmTypes.string
    },
    {
      uiTitle: 'set to do case insensitive search',
      vmType: vmTypes.boolean
    }
  ]
};

export const removeSpacesAndSpecialCharacters = (str) => str?.replace(/[^A-Z0-9]+/ig, '_');
