import { lazy } from 'react';

export const VmInput = lazy(() => import('./VmInput'));
export const VmInput2 = lazy(() => import('./VmInput2'));
export const VmInput3 = lazy(() => import('./VmInput3'));
export const VmSelect = lazy(() => import('./VmSelect'));
export const VmSelect2 = lazy(() => import('./VmSelect2'));
export const VmSelectWithoutClientData = lazy(() => import('./VmSelectWithoutClientData'));
export const VmTextarea = lazy(() => import('./VmTextarea'));
export const VmTextarea2 = lazy(() => import('./VmTextarea2'));
export const VmTextarea3 = lazy(() => import('./VmTextarea3'));
export const VmFilesUpload = lazy(() => import('./vm-files-upload'));
export const VmInputDateTime3 = lazy(() => import('./VmInputDateTime3'));
export const VmHtmlInput = lazy(() => import('./vm-html-input'));
export const Switch = lazy(() => import('./Switch'));
export const Checkbox = lazy(() => import('./Checkbox'));
export const LocationSearch = lazy(() => import('./location-search'));
export const VmAsyncSelect = lazy(() => import('./VmAsyncSelect'));
