import { coerceToObj } from '../myNetworkingUtils';
import { isUserInvited } from './communityUserInvitations';
import { isUserRequested } from './communityUserRequests';
import { userIsNeutral } from './communityUsers';

const NO_CHAT = 'NO_CHAT';
const HAS_CHAT = 'HAS_CHAT';
const REQUEST_PENDING = 'REQUEST_PENDING';
const INVITATION_PENDING = 'INVITATION_PENDING';
const MYSELF_DELETED = 'MYSELF_DELETED';

// IMPORTANT
// 1. the chatObj can be undefined if there is not chat relation estabilished
// 2. some values can be null which fall into the category falsy, not necessery the same as
//    if value is set to false!
const getChatState = (chatObj) => {
  const {
    myself_blocked: myselfBlocked,
    myself_deleted: myselfDeleted,
    partner_blocked: partnerBlocked
  } = coerceToObj(chatObj);

  // if any of the properties is undefined, that means there is no chat in the chat array
  if (
    [myselfBlocked, myselfDeleted, partnerBlocked].some(
      (el) => el === undefined
    )
  ) return NO_CHAT;

  // if value is set to null, we treat it as if it was set to null. This can happen when
  // chats were created outside vm2 which sets it to false.
  if (myselfDeleted) return MYSELF_DELETED;
  if (!myselfBlocked && !partnerBlocked) return HAS_CHAT;
  if (!myselfBlocked && partnerBlocked) return REQUEST_PENDING;
  if (myselfBlocked && !partnerBlocked) return INVITATION_PENDING;
  return NO_CHAT;
};

export const noChat = (chatObj) => getChatState(chatObj) === NO_CHAT;
export const hasChat = (chatObj) => getChatState(chatObj) === HAS_CHAT;
export const isChatRequestPending = (chatObj) => getChatState(chatObj) === REQUEST_PENDING;
export const isChatInvitationPending = (chatObj) => getChatState(chatObj) === INVITATION_PENDING;
export const myselfDeleted = (chatObj) => getChatState(chatObj) === MYSELF_DELETED;
