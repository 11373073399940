import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicPageRenderer from '../components/core/DynamicPageRenderer';
import { apiRequestResetAll } from '../global-state/redux/actions';
import { vmRefProcessorApp } from '../global-utils';

const TestFlightPage = (props) => {
  const { testFlightPageInfo: tfpInfo, testFlightPageParams: tfpParams, core } = useSelector((state) => state.appState);
  const dispatch = useDispatch();

  const testFlightPageInfo = useMemo(() => vmRefProcessorApp.resolve(tfpInfo), [tfpInfo]);
  const testFlightPageParams = useMemo(() => vmRefProcessorApp.resolve(tfpParams), [tfpParams]);

  useEffect(() => {
    // On core change, we wish to reset the api data - to get fresh data.
    dispatch(apiRequestResetAll());
  }, [core]);

  if (!testFlightPageInfo) {
    // console.log('No Test flight page');
    return 'No such Page...';
  }
  if (testFlightPageInfo.route.path.indexOf(':') > 0 - 1) {
    if (testFlightPageParams.some((elm) => elm.value === '') || testFlightPageParams.length === 0) {
      return (
        <div className="p3 m3">
          TestFlightPage: missing page parameters
        </div>
      );
    }
  }

  const pageParams = testFlightPageParams.reduce((acc, el) => ({ ...acc, [el.title]: el.value }), {});

  return (
    <DynamicPageRenderer {...props} pageParams={pageParams} pageName={testFlightPageInfo.pageName} {...testFlightPageInfo} />
  );
};

export default TestFlightPage;
