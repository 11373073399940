import { componentStylesToCss } from '../componentStylesUtils';

const stylesAnyButton = (componentStyles) => `
  .jme-collapse-wrapper {
    position: relative;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }

  .jme-collapse-showAll-wrapper {
  }

  .jme-collapse-showAll-extendable {
  }

  .jme-collapse-showAll-button {
    position: absolute;
    left: 42%;
  }

  .jme-collapse-showAll-icon,
  .jme-collapse-showAll-icon:focus,
  .jme-collapse-showAll-icon:hover {
    border: solid 2px #E35205 !important;
    border-radius: 50% !important;
    box-shadow: none !important;
    color: #E35205 !important;
    background-color: #fff !important;
  }
  .jme-collapse-showAll-label {
    color: #E35205;
    font-size: 18px;
    font-weight: 600;
  }
`;

export default stylesAnyButton;
