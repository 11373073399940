import { z } from 'zod';
import { DEBOUNCE_INTERVAL } from './constants';

const sourceApplicationActionSchema = z
  .enum([
    'vmx-page',
    'vmx-page-program',
    'vmx-page-profile',
    'vmx-imageMap-link',
    'vmx-page-profile-symposium',
    'vmx-page-profile-exhibitor',
    'vmx-profile-banner',
    'vmx-page-profile-externallink',
    'vmx-page-profile-email',
    'vmx-page-profile-social',
    // Backward compatibility:
    'vmx-page-leave', // ERA
    'vmx-floorplan-action'
  ])
  .nullable()
  .default(null);

const sourceApplicationSchema = z.string().default('vmx');
const entityIdSchema = z.union([z.string(), z.number()]).optional();
const sourceApplicationContextSchema = z
  .string()
  .min(1)
  .nullable()
  .default(null);

export const trackEventSchema = z.object({
  sourceApplicationAction: sourceApplicationActionSchema,
  sourceApplication: sourceApplicationSchema,
  sourceApplicationContext: sourceApplicationContextSchema,
  entityId: entityIdSchema,
  pageTitle: z.string().optional(),
  sourceApplicationUrl: z.string().url().optional()
});

export const dataInsightSchema = z.object({
  debounceInterval: z.preprocess((val) => {
    const n = Number(val);
    if (Number.isNaN(n)) return DEBOUNCE_INTERVAL;
    if (n < 0) return DEBOUNCE_INTERVAL;
    return val;
  }, z.number()),

  authKey: z.preprocess((val) => {
    if (typeof val === 'string') return val;
    return null;
  }, z.string().nullable())
});
