import PropTypes from 'prop-types';
import { componentsPropType, vmPropTypes as vmTypes } from '../../global-prop-types';
import ComponentsArrRenderer from './ComponentsArrRenderer';

const DynamicContentLooper = (props) => {
  /* Itterate over contentList and render components assigned to each contentList item.

  The link between content item and components to render is
  contentListItem.lable <=> contentComponents[label]. */
  const { contentList, contentComponents, ...restProps } = props;

  // Run through the content list and map it to an array of componenets
  // Also, add to each component relevant content data as contentItemData.
  const components = contentList.reduce(
    (acc, contentItem) => acc.concat(
      contentComponents[contentItem.label]?.map((component, idx) => ({
        ...component,
        id: idx,
        contentItemData: contentItem.data,
      })) ?? []
    ),
    []
  );

  return <ComponentsArrRenderer components={components} {...restProps} />;
};

DynamicContentLooper.propTypes = {
  contentList: PropTypes.arrayOf(PropTypes.shape()),
  contentComponents: PropTypes.objectOf(componentsPropType),
};

DynamicContentLooper.defaultProps = {
  contentList: [],
  contentComponents: {},
};

DynamicContentLooper.vmPropTypes = {
  contentList: vmTypes.array,
};

export default DynamicContentLooper;
