import PropTypes from 'prop-types';
import { componentsPropType, vmPropTypes } from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';

const JmeIfElse = (props) => {
  const {
    vmCondition, ifComponents, elseComponents, ...restProps
  } = props;

  const components = vmCondition ? ifComponents : elseComponents;
  return <ComponentsArrRenderer components={components} {...restProps} />;
};

JmeIfElse.propTypes = {
  vmCondition: PropTypes.any,
  ifComponents: componentsPropType,
  elseComponents: componentsPropType,
};

JmeIfElse.defaultProps = {
  vmCondition: false,
  ifComponents: [],
  elseComponents: [],
};

JmeIfElse.vmPropTypes = {
  vmCondition: vmPropTypes.boolean,
  ifComponents: vmPropTypes.components,
  elseComponents: vmPropTypes.components
};

export default JmeIfElse;
