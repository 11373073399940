import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmeDivider = (componentStyles) => `
  .jme-divider-wrapper {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px thin 0px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    height: auto;
    align-self: stretch;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
`;

export default stylesJmeDivider;
