import React from 'react';
import PropTypes from 'prop-types';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../../global-prop-types';
import ComponentsArrRenderer from '../../core/ComponentsArrRenderer';

const GridRow = (props) => {
  const {
    nbOfColumns,
    parent,
    variant,
    styles,
    ...restProps
  } = props;

  const numberOfColumnsMapper = () => (
    (nbOfColumns && Object.keys(nbOfColumns).length)
      ? Object.keys(nbOfColumns)
        // ?.map((el) => (nbOfColumns[el] ? `row-cols-${el === 'xs' ? '' : el}-${nbOfColumns[el]}` : ''))
        // eslint-disable-next-line prefer-template
        ?.map((el) => (nbOfColumns[el] ? ('row-cols-' + (el === 'xs' ? '' : (el + '-')) + nbOfColumns[el]) : ''))
        ?.join(' ')
      : ''
  );

  return (
    <div
      className={`row ${numberOfColumnsMapper()} ${variant || ''}`}
      style={styles?.wrapper}
    >
      <ComponentsArrRenderer
        {...restProps}
        parent={[...parent, 'GridRow']}
      />
    </div>
  );
};

GridRow.propTypes = {
  nbOfColumns: PropTypes.shape({
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
  }),
  variant: PropTypes.string,
  parent: PropTypes.array,
  styles: stylesPropType,
  components: componentsPropType,
};

GridRow.vmPropTypes = {
  nbOfColumns: vmPropTypes.columnSizesPerBreakpoint,
  variant: vmPropTypes.string,
  styles: { wrapper: vmPropTypes.styles },
  components: vmPropTypes.components,
};

GridRow.defaultProps = {
  variant: '',
  nbOfColumns: {},
  styles: {
    wrapper: {}
  },
  components: [],
};

GridRow.vmPreview = {
  styles: { wrapper: { border: '2px solid black' } },
  components: [
    {
      componentName: 'JmeText',
      text: 'Grid Row display, number of columns can be changed'
    }
  ]
};

export default GridRow;
