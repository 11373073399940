import useShallowEqualSelector from '../components/core/useShallowEqualSelector';

export const useAddonSettings = (addonKey) => {
  const addonSettings = useShallowEqualSelector(
    (state) => state.appState.settings?.[addonKey]
  );

  return addonSettings;
};

export const useIsActive = (addonKey) => !!useAddonSettings(addonKey)?.isActive;
