import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import PageRenderer from '../components/core/PageRenderer';
import { componentsPropType } from '../global-prop-types';

// TODO: Guy make handler list global

// TODO: Guy forward to the page renderer the query string
const CoursesPage = (props) => (
  <PageRenderer
    urlParams={useParams()}
    {...props}
  />
);

CoursesPage.propTypes = {
  components: componentsPropType,
  apiDataInfo: PropTypes.array,
};

CoursesPage.defaultProps = {
  components: [],
};
export default CoursesPage;
