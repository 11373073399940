import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import { componentsPropType, stylesPropType, vmPropTypes } from '../../../global-prop-types';
import CarouselButtons from './CarouselButtons';
import responsive from './carouselUtils';
import 'react-multi-carousel/lib/styles.css';
import DynamicCompRenderer from '../../core/DynamicCompRenderer';

const CarouselComp = (props) => {
  const {
    parent,
    components,
    arrows,
    customArrows,
    arrowsBottom,
    showDots,
    numOfItemsToShow,
    styles,
    variant,
    ...restProps
  } = props;

  if (!components) return null;

  return (
    <div className={`${variant || ''}`} style={styles?.wrapper}>
      <Carousel
        responsive={responsive(components, numOfItemsToShow)}
        arrows={arrows}
        draggable={false}
        showDots={showDots}
        infinite
        swipeable
        customButtonGroup={customArrows ? <CarouselButtons arrowsBottom={arrowsBottom} /> : ''}
        itemClass="carouselComp-itemWrapper"
      >
        {components.map((carouselItemComp, idx) => (
          <div className="carouselComp-item" key={carouselItemComp.key ?? carouselItemComp.id ?? idx} style={styles?.item}>
            <DynamicCompRenderer {...restProps} {...carouselItemComp} parent={[...parent, idx]} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

CarouselComp.propTypes = {
  variant: PropTypes.string,
  parent: PropTypes.array,
  styles: stylesPropType,
  components: componentsPropType,
  arrows: PropTypes.bool,
  customArrows: PropTypes.bool,
  arrowsBottom: PropTypes.bool,
  showDots: PropTypes.bool,
  numOfItemsToShow: PropTypes.shape({
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number
  })
};

CarouselComp.defaultProps = {
  variant: '',
  components: [],
  arrows: false,
  customArrows: true,
  arrowsBottom: false,
  showDots: true,
  numOfItemsToShow: {
    xl: 3, lg: 4, md: 3, sm: 2, xs: 1
  },
};

CarouselComp.vmPreview = {
  components: [
    {
      componentName: 'ImageComp',
      id: 1
    },
    {
      componentName: 'ImageComp',
      id: 2
    },
    {
      componentName: 'ImageComp',
      id: 3
    },
    {
      componentName: 'ImageComp',
      id: 4
    }
  ]
};

CarouselComp.vmPropTypes = {
  variant: vmPropTypes.className,
  components: vmPropTypes.components,
  arrows: vmPropTypes.boolean,
  customArrows: vmPropTypes.boolean,
  arrowsBottom: vmPropTypes.boolean,
  showDots: vmPropTypes.boolean,
  numOfItemsToShow: vmPropTypes.object
};

export default CarouselComp;
