import { componentStylesToCss } from '../componentStylesUtils';

const stylesAnyTypography = (componentStyles) => `
  .jme-text-wrapper {
    word-break: break-word;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-text-h1 {
    text-transform: none;
    font-weight: 700;
    font-size: 28px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-text-h2 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 28px;
    margin: 1rem 0 1rem 0;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-text-h3 {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-text-h4 {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-text-h5 {
    font-weight: 400;
    font-size: 1rem;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-text-h6 {
    font-weight: 700;
    font-size: 1rem;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jme-text-p {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
`;

export default stylesAnyTypography;
