import { componentStylesToCss } from '../componentStylesUtils';

const stylesShareButton = (componentStyles) => `
  .share-button-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .dropdown {
    list-style: none;
    display: inline-block;
  }
  .dropdown-button {
    border: none;
    background: transparent;
    padding: 0px;
  }
  .dropdown-menu {
    list-style: none;
    min-width: unset;
  }
  .dropdown-menu.show {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 56px;
    transform: translate3d(-2px, 53px, 0px) !important;
  }
  .dropdown-item {
    padding: 0px;
    width: fit-content;
    margin-bottom: 8px;
  }
  .dropdown-item:last-child {
    margin-bottom: 0px !important;
  }
  .dropdown-item:hover {
    background: transparent;
  }
`;

export default stylesShareButton;
