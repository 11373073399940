/**
 * Hook that sets and access clientData.
 * It is important to keep data that an admin user should have an accest to under the
 * clientData in redux. This way we can ease the naviagtion to the data in the GUI on
 * the admin side.
 *
 * TODO: go throught the application and consistently use these hooks to set and access
 *   client data.
 */

import { useDispatch } from 'react-redux';
import useShallowEqualSelector from '../components/core/useShallowEqualSelector';
import { setClientData, deleteClientData } from '../global-state/redux/actions';

// eslint-disable-next-line import/prefer-default-export
export const useClientData = (clientDataKey) => {
  const dispatch = useDispatch();

  const clientDataVal = useShallowEqualSelector(
    (store) => store?.appState?.clientData?.[clientDataKey]
  );

  const setClientDataVal = (newVal) => {
    dispatch(setClientData(clientDataKey, newVal));
  };

  const deleteClientDataVal = () => {
    dispatch(deleteClientData([clientDataKey]));
  };

  return [clientDataVal, setClientDataVal, deleteClientDataVal];
};
