import store from '../../../global-state/redux';
import { apiFavourtesDelete, apiFavourtesGet, apiFavourtesPost } from '../../../components/favourites/favourites-api';

export const getFavored = () => {
  const state = store.getState();
  const { isActive, settings } = state.appState.settings?.modules?.addToFavouritesModule;
  if (!isActive) return null;

  const { callName } = settings ?? {};
  return state.data[callName]?.data ?? [];
};

const isOfType = (type) => (el) => el.favouriteable_type === type;
export const getFavoredArticles = () => getFavored().filter(isOfType('Article'));
export const getFavoredWebinars = () => getFavored().filter(isOfType('Webinar'));
export const getFavoredCourses = () => getFavored().filter(isOfType('Course'));
export { apiFavourtesPost, apiFavourtesDelete, apiFavourtesGet };
