import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Loading } from '../components';
import { useIsUserLoggedIn } from '../global-utils';
import { ocaLogin } from '../global-state/redux/actions/app-state-actions';

const OcaPage = () => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ocaLogin(params));
  }, []);

  if (!isUserLoggedIn) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Loading />
      </div>
    );
  }
  return null;
};

export default OcaPage;
