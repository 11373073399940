import { lazy } from 'react';

// Buttons
// export const JmeButton = lazy(() => import('./JmeButton'));
export const JmeBackButton = lazy(() => import('./JmeBackButton'));
export const DownloadIcsFileButton = lazy(() => import('./DownloadIcsFileButton'));
export const JmePlusMinusButtonActive = lazy(() => import('./JmePlusMinusButtonActive'));
export const JmeBreadcrumbs = lazy(() => import('./JmeBreadcrumbs'));
export const ButtonDownload = lazy(() => import('./ButtonDownload'));

// Buttons
export { default as JmeButton } from './JmeButton';
// export { default as JmeBackButton } from './JmeBackButton';
// export { default as JmePlusMinusButtonActive } from './JmePlusMinusButtonActive';
// export { default as JmeBreadcrumbs } from './JmeBreadcrumbs';
