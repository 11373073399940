import store from '../../../../../global-state/redux';
import {
  setMyNetworking,
  apiRequestDeleteData
} from '../../../../../global-state/redux/actions';
import { appendId, coerceToObj } from '../../myNetworkingUtils';
import {
  MY_NETWORKING_CHATGROUPTYPE_ID,
  CHATGROUPS_SELECTOR_KEY,
  CHATGROUPS_SELECTOR_KEY_BY_ID
} from './chatgroupConstants';
import {
  myNetworkingApiCall,
  myNetworkingApiStatus
} from '../../myNetworkingApiUtils';
import { getEventId } from '../../../core';
import { getAccessToken, getUserId } from '../../../loginInfo/loginInfo';
import { getChatgroupById, getChatgroups } from './chatgroupSelectors';
import { selectConfirmationEmail } from '../../../../../modules/my-networking';

const { dispatch, getState } = store;

// Constants used to store results of the API response in store:
const STORE_MASK_CHATGROUPS_GET = 'myNetworking/chatgroups/get';
const STORE_MASK_CHATGROUPS_LEAVE = 'myNetworking/chatgroups/leave';
const STORE_MASK_CHATGROUPS_CREATE = 'myNetworking/chatgroups/create';
const STORE_MASK_CHATGROUPS_DELETE = 'myNetworking/chatgroups/delete';
const STORE_MASK_CHATGROUPS_UPDATE = 'myNetworking/chatgroups/update';
const STORE_MASK_CHATGROUPS_USER_DELETE = 'myNetworking/chatgroups/user/delete';
const STORE_MASK_EMAILS_CREATE = 'myNetworking/chatgroups/email/create';

const PATHNAME = '/v1/chatgroups.json';
const getPathname = (id) => `/v1/chatgroups/${id}.json`;

const apiEmail = (props) => {
  const {
    content,
    emailFormId,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);

  const body = {
    access_token: getAccessToken(),
    event_id: getEventId(),
    content,
    email_form_id: emailFormId
  };

  return myNetworkingApiCall({
    method: 'POST',
    storeMask: STORE_MASK_EMAILS_CREATE,
    pathname: '/v1/emails.json',
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  });
};

export const apiGetChatgroups = async (props) => {
  const { onSuccessVmEventList, onErrorVmEventList, skipDebounce } = coerceToObj(props);

  const body = {
    per_page: 999, // get all of the user
    user_id: getUserId(),
    access_token: getAccessToken(),
    event_id: getEventId(),
    chatgrouptype_id: MY_NETWORKING_CHATGROUPTYPE_ID,
    joined_only: 1, // get only joined groups
    is_approved: 1, // fetch only the groups that were approved
    is_hidden: 0, // Show only not deleted groups
    include_tags: 1,
    sort_by: 'last_comment_desc'
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_CHATGROUPS_GET,
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      // save to relevant myNetworking location in redux:
      dispatch(setMyNetworking(CHATGROUPS_SELECTOR_KEY, res.data));
    }

    return res;
  });
};

export const apiCreateGroup = async (props) => {
  const {
    title,
    description,
    isPublic,
    logoUrl,
    tags,
    onSuccessVmEventList,
    onErrorVmEventList
  } = coerceToObj(props);
  const { content, emailFormId } = selectConfirmationEmail(getState());

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    chatgrouptype_id: MY_NETWORKING_CHATGROUPTYPE_ID,
    is_public: isPublic ? 1 : 0,
    logo_url: logoUrl,
    title,
    // description in the API call has to be an object. This confusing pattern
    // was set by networking widget and we `dont-ask-we-do-it`:
    description: JSON.stringify(description),
    is_approved: 0,
    tag_ids: tags?.join() ?? ''
  };

  return myNetworkingApiCall({
    method: 'POST',
    storeMask: STORE_MASK_CHATGROUPS_CREATE,
    pathname: PATHNAME,
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return res;
    return apiEmail({
      emailFormId,
      content: content
        .replace('__title__', title)
        .replace('__description__', description.body)
        .replace('__isPublic__', isPublic ? 'public' : 'private')
    });
  });
};

export const apiGetChatgroupById = async (props) => {
  const {
    groupId: groupIdStr,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  if (!groupId) {
    return Promise.reject(
      new Error('Wrong function arguments. ', { cause: props })
    );
  }

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    include_active_chatgroup_users: 1,
    check_member: 0,
    include_tags: 1,
    is_approved: 1, // fetch only the groups that were approved
    include_manage_admin_user_roles: 1
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: appendId(STORE_MASK_CHATGROUPS_GET, groupId),
    pathname: getPathname(groupId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce
  }).then((res) => {
    if (!res.isDebounced) {
      const selectorKey = appendId(CHATGROUPS_SELECTOR_KEY_BY_ID, groupId);
      dispatch(setMyNetworking(selectorKey, res.data));
    }

    return res;
  });
};

export const apiUpdateGroup = async (props) => {
  const {
    groupId: groupIdStr,
    title,
    description,
    isPublic,
    logoUrl,
    tags,
    onSuccessVmEventList,
    onErrorVmEventList
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  if (!groupId) return Promise.resolve(null);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    chatgrouptype_id: MY_NETWORKING_CHATGROUPTYPE_ID,
    is_public: isPublic ? 1 : 0,
    logo_url: logoUrl,
    title,
    // description in the API call has to be an object. This confusing pattern
    // was set by networking widget and we `dont-ask-we-do-it`:
    description: JSON.stringify(description),
    tag_ids: tags?.join() ?? ''
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: STORE_MASK_CHATGROUPS_UPDATE,
    pathname: getPathname(groupId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    refreshRate: 5000 // allow subsequent API calls
  }).then((res) => {
    if (res.isDebounced) return res;
    const skipDebounce = true;
    return Promise.all([
      apiGetChatgroupById({ groupId, skipDebounce }),
      apiGetChatgroups({ skipDebounce })
    ]);
  });
};

export const apiGetCreateGroupApiStatus = () => myNetworkingApiStatus(STORE_MASK_CHATGROUPS_CREATE);
export const apiGetUpdateGroupApiStatus = () => myNetworkingApiStatus(STORE_MASK_CHATGROUPS_UPDATE);

export const apiCreateGroupDeleteStoreData = () => {
  dispatch(apiRequestDeleteData(STORE_MASK_CHATGROUPS_CREATE));
};

export const apiUpdateGroupDeleteStoreData = () => {
  dispatch(apiRequestDeleteData(STORE_MASK_CHATGROUPS_UPDATE));
};

export const apiDeleteGroup = (props) => {
  const {
    groupId: groupIdStr,
    onSuccessVmEventList,
    onErrorVmEventList
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    id: groupId,
    is_hidden: 1 // deleting group means making it hidden
  };

  myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_CHATGROUPS_DELETE, groupId),
    pathname: getPathname(groupId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return; // Do not perform data updates on debounced API calls

    // remoove the group from mynetworking location of redux:
    dispatch(
      setMyNetworking(
        CHATGROUPS_SELECTOR_KEY,
        getChatgroups().filter((el) => el.id !== groupId)
      )
    );
  });
};

export const apiLeaveGroup = (props) => {
  const {
    groupId: groupIdStr,
    onSuccessVmEventList,
    onErrorVmEventList
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    id: groupId,
    user_deleted: '1' // deleting group means making it hidden
  };

  myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_CHATGROUPS_LEAVE, groupId),
    pathname: getPathname(groupId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return; // Do not perform data updates on debounced API calls

    // remoove the group from myNetworking location of redux:
    dispatch(
      setMyNetworking(
        CHATGROUPS_SELECTOR_KEY,
        getChatgroups().filter((el) => el.id !== groupId)
      )
    );
  });
};

export const apiDeleteUser = (props) => {
  const {
    groupId: groupIdStr,
    userId: userIdStr,
    onSuccessVmEventList,
    onErrorVmEventList
  } = coerceToObj(props);
  const groupId = parseInt(groupIdStr, 10);
  const userId = parseInt(userIdStr, 10);

  if (!groupId || !userId) return;

  const body = {
    user_id: userId,
    access_token: getAccessToken(),
    user_deleted: 1 // Set particular user id as deleted
  };

  myNetworkingApiCall({
    method: 'PUT',
    storeMask: appendId(STORE_MASK_CHATGROUPS_USER_DELETE, userId),
    pathname: getPathname(groupId),
    body,
    onSuccessVmEventList,
    onErrorVmEventList
  }).then((res) => {
    if (res.isDebounced) return; // Do not perform data updates on debounced API calls

    // Remove the user from group data:
    const chatgroupOld = getChatgroupById(groupId);
    const { active_chatgroup_users: activeChatgroupUsersOld = [] } = chatgroupOld;
    const activeChatgroupUsersNew = activeChatgroupUsersOld.filter(
      (el) => el.user.id !== userId
    );

    const chatgroupNew = {
      ...chatgroupOld,
      active_chatgroup_users: activeChatgroupUsersNew,
      active_chatgroup_users_count: activeChatgroupUsersNew.length
    };

    const selectorKey = appendId(CHATGROUPS_SELECTOR_KEY_BY_ID, groupId);
    dispatch(setMyNetworking(selectorKey, chatgroupNew));
  });
};
