// This is different than appTimezoned functions. It formats the now timestamp
// in the UTC not in the app timezone. This it needed, because Chen when compating dates
// he compares the steings not time objects. So the app timezoned string and UTC timezoned
// string are different.
import { formatInTimeZone } from 'date-fns-tz';
import { vmTypes } from '../../../global-prop-types';

// eslint-disable-next-line import/prefer-default-export
export const utcTimezonedNow = (formatStr) => formatInTimeZone(Date.now(), 'UTC', formatStr);
utcTimezonedNow.vmFunctionMetaData = {
  returnVmType: vmTypes.string,
  argsInfo: [{ uiTitle: 'Formatting', vmType: vmTypes.string }]
};
