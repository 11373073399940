import { componentStylesToCss } from '../componentStylesUtils';

const stylesLogoWrapper = (componentStyles, theme) => `
  .logoTitle-wrapper {
    display: flex;
    align-items: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .logoTitle-imgLogo {
    display: block;
    max-width: 100px;
    max-height: 70px;
    ${componentStylesToCss(componentStyles?.imgLogo)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .logoTitle-imgLogo {
      display: none;
    }
  }
  .logoTitle-textLogo {
    display: block;
    max-width: 100px;
    max-height: 40px;
    ${componentStylesToCss(componentStyles?.textLogo)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .logoTitle-textLogo {
      max-width: 81px;
      max-height: 19px;
    }
  }
`;

export default stylesLogoWrapper;
