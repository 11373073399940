import PropTypes from 'prop-types';

const apiDataInfoPropType = PropTypes.arrayOf(
  PropTypes.shape({
    modelName: PropTypes.string,
    apiEndpointName: PropTypes.oneOf(['single', 'list']),
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    body: PropTypes.shape({}),
    refreshRate: PropTypes.number, // Not a must.
  })
);

export default apiDataInfoPropType;
