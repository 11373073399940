import { CHATGROUP_COMMENT } from '../../groups';
import { PIN_BOARD_COMMENT_TYPE } from '../../pinboard';
import {
  apiMarkChannelMessageAsOpened,
  apiGetNotifications
} from './channelMessagesApi';
import { getNotifications } from './channelMessagesSelectors';

export const getHasParent = (el) => el?.content?.community?.has_parent;
export const isPost = (el) => !getHasParent(el);
export const isComment = (el) => !!getHasParent(el);
export const isPostOrComment = (el) => el?.content?.community?.commenttype === CHATGROUP_COMMENT;
export const isGroupInvitation = (el) => !!el?.content?.chatgroupinvitation;
export const isGroupApproved = (el) => !!el?.content?.chatgroup?.is_approved;
export const isChatgroupRequest = (el) => !!el?.content?.chatgrouprequest;
export const isFriendRequestAccepted = (el) => el?.content?.chat
  && !el?.content?.chat?.partner_blocked
  && !el?.content?.chat?.partner_deleted
  && !el?.content?.chat?.myself_blocked
  && !el?.content?.chat?.myself_deleted;
export const isFriendRequest = (el) => el?.content?.chat && !isFriendRequestAccepted(el);
export const isCommentReport = (el) => !!el?.content?.comment_report;
export const isPinboardNotification = (el) => el?.content?.community?.commenttype === PIN_BOARD_COMMENT_TYPE;

export const clearFriendRequests = (fromUserId) => {
  const channelNotifications = getNotifications();
  const idList = channelNotifications
    .filter(
      (el) => isFriendRequest(el) && el.content.chat.partner.id === fromUserId
    )
    .map((el) => el.id);

  return Promise.all(
    idList.map((el) => apiMarkChannelMessageAsOpened({ channelMessageId: el }))
  ).then(() => apiGetNotifications({ refreshRate: 1000 }));
};

export const clearGroupRequests = (groupId) => {
  const channelNotifications = getNotifications();
  const idList = channelNotifications
    .filter(
      (el) => isChatgroupRequest(el)
        && el.content.chatgrouprequest.chatgroup.id === groupId
    )
    .map((el) => el.id);

  return Promise.all(
    idList.map((el) => apiMarkChannelMessageAsOpened({ channelMessageId: el }))
  ).then(() => apiGetNotifications({ refreshRate: 1000 }));
};
