// import { setContrast } from '../components';
import { componentStylesToCss } from '../componentStylesUtils';

const stylesProsCons = (componentStyles, theme) => `
  .prosCons-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .prosCons-column {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-radius: 8px;
    padding: 1.5rem;
    ${componentStylesToCss(componentStyles?.column)}
  }
  .prosCons-headerIcon {
    margin-bottom: 22px;
    ${componentStylesToCss(componentStyles?.icon)}
  }
  .prosCons-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: initial;
    color: ${theme.colors.primary?.main};
    font-size: 16px;
    ${componentStylesToCss(componentStyles?.item)}
  }
  .prosCons-textIcon {
    margin-right: 10px;
    font-size: 16px;
    ${componentStylesToCss(componentStyles?.textIcon)}
  }
  .prosCons-columnPros {
    background-color: #E5FFFF;
    ${componentStylesToCss(componentStyles?.columnPros)}
  }
  .prosCons-headerIconPros {
    color: #069595;
    ${componentStylesToCss(componentStyles?.headerIconPros)}
  }
  .prosCons-columnCons {
    background-color: #FFEDF0;
    ${componentStylesToCss(componentStyles?.columnCons)}
  }
  .prosCons-headerIconCons {
    color: #F26D85;
    ${componentStylesToCss(componentStyles?.headerIconCons)}
  }
`;

export default stylesProsCons;
