const responsive = (items, numOfItemsToShow) => ({
  // the naming can be any, depends on us.
  xl: {
    breakpoint: { max: 4000, min: 3000 },
    items: items.length > numOfItemsToShow.xl ? numOfItemsToShow.xl : items.length
  },
  lg: {
    breakpoint: { max: 3000, min: 1024 },
    items: items.length > numOfItemsToShow.lg ? numOfItemsToShow.lg : items.length
  },
  md: {
    breakpoint: { max: 1024, min: 464 },
    items: items.length > numOfItemsToShow.md ? numOfItemsToShow.md : items.length
  },
  sm: {
    breakpoint: { max: 464, min: 0 },
    items: items.length > numOfItemsToShow.sm ? numOfItemsToShow.sm : items.length
  }
});

export default responsive;
