import { isObj, isStr } from '../../../global-utils';

function applyDefaults(config_) {
  const config = {};
  config.isEventsAirSyncSuccessful = config_.isEventsAirSyncSuccessful === true;
  config.guardedUrl = isStr(config_.guardedUrl) ? config_.guardedUrl : '.*';
  config.accessToken = config_.accessToken;
  config.eventsAirPageUrl = isStr(config_.eventsAirPageUrl)
    ? config_.eventsAirPageUrl
    : '/events-air-auth';
  return config;
}

export const checkRedirectToEventsAirSync = (config_) => {
  const config = isObj(config_) ? config_ : {};
  const {
    guardedUrl,
    eventsAirPageUrl,
    accessToken
  } = applyDefaults(config);
  return (urlStr) => {
    const guardedUrlRe = new RegExp(guardedUrl);
    const eventsAirPageUrlRe = new RegExp(eventsAirPageUrl);
    if (accessToken) return false;
    if (eventsAirPageUrlRe.test(urlStr)) return false;
    if (guardedUrlRe.test(urlStr)) return false;

    return true;
  };
};
