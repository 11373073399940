import { componentStylesToCss } from '../componentStylesUtils';

const stylesJMESendMessageToAuthor = (componentStyles) => `
  .stylesJMESendMessageToAuthor-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
  }
  .stylesJMESendMessageToAuthor-icon {
    font-size: 1rem;
    margin-right: 5px;
  }
`;

export default stylesJMESendMessageToAuthor;
