import { componentStylesToCss } from '../componentStylesUtils';

const stylesQnaContainer = (componentStyles) => `
  .jmeQnaContainer-wrapper {
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .jmeQnaContainer-text {
    font-size: 18px;
    font-weight: 700;
    color: #58595B;
  }
`;

export default stylesQnaContainer;
