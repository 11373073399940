import { componentStylesToCss } from '../componentStylesUtils';

const stylesJmePlusMinusButton = (componentStyles) => `
  .plusMinusButton-wrapper {
    display: flex;
    height: 25px;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  .plusMinusButton-main {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: transparent;
  }
  .plusMinusButton-main:hover{
    background:#232470;
    color: #ffffff !important;
  }
  .plusMinusButton-text {
    font-weight: 600;
    font-size: 1.5em;
  }
  
  .plusMinusButton-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
  }
  .plusMinusButton-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .plusMinusButton-display {
    display: flex;
    min-width: 40%;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: transparent;
  }
`;

export default stylesJmePlusMinusButton;
