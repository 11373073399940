import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import { mapData } from '../../dataMapping';
import vmFunctions from '..';
import {
  getPropsMask,
  getStoreMask,
  ifToDebounceApiCall
} from '../../../global-hooks/useAPIDataHook';
import {
  apiRequestFinished,
  apiRequestProcessor
} from '../../../global-state/redux/actions';
import { vmTypes } from '../../../global-prop-types';
import storageAndCookie from '../../storage-and-cookies';
import store from '../../../global-state/redux';
import { getManageConfig } from '../../../modules/manage-config';

const { getState: tmpGetState } = store;

export const makeApiCall = (apiDataInfo = []) => (dispatch, getState) => {
  const reduxStore = getState();
  const storeData = reduxStore.data;
  const dataBank = { reduxStore, vmFunctions };
  apiDataInfo.forEach((apiDataInfoItem) => {
    const entryReplaced = mapData(dataBank, apiDataInfoItem);
    const propsMask = getPropsMask(entryReplaced);
    const storeMask = getStoreMask(entryReplaced);
    const apiDataInfoItemClone = {
      ...entryReplaced,
      propsMask,
      storeMask
    };
    if (!ifToDebounceApiCall(storeData, apiDataInfoItemClone)) {
      dispatch(apiRequestProcessor(apiDataInfoItemClone));
    } else {
      console.log('Debouncing API call:', storeMask);
      // We made a request to bring API responce from call that was already made.
      // However, the data under the propsMask can coem from a call with different
      // request body. Next line makes sure, we bring data from history to propsMask
      dispatch(apiRequestFinished(propsMask, { ...storeData[storeMask] }));
    }
  });
};
makeApiCall.isReduxAction = true;

export const postProcessApiResponse = (dataMap) => (res) => {
  const data = mapData({ res, vmFunctions }, dataMap, null, {
    dataMappingVmType: vmTypes.postProcessApiResponse
  });
  return { ...res, data };
};

export const apiManageCaller = async (props) => {
  const {
    replaceConfig = {},
    endpoint,
    addToParams = {},
    addToBody = {},
    body,
    method = 'GET'
  } = props;

  const {
    pLanguage, pApiBaseUrl, pEventId, pShort
  } = replaceConfig;

  const {
    instance, apiBaseUrl, eventId, language, eventShort, societyShort
  } = getManageConfig();

  const { accessToken } = JSON.parse(storageAndCookie.get(`${instance}.user`)) ?? {};

  // if module config misses crucial params do not make API call
  // but also do not reject promise, coz we can still continue with tracking
  // without user being logged-in

  // if (!(instance && eventId)) return {};

  // priority to replaceConfig here:
  const newApiBaseUrl = pApiBaseUrl || apiBaseUrl;
  const newEventId = pEventId || eventId;
  const newLanguage = pLanguage || language;
  const newShort = pShort || eventShort;

  let url = `${newApiBaseUrl}/${endpoint}`;

  const queryParams = [];
  const parseObject = (obj) => Object.entries(obj).map(([key, value]) => `${key}=${value}`);
  if (addToParams?.useEventId) queryParams.push(`eventid=${newEventId}`);
  if (addToParams?.useShort) queryParams.push(`eventshort=${newShort}`);
  if (addToParams?.useShortName) queryParams.push(`shortName=${newShort}`);
  if (addToParams?.useSocietyShort) queryParams.push(`societyShort=${newShort}`);
  if (addToParams?.useLanguage) queryParams.push(`language=${newLanguage}`);
  if (addToParams?.additionalQuery) queryParams.push(...parseObject(addToParams.additionalQuery));
  // Combine query parameters into a query string
  if (queryParams.length > 0) url += `?${queryParams.join('&')}`;

  const options = { method, headers: { 'content-type': 'application/json' } };

  if (method !== 'GET') options.body = {};
  if (body && method !== 'GET') options.body = body;
  if (accessToken) options.headers.Authorization = `Bearer ${accessToken}`;
  if (addToBody?.useShort) options.body.eventshort = eventShort;
  if (addToBody?.useShortName) options.body.shortName = eventShort;
  if (addToBody?.useSocietyShort) options.body.societyShort = societyShort;
  if (addToBody?.useLanguage) options.body.language = language;
  if (addToBody?.useEventId) options.body.eventid = parseInt(eventId, 10);
  // Conditionally add headers only if accessToken exists

  // need to stringify body
  if (options.body && Object.keys(options.body)?.length) {
    options.body = JSON.stringify(options.body);
  }

  return fetch(url, options).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(
        new Error(`Error - ${url}`, {
          cause: resp
        })
      );
    }
    return resp.json();
  });
};

export const fetchWebcastToken = () => {
  const fetchingToken = () => {
    const { instance, apiBaseUrl, eventShort } = getManageConfig() ?? {};
    const { accessToken } = JSON.parse(storageAndCookie.get(`${instance}.user`)) ?? {};

    if (!accessToken) return 'No accesstoken';
    const options = {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    const url = `${apiBaseUrl}/api/Core/BearerTokenWebcastAccess?eventshort=${eventShort}`;
    fetch(url, options).then((resp) => {
      if (resp.status) {
        storageAndCookie.set('webcastToken', resp.headers?.get('bearertoken'));
      }
    });
    return storageAndCookie.get('webcastToken');
  };
  const isTokenValid = (token) => {
    if (!token) fetchingToken();

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decoded.exp > currentTime;
    } catch (error) {
      console.error('Token decoding failed, refetching token', error);
      fetchingToken();
    }
  };

  if (isTokenValid(storageAndCookie.get('webcastToken'))) return storageAndCookie.get('webcastToken');
  fetchingToken();
};

export const sessionRecordingCondition = (session) => {
  const sessionRecording = session.SessionRecording;
  if (!sessionRecording) return false;
  if (!session.OnDemandAvailability) return false;
  if (sessionRecording.IsHidden) return false;

  const now = dayjs();

  const availableFromUtc = sessionRecording.AvailableFromUtc
    ? dayjs(sessionRecording.AvailableFromUtc)
    : null;
  const availableUntilUtc = sessionRecording.AvailableUntilUtc
    ? dayjs(sessionRecording.AvailableUntilUtc)
    : null;

  const onDemandAvailability = session.OnDemandAvailability
    ? dayjs(session.OnDemandAvailability)
    : null;

  // Check if OnDemandAvailability is null, return false
  if (onDemandAvailability === null) return false;

  // Check if the current time is before OnDemandAvailability
  if (now.isBefore(onDemandAvailability)) return false;

  // Condition to show video if AvailableFromUtc is null or it's a valid UTC date and after now
  if (availableFromUtc === null || availableFromUtc.isBefore(now)) {
    // Condition to show video if AvailableUntilUtc is null or it's a valid UTC date and before now
    if (availableUntilUtc === null || availableUntilUtc.isAfter(now)) {
      return true;
    }
  }

  // Condition to show video if AvailableFromUtc is null or it's a valid UTC date and after now
  if (availableFromUtc === null || availableFromUtc.isBefore(now)) {
    // Condition to show video if AvailableUntilUtc is null or it's a valid UTC date and before now
    if (availableUntilUtc === null || availableUntilUtc.isAfter(now)) {
      return true;
    }
  }

  // If both AvailableFromUtc and AvailableUntilUtc are valid dates, check if now is between them
  if (availableFromUtc && availableUntilUtc) {
    if (now.isBetween(availableFromUtc, availableUntilUtc, null, '[]')) {
      return true;
    }
  }

  return false;
};

export const sessionRecordingObject = (session) => {
  const sessionRecording = session.SessionRecording;
  if (!sessionRecording) return {};
  return {
    title: session.Name,
    exporttype: 'autoFolder',
    signed_url: `?jwt=${fetchWebcastToken()}`,
    baseurl: `${sessionRecording.CdnBaseUrl}/`,
    sessionRecording: true,
    id: `${sessionRecording.SessionId}`,
    shouldUseId: true
  };
};

export const sessionRecordingObjectMEvents = (session, webcastToken) => {
  const { title } = session ?? {};
  const { CdnBaseUrl, SessionId: id } = session.session_recording ?? {};

  return {
    title,
    exporttype: 'autoFolder',
    signed_url: `?jwt=${webcastToken}`,
    baseurl: `${CdnBaseUrl}/`,
    sessionRecording: true,
    id,
    shouldUseId: true
  };
};

export const apiStatusIsReady = (storeMask) => tmpGetState().data?.[storeMask]?.isReady;
export const apiStatusIsLoading = (storeMask) => tmpGetState().data?.[storeMask]?.isLoading;
export const apiStatusIsError = (storeMask) => tmpGetState().data?.[storeMask]?.isError;
