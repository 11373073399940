import { getAccessToken, getUserId } from '../../../loginInfo';
import { myNetworkingApiCall } from '../../myNetworkingApiUtils';
import { coerceToObj } from '../../myNetworkingUtils';
import { getEventId } from '../../../core';
import {
  markChannelMessageAsOpened,
  processChannelMessages
} from './channelMessagesOperations';
import { getUnopened } from './channelMessagesSelectors';

export const STORE_MASK_COMMUNITY_NOTIFICATIONS = 'myNetworking/notifications';
export const STORE_MASK_COMMUNIT_NOTIFICATIONS_PUT = 'myNetworking/notifications/put';

export const channelMessagesPath = '/v1/channel_messages.json';
const getPathname = (id) => `/v1/channel_messages/${id}.json`;

export const apiGetNotifications = async (props) => {
  // comment on user post or new post in a group (only groups that the user is member)
  const {
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce,
    refreshRate
  } = coerceToObj(props);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    message_type: 'User',
    channel_id: `User_${getUserId()}_${getEventId()}`,
    per_page: 20,
    sort_by: 'id desc'
  };

  return myNetworkingApiCall({
    method: 'GET',
    storeMask: STORE_MASK_COMMUNITY_NOTIFICATIONS,
    pathname: channelMessagesPath,
    body,
    onSuccessVmEventList,
    onErrorVmEventList,
    skipDebounce,
    refreshRate
  }).then((res) => processChannelMessages(res.data));
};

export const apiMarkChannelMessageAsOpened = async (props) => {
  const { channelMessageId, ...restProps } = coerceToObj(props);

  const body = {
    user_id: getUserId(),
    access_token: getAccessToken(),
    id: channelMessageId,
    opened: 1
  };

  return myNetworkingApiCall({
    method: 'PUT',
    storeMask: `${STORE_MASK_COMMUNIT_NOTIFICATIONS_PUT}_${channelMessageId}`,
    pathname: getPathname(channelMessageId),
    body,
    ...restProps
  }).then((res) => markChannelMessageAsOpened(res.data));
};

export const apiOpenAllMessages = async () => Promise.all(
  getUnopened().map((el) => {
    const { id } = el;
    const body = {
      user_id: getUserId(),
      access_token: getAccessToken(),
      id,
      opened: 1
    };

    return myNetworkingApiCall({
      method: 'PUT',
      storeMask: `${STORE_MASK_COMMUNIT_NOTIFICATIONS_PUT}_${id}`,
      pathname: getPathname(id),
      body,
      skipDebounce: true
    });
  })
).then(() => apiGetNotifications({ skipDebounce: true }));
