import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import vmFunctions from '../../global-utils/vmFunctions';
import {
  stylesPropType,
  componentsPropType,
  vmPropTypes
} from '../../global-prop-types';
import ComponentsArrRenderer from '../core/ComponentsArrRenderer';
import { useDispatchVmEventList } from '../../global-utils';

const JmeClampBox = (props) => {
  const {
    styles,
    variant,
    onWrappable,
    ...restProps
  } = props;
  const dispatchVmEventList = useDispatchVmEventList();
  const clampBoxRef = useRef(null);
  const store = useSelector((state) => state);

  useEffect(() => {
    const dataBank = { props, vmFunctions, store };
    const { clientHeight, scrollHeight } = clampBoxRef.current;

    if (scrollHeight > clientHeight) {
      dispatchVmEventList(onWrappable, dataBank);
    }
  }, [clampBoxRef.current]);

  return (
    <div
      className={variant ?? ''}
      ref={clampBoxRef}
      style={styles?.wrapper}
    >
      <ComponentsArrRenderer {...restProps} />
    </div>
  );
};

JmeClampBox.propTypes = {
  styles: stylesPropType,
  variant: PropTypes.string,
  components: componentsPropType,
  onWrappable: PropTypes.array
};

JmeClampBox.vmPreview = {
  components: [
    {
      componentName: 'JmeText',
      text: 'JmeClampBox display'
    }
  ]
};

JmeClampBox.vmPropTypes = {
  styles: { wrapper: vmPropTypes.styles },
  variant: vmPropTypes.className,
  components: vmPropTypes.components,
  onWrappable: vmPropTypes.array
};

export default JmeClampBox;
