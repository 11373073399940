import parser from 'ua-parser-js';
import { Logger as SeqLogger } from '../../temp/seq-logging';
import clientConf from './seq-logger-client-conf.json';

const logger = new SeqLogger(clientConf);

const uaString = window.navigator.userAgent;
const ua = parser(uaString);

export default {
  close: () => logger.close(),
  emit: ({ messageTemplate, level = 'Debug', properties }) => {
    if (window.location.href.includes('http://localhost')) {
      // disable logging from local developer env
      return;
    }
    const { vmRevolutionCoreValue } = window;
    logger.emit({
      timestamp: new Date(),
      level,
      messageTemplate,
      properties: {
        ...properties,
        ApplicationHost: window.location.host,
        RequestPath: window.location.href,
        Path: window.location.pathname,
        UserDevice: ua.device,
        UserBrowser: ua.browser,
        UserOS: ua.os,
        Client: vmRevolutionCoreValue.instance_title,
        AppEnvironment: vmRevolutionCoreValue.server_type,
      }
    });
  }
};
