import { componentStylesToCss } from '../componentStylesUtils';

const stylesProfileItems = (componentStyles, theme) => `
  .profileItems-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${componentStylesToCss(componentStyles?.wrapper)}
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .profileItems-wrapper {
      display: none;
    }
  }
  .profileItems-link {
    margin: 2px;
    text-decoration: none !important;
    ${componentStylesToCss(componentStyles?.link)}
  }
  .profileItems-link:hover {
  }
  .profileItems-link:visited {
    color: inherit;
  }
  .profileItems-link:link {
    color: inherit;
  }
  @media screen and (max-width: ${theme.breakpoints.values.md}${theme.breakpoints.unit}) {
    .profileItems-link {
      font-size: 12px;
      padding: 3px 8px;
    }
  }
  .profileItems-avatar {
    margin-left: 1rem;
    vertical-align: middle;
    width: 3.125px;
    height: 3.125px;
    border-radius: 50%;
    ${componentStylesToCss(componentStyles?.avatar)}
  }
`;

export default stylesProfileItems;
