import { apiRequest } from '../../../global-utils/api-request';
import {
  API_REQUEST_STARTED,
  API_REQUEST_ERROR,
  API_MANUAL_DATA_UPDATE,
  API_REQUEST_FINISHED,
  API_REQUEST_DELETE_DATA,
  API_REQUEST_RESET_ALL
} from '../constants';

export const apiRequestResetAll = () => ({
  type: API_REQUEST_RESET_ALL
});
export const apiRequestStarted = (parent) => ({
  type: API_REQUEST_STARTED,
  payload: { parent }
});
export const apiRequestError = (parent, err) => ({
  type: API_REQUEST_ERROR,
  payload: { parent, err }
});
export const apiManualDataUpdate = (parent, data) => ({
  type: API_MANUAL_DATA_UPDATE,
  payload: { parent, data }
});
export const apiRequestFinished = (parent, { data, headers }) => ({
  type: API_REQUEST_FINISHED,
  payload: { parent, data, headers }
});
export const apiRequestDeleteData = (parent) => ({
  type: API_REQUEST_DELETE_DATA,
  payload: { parent }
});

/* END OF DIFFERENT FILE */
export const apiRequestProcessor = (apiDataInfo = {}) => (dispatch, getState) => {
  const {
    propsMask,
    storeMask,
    body,
    method,
    headers,
    headersAppend,
    serverAddr,
    url,
    postProcessApiResponse,
    protocol
  } = apiDataInfo;
  const { core = {} } = getState().appState;

  dispatch(apiRequestStarted(storeMask));
  apiRequest({
    url: `${protocol ?? window.location.protocol}//${
      serverAddr ?? core.server_addr
    }${url}`,
    method,
    body,
    headers,
    headersAppend
  })
    .then((res) => postProcessApiResponse?.(res) ?? res)
    .then((res) => {
      dispatch(apiRequestFinished(propsMask, res));
      dispatch(apiRequestFinished(storeMask, res));
    })
    .catch((e) => {
      dispatch(apiRequestError(storeMask, e));
    });
};
