export const scrollY = () => window.scrollY;
export const scrollX = () => window.scrollX;
export const open = (href, target) => window.open(href, target)
// DEPRICATED!!!
// For window location functions we need to be able to get and set.
// To make some order please define new setters and getters in folder
// windowLocation
export const pathName = () => window.location.pathname;
export const location = () => window.location.pathname;
export const href = () => window.location.href;
export const search = () => window.location.search;
