import {
  useState, useEffect, useCallback, useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventId } from '../../global-utils/vmFunctions/core';
import { updateIsSocketOpen, updateIsSubscribed } from '../../global-state/redux/actions';
import { getUserId } from '../../global-utils/vmFunctions/loginInfo';
import { selectIsCommunicable } from '../../global-utils/vmFunctions/myNetworking/settings';

const useWebsocket = (
  baseApi,
  onNewMessage,
  channelName,
  maxAttemptsToConnect,
  reconnectInterval
) => {
  const [isSocketOpened, setIsSocketOpened] = useState(false);
  const [reconnectCounter, setReconnectCounter] = useState(0);
  const userId = getUserId();
  const dispatch = useDispatch();
  const isCommunicable = useSelector(selectIsCommunicable);

  const lastChannelRef = useRef(null);
  const timerReconnectInterval = useRef(null);
  const ws = useRef(null);

  const onSocketOpen = () => {
    setIsSocketOpened(true);
    dispatch(updateIsSocketOpen(true));
    clearInterval(timerReconnectInterval.current);
  };

  const onClose = () => {
    setIsSocketOpened(false);
    dispatch(updateIsSocketOpen(false));
    clearInterval(timerReconnectInterval.current);
    // increase every 15 sec
    timerReconnectInterval.current = setInterval(
      () => setReconnectCounter((prev) => prev + 1),
      (reconnectInterval || 15) * 1000
    );
  };

  useEffect(() => {
    if (isCommunicable && !ws.current && (reconnectCounter < (maxAttemptsToConnect || 10))) {
      // assign new Websocket only if we didn't do it already
      // and if we try to reconnect less then 6 times
      ws.current = new WebSocket(baseApi);
      ws.current.onopen = onSocketOpen;
      ws.current.onclose = onClose;
    }

    return () => {
      console.log('clean socket hook');
      if (
        ws.current
        && [WebSocket.CLOSED, WebSocket.CLOSING].indexOf(ws.current?.readyState) === -1
      ) {
        ws.current?.onclose();
      }
    };
  }, [baseApi, reconnectCounter, isCommunicable]);

  useEffect(() => {
    if (!ws.current && !isSocketOpened) return;
    const onMessage = async (e) => {
      const response = JSON.parse(e.data);
      if (response.type === 'confirm_subscription') {
        dispatch(updateIsSubscribed(true));
      }
      if (
        response.message === null
        || response.message === undefined
        || (typeof response.message === 'number'
        && Math.floor(response.message) === response.message)
      ) return;

      onNewMessage(response);
    };
    ws.current.onmessage = onMessage;
  }, [onNewMessage, isSocketOpened]);

  const unsubscribe = useCallback(
    (channel) => {
      if (isSocketOpened) {
        const sb = {
          command: 'unsubscribe',
          identifier: `{"channel": ${channelName}, "room":"api-lb_user_${userId}_${getEventId()}"}`,
        };
        lastChannelRef.current = channel;

        dispatch(updateIsSubscribed(false));
        ws.current.send(JSON.stringify(sb));
      }
    },
    [isSocketOpened, ws]
  );

  const subscribe = useCallback(
    (channel) => {
      if (isSocketOpened && userId) {
        if (lastChannelRef.current?.length > 0) {
          dispatch(updateIsSubscribed(false));
          unsubscribe(lastChannelRef.current);
        }
        const sb = {
          command: 'subscribe',
          identifier: `{"channel": "${channelName}", "room":"api-lb_user_${userId}_${getEventId()}"}`,
        };
        lastChannelRef.current = channel;
        ws.current.send(JSON.stringify(sb));
      }
    },
    [isSocketOpened, ws, unsubscribe, userId]
  );

  return {
    isSocketOpened, isCommunicable, subscribe, unsubscribe
  };
};

export default useWebsocket;
