import { strToSlug } from '../../vmString';
import { getChatgroupinvitations } from './chatgroupinvitations';
import { getChatgrouprequests } from './chatgrouprequests';
import { getChatgroups } from './chatgroups';

export const isNeutral = (groupIdStr) => {
  const groupId = parseInt(groupIdStr, 10);
  const chatgroups = getChatgroups();
  const chatgrouprequests = getChatgrouprequests();
  const chatgroupinvitations = getChatgroupinvitations();

  return (
    !chatgroups.some((el) => el.id === groupId)
    && !chatgrouprequests.some((el) => el.chatgroup.id === groupId)
    && !chatgroupinvitations.some((el) => el.chatgroup.id === groupId)
  );
};

export const filterNeutralGroups = (groupList) => groupList?.filter((el) => isNeutral(el.id));

export const getMyChatgroups = () => {
  const chatgroups = getChatgroups();
  const chatgrouprequests = getChatgrouprequests();
  const chatgrouprequestGroups = chatgrouprequests.map((el) => el.chatgroup);

  return [...chatgrouprequestGroups, ...chatgroups];
};

export const groupToSlug = (aGroup) => strToSlug(aGroup?.title);
