import { isMySelf, isNotMySelf } from '../../../loginInfo';
import { getMyNetworkingByKey } from '../../myNetworkingSelectors';
import { appendId } from '../../myNetworkingUtils';
import { hasChat } from '../chatUtils';
import { isUserInvited } from '../communityUserInvitations';
import { isUserRequested } from '../communityUserRequests';
import { COMMUNITYUSERS_SELECTOR_KEY } from './communityUserConstants';

// we need to add chat array to partner object, because many templates, which accept user object, expect also the
// information about user connections inside the aUser.chat[0] element, however information is not in chat.partner object:
const enchancePartnerByChat = (aChat) => {
  const { partner, ...restChat } = aChat;
  return { ...aChat, partner: { ...partner, chat: [restChat] } };
};

export const getCommunityUsers = () => getMyNetworkingByKey(COMMUNITYUSERS_SELECTOR_KEY)?.map(
  enchancePartnerByChat
) ?? [];

export const getCommunityUsersByGroupId = (groupId) => getMyNetworkingByKey(appendId(COMMUNITYUSERS_SELECTOR_KEY, groupId))?.map(
  enchancePartnerByChat
) ?? [];

export const getCommunityPartnerByPartnerId = (partnerId) => getCommunityUsers().find((el) => el.partner.id === partnerId);

export const isUserConnected = (partnerIdStr) => {
  const users = getCommunityUsers();
  const partnerId = parseInt(partnerIdStr, 10);
  return users.some((el) => el.partner.id === partnerId && hasChat(el));
};

export const userIsNeutral = (partnerId) => !isUserRequested(partnerId)
  && !isUserConnected(partnerId)
  && !isUserInvited(partnerId);

export const canSendMessage = (partnerId) => isNotMySelf(partnerId) && isUserConnected(partnerId);

export const canShowUserDetails = (partnerId) => isMySelf(partnerId) || isUserConnected(partnerId);

export const getPartnerList = () => getCommunityUsers().map((el) => el.partner);
export const getPartnerListByGroupId = (groupId) => getCommunityUsersByGroupId(groupId).map((el) => el.partner);

// This is tricky to manage this state.
// Updating user status on a card is tricky. We assume, that the priority in decidin about
// the partner stat is from myNetworking arrays, then from chat element of the user.
// if (noChat(chatObj)) return true;
// if (myselfDeleted(chatObj)) return true;
export const canSendRequestToConnect = (partnerId) => userIsNeutral(partnerId) && isNotMySelf(partnerId);
